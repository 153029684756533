import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const eventService = {
  getEvents: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_EVENTS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  updateEvent: async (event, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.EVENT_WITH_ID(event._id),
      requestBody: event,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  createEvent: async (event, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_EVENTS,
      requestBody: event,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateEvent: async (eventId, newEvent, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.EVENT_WITH_ID(eventId),
      requestBody: newEvent,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteEvent: async (event, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.EVENT_WITH_ID(event._id),
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
};
