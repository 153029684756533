import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/Header";
import { AppChoicesProvider } from "../context/AppChoicesContext";
import { AppointmentProvider } from "../context/AppointmentContext";
import { ContactAndDealPopupProvider } from "../context/ContactAndDealPopupContext";
import { WebSocketProvider } from "../context/WebSocketContext";
import { UsersProvider } from "../context/UsersContext";
import { ApointmentCalendarProvider } from "../context/ApointmentCalendarContext";
import { ActiveTrailAndFacebookCampaignProvider } from "../context/ActiveTrailAndFacebookCampaignContext";

const ProtectedRouteLayout = ({ headerVisible = true }) => {
  return (
    <>
      {headerVisible && <Header />}
      <AppChoicesProvider>
        <AppointmentProvider>
          <WebSocketProvider>
            <UsersProvider>
              <ContactAndDealPopupProvider>
                <ApointmentCalendarProvider>
                  <ActiveTrailAndFacebookCampaignProvider>
                    <Outlet />
                  </ActiveTrailAndFacebookCampaignProvider>
                </ApointmentCalendarProvider>
              </ContactAndDealPopupProvider>
            </UsersProvider>
          </WebSocketProvider>
        </AppointmentProvider>
      </AppChoicesProvider>
    </>
  );
};

export default ProtectedRouteLayout;
