import React, { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import { CustomEvent } from "../../common/CustomEvent";
import DraggableModal from "../../common/DraggableModal";
import AppointmentDetails from "../../common/AppointmentDetails";
import AppModal from "../../common/AppModal";

const ShowMoreAppointmentHeader = ({
  onCloseAppointmentDetail,
  detailMode,
  title,
}) => {
  return (
    <Modal.Header
      className=""
      style={{ cursor: "grab", height: 40 }}
      closeButton
    >
      {detailMode ? (
        <Button
          size="sm"
          className="px-1 py-0"
          variant="outline-dark"
          onClick={onCloseAppointmentDetail}
        >
          {" "}
          <ArrowLeft />
          <span className="mx-1 smallFont">Back</span>
        </Button>
      ) : (
        <span className="mid mx-1 fw-bold">{title}</span>
      )}
    </Modal.Header>
  );
};

const AppointmentList = ({ appointments = [], onAppointmentClick }) => (
  <div className="overflow-auto p-2" style={{ height: 350 }}>
    {appointments?.map((appointment, index) => {
      return (
        <div
          onClick={() => onAppointmentClick && onAppointmentClick(appointment)}
          className="mb-2 mx-1 hover"
          key={index}
        >
          <CustomEvent event={appointment} showFullDate />
        </div>
      );
    })}
  </div>
);

const ShowAppointmentModal = ({
  show,
  onHide,
  deal,
  showDealAppointmentMeta,
  onCloseAppointmentDetail,
  onAppointmentClick,
}) => {
  const { translate, isRTL } = useLocalization();

  const appointmentToShowDetail = useMemo(
    () => showDealAppointmentMeta?.activeAppointment,
    [showDealAppointmentMeta]
  );

  const appointments = useMemo(
    () => showDealAppointmentMeta?.appointments || [],
    [showDealAppointmentMeta]
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      <ShowMoreAppointmentHeader
        onCloseAppointmentDetail={onCloseAppointmentDetail}
        detailMode={Boolean(appointmentToShowDetail)}
        title={translate("viewing_appointment_of", {
          name: `${deal?.contact?.firstName || ""} ${
            deal?.contact?.lastName || ""
          }`,
        })}
        onHide={onHide}
      />
      <Modal.Body className="p-1">
        {" "}
        {!appointmentToShowDetail ? (
          <AppointmentList
            appointments={appointments}
            onAppointmentClick={onAppointmentClick}
          />
        ) : (
          <AppointmentDetails appointment={appointmentToShowDetail} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ShowAppointmentModal;
