import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import ClockInAndOutModal from "../components/contacts/employee-record/timing/ClockInAndOutModal";
import { timeLogService } from "../services/timeLogServices";

export const TimeTrackerContext = createContext();

export const TimeTrackerProvider = ({ children }) => {
  const { user, onUserChange } = useAuth();

  const [openTimeTrackerModal, setOpenTimeTrackerModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const activeTimeEntry = useMemo(() => user?.activeTimeEntry, [user]);

  const [elapsedTime, setElapsedTime] = useState(0);

  const [activeJob, setActiveJob] = useState(null);

  const clockIn = async (reqBody) => {
    try {
      setLoading(true);
      const { response, error } = await timeLogService.clockIn(reqBody);
      if (error) {
        return toast.error(error);
      }
      toast.success("Your session has started");
      onUserChange({ ...user, activeTimeEntry: response });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const clockOut = async () => {
    try {
      setLoading(true);
      const { response, error } = await timeLogService.clockOut();
      if (error) {
        return toast.error(error);
      }
      toast.success("Your session has ended");
      onUserChange({ ...user, activeTimeEntry: null });
      setElapsedTime(0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const openModal = () => {
    setOpenTimeTrackerModal(true);
  };
  const closeModal = () => {
    setOpenTimeTrackerModal(false);
  };

  const setActiveJobDetails = (job) => {
    setActiveJob(job);
  };

  const handleAutoClockOut = async () => {
    onUserChange({ ...user, activeTimeEntry: null });
    setElapsedTime(0);
    setActiveJob(null);
    toast.success("You were automatically clocked out by the system.");
  };

  useEffect(() => {
    if (activeTimeEntry) {
      const activeEntryTime = new Date(activeTimeEntry?.from).getTime();

      const expiryTime = new Date(activeTimeEntry?.clockOutExpiry).getTime();

      const updateElapsedTime = () => {
        const currentTime = new Date().getTime();
        const elapsed = currentTime - activeEntryTime;
        setElapsedTime(elapsed);
        if (elapsed >= expiryTime - activeEntryTime) {
          handleAutoClockOut();
        }
      };

      updateElapsedTime();
      const intervalId = setInterval(updateElapsedTime, 1000);

      return () => clearInterval(intervalId);
    } else {
      setElapsedTime(0);
    }
  }, [activeTimeEntry]);

  const contextObj = {
    activeTimeEntry,
    elapsedTime,
    clockIn,
    clockOut,
    loading,
    openModal,
    closeModal,
    activeJob,
    setActiveJobDetails,
  };

  return (
    <TimeTrackerContext.Provider value={contextObj}>
      {children}
      <ClockInAndOutModal show={openTimeTrackerModal} onHide={closeModal} />
    </TimeTrackerContext.Provider>
  );
};
