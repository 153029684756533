import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const dashboardService = {
  getStats: async (requestBody,signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_STATS,
      requestBody,
      signal
    });

    return { response, error };
  },
};
