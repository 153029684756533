import React, { useEffect, useRef, useState } from "react";
import useLocalization from "../../../hooks/useLocalization";
import { ENDPOINTS } from "../../../helpers/constants";

import DraggableModal from "../../common/DraggableModal";

const playbackRates = [
  0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0,
];

const CallDetailModal = ({ show, onHide, callInfo }) => {
  const { translate } = useLocalization();

  const audioRef = useRef(null);
  const [playbackRate, setPlaybackRate] = useState(1.0);

  useEffect(() => {
    if (audioRef.current && audioRef.current.playbackRate) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
  };

  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      title={translate("call_recording")}
      height={120}
      width={{
        sm: "90%",
        md: 600,
        lg: 600,
      }}
    >
      {callInfo && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="position-relative w-100">
            <audio
              className="rounded"
              controls
              ref={audioRef}
              autoPlay
              style={{ height: "60px", width: "100%" }}
            >
              <source
                src={`${process.env.REACT_APP_API_URL}${ENDPOINTS.GET_CALL_RECORDING_STREAM}?audioLink=${callInfo?.recordingLink}`}
                phoneType="audio/mpeg"
                style={{ size: "10px" }}
              />
              Your browser does not support the audio element.
            </audio>
            <div
              className="position-absolute d-flex align-items-center rounded-end-5"
              style={{
                right: "0px",
                top: "0px",
                height: "60px",
                minWidth: "70px",
              }}
            >
              <select
                className="border-0"
                style={{
                  background: "#f1f3f4",
                  height: "40px",
                  outline: "none",
                }}
                onChange={(e) =>
                  handlePlaybackRateChange(parseFloat(e.target.value))
                }
                value={playbackRate}
              >
                {playbackRates.map((rate) => (
                  <option key={rate} value={rate}>
                    {rate}x
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </DraggableModal>
  );
};

export default CallDetailModal;
