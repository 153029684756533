import { useContext } from "react";
import { ActiveTrailAndFacebookCampaignContext } from "../context/ActiveTrailAndFacebookCampaignContext";

const useActiveTrailAndFacebookCampaign = () => {
  const {
    facebookCampaigns,
    activeTrailLists,
    fetchingActiveTrailAndCampaigns,
    newlyCreatedActiveTrail,
    setNewlyCreatedActiveTrail,
    handleCreateNewTrailGroup,
  } = useContext(ActiveTrailAndFacebookCampaignContext);

  return {
    facebookCampaigns,
    activeTrailLists,
    fetchingActiveTrailAndCampaigns,
    newlyCreatedActiveTrail,
    setNewlyCreatedActiveTrail,
    handleCreateNewTrailGroup,
  };
};

export default useActiveTrailAndFacebookCampaign;
