import React, { useEffect } from "react";
import useActiveTrailAndFacebookCampaign from "../../../hooks/useActiveTrailAndFacebookCampaign";
import CreatableSelect from "../../common/CreatableSelect";

const ActiveTrailDropdown = ({ fieldInfo, value = [], onChange }) => {
  const {
    activeTrailLists,
    handleCreateNewTrailGroup,
    newlyCreatedActiveTrail,
    setNewlyCreatedActiveTrail,
  } = useActiveTrailAndFacebookCampaign();

  useEffect(() => {
    if (newlyCreatedActiveTrail) {
      const { fieldInfo: fInfo, activeTrail } = newlyCreatedActiveTrail;
      if (fInfo === fieldInfo && activeTrail?.id) {
        onChange([activeTrail.id, ...value]);
        setNewlyCreatedActiveTrail(null);
      }
    }
  }, [newlyCreatedActiveTrail, fieldInfo]);

  return (
    <>
      <CreatableSelect
        items={activeTrailLists.map((aT) => ({
          label: aT.name,
          value: aT.id,
        }))}
        isMulti
        selectedItems={value}
        height="30px"
        onChange={onChange}
        placeholder="Select"
        onCreateOption={(name) =>
          handleCreateNewTrailGroup({ fieldInfo, name })
        }
      />
    </>
  );
};

export default ActiveTrailDropdown;
