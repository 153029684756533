import React from "react";
import { ProgressBar } from "react-bootstrap";

const HorizontalProgress = ({ className = "my-2", text }) => {
  return (
    <div className={className}>
      <ProgressBar striped animated variant="dark" now={100} label={text} />
    </div>
  );
};

export default HorizontalProgress;
