import React from "react";
import { Button } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import InfiniteScrollList from "../../common/InfiniteScroll";
import MeetingCard from "../../common/MeetingCard";
import CircularProgressBar from "../../common/circular-progress";
import Loader from "../../common/Loader";

const SearchAppointment = ({
  collapseSidebar,
  closeSearch,
  searchingMoreAppointments,
  searchingForTheFirstTime,
  appointments,
  onAppointmentClick,
  onNavigateToDate,
  onBottomReached,
}) => {
  const { isRTL, translate } = useLocalization();

  return (
    <div className="border border-top-0">
      <div
        style={{ padding: "10px 8px 8px 8px" }}
        className={`position-sticky border-bottom d-flex gap-2 flex-wrap align-items-center`}
      >
        {isRTL ? (
          <ArrowRightCircleFill
            size={20}
            className="hover"
            onClick={closeSearch}
          />
        ) : (
          <ArrowLeftCircleFill
            size={20}
            className="hover"
            onClick={closeSearch}
          />
        )}

        <h6 className="mb-0 fw-bold">Searched appointments</h6>
      </div>
      <div style={{ height: `calc(100vh - 80px)` }} className=" overflow-auto">
        {searchingForTheFirstTime ? (
          <div className="px-2">
            <Loader />
          </div>
        ) : (
          <div className="position-relative">
            {appointments?.length < 1 && (
              <div className="p-2">
                {" "}
                <h6 className="mid">0 results found</h6>
              </div>
            )}
            <InfiniteScrollList
              items={appointments}
              onReachBottom={onBottomReached}
              renderItem={(app) => (
                <MeetingCard
                  appointment={app}
                  onAppointmentClick={onAppointmentClick}
                  onNavigateToDate={onNavigateToDate}
                />
              )}
            />
            {searchingMoreAppointments && (
              <div className="position-absolute bottom-0 w-100 bg-white border-top py-4 d-flex justify-content-center align-items-center">
                <CircularProgressBar size={16} />
                <h6 className="mx-2 mb-0 smallFont text-muted fw-bold">
                  {translate("please_wait")}
                </h6>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchAppointment;
