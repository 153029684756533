import React from "react";
import useLocalization from "../../../hooks/useLocalization";

const FinancialStatBoard = ({fetching}) => {
  const { translate } = useLocalization();

  return (
    <div>
      Todo
    </div>
  );
};

export default FinancialStatBoard;
