import React from "react";

const RippleEffect = ({ size }) => {
  return (
    <div className="ripple-container" style={{ width: size, height: size }}>
      <div
        className="ripple bg-warning"
        style={{ width: size, height: size }}
      ></div>
    </div>
  );
};

export default RippleEffect;
