import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PublicRoute = ({ checkLogin = true }) => {
  const { isUserLoggedIn } = useAuth();

  if (checkLogin && isUserLoggedIn) {
    return <Navigate to="/contacts" />;
  }

  return <Outlet />;
};

export default PublicRoute;
