import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import ProductStageForm from "../forms/ProductStageForm";

const ProductStageModal = ({
  show,
  productStageModalMeta,
  onHide,
  productionStages,
  onEditProductionStageSubmit,
  onNewProductionStageSubmit,
  onDeleteProductionStepClick,
  onProductStepDragAndDrop,
  newProductionStage,
  setNewProductionStage,
  setEditingProductionStage,
  editingProductionStage,
}) => {
  const { translate } = useLocalization();
  return (
    <AppModal
      size={"sm"}
      show={show}
      onHide={onHide}
      title={translate(`production_stage`)}
      // showCloseButton={!showProgress}
    >
      <ProductStageForm
        productStageModalMeta={productStageModalMeta}
        productionStages={productionStages}
        onEditProductionStageSubmit={onEditProductionStageSubmit}
        onNewProductionStageSubmit={onNewProductionStageSubmit}
        onDeleteProductionStepClick={onDeleteProductionStepClick}
        onProductStepDragAndDrop={onProductStepDragAndDrop}
        newProductionStage={newProductionStage}
        setNewProductionStage={setNewProductionStage}
        setEditingProductionStage={setEditingProductionStage}
        editingProductionStage={editingProductionStage}
      />
    </AppModal>
  );
};

export default ProductStageModal;
