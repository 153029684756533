import React, { useMemo, useState } from "react";
import { getPhoneCallTableColumns } from "../../../helpers/dataSheetConstants";
import DataTable from "../../common/data-table/DataTable";
import CallDetailModal from "./CallDetailModal";

const PhoneCalls = ({ contact = [], columnToHide }) => {
  const { phoneCalls } = contact;
  const [callDetailModalMeta, setCallDetailModalMeta] = useState(null);

  const onPlayClick = (callInfo) => {
    setCallDetailModalMeta(callInfo);
  };
  const tableColumns = useMemo(
    () => getPhoneCallTableColumns(onPlayClick, columnToHide),
    [onPlayClick, columnToHide]
  );

  return (
    <div className="" style={{ maxHeight: "500px", overflowY: "auto" }}>
      <DataTable
        maxTableHeight={`150px`}
        rowKey={"_id"}
        columns={tableColumns}
        data={phoneCalls}
        allowSort={false}
        allowFilter={false}
      />
      {callDetailModalMeta && (
        <CallDetailModal
          show={Boolean(callDetailModalMeta)}
          onHide={() => setCallDetailModalMeta(null)}
          callInfo={callDetailModalMeta}
        />
      )}
    </div>
  );
};

export default PhoneCalls;
