import React, { useEffect, useMemo, useRef } from "react";
import CircularProgressBar from "../../common/circular-progress";
import useLocalization from "../../../hooks/useLocalization";
import OverViewColumns from "../../common/overview-columns/OverViewColumns";
import { Check, XLg } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import useAppChoices from "../../../hooks/useAppChoices";
import { newContactField } from "../../../helpers/constants";
import { ABORT_ERROR } from "../../../helpers/api";
import { referralService } from "../../../services/referralServices";
import useAuth from "../../../hooks/useAuth";
import useContactStatus from "../../../hooks/useContactStatus";

const NewContactCard = ({
  tags,
  contact,
  onContactChange,
  onHideNewContact,
  addingNewContact,
  onSaveNewContact,
  onCreateNewReferralClick,
  referrals = [],
  createdReferral,
}) => {
  const { GROUPPED_STATUS } = useContactStatus();
  const users = useAppChoices("users");
  const { translate } = useLocalization();
  const abortControllerRef = useRef(null);
  const { user } = useAuth();
  const searchReferral = async (query) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    let reqBody = {
      query,
    };

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    let { response, error } = await referralService.getReferrals(
      reqBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    let { results = [] } = response;
    return results?.map((r) => ({ label: r?.name, value: r?._id, ...r }));
  };

  const newContactFields = useMemo(
    () =>
      newContactField({
        users,
        tags,
        translate,
        referrals,
        onCreateNewReferralClick,
        searchReferral,
        user,
        GROUPPED_STATUS,
      }),
    [users, tags, translate, referrals, user, GROUPPED_STATUS]
  );

  useEffect(() => {
    if (createdReferral) {
      onContactChange({ ...contact, referredBy: createdReferral });
    }
  }, [createdReferral]);

  return (
    <div className={`border m-1`}>
      <OverViewColumns
        parentContainerClassName="mid m-0 p-2 bg-light-gray"
        inputCellStyle={{ fontSize: 14 }}
        cellSizeMD={2}
        data={contact}
        onDataChange={(newData) => {
          onContactChange({ ...contact, ...newData });
        }}
        fields={newContactFields}
        isUpdating={addingNewContact}
        alwaysInEditMode={false}
        editable={true}
      />
      <div className="d-flex justify-content-end align-items-center bg-gray border-top border-secondary-dark px-1 py-2">
        <div className="d-flex">
          <Button
            variant="danger"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={addingNewContact}
            onClick={onHideNewContact}
          >
            <span className="">
              {translate("cancel")} <XLg size={12} />
            </span>
          </Button>
          <Button
            variant="success"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={addingNewContact}
            onClick={onSaveNewContact}
          >
            {addingNewContact ? (
              <span className="mx-1 px-1 text-white">
                <CircularProgressBar size={12} />
                <span className="mx-1 smallFont">
                  {translate("please_wait")}
                </span>
              </span>
            ) : (
              <span className="">
                {translate("save")} <Check size={15} />
              </span>
            )}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default NewContactCard;
