import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import AddEditContractForm from "../forms/AddEditContractForm";
import AddEditProductForm from "../forms/AddEditProductForm";

const AddEditProductsModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={
        editMode ? translate(`update_product`) : translate(`add_new_product`)
      }
      showCloseButton={!showProgress}
    >
      <AddEditProductForm
        initialValues={initialValues}
        showProgress={showProgress}
        editMode={editMode}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default AddEditProductsModal;
