import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const appConfigurationService = {
  getAppointmentDefalutType: async () => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_APPOINTMENT_DEFAULT,
      requestBody: {past: true},
      method: "POST",
    });

    return { response, error };
  },
  getAppointmentsFromPast: async() => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_APPOINTMENTS_DEFAULT_PAST,
      requestBody: {},
      method: "POST",
    });

    return { response, error };
  },

  updateAppointmentDefaultType: async (appointment, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_APPOINTMENT_DEFAULT(appointment?._id),
      requestBody: appointment,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
};
