import React, { KeyboardEventHandler, useCallback, useMemo } from "react";
import { components } from "react-select";
import CS from "react-select/creatable";

const CreatableSelect = ({
  items,
  onChange,
  selectedItems = [],
  isMulti,
  height = "30px",
  closeMenuOnSelect = false,
  closeMenuOnScroll = true,
  isClearable = false,
  disabled,
  separator,
  maxToShow = 100,
  formatCreateLabel = (inputValue) => `Add '${inputValue}'`,
  onCreateOption,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = useCallback(
    (selectedOptions) => {
      onChange(
        isMulti
          ? selectedOptions.map((option) => option.value)
          : selectedOptions?.value
      );
    },
    [onChange, isMulti, items]
  );

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (onCreateOption) {
          onCreateOption(inputValue);
        } else {
          onChange(isMulti ? [...selectedItems, inputValue] : inputValue);
        }
        setInputValue("");
        event.preventDefault();
    }
  };

  const value = useMemo(() => {
    return (
      Array.isArray(selectedItems) ? selectedItems : [selectedItems]
    )?.map((item) => {
      const existingOption = items.find(
        (o) => o.value === item || o._id === item
      );
      return {
        label: existingOption?.label || item,
        value: existingOption?.value || item,
      };
    });
  }, [items, selectedItems]);

  const MultiValue = ({ index, getValue, ...props }) => {
    const values = getValue();
    const overflow = values.slice(maxToShow).map((x) => x.label);

    // Adding the separator logic
    const shouldShowSeparator = isMulti && separator && index > 0;

    return index < maxToShow ? (
      <>
        {shouldShowSeparator && <span className="mx-1 tiny">{separator}</span>}
        <components.MultiValue {...props} />
      </>
    ) : index === maxToShow ? (
      <MoreSelectedBadge
        items={showMessageOnlyOnOverflow ? values : overflow}
      />
    ) : null;
  };

  return (
    <CS
      components={{
        MultiValue,
      }}
      formatCreateLabel={formatCreateLabel}
      isDisabled={disabled}
      isClearable={isClearable}
      value={value}
      inputValue={inputValue}
      options={items}
      isMulti={isMulti}
      onChange={handleChange}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      onCreateOption={onCreateOption}
      placeholder=""
      createOptionPosition="first"
      className="basic-multi-select"
      classNamePrefix="select"
      closeMenuOnSelect={closeMenuOnSelect}
      closeMenuOnScroll={closeMenuOnScroll}
      menuPlacement="bottom"
      styles={{
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#ced9d9",
          minHeight: height,
          fontSize: 12,
        }),
        menuList: (provided, state) => ({
          ...provided,
          fontSize: "12px",
          maxHeight: "200px",
          overflowY: "auto",
          textWrap: "wrap",
          zIndex: 999999999,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          minHeight: height,
          padding: "0px 5px",
          marginTop: "0px",
        }),
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
          padding: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          maxHeight: 20,
          margin: "auto 0px",
          padding: "0px",
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          width: "fit-content",
          padding: 0,
        }),
        multiValue: (provided, state) => ({
          ...provided,
          fontSize: "12px",
          height: "20px",
        }),
        placeholder: (provided, state) => ({
          ...provided,
          fontSize: "12px",
          margin: "1px 1px",
        }),
        menuPortal: (provided, state) => ({
          ...provided,
          zIndex: 999999999,
          minWidth: "80px",
        }),
      }}
    />
  );
};

export default CreatableSelect;
