import React, { useEffect, useState } from "react";
import AppModal from "../common/AppModal";
import { logsService } from "../../services/logsService";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import AtomicAction from "./AtomicAction";

const LogDetailsModal = ({ show, onHide, log, onActionClick }) => {
  const [logDetails, setLogDetails] = useState(null);
  const [fetchingLogDetails, setFetchingLogDetails] = useState(true);

  useEffect(() => {
    if (!show && !log) return;
    const fetchLog = async () => {
      const reqBody = {
        filter: [{ key: "_id", value: log._id }],
        populate: true,
      };
      setFetchingLogDetails(true);
      const { response, error } = await logsService.getLogs(reqBody);

      if (error) {
        return toast.error(error);
      }
      const logDetail = response?.results[0];
      setLogDetails(logDetail);
      setFetchingLogDetails(false);
    };
    fetchLog();
  }, [show, log]);

  return (
    <AppModal
      title={"Log details"}
      show={show}
      onHide={onHide}
      size={"lg"}
      modalBodyClassName="p-2"
    >
      <div style={{ maxHeight: "80vh" }} className="overflow-auto">
        {fetchingLogDetails ? (
          <Loader />
        ) : (
          <div>
            {logDetails?.atomicActions?.map((action) => {
              return (
                <AtomicAction
                  key={action._id}
                  action={action}
                  onActionClick={onActionClick}
                  log={log}
                />
              );
            })}
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default LogDetailsModal;
