import React from "react";
import { Table } from "react-bootstrap";
import "./AppTable.scss";

const AppTable = ({
  children,
  striped = false,
  className = "smallFont mt-2",
  hover = true,
}) => {
  return (
    <Table
      bordered
      striped={striped}
      responsive
      hover={hover}
      className={className}
    >
      {children}
    </Table>
  );
};

export default AppTable;
