import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import AddEditTagForm from "../../forms/AddEditTagForm";

const AddEditTagModal = ({
  show,
  initialValues,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={"sm"}
      show={show}
      onHide={onHide}
      title={initialValues?._id ?  translate(`update_tag`) :translate(`add_tag`)}
      showCloseButton={!showProgress}
    >
      <AddEditTagForm
        initialValues={initialValues}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default AddEditTagModal;
