import { isNumber } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PlusCircle, Trash } from "react-bootstrap-icons";
import { swap } from "react-grid-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useContact } from "../../context/ContactContext";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { ABORT_ERROR, makeRESTApiRequests } from "../../helpers/api";
import {
  CALENDAR_QS,
  DEFAULT_PAGE_SIZE,
  ENDPOINTS,
  SALES_ROLE,
  USER_RECORD_QS,
} from "../../helpers/constants";
import { getContactTableColumns } from "../../helpers/dataSheetConstants";
import {
  NEW_CONTACT_DEFAULT_FORM_VALUES,
  downloadFileFromString,
  updateItemsInArray,
} from "../../helpers/global";
import { isAdminOrManager } from "../../helpers/session";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { contactService } from "../../services/contactService";
import { filterServices } from "../../services/filterServices";
import AlertModal from "../common/AlertModal";
import FloatingButton from "../common/FloatingButton";
import DataTable from "../common/data-table/DataTable";
import SearchBox from "../common/searchbox";
import ContactActions from "./ContactActions";
import ConfirmMergeContactModal from "./ConfirmMergeContactModal";
import FilterSideBar from "./FilterSideBar";
import AppointmentModal from "./contact-details/AppointmentModal";
import NewContactCard from "./contact-details/NewContactCard";
import EmployeeRecordModal from "./employee-record/EmployeeRecordModal";
import useSelection from "../../hooks/useSelection";
import SendWhatsappCampaignModal from "./SendWhatsappCampaignModal";
import { whatsappCampaignService } from "../../services/whatsappCampaignService";
import useApointmetnCalendar from "../../hooks/useApointmetnCalendar";
import useContactStatus from "../../hooks/useContactStatus";
// import useWebSocket from "../../hooks/useWebSocket";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

export const findIfObjectIsInArrayOrNot = (array, object, key = "_id") => {
  return array?.find((c) => c?.[key] === object?.[key]) || false;
};

const defaultTableSortOption = {
  key: "lastInboundDate",
  order: "desc",
};

const BrowseContacts = () => {
  const { message } = useWebSocket();

  const navigate = useNavigate();
  const location = useLocation();
  const abortControllerRef = useRef(null);
  const duplicateAbortControllerRef = useRef(null);

  const users = useAppChoices("users");
  const tags = useAppChoices("tags");
  const groups = useAppChoices("groups");

  const inboundCampaignUrlOptions = useAppChoices("inboundCampaignUrlOptions");

  const { contactId, dealId, view } = useQueryParams();

  const { user } = useAuth();
  const { screenWidth } = useScreenWidth();
  const mobileView = useMemo(() => screenWidth <= 992, [screenWidth]);
  const { translate } = useLocalization();
  const {
    openContactPopup,
    hideContactPopup,
    updatedContact,
    onCreateNewReferralClick,
    referrals = [],
    createdReferral,
    followupDateChangeInfo,
    mergeContactModalMeta,
    setMergeContactModalMeta,
  } = useContactAndDealPopup();
  const { openApointmentCalendar } = useApointmetnCalendar();
  const { searchBoxOptions, setIsSearchBoxDisabled, isSearchBoxDisabled } =
    useSearchAndFilterBox();

  const { addContactMeta, setAddContactMeta } = useContact();
  const [fetchingFirstPageContacts, setFetchingFirstPageContacts] =
    useState(false);
  const [fetchingMoreContacts, setFetchingMoreContacts] = useState(false);
  //original contacts array
  const [contacts, setContacts] = useState([]);
  const [lastUpdatedContact, setLastUpdatedContact] = useState(null);

  const [toLoadPageInfo, setToLoadPageInfo] = useState();
  const [loadedPageInfo, setLoadedPageInfo] = useState();
  const [alertModalMeta, setAlertModalMeta] = useState(null);
  const [deleteModalMeta, setDeleteModalMeta] = useState(null);
  const [archiveModalMeta, setArchiveModalMeta] = useState(null);
  const [sendWhatsappCampaignModalMeta, setSendWhatsappCampaignModalMeta] =
    useState(null);
  const [textToHighlight, setTextToHighlight] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editMultipleContactMeta, setEditMultipleContactMeta] = useState(null);

  const [showAllFields, setShowAllFields] = useState(false);

  const [confirmCreateNewContactMeta, setConfirmCreateNewContactMeta] =
    useState(null);

  const [tableScrollTop, setTableScrollTop] = useState();

  const {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(contacts);

  const { GROUPPED_STATUS, getContactStatus } = useContactStatus();

  const tableColumns = useMemo(
    () =>
      getContactTableColumns({
        selectAllMode,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        inboundCampaignUrlOptions,
        textToHighlight,
        translate,
        showAllFields,
        users,
        groups,
        getContactStatus,
        GROUPPED_STATUS,
      }),
    [
      showAllFields,
      selectAllMode,
      onToggleSelectAll,
      onToggleSelectItem,
      isItemSelected,
      inboundCampaignUrlOptions,
      textToHighlight,
      translate,
      users,
      groups,
      getContactStatus,
      GROUPPED_STATUS,
    ]
  );

  const scrollToContactRow = (scrollTop) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const onContactClick = (contact) => {
    openContactPopup(contact?._id);
  };

  const [tableSortOption, setTableSortOption] = useState(
    defaultTableSortOption
  );
  const [maxLimit, setMaxLimit] = useState(null);
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [salesPersonFilter, setSalesPersonFilter] = useState({ others: [] });
  const [customFilterMeta, setCustomFilterMeta] = useState(null);
  const [filterDeleteModalMeta, setFilterDeleteModalMeta] = useState(null);
  const [customFilter, setCustomeFilter] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);
  const [showContactWithFollowUpDue, setShowContactWithFollowUpDue] =
    useState(false);

  const [showArchivedContact, setShowArchivedContact] = useState(false);
  const [updatingFilterOrder, setUpdatingFilterOrder] = useState(false);

  // employee record
  const [employeeRecordModalMeta, setEmployeeRecordModalMeta] = useState(false);

  // view duplicate contacts
  const [showDuplicatesOnly, setShowDuplicatesOnly] = useState(false);
  const [confirmAutoMerge, setConfirmAutoMerge] = useState(null);

  const presetFilterToUse = useMemo(() => {
    return activeFilter ? activeFilter?.map((f) => f?._id) : [];
  }, [activeFilter]);

  const customFilterToUse = useMemo(() => {
    return customFilter?.filterParsed ?? "";
  }, [customFilter]);

  const salesPersonFilterToUse = useMemo(() => {
    const { salesperson, others } = salesPersonFilter;
    if (salesperson === "unassign") return "unassign";
    if (!salesperson && others.length > 0) return others;
    if (!salesperson) return undefined;
    return [...salesperson, ...others];
  }, [salesPersonFilter]);

  const allContactFilters = useMemo(() => {
    const { query } = searchBoxOptions;
    return {
      query,
      sort: tableSortOption,
      filter: tableFilterValues,
      archived: showArchivedContact,
      presetFilterToUse,
      customFilterToUse,
      salesPersonFilter: salesPersonFilterToUse,
      showContactWithFollowUpDue,
      showDuplicatesOnly,
      maxLimit,
    };
  }, [
    tableFilterValues,
    salesPersonFilterToUse,
    showDuplicatesOnly,
    showContactWithFollowUpDue,
    customFilterToUse,
    presetFilterToUse,
    searchBoxOptions,
    tableSortOption,
    showArchivedContact,
    maxLimit,
  ]);

  const shouldRenderClearFilterButton = useMemo(() => {
    return (
      activeFilter?.length > 0 ||
      customFilter ||
      tableFilterValues?.length > 0 ||
      showContactWithFollowUpDue
    );
  }, [
    activeFilter,
    customFilter,
    tableFilterValues,
    showContactWithFollowUpDue,
  ]);

  const onFilterSelect = (filter) => {
    setCustomeFilter(null);
    setActiveFilter(filter);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setCustomFilterMeta(null);
  };

  const fetchFilters = async () => {
    const { response, error } = await filterServices.getFilters();
    if (error) {
      return toast.error(error);
    }
    if (response) {
      setFilterData(response);
    }
  };

  const fetchContacts = async () => {
    setIsFetchingContacts(true);

    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      setIsFetchingContacts(false);
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const { query } = searchBoxOptions;
    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;

    const loadingMoreContacts = pageToFetch > 1;
    if (loadingMoreContacts) {
      setFetchingMoreContacts(true);
    } else {
      if (!isNotInitialFetch) {
        setIsSearchBoxDisabled(true);
        setFetchingFirstPageContacts(true);
      }
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      ...allContactFilters,
    };

    const { response, error } = await contactService.getContacts(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreContacts(false);
    setFetchingFirstPageContacts(false);
    setIsSearchBoxDisabled(false);

    if (error) {
      setIsFetchingContacts(false);
      toast.error(error);
      return;
    }

    if (isNumber(toLoadPageInfo?.scrollTop)) {
      scrollToContactRow(toLoadPageInfo?.scrollTop);
    }

    const {
      pageNumber: pgNumber,
      totalPages,
      resultCount,
      results,
      contactFollowUpDueCount = [],
      searchTerms,
    } = response;

    if (!isNotInitialFetch) {
      clearSelection();
    }

    setTextToHighlight([query]);

    setLoadedPageInfo({
      totalPages,
      pageNumber: pgNumber,
      resultCount,
      contactFollowUpDueCount,
    });

    setContacts((prevContacts) => {
      const newArray = loadingMoreContacts
        ? [...prevContacts, ...results]
        : results;

      return newArray;
    });
    setTextToHighlight(searchTerms || query?.trim().split(" ") || []);
    setIsFetchingContacts(false);
  };

  const fetchContactsAfterUpdate = (scrollTop) => {
    // fetches contacts and scrolls back to previous position
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      scrollTop,
      isNotInitialFetch: true,
    });
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageContacts || fetchingMoreContacts)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      scrollTop: undefined,
      isNotInitialFetch: true,
    });
  };

  const onContactChange = (contact, removeObjID) => {
    setContacts((prevContacts) => {
      const updatedContacts = updateItemsInArray(prevContacts, contact);

      return removeObjID
        ? updatedContacts?.filter((c) => c?._id !== removeObjID)
        : updatedContacts;
    });
  };

  const downloadContractedBuyersCSV = async () => {
    const requestBody = {
      ...allContactFilters,
      timezoneOffset: new Date().getTimezoneOffset(),
    };

    toast.info("File will be downloaded shortly...");
    setIsDownloading(true);
    const { response, error } = await makeRESTApiRequests({
      requestBody,
      endpoint: ENDPOINTS.CONTACTS_DOWNLOAD_CSV,
      method: "POST",
    });
    setIsDownloading(false);

    if (error) {
      toast.error(error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: "Contacts.csv" });
  };

  const resetFilter = () => {
    setShowContactWithFollowUpDue(false);
    // setIsClearingFilter(true);
    setActiveFilter([]);
    setCustomeFilter(null);
    setTableFilterValues([]);
    // setSearchBoxOptions({});
    setTableSortOption({
      key: "lastInboundDate",
      order: "desc",
    });
  };

  const onAddContactSubmit = async () => {
    const { contact } = addContactMeta;
    setAddContactMeta((addContactMeta) => ({
      ...addContactMeta,
      showProgress: true,
    }));
    toast.info("Saving contact...");

    const { response, error } = await contactService.createContact({
      contactData: contact,
    });

    setAddContactMeta((addContactMeta) => ({
      ...addContactMeta,
      showProgress: false,
    }));

    if (error) {
      return toast.error(error);
    }
    if (response?.duplicateContactFound) {
      setConfirmCreateNewContactMeta({
        duplicateContacts: response?.duplicates,
        baseContact: response?.newContact,
        type: "new",
      });
      return;
    }
    onContactChange(response);
    setAddContactMeta(null);
    toast.success("Contact added successfully!");
  };

  const onCreateDuplicateContactSubmit = async (contact) => {
    setConfirmCreateNewContactMeta((addContactMeta) => ({
      ...addContactMeta,
      showProgress: true,
    }));
    const { response, error } = await contactService.createContact({
      contactData: contact,
      allowDuplicate: true,
    });

    setConfirmCreateNewContactMeta((addContactMeta) => ({
      ...addContactMeta,
      showProgress: false,
    }));

    if (error) {
      return toast.error(error);
    }
    setAddContactMeta(null);
    setConfirmCreateNewContactMeta(null);
    fetchContactsAfterUpdate();
    toast.success("Contact added successfully!");
  };

  const onUpdateManyContactSubmit = async ({
    contactUpdates,
    fieldsToBeAdded = [],
  }) => {
    let reqBody = {
      selectAllMode,
      selectedContacts: selectedItems?.map((c) => c._id),
      unselectedContacts: unselectedItems.map((c) => c._id),
      contactUpdates,
      fieldsToBeAdded,
      filter: allContactFilters,
    };

    try {
      toast.info("Updating contact...");
      setEditMultipleContactMeta((editMultipleContactMeta) => ({
        ...editMultipleContactMeta,
        updatingField: Object.keys(contactUpdates)[0],
      }));
      const { response, error } = await contactService.updateManyContacts(
        reqBody
      );
      if (response) {
        fetchContactsAfterUpdate(tableScrollTop);
        toast.success(`Contact updated successfully!`);
      }

      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEditMultipleContactMeta((editMultipleContactMeta) => ({
        ...editMultipleContactMeta,
        updatingField: null,
      }));
    }
  };

  const onDeleteSelectedContactClick = () => {
    setDeleteModalMeta({
      contactToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const onArchiveSelectedContactClick = () => {
    setArchiveModalMeta({
      archive: true,
      showProgress: false,
    });
  };

  const onUnArchiveSelectedContactClick = () => {
    setArchiveModalMeta({
      archive: false,
      showProgress: false,
    });
  };

  const onSendWhatsappCampaignClick = () => {
    setSendWhatsappCampaignModalMeta({
      showProgress: false,
    });
  };

  const sendWhatsappCampaign = async (allSchedules) => {
    setSendWhatsappCampaignModalMeta({
      ...sendWhatsappCampaignModalMeta,
      showProgress: true,
    });

    let requestBody = {
      selectAllMode,
      selectedContacts: selectedItems?.map((c) => c._id),
      unselectedContacts: unselectedItems.map((c) => c._id),
      allSchedules,
      filter: allContactFilters,
    };

    let { response, error } =
      await whatsappCampaignService.createWhatsappCampaign(requestBody);
    if (error) {
      toast.error(error);
      setSendWhatsappCampaignModalMeta({
        ...sendWhatsappCampaignModalMeta,
        showProgress: false,
      });
      return;
    }
    setSendWhatsappCampaignModalMeta(null);
  };

  const handleArchiveContactSubmit = async () => {
    const { archive } = archiveModalMeta;

    setArchiveModalMeta((archiveModalMeta) => ({
      ...archiveModalMeta,
      showProgress: true,
    }));

    let contactUpdates = {
      archived: archive,
    };

    let reqBody = {
      selectAllMode,
      selectedContacts: selectedItems?.map((c) => c._id),
      unselectedContacts: unselectedItems.map((c) => c._id),
      contactUpdates,
      filter: allContactFilters,
    };

    try {
      const { response, error } = await contactService.updateManyContacts(
        reqBody
      );
      if (response) {
        let updatedContacts = contacts?.filter((contact) => {
          let archivedContact = response?.updatedContacts?.find(
            (res) => res._id === contact._id
          );
          return !Boolean(archivedContact);
        });
        setContacts(updatedContacts);

        toast.success(`Contact updated successfully!`);
      }

      if (error) {
        toast.error(error);
      }
      setArchiveModalMeta(null);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSelectedContact = async () => {
    const { contactToBeDeleted } = deleteModalMeta;
    setDeleteModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contactService.deleteSelectedContact({
        ids: contactToBeDeleted?.map((contact) => contact._id),
      });
      if (response) {
        setContacts((contacts) =>
          contacts.filter(
            (contact) => !contactToBeDeleted.find((c) => c._id === contact?._id)
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteModalMeta(null);
    }
  };

  const onCustomFilterApply = async (cF) => {
    setActiveFilter([]);
    setCustomeFilter(cF);
  };

  const deleteFilter = async (formData) => {
    const { filter } = filterDeleteModalMeta;
    setFilterDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    try {
      const { response, error } = await filterServices.deleteFilter(filter);

      setFilterDeleteModalMeta(null);
      if (error) {
        return toast.error(error);
      }
      if (activeFilter.some((f) => response.id === f?._id)) {
        setActiveFilter([
          ...activeFilter?.filter((f) => f?._id !== response.id),
        ]);
      }
      setFilterData((filters) => filters.filter((p) => p._id !== response.id));
      toast.success("Successfully deleted filter");
    } catch (error) {
      console.log(error);
    }
  };

  const onCustomFilterSubmit = async (formData) => {
    const { mode } = customFilterMeta;
    try {
      setCustomFilterMeta({ ...customFilterMeta, showProgress: true });
      const { response, error } =
        mode === "Custom"
          ? await filterServices.createFilter(formData)
          : await filterServices.updateFilter(formData);
      if (response) {
        setFilterData(
          mode === "Custom"
            ? [...filterData, response]
            : updateItemsInArray(filterData, response)
        );
      }
      if (error) {
        toast.error(error);
      }
      setCustomFilterMeta({ ...customFilterMeta, showProgress: false });
      setActiveFilter([...activeFilter, response]);
    } catch (error) {
      toast.error(error);
    }
  };

  const onDragEnd = async (sourceId, sourceIndex, targetIndex, targetId) => {
    if (sourceIndex === 0 || targetIndex === 0) return;
    let oldFilter = [...filterData];
    const newFilter = swap(filterData, sourceIndex - 1, targetIndex - 1);

    setFilterData(newFilter);

    try {
      setUpdatingFilterOrder(true);
      const { response, error } = await filterServices.updateMultiple(
        newFilter.map((o, index) => ({
          _id: o._id,
          filterUpdates: { index: index },
        }))
      );

      if (error) {
        setFilterData(oldFilter);
        return toast.error(error);
      }
      if (response) {
        toast.success("Successfully updated filters");
      }
      setUpdatingFilterOrder(false);
    } catch (error) {
      console.log(error);
    }
    setFilterData(newFilter);
  };

  const onViewArchiedContactClick = () => {
    setShowArchivedContact(!showArchivedContact);
  };

  const onMergeContact = async (mergeInfo) => {
    let mergeContactIds = mergeInfo?.mergeContactIds || [];

    setMergeContactModalMeta((prev) => ({
      ...prev,
      showProgress: true,
    }));
    toast.info("Merging contact...");
    const { response, error } = await contactService.mergeContacts(mergeInfo);
    setMergeContactModalMeta((prev) => ({
      ...prev,
      showProgress: false,
    }));
    if (error) {
      return toast.error(error);
    }

    if (response) {
      toast.success("Contact merged successfully!");
      setContacts((prevContacts) => {
        const updatedContacts = updateItemsInArray(prevContacts, response);

        return updatedContacts?.filter(
          (c) => !mergeContactIds?.includes(c?._id)
        );
      });
      hideContactPopup();
      setAddContactMeta(null);
      setMergeContactModalMeta(null);
      setConfirmCreateNewContactMeta(null);
    }
  };

  const openUserRecord = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("view", USER_RECORD_QS);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const closeUserRecord = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("view");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const onAutoMergeClick = () => {
    setConfirmAutoMerge({ showProgress: false });
  };

  const autoMergeContacts = async () => {
    let reqBody = {
      selectAllMode,
      selectedContacts: selectedItems?.map((c) => c._id),
      unselectedContacts: unselectedItems.map((c) => c._id),
      filter: allContactFilters,
    };

    try {
      setConfirmAutoMerge((autoMergeMeta) => ({
        ...autoMergeMeta,
        showProgress: true,
      }));
      const { response, error } = await contactService.autoMergeContacts(
        reqBody
      );
      if (response) {
        fetchContactsAfterUpdate();
        toast.success(`Contact merged succesfully!`);

        setConfirmAutoMerge(null);
      }

      if (error) {
        setConfirmAutoMerge((autoMergeMeta) => ({
          ...autoMergeMeta,
          showProgress: false,
        }));
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onViewDuplicateContactsClick = () => {
    setShowDuplicatesOnly(!showDuplicatesOnly);
  };

  useEffect(() => {
    fetchContacts({});
  }, [toLoadPageInfo]);

  useEffect(() => {
    const fetchDuplicates = async () => {
      const contactsWithoutDuplicateKeys = contacts
        .filter((c) => !c.duplicates)
        .map((c) => c._id);

      if (!contactsWithoutDuplicateKeys.length) return;

      const controller = new AbortController();
      const { signal } = controller;

      duplicateAbortControllerRef.current = controller;

      const requestBody = {
        contactIds: contactsWithoutDuplicateKeys,
      };

      const { response, error } = await contactService.getDuplicateContacts(
        requestBody,
        signal
      );

      if (error) return;

      setContacts(
        contacts.map((c) => ({
          ...c,
          duplicates:
            response.find((r) => r.contactId === c._id)?.duplicates ||
            c.duplicates ||
            [],
        }))
      );
    };

    fetchDuplicates();
  }, [contacts]);

  useEffect(() => {
    if (searchBoxOptions || activeFilter || customFilter) {
      setToLoadPageInfo({ ...initialPageInfo, scrollTop: 0 });
    }
  }, [
    searchBoxOptions,
    activeFilter,
    customFilter,
    showContactWithFollowUpDue,
    showArchivedContact,
    showDuplicatesOnly,
  ]);

  useEffect(() => {
    fetchFilters();
  }, []);

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo, scrollTop: 0 });
    },
    [salesPersonFilterToUse, tableFilterValues, tableSortOption, maxLimit],
    800
  );

  useEffect(() => {
    if (Boolean(updatedContact)) {
      let { action, contact } = updatedContact;
      if (action === "update") {
        fetchContactsAfterUpdate(tableScrollTop);
      }
    }
  }, [updatedContact]);

  useEffect(() => {
    if (followupDateChangeInfo) {
      const { contact: updatedContact, followupDate } = followupDateChangeInfo;
      setContacts(
        contacts?.map((c) =>
          c?._id === updatedContact?._id ? { ...c, followupDate } : c
        )
      );
    }
  }, [followupDateChangeInfo]);

  useEffect(() => {
    if (view === USER_RECORD_QS) {
      setEmployeeRecordModalMeta(true);
    } else {
      setEmployeeRecordModalMeta(false);
    }
  }, [contactId, dealId, view]);

  useEffect(() => {
    if (!isAdminOrManager(user.role)) {
      const filterKey = user.role === SALES_ROLE ? "salesperson" : "others";
      setSalesPersonFilter((prevFilter) => ({
        ...prevFilter,
        [filterKey]: [user._id],
      }));
    }
  }, [user]);

  useDebouncedEffect(
    () => {
      if (message) {
        let { type, payload } = message;
        switch (type) {
          case BROADCAST_TYPES.CONTACT_UPDATE:
            fetchContactsAfterUpdate(tableScrollTop);
            setLastUpdatedContact(payload._id);
            break;

          case BROADCAST_TYPES.CONTACT_CREATE:
            fetchContactsAfterUpdate(tableScrollTop);
            break;
        }
      }
    },
    [message],
    500
  );

  useEffect(() => {
    if (message) {
      let { type, payload } = message;
      switch (type) {
        case BROADCAST_TYPES.CONTACT_DELETE:
          const updatedContacts = contacts.filter(
            (c) => c._id !== payload?._id
          );
          setContacts(updatedContacts);
          break;
      }
    }
  }, [message]);

  useEffect(() => {
    if (!lastUpdatedContact) return;
    setTimeout(() => {
      setLastUpdatedContact(null);
    }, 500);
  }, [lastUpdatedContact]);

  return (
    <>
      <Container fluid className={"px-2"}>
        {mobileView && (
          <SearchBox
            containerClass="bg-white rounded mt-2 mb-0"
            disabled={isSearchBoxDisabled}
          />
        )}
        {showArchivedContact && (
          <>
            <div className="fw-bold xxlarge d-inline-flex align-items-center">
              <Trash />
              <span className="mx-2">{translate("trash")}</span>
            </div>
            <hr
              className="mt-0 mb-2 border-danger"
              style={{ width: 100, borderWidth: 5 }}
            />
          </>
        )}
        <div>
          <ContactActions
            mobileView={mobileView}
            users={users}
            isFetchingContacts={isFetchingContacts}
            isSearchBoxDisabled={isSearchBoxDisabled}
            selectAllMode={selectAllMode}
            loadedPageInfo={loadedPageInfo}
            selectedContact={selectedItems}
            unselectedContacts={unselectedItems}
            toggleFilter={toggleFilter}
            activeFilter={activeFilter}
            customFilter={customFilter}
            salesPersonFilter={salesPersonFilter}
            setSalesPersonFilter={setSalesPersonFilter}
            showContactWithFollowUpDue={showContactWithFollowUpDue}
            setShowContactWithFollowUpDue={setShowContactWithFollowUpDue}
            shouldRenderClearFilterButton={shouldRenderClearFilterButton}
            resetFilter={resetFilter}
            showArchivedContact={showArchivedContact}
            onArchiveSelectedContactClick={onArchiveSelectedContactClick}
            onDeleteSelectedContactClick={onDeleteSelectedContactClick}
            onUnArchiveSelectedContactClick={onUnArchiveSelectedContactClick}
            onSendWhatsappCampaignClick={onSendWhatsappCampaignClick}
            onUpdateManyContactClick={toggleFilter}
            onViewArchiedContactClick={onViewArchiedContactClick}
            isDownloading={isDownloading}
            downloadContractedBuyersCSV={downloadContractedBuyersCSV}
            openApointmentCalendar={openApointmentCalendar}
            openEmployeeRecordModal={openUserRecord}
            showAllFields={showAllFields}
            setShowAllFields={setShowAllFields}
            onViewDuplicateContactsClick={onViewDuplicateContactsClick}
            showDuplicatesOnly={showDuplicatesOnly}
            onAutoMergeClick={onAutoMergeClick}
            maxLimit={maxLimit}
            setMaxLimit={setMaxLimit}
          />
          <div
            className="d-flex gap-2"
            style={{
              width: "100%",
            }}
          >
            <FilterSideBar
              show={showFilter}
              onHide={toggleFilter}
              filterData={filterData}
              allTags={tags}
              disabled={isSearchBoxDisabled || updatingFilterOrder}
              updatingFilterOrder={updatingFilterOrder}
              activeFilter={activeFilter}
              onFilterSelect={onFilterSelect}
              onCustomFilterApply={onCustomFilterApply}
              onCustomFilterSubmit={onCustomFilterSubmit}
              customFilterMeta={customFilterMeta}
              setCustomFilterMeta={setCustomFilterMeta}
              setFilterDeleteModalMeta={setFilterDeleteModalMeta}
              onDragEnd={onDragEnd}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              width={mobileView ? "100%" : customFilterMeta ? "75%" : "20%"}
              height={`calc(100vh - ${mobileView ? "230px" : "100px"})`}
              editMultipleContactMeta={editMultipleContactMeta}
              onUpdateManyContactSubmit={onUpdateManyContactSubmit}
              selectAllMode={selectAllMode}
              selectedContact={selectedItems}
            />

            <div
              style={{
                width: showFilter
                  ? mobileView
                    ? "0%"
                    : customFilterMeta
                    ? "25%"
                    : "80%"
                  : "100%",
              }}
            >
              <DataTable
                maxTableHeight={`calc(100vh - ${
                  mobileView ? "230px" : "100px"
                })`}
                rowKey={"_id"}
                columns={tableColumns}
                data={contacts}
                onTableScrollTopChange={setTableScrollTop}
                rowToHighlight={lastUpdatedContact}
                sortOptions={tableSortOption}
                onSortChange={setTableSortOption}
                filterValues={tableFilterValues}
                onFilterValuesChange={setTableFilterValues}
                bottomOffset={300}
                onBottomReached={loadMoreData}
                loadingMoreData={fetchingMoreContacts}
                loadingFirstPageData={fetchingFirstPageContacts}
                onRowClick={onContactClick}
                showNewRow={Boolean(addContactMeta)}
                renderNewRow={() => (
                  <NewContactCard
                    tags={tags}
                    contact={addContactMeta?.contact}
                    onSaveNewContact={onAddContactSubmit}
                    onHideNewContact={() => setAddContactMeta(null)}
                    addingNewContact={addContactMeta?.showProgress}
                    onContactChange={(contact) =>
                      setAddContactMeta((meta) => ({ ...meta, contact }))
                    }
                    onCreateNewReferralClick={onCreateNewReferralClick}
                    referrals={referrals}
                    createdReferral={createdReferral}
                  />
                )}
                showEditMultipleRow={Boolean(editMultipleContactMeta)}
                headerFontSize="14px"
                columnFontSize="16px"
              />
            </div>
          </div>
        </div>
      </Container>

      <AlertModal
        show={Boolean(alertModalMeta)}
        alertText={alertModalMeta?.alertText}
        onHide={() => setAlertModalMeta(null)}
        onDismissClick={alertModalMeta?.onDismissClick}
        onContinueClick={alertModalMeta?.onContinueClick}
        showProgress={alertModalMeta?.showProgress}
      />

      {!addContactMeta && mobileView && (
        <FloatingButton
          className="text-white"
          Icon={PlusCircle}
          variant="success"
          text={translate("new")}
          onClick={() =>
            setAddContactMeta({
              contact: NEW_CONTACT_DEFAULT_FORM_VALUES(user),
            })
          }
        />
      )}
      <SendWhatsappCampaignModal
        show={Boolean(sendWhatsappCampaignModalMeta)}
        onHide={() => setSendWhatsappCampaignModalMeta(null)}
        showProgress={sendWhatsappCampaignModalMeta?.showProgress}
        onSubmit={sendWhatsappCampaign}
      />

      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteModalMeta)}
          onHide={() => setDeleteModalMeta(false)}
          onDismissClick={() => setDeleteModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contacts")}
          showProgress={deleteModalMeta?.showProgress}
          onContinueClick={deleteSelectedContact}
        />
      )}
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(archiveModalMeta)}
          onHide={() => setArchiveModalMeta(null)}
          onDismissClick={() => setArchiveModalMeta(null)}
          alertText={translate(
            archiveModalMeta?.archive
              ? "are_you_sure_you_want_to_trash_contacts"
              : "are_you_sure_you_want_to_restore_contacts"
          )}
          showProgress={archiveModalMeta?.showProgress}
          onContinueClick={handleArchiveContactSubmit}
        />
      )}

      <AlertModal
        show={Boolean(filterDeleteModalMeta)}
        onHide={() => setFilterDeleteModalMeta(false)}
        onDismissClick={() => setFilterDeleteModalMeta(false)}
        alertText={translate("are_you_sure_you_want_to_delete_filter")}
        showProgress={filterDeleteModalMeta?.showProgress}
        onContinueClick={deleteFilter}
      />
      <AlertModal
        show={Boolean(confirmAutoMerge)}
        onHide={() => setConfirmAutoMerge(null)}
        onDismissClick={() => setConfirmAutoMerge(null)}
        alertText={translate("are_you_sure_to_auto_merge")}
        showProgress={confirmAutoMerge?.showProgress}
        onContinueClick={autoMergeContacts}
      />

      <AlertModal
        show={Boolean(confirmCreateNewContactMeta)}
        onHide={() => setConfirmCreateNewContactMeta(null)}
        onDismissClick={() =>
          onCreateDuplicateContactSubmit(
            confirmCreateNewContactMeta?.baseContact
          )
        }
        onContinueClick={() =>
          setMergeContactModalMeta({
            duplicateContacts: confirmCreateNewContactMeta?.duplicateContacts,
            baseContact: confirmCreateNewContactMeta?.baseContact,
            type: "new",
          })
        }
        dismissButtonText="Create duplicate"
        continueButtonText="Merge contacts"
        continueButtonVariant="primary"
        alertText={"Duplicate contact found"}
        showProgress={confirmCreateNewContactMeta?.showProgress}
      />
      {Boolean(mergeContactModalMeta) && (
        <ConfirmMergeContactModal
          show={Boolean(mergeContactModalMeta)}
          onHide={() => setMergeContactModalMeta(null)}
          newContactMerge={mergeContactModalMeta?.type === "new"}
          duplicateContacts={mergeContactModalMeta?.duplicateContacts}
          baseContact={mergeContactModalMeta?.baseContact}
          showProgress={mergeContactModalMeta?.showProgress}
          users={users}
          onMergeContactClick={onMergeContact}
          referrals={referrals}
        />
      )}

      {employeeRecordModalMeta && (
        <EmployeeRecordModal
          show={employeeRecordModalMeta}
          onHide={closeUserRecord}
        />
      )}
    </>
  );
};

export default BrowseContacts;
