import React, { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";

import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import HorizontalProgress from "../common/HorizontalProgress";
import TextDropdownToggle from "../common/TextDropdownToggle";

const DeleteProductionStepModal = ({
  show,
  onHide,
  productStageDeleteModalMeta,
  onContinueClick,
  onDismissClick,
  showProgress,
  setProductStageDeleteModalMeta,
}) => {
  const { translate } = useLocalization();
  const [errorMessage, setErrorMessage] = useState("");
  const handleContinueClick = () => {
    // if (!productStageDeleteModalMeta?.anotherProductionStageToAssign) {
    //   return setErrorMessage("Please select another production stage or unassign!");
    // }
    onContinueClick();
    setErrorMessage("");
  };
  return (
    <AppModal
      size={"md"}
      show={show}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      {show && (
        <>
          <div className="text-center">
            <ExclamationTriangle className="text-danger" size={30} />
            <h6 className="mt-3">
              {translate("are_you_sure_you_want_to_delete_product_stage", {
                stageName: productStageDeleteModalMeta?.step?.step,
              })}{" "}
            </h6>{" "}
            <h6>{translate("assign_another_step")}</h6>
            <div className="w-100 d-flex justify-content-center ">
              <TextDropdownToggle
                onOptionClick={(step) =>
                  setProductStageDeleteModalMeta({
                    ...productStageDeleteModalMeta,
                    anotherProductionStageToAssign: !step ? "unassign" : step,
                  })
                }
                value={
                  productStageDeleteModalMeta?.anotherProductionStageToAssign
                }
                className="w-50"
                required
                noHint
                options={[
                  { label: "Unassign", value: "unassign" },
                  ...productStageDeleteModalMeta?.product?.productionStages
                    ?.map((step) => ({ label: step?.step, value: step?._id }))
                    ?.filter(
                      (step) =>
                        step?.value !== productStageDeleteModalMeta?.step?._id
                    ),
                ]}
              />
            </div>
          </div>
          {showProgress && (
            <HorizontalProgress text={translate("please_wait")} />
          )}
          {errorMessage && (
            <div className="w-100 d-flex justify-content-center">
              <Alert
                variant="danger"
                className="px-2 py-2 smallFont w-50 mt-2 d-flex justify-content-center text-center rounded-0"
              >
                {errorMessage}
              </Alert>
            </div>
          )}
          <div className="text-end mt-4">
            <Button
              size="sm"
              className="ms-2 px-2"
              variant={"dark"}
              onClick={onDismissClick}
              disabled={showProgress}
            >
              {translate("cancel")}
            </Button>
            <Button
              size="sm"
              className="ms-2 px-2"
              variant={"success text-white"}
              onClick={() => handleContinueClick()}
              disabled={showProgress}
            >
              {translate("continue")}
            </Button>
          </div>{" "}
        </>
      )}
    </AppModal>
  );
};

export default DeleteProductionStepModal;
