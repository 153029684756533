import { ErrorMessage, Field, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  FormCheck,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { Clipboard2 } from "react-bootstrap-icons";
import Switch from "react-switch";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  adjustTimeWithDuration,
  sortUsersAlphabetically,
  timeDifferenceInMinutes,
} from "../../../helpers/global";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import useContacts from "../../../hooks/useContacts";
import useLocalization from "../../../hooks/useLocalization";
import ContactOverviewCard from "../../common/ContactOverviewCard";
import EditableTagsInput from "../../common/EditableTagsInput";
import HorizontalProgress from "../../common/HorizontalProgress";
import MeetLink from "../../common/MeetLink";
import SelectContactModal from "../../common/SelectContactModal";
import TimePicker from "../../common/TimePicker";
import {
  BROADCAST_TYPES,
  useWebSocket,
} from "../../../context/WebSocketContext";
import ApointmentRecurranceField from "./ApointmentRecurranceField";
import moment from "moment";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Please provide title"),
  description: Yup.string(),
  date: Yup.string().required("Please provide date"),
  start: Yup.string().required("Please provide start time"),
  end: Yup.string().required("Please provide end time"),
  appointmentType: Yup.string().required("Please provide appointment type"),
  invitees: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .required("Please provide invitees"),
  participants: Yup.array()
    .of(Yup.string())
    .required("Please provide participants"),
  recurrenceInfo: Yup.object()
    .shape({
      frequency: Yup.string()
        .oneOf(
          ["daily", "weekly", "monthly"],
          "Frequency must be either 'daily', 'weekly', or 'monthly'"
        )
        .nullable(),
      interval: Yup.number()
        .min(1, "Interval must be greater than 0")
        .nullable(),
      occurrences: Yup.number()
        .min(1, "Occurrences must be greater than 0")
        .nullable()
        .test(
          "occurrences-required-if-selected",
          "Occurrences is required",
          function (value) {
            const { selectedEndsOnType } = this.parent;
            return selectedEndsOnType !== "occurrences" || !!value;
          }
        ),
      endDate: Yup.string()
        .nullable()
        .test(
          "endDate-required-if-selected",
          "End date is required",
          function (value) {
            const { selectedEndsOnType } = this.parent;
            return selectedEndsOnType !== "endDate" || !!value;
          }
        ),
      daysOfWeek: Yup.array()
        .of(
          Yup.string().oneOf(
            ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
            "Invalid day of the week"
          )
        )
        .nullable()
        .test(
          "daysOfWeek-required-if-weekly",
          "Days of the week are required for weekly frequency",
          function (value) {
            const { frequency } = this.parent;
            return (
              frequency !== "weekly" ||
              (Array.isArray(value) && value.length > 0)
            );
          }
        ),
      repeatOn: Yup.string()
        .oneOf(
          ["dayOfMonth", "dayOfWeek"],
          "RepeatOn must be either 'dayOfMonth' or 'dayOfWeek'"
        )
        .nullable()
        .test(
          "repeatOn-required-if-monthly",
          "Repeat on is required for monthly frequency",
          function (value) {
            const { frequency } = this.parent;
            return frequency !== "monthly" || !!value;
          }
        ),
    })
    .nullable(),
});

const ParticipantsOption = ({
  translate,
  showHostButton = false,
  data,
  selected = false,
  disabled,
  onParticipantsChange,
  isHostUser = false,
  makeHost,
  removeHost,
}) => (
  <div className="participant-card border hover rounded-md  d-flex  align-items-center">
    <FormCheck
      checked={selected}
      disabled={disabled}
      onChange={(e) => onParticipantsChange(e.target.checked)}
      type={"checkbox"}
      className="mx-1"
      id={`participant-${data?.email}`}
    />
    <div className="flex-grow-1">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mid mb-0 flex-grow-1">{data?.name}</h6>
        {showHostButton && (
          <Button
            className={`${
              isHostUser ? "" : "host-button"
            } px-1 py-0 d-flex align-items-center`}
            size="sm"
            variant={isHostUser ? "danger" : "success text-light"}
            onClick={() => {
              if (isHostUser) {
                removeHost(data);
              } else {
                makeHost(data);
              }
            }}
          >
            <span className="tiny">
              {isHostUser ? translate("remove_host") : translate("make_host")}
            </span>
          </Button>
        )}
      </div>
      <h6 className="smallFont text-secondary truncate mb-0">{data?.email}</h6>
    </div>
  </div>
);

const IDS_TO_AVOID_CHECKING_DEFAULT = ["65f15ce91fe8de9845a72e20"];

const AddEditAppointmentForm = ({
  formValues = {},
  showProgress,
  defaultInviteesEmail,
  appointmentMetaDataByType,
  editMode,
  disabled,
  onSubmit,
  onAppointmentDelete,
  contact,
  specificContactMode,
  setAppointmentMetadata,
}) => {
  const { translate, isRTL } = useLocalization();
  const { user: loggedInUser } = useAuth();
  const users = useAppChoices("users");
  const formContentRef = useRef(null);
  const { message } = useWebSocket();
  const [openSelectContactModal, setOpenSelectContactModal] = useState(null);
  const { contacts, loadingContacts, setContactQuery } = useContacts();
  const isZoomMeet = (value) =>
    value?.appointmentType === "Zoom Meeting" || value?.createZoomMeeting;

  const showCreateZoomButton = (appointmentType) =>
    appointmentType === "Office Meeting" || appointmentType === "Other";

  const defaultValues = useMemo(
    () => ({
      contact: formValues?.contact || contact || null,
      title: "",
      date: "",
      description: "",
      invitees: defaultInviteesEmail ?? [],
      appointmentType: "",
      allDay: false,
      participants: [loggedInUser].filter(
        (u) => !IDS_TO_AVOID_CHECKING_DEFAULT.includes(u._id)
      ),
      start: "08:00",
      end: "08:30",
      duration: 30,
      isAppEvent: true,
    }),
    [contact, formValues, defaultInviteesEmail, loggedInUser]
  );

  const onContactSelect = (contact, values, setFieldValue) => {
    const emails = contact.emails ?? [];
    setFieldValue("contact", contact);
    setOpenSelectContactModal(false);
    setFieldValue("invitees", emails);
  };

  const initialValues = useMemo(() => {
    const duration =
      formValues?.duration || appointmentMetaDataByType[0]?.duration;
    const { newStartTime, newEndTime } = adjustTimeWithDuration(
      formValues?.start,
      formValues?.end,
      duration
    );

    let title = "";
    if (contact) {
      title = `${title} (${contact?.firstName || ""} ${
        contact?.lastName || ""
      } ${
        contact?.phoneNumbers?.[0] ? `- ${contact?.phoneNumbers?.[0]}` : ""
      })`;
    }

    const recurrenceInfo = formValues?.recurrenceInfo
      ? {
          ...formValues.recurrenceInfo,
          endDate: formValues.recurrenceInfo.endDate
            ? moment(formValues.recurrenceInfo.endDate).format("YYYY-MM-DD")
            : null,
          selectedEndsOnType: formValues.recurrenceInfo.occurrences
            ? "occurrences"
            : formValues.recurrenceInfo.endDate
            ? "endDate"
            : "never",
        }
      : null;

    return {
      title: title,
      date: "",
      description: "",
      invitees: defaultInviteesEmail ?? [],
      appointmentType: "",
      allDay: Boolean(formValues?.allDay),
      duration,
      start: newStartTime,
      end: newEndTime,
      inviteUser: true,
      isAppEvent: true,
      ...formValues,
      participants: formValues?.participants
        ? formValues?.participants?.map((p) => p?._id || p)
        : defaultValues.participants?.map((p) => p._id),
      contact: !editMode ? formValues?.contact || contact : formValues?.contact,
      recurrenceInfo,
    };
  }, [
    formValues,
    appointmentMetaDataByType,
    defaultInviteesEmail,
    contact,
    specificContactMode,
    defaultValues,
  ]);

  const calculateEndTime = (startTime, durationInMinutes = 0) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    let endHour = startHour;
    let endMinute = startMinute + durationInMinutes;

    if (endMinute >= 60) {
      endHour += 1;
      endMinute -= 60;
    }
    // Ensure endHour does not exceed 24
    endHour %= 24;
    // Format the end time
    const formattedEndHour = endHour.toString().padStart(2, "0");
    const formattedEndMinute = endMinute.toString().padStart(2, "0");
    return `${formattedEndHour}:${formattedEndMinute}`;
  };

  useEffect(() => {
    if (formContentRef.current) {
      formContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        let { recurrenceInfo = null, ...remainingValues } = values;
        if (recurrenceInfo) {
          if (recurrenceInfo.selectedEndsOnType === "never") {
            delete recurrenceInfo.occurrences;
            delete recurrenceInfo.endDate;
          }
          if (recurrenceInfo.selectedEndsOnType === "endDate") {
            delete recurrenceInfo.occurrences;
          } else {
            delete recurrenceInfo.endDate;
          }
        }
        onSubmit({
          ...remainingValues,
          recurrenceInfo,
          contact: remainingValues?.contact?._id || null,
        });
      }}
    >
      {({ values, setFieldValue, errors, submitForm, resetForm }) => {
        useEffect(() => {
          if (message) {
            let { type, payload } = message;
            switch (type) {
              case BROADCAST_TYPES.CONTACT_UPDATE:
                if (values?.contact?._id === payload._id) {
                  setFieldValue("contact", payload);
                }
                break;

              default:
                console.log("Unknown message type");
                break;
            }
          }
        }, [message]);

        return (
          <Form
            style={{ height: 355 }}
            className="relative bg-white d-flex flex-column justify-content-between"
          >
            <Row
              className="m-0 pb-2 form-content"
              style={{ overflowY: "scroll" }}
              ref={formContentRef}
            >
              <Col
                xs={12}
                md={12}
                lg={showCreateZoomButton(values?.appointmentType) ? 6 : 12}
              >
                {values?.isAppEvent && (
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate("appointment_type")}
                    </FormLabel>
                    <Field
                      name={"appointmentType"}
                      className="form-control form-control-sm"
                      as="select"
                      disabled={disabled}
                      onChange={(e) => {
                        const appointmentType = e?.target?.value;
                        const prevAppointmentType = values?.appointmentType;

                        if (appointmentType === "Select one") {
                          setFieldValue("appointmentType", null);
                          return;
                        }

                        const newAppointmentMeta =
                          appointmentMetaDataByType?.find(
                            (aP) => aP.key === appointmentType
                          ) || {};

                        const prevAppointmentMeta =
                          appointmentMetaDataByType?.find(
                            (aP) => aP.key === prevAppointmentType
                          ) || {};

                        let {
                          title = "",
                          description = "",
                          duration = 30,
                        } = values;

                        title =
                          !prevAppointmentMeta?.title ||
                          title === prevAppointmentMeta?.title
                            ? newAppointmentMeta.title
                            : title;

                        description =
                          !prevAppointmentMeta?.description ||
                          description === prevAppointmentMeta?.description
                            ? newAppointmentMeta.description
                            : description;

                        duration =
                          !prevAppointmentMeta?.duration ||
                          duration === prevAppointmentMeta?.duration
                            ? newAppointmentMeta.duration
                            : duration;

                        if (contact) {
                          title = `${title} (${contact?.firstName || ""} ${
                            contact?.lastName || ""
                          }${
                            contact?.phoneNumbers?.[0]
                              ? ` - ${contact.phoneNumbers[0]}`
                              : ""
                          })`;
                        }

                        setFieldValue(
                          "invitees",
                          values.invitees?.length
                            ? values.invitees
                            : contact?.emails || []
                        );

                        const { start = "12:00", end } = values;
                        const { newStartTime, newEndTime } =
                          adjustTimeWithDuration(start, end, duration);

                        setFieldValue("appointmentType", appointmentType);
                        setFieldValue("title", title);
                        setFieldValue("description", description);
                        setFieldValue("duration", duration);
                        setFieldValue("start", newStartTime);
                        setFieldValue("end", newEndTime);

                        if (
                          appointmentType === "Zoom Meeting" ||
                          showCreateZoomButton(appointmentType)
                        ) {
                          const zoomUserId =
                            users?.find(
                              (u) =>
                                values?.participants?.includes(u?._id) &&
                                u?.zoomUserId
                            )?.zoomUserId || null;
                          setFieldValue("zoomUserId", zoomUserId);
                          setFieldValue("autoStartRecording", true);
                          setFieldValue("createZoomMeeting", true);
                        } else {
                          setFieldValue("zoomUserId", null);
                          setFieldValue("autoStartRecording", null);
                          setFieldValue("createZoomMeeting", null);
                        }
                      }}
                    >
                      <option value={null}>Select one</option>
                      {appointmentMetaDataByType?.map(({ key, title }) => {
                        return (
                          <option key={key} value={key}>
                            {translate(snakeCase(key)) || key}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name={"appointmentType"}
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                )}
              </Col>
              {showCreateZoomButton(values?.appointmentType) &&
                values.isAppEvent && (
                  <Col xs={12} md={12} lg={6}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-2">
                        {translate("create_zoom_meeting")}
                      </FormLabel>
                      <div>
                        {" "}
                        <Switch
                          onChange={(checked) => {
                            setFieldValue("createZoomMeeting", checked);
                            setFieldValue("autoStartRecording", checked);
                          }}
                          checked={values?.createZoomMeeting}
                          height={15}
                          width={40}
                          offHandleColor="#d1e6cc"
                          onHandleColor="#d1e6cc"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
              {isZoomMeet(values) && formValues?.zoomMeetLink && (
                <Col xs={12} md={12} lg={12}>
                  <FormLabel className="mid mb-1">
                    {translate("meeting_link")}
                  </FormLabel>
                  <MeetLink appointment={formValues} />
                </Col>
              )}
              <Col xs={12} md={12} lg={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Title</FormLabel>
                  <Field
                    name="title"
                    disabled={disabled}
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Description</FormLabel>
                  <Field
                    name="description"
                    disabled={disabled}
                    type="input"
                    as="textarea"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Date</FormLabel>
                  <Field
                    name="date"
                    disabled={disabled}
                    type="date"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">All day</FormLabel>
                  <FormCheck
                    style={{ fontSize: 14 }}
                    type="checkbox"
                    disabled={disabled}
                    label={<h6 className="smallFont">{translate("yes")}</h6>}
                    checked={values?.allDay}
                    className="hover mt-1"
                    onChange={(e) => {
                      setFieldValue("allDay", e.target.checked);
                      if (e.target.checked) {
                        setFieldValue("start", "00:00");
                        setFieldValue("end", "23:59");
                      }
                    }}
                  />
                </FormGroup>
              </Col>{" "}
              {!Boolean(values?.allDay) && (
                <>
                  <Col xs={6} sm={4} md={4} lg={4}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">Start Time</FormLabel>
                      <TimePicker
                        value={values?.start}
                        disabled={disabled}
                        onChange={(startTime) => {
                          const endTime = calculateEndTime(
                            startTime,
                            values.duration
                          );
                          setFieldValue("start", startTime);
                          setFieldValue("end", endTime);
                        }}
                      />
                      <ErrorMessage
                        name="start"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={4} md={4} lg={4}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">End Time</FormLabel>
                      <TimePicker
                        value={values?.end}
                        disabled={disabled}
                        onChange={(endTime) => {
                          const startTime = values?.start ?? "00:00";
                          const duration = timeDifferenceInMinutes(
                            startTime,
                            endTime
                          );
                          setFieldValue("end", endTime);
                          setFieldValue("duration", duration);
                        }}
                      />

                      <ErrorMessage
                        name="end"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={4} lg={4}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        Duration(Minutes)
                      </FormLabel>
                      <Field
                        name="duration"
                        type="number"
                        disabled={disabled}
                        onChange={(e) => {
                          const { start, end } = values;
                          const duration = e.target.value;
                          const { newStartTime, newEndTime } =
                            adjustTimeWithDuration(start, end, duration);

                          setFieldValue("duration", duration);
                          setFieldValue("start", newStartTime);
                          setFieldValue("end", newEndTime);
                        }}
                        className="form-control form-control-sm"
                        size="sm"
                      />
                      <ErrorMessage
                        name="duration"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {values.isAppEvent && (
                <Col xs={12}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">Occurance</FormLabel>
                    <ApointmentRecurranceField
                      date={values.date}
                      errors={errors?.recurrenceInfo || {}}
                      recurrenceInfo={values?.recurrenceInfo}
                      onChange={(v) => setFieldValue("recurrenceInfo", v)}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12} md={12} lg={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Invite</FormLabel>
                  <EditableTagsInput
                    disabled={disabled}
                    tags={values?.invitees}
                    onTagsChange={(e) => setFieldValue("invitees", e)}
                    errors={errors?.invitees}
                    autoLowerCase
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <FormGroup className="mb-2">
                  {" "}
                  {(values?.contact || !disabled) && (
                    <FormLabel className="mid mb-1">Contact</FormLabel>
                  )}
                  {values?.contact && (
                    <ContactOverviewCard
                      contact={values?.contact}
                      openContact
                    />
                  )}
                  <div>
                    {!disabled && (
                      <Button
                        className="text-white"
                        variant="dark"
                        size="sm"
                        onClick={() => setOpenSelectContactModal(true)}
                      >
                        <h6 className="mb-0 smallFont">
                          {translate("select_contact")}
                        </h6>
                      </Button>
                    )}
                    {values?.contact && (
                      <Button
                        className="mx-1 text-white"
                        variant="danger"
                        size="sm"
                        onClick={() => setFieldValue("contact", null)}
                      >
                        <h6 className="mb-0 smallFont">
                          {" "}
                          {translate("remove_contact")}
                        </h6>
                      </Button>
                    )}
                  </div>
                  <ErrorMessage
                    name="contact"
                    component="div"
                    className="text-danger mid"
                  />
                  <SelectContactModal
                    show={openSelectContactModal}
                    onHide={() => setOpenSelectContactModal(null)}
                    contacts={contacts}
                    showProgress={loadingContacts}
                    setContactQuery={setContactQuery}
                    onContactSelect={(contact) =>
                      onContactSelect(contact, values, setFieldValue)
                    }
                    selectedContact={values?.contact}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("participants")}
                  </FormLabel>
                  <Row className="m-0">
                    {sortUsersAlphabetically(users).map((u) => {
                      let isUserAParticipant = values?.participants?.includes(
                        u?._id
                      );
                      return (
                        <Col key={u?._id} sm={12} md={6} className="p-1 m-0">
                          <ParticipantsOption
                            translate={translate}
                            data={u}
                            disabled={disabled}
                            selected={isUserAParticipant}
                            onParticipantsChange={(checked) => {
                              if (checked) {
                                setFieldValue("participants", [
                                  ...values?.participants,
                                  u?._id,
                                ]);
                              } else {
                                let updatedParticipants =
                                  values?.participants?.filter(
                                    (p) => p !== u?._id
                                  );
                                let participants = users?.filter((u) =>
                                  updatedParticipants?.some((p) => u?._id === p)
                                );
                                setFieldValue(
                                  "participants",
                                  updatedParticipants
                                );
                                if (
                                  participants?.every(
                                    (p) => p?.zoomUserId !== values?.zoomUserId
                                  )
                                ) {
                                  setFieldValue(
                                    "zoomUserId",
                                    participants?.find((u) =>
                                      Boolean(u?.zoomUserId)
                                    )?.zoomUserId || null
                                  );
                                }
                              }
                            }}
                            makeHost={(user) => {
                              const zoomId = user?.zoomUserId;
                              if (!zoomId) {
                                toast.error(
                                  "This user is not part of the office zoom account yet. Please contact your admin."
                                );
                                return;
                              }
                              setFieldValue("zoomUserId", zoomId);
                            }}
                            removeHost={(zoomId) => {
                              setFieldValue("zoomUserId", null);
                            }}
                            hasZoomId={Boolean(u?.zoomUserId)}
                            isHostUser={
                              values?.zoomUserId &&
                              values?.zoomUserId === u?.zoomUserId
                            }
                            showHostButton={
                              isZoomMeet(values) && isUserAParticipant
                            }
                          />
                        </Col>
                      );
                    })}
                  </Row>

                  <ErrorMessage
                    name="participants"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup className="mb-2">
                  <FormCheck
                    style={{ fontSize: 14 }}
                    disabled={disabled}
                    type="checkbox"
                    label={
                      <h6 className="smallFont">
                        {translate("send_invition_link")}
                      </h6>
                    }
                    checked={values?.inviteUser}
                    className="hover mt-1"
                    onChange={(e) => {
                      setFieldValue("inviteUser", e.target.checked);
                    }}
                  />
                </FormGroup>
              </Col>
              {isZoomMeet(values) && (
                <Col xs={12} md={6} lg={6}>
                  <FormGroup className="mb-2">
                    <FormCheck
                      style={{ fontSize: 14 }}
                      type="checkbox"
                      label={
                        <h6 className="smallFont">
                          {translate("auto_start_recording_meeting")}
                        </h6>
                      }
                      checked={values?.autoStartRecording}
                      className="hover mt-1"
                      onChange={(e) => {
                        setFieldValue("autoStartRecording", e.target.checked);
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            {!disabled && (
              <div className="bg-light border-top p-1">
                {showProgress && (
                  <HorizontalProgress text={`${translate("please_wait")}`} />
                )}
                {isZoomMeet(values) && !values?.zoomUserId && (
                  <h6 className="smallFont rounded bg-danger-light p-1 border text-center">
                    {translate("no_host_has_been_selected")}
                  </h6>
                )}

                <div
                  className={`d-flex justify-content-${
                    editMode ? "between" : "end"
                  } align-items-center`}
                >
                  {editMode && (
                    <div className="d-flex">
                      <Button
                        className="text-white px-1 py-0"
                        variant="success"
                        onClick={() => {
                          resetForm({ values: {} });
                          setAppointmentMetadata({
                            mode: "add",
                            appointment: defaultValues,
                          });
                        }}
                        size="sm"
                        disabled={showProgress}
                      >
                        <span className="smallFont">
                          {translate("new_appointment")}{" "}
                        </span>
                      </Button>
                      <Button
                        className="text-white px-1 py-0 mx-2 d-flex align-items-center"
                        variant="dark"
                        onClick={() => {
                          setAppointmentMetadata({
                            mode: "add",
                            appointment: {
                              ...values,
                              date: "",
                              _id: undefined,
                              createdAt: undefined,
                              updatedAt: undefined,
                              zoomMeetingsMigrated: undefined,
                              googleCalendarEventId: undefined,
                              googleMeetLink: undefined,
                            },
                          });
                        }}
                        size="sm"
                        disabled={showProgress}
                      >
                        <Clipboard2 size={12} />{" "}
                        <span className="smallFont mx-1">
                          {translate("clone")}{" "}
                        </span>
                      </Button>
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    {editMode && (
                      <Button
                        className="text-white mx-1 px-1 py-0"
                        variant="danger"
                        onClick={() => onAppointmentDelete(formValues)}
                        size="sm"
                        disabled={showProgress}
                      >
                        <span className="smallFont">{translate("delete")}</span>
                      </Button>
                    )}
                    <Button
                      className="text-white mx-1 px-1 py-0"
                      variant="primary"
                      onClick={submitForm}
                      size="sm"
                      disabled={showProgress}
                    >
                      <span className="smallFont">
                        {translate(!editMode ? "save" : "update")}{" "}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditAppointmentForm;
