import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const productService = {
  getProducts: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_PRODUCTS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  createProduct: async (product, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_PRODUCTS,
      requestBody: product,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateProduct: async (productId, newProduct, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.PRODUCT_WITH_ID(productId),
      requestBody: newProduct,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteProduct: async (product, requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.PRODUCT_WITH_ID(product._id),
      method: "DELETE",
      requestBody,
      signal,
    });

    return { response, error };
  },
  getUnits: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_UNITS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  createUnit: async (unit, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_UNITS,
      requestBody: unit,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateUnit: async (unitId, newUnit, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UNIT_WITH_ID(unitId),
      requestBody: newUnit,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteUnit: async (unit, requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UNIT_WITH_ID(unit._id),
      method: "DELETE",
      requestBody,
      signal,
    });

    return { response, error };
  },
  updateProductStage: async (stepId, step, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STEP_WITH_ID(stepId),
      requestBody: step,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  createProductStage: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_PRODUCTION_STAGE,
      requestBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  deleteProductStage: async (stepId, requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STEP_WITH_ID(stepId),
      requestBody,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
  updateMultiple: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_MANY_PRODUCTS,
      requestBody: reqBody,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
};
