import React from "react";

const FormSection = ({ children, Icon, title,className='',bodyClassName='' }) => {
  return (
    <div className={`border bg-white ${className}`}>
      <div className="d-flex align-items-center bg-gray border-bottom border-secondary-dark px-2 pt-1 pb-2">
        {Icon && <Icon size={12} className="" />}
        <span className="mx-1 smallFont fw-bold">{title}</span>
      </div>
      <div className={`${bodyClassName}`}>{children}</div>
    </div>
  );
};

export default FormSection;
