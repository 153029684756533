import { ErrorMessage, Field, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import {
  ADMIN_ROLE,
  ALL_ROLES,
  SALES_ROLE,
  USER_STATUS,
  VIEW_ALL_EDIT_ALL,
  specialRolesEnum,
} from "../../helpers/constants";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import EditableTagsInput from "../common/EditableTagsInput";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide the user name"),
  email: Yup.string()
    .email("Email is not valid")
    .required("Please provide the user email"),
  role: Yup.string().required("Please provide the user role"),
  phone: Yup.string(),
  driveFolder: Yup.string(),
});

const defaultValues = {
  name: "",
  email: "",
  role: ADMIN_ROLE,
  secondaryEmails: [],
  phone: "",
  isEnabled: undefined,
  specialRoles: {},
  color: "#7986CB",
  driveFolder: "",
};

const AddEditUserForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  const { user } = useAuth();
  return (
    <Formik
      initialValues={initialValues ?? defaultValues} // setting default role to Admin
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("date_of_birth")}
                  </FormLabel>
                  <FormControl
                    value={
                      values.birthDate ? values.birthDate.split("T")[0] : ""
                    }
                    onChange={(e) => setFieldValue("birthDate", e.target.value)}
                    type="date"
                    size="sm"
                  />
                  <ErrorMessage
                    name="birthDate"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              {editMode && (
                <Col xs={12} md={4}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate("status")}
                    </FormLabel>
                    <Field
                      as="select"
                      name="inEnabled"
                      value={values?.isEnabled ? "Enable" : "Disable"}
                      onChange={(e) =>
                        setFieldValue("isEnabled", e.target.value === "Enable")
                      }
                      className="form-control form-control-sm"
                    >
                      {USER_STATUS.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </Field>
                  </FormGroup>
                </Col>
              )}
              {(isAdminOrManager(user?.role) || !editMode) && (
                <Col xs={12} md={editMode ? 6 : 4}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate("email")}
                    </FormLabel>
                    <Field
                      name="email"
                      type="text"
                      className="form-control form-control-sm"
                      size="sm"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              )}

              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("secondary_emails")}
                  </FormLabel>
                  <EditableTagsInput
                    tags={values?.secondaryEmails}
                    onTagsChange={(emails) =>
                      setFieldValue("secondaryEmails", emails)
                    }
                  />
                  <ErrorMessage
                    name="secondaryEmails"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("phone_number")}
                  </FormLabel>
                  <Field
                    name="phone"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />

                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={editMode ? 6 : 12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("drive_folder")}
                  </FormLabel>
                  <Field
                    name="driveFolder"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="driveFolder"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("role")}
                  </FormLabel>
                  <Field
                    name="role"
                    as="select"
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      const role = e.target.value;
                      setFieldValue("role", role);
                      if (isAdminOrManager(user?.role)) {
                        setFieldValue("specialRoles", {
                          crm: VIEW_ALL_EDIT_ALL,
                          app: VIEW_ALL_EDIT_ALL,
                        });
                      } else if (role === SALES_ROLE) {
                        setFieldValue("specialRoles", {
                          crm: VIEW_ALL_EDIT_ALL,
                          app: VIEW_ALL_EDIT_ALL,
                        });
                      } else {
                        setFieldValue("specialRoles", {
                          crm: VIEW_ALL_EDIT_ALL,
                          app: VIEW_ALL_EDIT_ALL,
                        });
                      }
                    }}
                  >
                    {ALL_ROLES.map((role) => (
                      <option key={role} value={role}>
                        {translate(snakeCase(role)) || role}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="role"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">SIP</FormLabel>
                  <EditableTagsInput
                    tags={values?.sip}
                    onTagsChange={(sip) => setFieldValue("sip", sip)}
                  />
                  <ErrorMessage
                    name="sip"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("color")}
                  </FormLabel>
                  <FormControl
                    onChange={(c) => setFieldValue("color", c.target.value)}
                    type="color"
                    value={values?.color}
                    className="w-100"
                    size="sm"
                  />

                  <ErrorMessage
                    name="color"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>
            {!isAdminOrManager(values?.role) && (
              <Card className="my-2">
                <Card.Header>
                  <h6 className="mb-0 smallFont">Special Roles</h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      {" "}
                      <FormGroup className="mb-2">
                        <FormLabel className="mid mb-1">CRM</FormLabel>
                        <select
                          className={`form-control form-control-sm truncate `}
                          style={{ fontSize: 12 }}
                          onChange={(e) =>
                            setFieldValue(`specialRoles.crm`, e.target.value)
                          }
                          value={values?.specialRoles?.crm}
                        >
                          <option value="" disabled>
                            {translate(snakeCase("please_select_one"))}
                          </option>
                          {specialRolesEnum.map((option) => {
                            return (
                              <option value={option} key={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>

                        <ErrorMessage
                          name="specialRoles"
                          component="div"
                          className="text-danger mid"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      {" "}
                      <FormGroup className="mb-2">
                        <FormLabel className="mid mb-1">Rujum</FormLabel>
                        <select
                          className={`form-control form-control-sm truncate `}
                          style={{ fontSize: 12 }}
                          onChange={(e) =>
                            setFieldValue(`specialRoles.app`, e.target.value)
                          }
                          value={values?.specialRoles?.app}
                        >
                          <option value="" disabled>
                            {translate(snakeCase("please_select_one"))}
                          </option>
                          {specialRolesEnum.map((option) => {
                            return (
                              <option value={option} key={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>

                        <ErrorMessage
                          name="specialRoles.app"
                          component="div"
                          className="text-danger mid"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "save" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditUserForm;
