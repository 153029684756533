import React, { useEffect, useState } from "react";
import { Alert, Card, Spinner } from "react-bootstrap";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { contactService } from "../../services/contactService";
import Appointment from "../appointment/Appointment";
import useLocalization from "../../hooks/useLocalization";
import FloatingButton from "../common/FloatingButton";
import { PlusCircle } from "react-bootstrap-icons";
import CircularProgressBar from "../common/circular-progress";
let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const CustomerAppointment = () => {
  const { translate } = useLocalization();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");
  const [contact, setContact] = useState(null);
  const [fetchingContact, setFetchingContact] = useState(true);
  const [appointmentModalMeta, setAppointmentModalMeta] = useState();

  const fetchContact = async () => {
    try {
      setFetchingContact(true);
      const requestBody = {
        filter: [{ key: "emails", value: email }],
      };

      const { response, error } = await contactService.getContacts(requestBody);
      if (response.results.length > 0) {
        setContact(response.results[0]);
      }
      if (error) {
        toast.error(error);
      }
      setFetchingContact(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (emailRegex.test(email)) {
      fetchContact();
    }
  }, [email]);
  return fetchingContact ? (
    <Card className="m-3">
      <Card.Body className="text-center p-5">
        <CircularProgressBar size={100} />
      </Card.Body>
    </Card>
  ) : (
    <div className="">
      {contact ? (
        <Appointment
          contact={contact}
          editable={true}
          appointmentModalMeta={appointmentModalMeta}
          setAppointmentModalMeta={setAppointmentModalMeta}
          specificContactMode
        />
      ) : (
        <div
          style={{
            height: "50vh",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Alert variant="danger">
            <span>{translate("no_contact_found")} </span>
          </Alert>
        </div>
      )}
      <FloatingButton
        onClick={() => {
          setAppointmentModalMeta({ mode: "add", appointment: null });
        }}
        style={{ zIndex: 1000 }}
        text={"New"}
        variant="success text-white"
        Icon={PlusCircle}
      />
    </div>
  );
};

export default CustomerAppointment;
