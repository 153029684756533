import React from "react";
import useLocalization from "../../hooks/useLocalization";
import { snakeCase, startCase } from "lodash";
import { Table } from "react-bootstrap";

// Function to flatten objects like formData
const flattenObject = (data) => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const flattenedSubObject = flattenObject(value); // Flatten nested object
      for (const [subKey, subValue] of Object.entries(flattenedSubObject)) {
        result[`${key}.${subKey}`] = subValue; // Use dot notation for nested keys
      }
    } else {
      result[key] = value; // Keep other fields as they are
    }
  }
  return result;
};

// Sort before flattening
const sortBeforeFlatten = (data) => {
  const sortedEntries = Object.entries(data).sort(([key1], [key2]) =>
    key1.localeCompare(key2)
  );
  const sortedObject = {};
  for (const [key, value] of sortedEntries) {
    sortedObject[key] = value;
  }
  return flattenObject(sortedObject);
};

const renderObject = (data, prefix = "", translate) => {
  const flatData = sortBeforeFlatten(data);
  const sortedEntries = Object.entries(flatData).sort(([key1], [key2]) =>
    key1.localeCompare(key2)
  );

  return sortedEntries.map(([key, value]) => {
    let keyTouse = key.replace("formData", "");
    return (
      <tr key={prefix + key}>
        <td className="smallFont fw-bold" style={{ width: 120 }}>
          {translate(snakeCase(keyTouse)) || startCase(keyTouse)}
        </td>
        <td className="smallFont">{String(value)}</td>
      </tr>
    );
  });
};

const ObjectDetails = ({ object = {} }) => {
  const { translate } = useLocalization();

  return (
    <Table striped bordered hover size="sm" className="mb-0">
      <tbody>{renderObject(object, "", translate)}</tbody>
    </Table>
  );
};

export default ObjectDetails;
