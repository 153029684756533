import React from "react";
import AppModal from "../../common/AppModal";
import useLocalization from "../../../hooks/useLocalization";
import ObjectDetails from "../../common/ObjectDetails";

const InboundDetailsModal = ({ show, inbound, onHide }) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      show={show}
      title={translate("inbound_source_details")}
      onHide={onHide}
      modalBodyClassName="p-2"
    >
      {inbound && <ObjectDetails object={inbound} />}
    </AppModal>
  );
};

export default InboundDetailsModal;
