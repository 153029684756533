import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const googleDriveServices = {
  getFilesAndFolder: async ({ folder, query }) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_FILES_OR_FOLDERS({ folder, query }),
      method: "GET",
    });

    return { response, error };
  },
  createNewFolder: async (reqBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_NEW_FOLDER,
      method: "POST",
      requestBody: reqBody,
    });

    return { response, error };
  },
  deleteFilesAndFolders: async (id) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_FILE_OR_FOLDER(id),
      method: "DELETE",
    });

    return { response, error };
  },
  uploadFiles: async (reqBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPLOAD_FILES,
      method: "POST",
      requestBody: reqBody,
      contentType: "multipart/form-data",
      stringify: false, // Ensure the request body is not stringified
    });

    return { response, error };
  },
};
