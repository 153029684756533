import React, { useCallback, useState } from "react";
import { Badge, Button, FormControl, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "./AppModal";
import ContactOverviewCard from "./ContactOverviewCard";
import HorizontalProgress from "./HorizontalProgress";
import { useAppointment } from "../../context/AppointmentContext";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";

const SelectContactModal = ({
  show,
  onHide,
  contacts,
  showProgress,
  onContactSelect,
  onContactSelectClick,
  selectedContact,
  setContactQuery,
  defaultQuery = "",
  showFooter = false,
}) => {
  const { translate, isRTL } = useLocalization();
  const [query, setQuery] = useState(defaultQuery || "");
  const { latestActiveContacts } = useAppointment();

  useDebouncedEffect(
    () => {
      setContactQuery(query);
    },
    [query],
    200
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setContactQuery(query);
    }
  };

  const ModalFooter = useCallback(() => {
    return (
      <div className="d-flex justify-content-end align-items-center gap-2">
        <Button
          disabled={showProgress}
          className=""
          variant="dark"
          size="sm"
          onClick={onHide}
        >
          <h6 className="mb-0 smallFont">Cancel</h6>
        </Button>{" "}
        <Button
          disabled={!Boolean(selectedContact) || showProgress}
          className=""
          variant="success text-white"
          size="sm"
          onClick={onContactSelectClick}
        >
          <h6 className="mb-0 smallFont">Select</h6>
        </Button>
      </div>
    );
  }, [onContactSelectClick, onHide, showProgress, selectedContact]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      size={"md"}
      title={translate(`select_contact`)}
      modalBodyClassName="p-2"
      footerContent={showFooter ? ModalFooter : null}
    >
      <div
        style={{
          maxHeight: `calc(100vh - 200px)`,
          overflowY: "scroll",
        }}
      >
        <InputGroup size="sm" className="mb-2">
          {isRTL && (
            <InputGroup.Text
              className="hover"
              onClick={() => setContactQuery(query)}
            >
              <Search size={12} />
            </InputGroup.Text>
          )}
          <FormControl
            className="form-control-sm form-control"
            placeholder="Leave empty to view recent result..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {!isRTL && (
            <InputGroup.Text
              className="hover"
              onClick={() => setContactQuery(query)}
            >
              <Search size={12} />
            </InputGroup.Text>
          )}
        </InputGroup>
        {showProgress && (
          <div className="text-center">
            {" "}
            <HorizontalProgress />
          </div>
        )}{" "}
        {contacts?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <h6 className="mb-0 smallFont">No any contacts to show!</h6>
          </div>
        ) : (
          contacts?.map((contact) => {
            return (
              <ContactOverviewCard
                key={contact?._id}
                contact={contact}
                onContactSelect={onContactSelect}
                selectedContact={selectedContact}
              />
            );
          })
        )}
      </div>
    </AppModal>
  );
};

export default SelectContactModal;
