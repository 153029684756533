import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const availabilityService = {
  search: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_AVAILABILITY,
      requestBody: reqBody ? reqBody : {},
      method: "POST",
      signal,
    });

    return { response, error };
  },
  update: async (availability) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.AVAILABILITY_WITH_ID(availability?._id),
      requestBody: availability,
      method: "PUT",
    });

    return { response, error };
  },
  delete: async (availability) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.AVAILABILITY_WITH_ID(availability?._id),
      requestBody: availability,
      method: "DELETE",
    });

    return { response, error };
  },
  create: async (availability) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_AVAILABILITY,
      requestBody: availability,
    });

    return { response, error };
  },
  createHoliday: async (holiday) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_HOLIDAY,
      requestBody: holiday,
    });

    return { response, error };
  },
  searchUntrackedZoomMeetings: async () => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_UNTRACKED_ZOOM_MEETINGS,
      method: "POST",
    });

    return { response, error };
  },
  searchZoomRecordings: async (reqBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_ZOOM_MEET_RECORDINGS,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  getZoomMeetRecordings: async (reqBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_ZOOM_MEET_RECORDINGS,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  deleteUntrackedZoomMeet: async (meetId) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_UNTRACKED_ZOOM_MEET(meetId),
      method: "DELETE",
    });

    return { response, error };
  },
  assignRecordToAppointment: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.ASSIGN_RECORD_TO_APPOINTMENT,
      method: "POST",
      requestBody: requestBody,
    });

    return { response, error };
  },
};
