import React, { useEffect, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import { extractFolderId } from "../../../../helpers/global";
import { isAdmin } from "../../../../helpers/session";
import useAuth from "../../../../hooks/useAuth";
import useLocalization from "../../../../hooks/useLocalization";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import { userService } from "../../../../services/userService";
import GoogleDriveManager from "../../../common/google-drive-manager/GoogleDriveManager";
import Loader from "../../../common/Loader";

const AddGoogleDrive = ({ onAddDriveFolder }) => {
  const { translate } = useLocalization();
  let { user } = useAuth();
  let { screenSize } = useScreenWidth();

  const [driveFolder, setDriveFolder] = useState("");
  return (
    <div
      style={{ height: "100%" }}
      className="border  d-flex align-items-center justify-content-center mb-2"
    >
      {isAdmin(user?.role) ? (
        <div className="w-75 gap-2 d-flex flex-column justify-content-center align-items-center">
          <h6 className="text-center">
            This user does not have any associated google drive folder. Please
            provide a url or id of the drive folder associated with this user!
          </h6>
          <div className="d-flex justify-content-center w-100 gap-2">
            {" "}
            <FormControl
              placeholder="Google drive URL or folder ID"
              size="sm"
              value={driveFolder}
              onChange={(e) => setDriveFolder(e.target.value)}
              className={` w-${screenSize === "lg" ? "50" : "100"}`}
            />
            <Button
              variant="success"
              className="text-white d-flex align-items-center gap-1"
              size="sm"
              onClick={() => {
                let driveFolderId = extractFolderId(driveFolder);
                onAddDriveFolder(driveFolderId);
              }}
            >
              <PlusLg /> <h6 className="mb-0 smallFont">{translate("add")}</h6>
            </Button>
          </div>
        </div>
      ) : (
        <h6 className="text-center">
          No any drive folder is assigned to you. Please contact Admin for
          further information.
        </h6>
      )}
    </div>
  );
};

const UserFileManager = ({ userId }) => {
  let { user } = useAuth();
  const [fetchingUser, setFetchingUser] = useState(true);
  const [fetchedUser, setFetchedUser] = useState(null);

  const fetchUser = async () => {
    try {
      setFetchingUser(true);
      let { response, error } = await userService.getUserByUserID(userId);
      if (error) return toast.error(error);
      setFetchedUser(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingUser(false);
    }
  };

  const addDriveFolderToUser = async (driveFolderId) => {
    try {
      setFetchingUser(true);
      if (!driveFolderId) return toast.error("Drive folder ID is required");
      let updatedUser = {
        _id: fetchedUser?._id,
        email: fetchedUser?.email,
        driveFolder: driveFolderId,
      };
      let { response, error } = await userService.updateUser(updatedUser);
      if (error) return toast.error(error);
      setFetchedUser(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingUser(false);
    }
  };

  useEffect(() => {
    if (isAdmin(user?.role)) {
      fetchUser();
    } else {
      setFetchedUser(user);
      setFetchingUser(false);
    }
  }, [user, userId]);

  return (
    <div className="h-100">
      {fetchingUser ? null : fetchedUser?.driveFolder ? (
        <GoogleDriveManager googleDriveFolderId={fetchedUser?.driveFolder} />
      ) : (
        <AddGoogleDrive onAddDriveFolder={addDriveFolderToUser} />
      )}
    </div>
  );
};

export default UserFileManager;
