import classnames from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { DEFAULT_DATE_TIME_FORMAT } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import { snakeCase } from "lodash";

const StepCard = React.memo(
  ({
    isStart,
    isEnd,
    isCurrent,
    stage,
    accomplished,
    onProductionStageChange,
    productionStageHistory,
  }) => {
    const showMoreInfo = isStart || isEnd || isCurrent;
    const { translate } = useLocalization();
    const updatedDate = useMemo(() => {
      const filteredStages = productionStageHistory?.filter(
        (s) => s?.stage === stage?._id
      );

      return filteredStages?.length
        ? moment(filteredStages[filteredStages.length - 1]?.updateDate).format(
            DEFAULT_DATE_TIME_FORMAT
          )
        : null;
    }, [stage, productionStageHistory]);

    const stepClass = classnames({
      "bg-dark-green text-white": accomplished,
      "bg-gray border-dark-green": !accomplished,
      "p-relative border border-light hover": true,
    });

    return (
      <OverlayTrigger
        delay={{ hide: 250, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} width={1000}>
            <h6 className="mb-0 smallFont">{stage?.step}</h6>
          </Tooltip>
        )}
        placement={"top"}
      >
        <div
          onClick={() => onProductionStageChange(stage?._id)}
          className={stepClass}
          style={{
            height: 22,
            width: 22,
            borderRadius: "50%",
            marginLeft: isStart ? 8 : -10,
          }}
        >
          <div
            className="text-dark"
            style={{
              position: "absolute",
              maxWidth: "100px",
              right: isEnd ? 0 : undefined,
              left: isStart ? 8 : undefined,
              marginTop: 20,
            }}
          >
            <h6 className="smallFont truncate mb-0 fw-bold">
              {translate(snakeCase(stage?.step)) || stage?.step || ""}
            </h6>
            {accomplished && (
              <h6 className="tiny truncate mb-0">{updatedDate}</h6>
            )}
          </div>
        </div>
      </OverlayTrigger>
    );
  }
);

const ProductionStageProgress = ({
  className,
  label,
  productionStages,
  productionStageHistory,
  currentProductionStage,
  onProductionStageChange,
}) => {
  const [stepPositions, setStepPositions] = useState([]);

  function calculatePositions(productionStages) {
    const numStages = productionStages?.length;
    const step = 100 / (numStages - 1);
    const positions = [];
    for (let i = 0; i < numStages; i++) {
      positions.push(i === 0 ? 0.1 : step * i);
    }
    return positions;
  }

  const percent = useMemo(() => {
    const valuePosition = productionStages?.findIndex(
      (s) => s?._id === currentProductionStage
    );
    return stepPositions[valuePosition] ?? 0;
  }, [currentProductionStage, productionStages, stepPositions]);

  useEffect(() => {
    if (productionStages?.length > 0) {
      setStepPositions(calculatePositions(productionStages));
    } else {
      setStepPositions([]);
    }
  }, [productionStages]);
  return (
    <div className={`${className}`} style={{ height: "65px" }}>
      <h6 className="mid mb-2">{label}</h6>
      <ProgressBar
        percent={percent}
        filledBackground="#28a745"
        stepPositions={stepPositions}
        height={20}
      >
        {stepPositions?.map((_, index) => {
          let stage = productionStages?.[index];
          return (
            <Step key={index}>
              {({ accomplished }) => (
                <StepCard
                  isStart={index === 0}
                  isEnd={stepPositions?.length - 1 === index}
                  isCurrent={stage?._id === currentProductionStage}
                  stage={stage}
                  productionStageHistory={productionStageHistory}
                  accomplished={accomplished}
                  onProductionStageChange={onProductionStageChange}
                />
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default ProductionStageProgress;
