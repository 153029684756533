import React from "react";
import AppModal from "../../../common/AppModal";
import DraggableModal from "../../../common/DraggableModal";
import AddEditUserRecordForm from "../../../forms/AddEditUserRecordForm";
import useLocalization from "../../../../hooks/useLocalization";

const AddEditRecordModal = ({
  show,
  onHide,
  data,
  editMode,
  onSubmit,
  showProgress,
  onDeleteClick,
}) => {
  const { translate } = useLocalization();
  return (
    <DraggableModal
      // height={310}
      width={{ sm: 300, md: 400, lg: 400 }}
      show={show}
      onHide={onHide}
      title={translate(editMode ? "update_record" : "add_record")}
      modalBodyClassName="p-1"
      hasOverlay
    >
      {show && (
        <AddEditUserRecordForm
          initialValues={data}
          editMode={editMode}
          onSubmit={onSubmit}
          onDeleteClick={onDeleteClick}
          showProgress={showProgress}
        />
      )}
    </DraggableModal>
  );
};

export default AddEditRecordModal;
