import React from "react";
import CustomMultiSelect from "./CustomMultiSelect";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";

const UserDropdown = ({
  selecteduser,
  onChange,
  height = "25px",
  maxToShow,
  maxItemCustomMessage,
  isMultiSelect = false,
  closeMenuOnSelect = true,
}) => {
  const users = useAppChoices("users");
  const { translate } = useLocalization();
  return (
    <CustomMultiSelect
      isMulti={isMultiSelect}
      selectedItems={selecteduser}
      items={users
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
        ?.map((u) => ({ label: u?.name, value: u?._id }))}
      onChange={onChange}
      placeholder={translate("select_user")}
      height={height}
      closeMenuOnSelect={closeMenuOnSelect}
      maxToShow={maxToShow}
      maxItemCustomMessage={maxItemCustomMessage}
    />
  );
};

export default UserDropdown;
