import React, { useEffect, useRef } from "react";

const InfiniteScrollList = ({ items, renderItem, onReachBottom }) => {
  const loader = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onReachBottom(); // Call the callback when bottom is reached
        }
      },
      { threshold: 1.0 }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [onReachBottom]);

  return (
    <div>
      {items.map((item, index) => renderItem(item, index))}
      <div ref={loader} style={{ height: "50px", margin: "10px" }} />
    </div>
  );
};

export default InfiniteScrollList;
