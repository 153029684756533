import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

export const WebSocketContext = createContext();

export const BROADCAST_TYPES = {
  CONTACT_CREATE: "CONTACT_CREATED",
  CONTACT_UPDATE: "CONTACT_UPDATED",
  CONTACT_DELETE: "CONTACT_DELETED",
  APPOINTMENT_CREATE: "APPOINTMENT_CREATED",
  APPOINTMENT_UPDATE: "APPOINTMENT_UPDATED",
  APPOINTMENT_DELETE: "APPOINTMENT_DELETED",
  CONTRACT_CREATE: "CONTRACT_CREATED",
  CONTRACT_UPDATE: "CONTRACT_UPDATED",
  CONTRACT_DELETE: "CONTRACT_DELETED",
  APPOINTMENT_NOTIFICATION: "APPOINTMENT_NOTIFICATION",
};

export const WebSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [clientId, setClientId] = useState(null); // Store the client ID
  const [messageQueue, setMessageQueue] = useState([]); // Queue to store messages
  const [processingMessage, setProcessingMessage] = useState(false); // To handle processing state
  const [message, setMessage] = useState(null);
  const url = process.env.REACT_APP_WEB_SOCKET_URL;

  const socketRef = useRef(null); // Use useRef to store the WebSocket instance

  useEffect(() => {
    const socket = new WebSocket(url);
    socketRef.current = socket; // Assign the WebSocket instance to the ref

    socket.onopen = () => {
      setIsConnected(true);
      console.log("Connected to WebSocket");
    };

    // On receiving a message from the server
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // Check if the message is assigning a client ID
      if (data.type === "ASSIGN_CLIENT_ID") {
        setClientId(data.clientId); // Set the client ID in state
      } else {
        // Add the message to the queue
        setMessageQueue((prevQueue) => [...prevQueue, data]);
      }
    };

    // On disconnection
    socket.onclose = () => {
      setIsConnected(false);
      setClientId(null);
      console.log("Disconnected from WebSocket");
    };

    // Clean up the connection when the component is unmounted
    return () => {
      socketRef.current.close();
    };
  }, [url]);

  useEffect(() => {
    if (clientId) {
      sessionStorage.setItem("clientId", clientId);
    } else {
      sessionStorage.removeItem("clientId");
    }
  }, [clientId]);

  // Process messages one at a time
  useEffect(() => {
    const processMessage = async () => {
      if (!processingMessage && messageQueue.length > 0) {
        setProcessingMessage(true);
        const currentMessage = messageQueue[0];

        // Handle the message here (this would be done by the consuming component)
        setMessage(currentMessage);

        // Simulate a delay to ensure proper message handling
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Remove the processed message from the queue
        setMessageQueue((prevQueue) => prevQueue.slice(1));
        setProcessingMessage(false);
      }
    };

    processMessage();
  }, [messageQueue, processingMessage]);

  // Send a message to the server
  const sendMessage = (message) => {
    if (socketRef.current && isConnected) {
      const clientId = sessionStorage.getItem("clientId"); // Retrieve clientId from sessionStorage
      const messageWithClientId = { ...message, clientId }; // Attach clientId to the message
      socketRef.current.send(JSON.stringify(messageWithClientId)); // Send the message as JSON
    }
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const contextObj = {
    isConnected,
    clientId,
    message,
    sendMessage,
    clearMessage,
  };

  return (
    <WebSocketContext.Provider value={contextObj}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Uncomment if you want to use this in your components
export const useWebSocket = () => {
  const { clientId, message, sendMessage, clearMessage } =
    useContext(WebSocketContext);

  return {
    clientId,
    message,
    sendMessage,
    clearMessage,
  };
};
