import React from "react";
import AppModal from "../common/AppModal";
import AddReferralForm from "../forms/AddReferralForm";

const CreateNewReferralModal = ({
  show,
  onHide,
  data,
  showProgress,
  onSubmit,
}) => {
  return (
    <AppModal title={"Create new referral"} show={show} onHide={onHide} modalBodyClassName="p-1">
      <AddReferralForm
        initialValues={data}
        onSubmit={onSubmit}
        showProgress={showProgress}
      />
    </AppModal>
  );
};

export default CreateNewReferralModal;
