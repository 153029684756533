import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import AppModal from "../common/AppModal";
import Loader from "../common/Loader";
import SendWhatsappCampaignForm from "../forms/SendWhatsappCampaignForm";

const SendWhatsappCampaignModal = ({
  show,
  onHide,
  showProgress,
  onSubmit,
  salespersonMode = false,
}) => {
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    setFetchingWhatsappTemplates(false);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
  };

  useEffect(() => {
    if (show && !salespersonMode) {
      fetchTemplates();
    } else {
      setFetchingWhatsappTemplates(false);
    }
  }, [show, salespersonMode]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={
        salespersonMode ? "Send Whatsapp Campaign" : "Create Whatsapp Campaign"
      }
      modalBodyClassName="p-1"
    >
      <div className="px-2 py-1">
        {fetchingWhatsappTemplates ? (
          <Loader />
        ) : (
          <SendWhatsappCampaignForm
            onSubmit={(value) => onSubmit([value])}
            showProgress={showProgress}
            whatsappTemplates={whatsappTemplates}
            salespersonMode={salespersonMode}
          />
        )}
      </div>
    </AppModal>
  );
};

export default SendWhatsappCampaignModal;
