import React, { useMemo } from "react";
import {
  Button,
  Col,
  Form,
  FormCheck,
  FormControl,
  Row,
} from "react-bootstrap";
import { Kanban, Table } from "react-bootstrap-icons";
import { DEAL_STATUSES, DEAL_VIEWS } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import CustomMultiSelect from "../common/CustomMultiSelect";
import Switch from "react-switch";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import UserDropdown from "../common/UserDropdown";

const DealsTabAndFilter = ({
  activeView,
  setActiveView,
  products,
  users,
  actionBarRef,
  boardViewFilters,
  setBoardViewFilters,
  showFoatingContract,
  setShowFloatingContract,
  showDealsWithRemainingPayment,
  toggleRemainingPaymentDealFilter,
  showTableFloatingContract,
  setShowTableFloatingContract,
}) => {
  let { translate } = useLocalization();
  const { screenWidth } = useScreenWidth();
  const mobileView = useMemo(() => screenWidth <= 800, [screenWidth]);

  return (
    <Row className="align-items-center mx-0 mb-1 border-bottom py-2">
      <Col
        lg={5}
        md={4}
        xs={12}
        sm={5}
        className="p-0 m-0 d-flex align-items-center gap-2"
      >
        {DEAL_VIEWS?.map((view) => (
          <Button
            key={view?.name}
            className={`fw-bold px-2 py-1 d-flex align-items-center`}
            size="sm"
            style={{ fontSize: 13 }}
            variant={activeView === view?.name ? "dark" : "outline-dark"}
            onClick={() => setActiveView(view?.name)}
          >
            {view.name === "table" ? (
              <Table className="mx-1" />
            ) : (
              <Kanban className="mx-1" />
            )}
            {view?.label}
          </Button>
        ))}
      </Col>
      {activeView === "table" && (
        <>
          <Col
            xs={8}
            sm={5}
            md={6}
            lg={6}
            className="m-0 d-flex align-items-start justify-content-end "
          >
            <Form.Check
              id="show_deals_with_remaining_payment"
              checked={showDealsWithRemainingPayment}
              onChange={(e) =>
                toggleRemainingPaymentDealFilter(e.target.checked)
              }
              label={
                <h6
                  id="show_deals_with_remaining_payment"
                  className="smallFont mb-0"
                >
                  {translate("show_deals_with_remaining_payment")}
                </h6>
              }
            />
          </Col>{" "}
          <Col
            xs={4}
            sm={2}
            md={2}
            lg={1}
            className="m-0 d-flex align-items-start justify-content-end "
          >
            {" "}
            <div>
              <h6 className="smallFont fw-bold mb-0">
                {translate("show_floating")}
              </h6>{" "}
              <Switch
                checked={showTableFloatingContract}
                onChange={(checked) => setShowTableFloatingContract(checked)}
                height={15}
                width={40}
                offHandleColor="#d1e6cc"
                onHandleColor="#d1e6cc"
              />{" "}
            </div>
          </Col>
        </>
      )}
      {activeView === "board" && (
        <>
          <Col
            xs={4}
            sm={2}
            md={4}
            lg={1}
            className="m-0 d-flex align-items-start justify-content-end "
          >
            {" "}
            <div>
              <h6 className="smallFont fw-bold mb-0">
                {translate("show_floating")}
              </h6>{" "}
              <Switch
                checked={showFoatingContract}
                onChange={(checked) => setShowFloatingContract(checked)}
                height={15}
                width={40}
                offHandleColor="#d1e6cc"
                onHandleColor="#d1e6cc"
              />{" "}
            </div>
          </Col>
          <Col xs={8} sm={5} md={4} lg={2} className="m-0">
            <h6 className="smallFont fw-bold mb-0">{translate("products")}</h6>
            <CustomMultiSelect
              items={products?.map((product) => ({
                _id: product._id,
                name: product.description,
              }))}
              selectedItems={boardViewFilters?.product}
              onChange={(product) =>
                setBoardViewFilters({ ...boardViewFilters, product })
              }
              height="26px"
              maxItemCustomMessage={(length) => `+ ${length} more`}
              placeholder={translate("select_product")}
              maxToShow={mobileView ? 1 : 5}
            />
          </Col>{" "}
          <Col xs={6} sm={5} md={4} lg={2} className="m-0">
            <h6 className="smallFont fw-bold mb-0">
              {translate("assigned_to")}
            </h6>
            <UserDropdown
              selecteduser={boardViewFilters?.assignedTo}
              onChange={(assignedTo) =>
                setBoardViewFilters({ ...boardViewFilters, assignedTo })
              }
              maxItemCustomMessage={(length) => `+ ${length} more`}
              height="26px"
              maxToShow={mobileView ? 1 : 5}
              isMultiSelect
              closeMenuOnSelect={false}
            />
          </Col>
          <Col xs={6} sm={5} md={4} lg={2} className="m-0">
            <h6 className="smallFont fw-bold mb-0">{translate("status")}</h6>
            <CustomMultiSelect
              items={DEAL_STATUSES?.map((status) => ({
                _id: status,
                name: status === "Signed" ? "Deal won" : status,
              }))}
              selectedItems={boardViewFilters?.status}
              onChange={(status) => {
                setBoardViewFilters({ ...boardViewFilters, status });
              }}
              maxItemCustomMessage={(length) => `+ ${length} more`}
              height="26px"
              placeholder={translate("select_status")}
              maxToShow={mobileView ? 1 : 5}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default DealsTabAndFilter;
