import React from "react";
import useLocalization from "../../../hooks/useLocalization";
import { snakeCase } from "lodash";

const Title = ({ field: { label, required }, editMode, className, style }) => {
  const { translate } = useLocalization();
  return (
    <p style={style} className={`mb-0 ${className}`}>
      <span className="text-dark smallFont">{translate(snakeCase(label)) || label}</span>
      {required && editMode && <span className="text-danger">* </span>}
    </p>
  );
};

export default Title;
