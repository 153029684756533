import React from "react";
import { Button, Col, Dropdown, FormCheck, Row } from "react-bootstrap";
import { ArrowDown, ArrowUp, Funnel } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import AutoCompleteSearch from "./AutoCompleteSearch";

const SearchArea = ({
  query,
  onQueryChange,
  onSearchClick,
  disabled,
  showMaxLimitOptions,
  maxLimit,
  maxLimitOptions,
  onMaxLimitOptionChange,
  showSortOptions,
  sortBy,
  sortByOptions,
  onSortByOptionChange,
  descSort,
  onDescSortButtonClick,
  onFilterButtonClick,
  recommendations = [],
  setRecommendations,
  onRecommendationClick,
  showFilterOptions,
  showSearchButton
}) => {
  const { translate } = useLocalization();

  return (
    <Row className="">
      <Col
        xs={showFilterOptions ? 10 : 12}
        sm={showMaxLimitOptions ? 6 : showFilterOptions ? 11 : 12}
        lg={showMaxLimitOptions ? 7 : showFilterOptions ? 11 : 12}
        className="px-1"
      >
        <AutoCompleteSearch
          query={query}
          onQueryChange={onQueryChange}
          showSearchButton={showSearchButton}
          onSearchClick={onSearchClick}
          disabled={disabled}
          recommendations={recommendations}
          setRecommendations={setRecommendations}
          onRecommendationClick={onRecommendationClick}
        />
      </Col>
      {showFilterOptions && (
        <Col xs={2} sm={1} lg={1} className="px-1 d-sm-none">
          <Button
            size="sm"
            variant="dark"
            className="w-100"
            onClick={onFilterButtonClick}
            disabled={disabled}
          >
            <Funnel className="align-text-top" />
          </Button>
        </Col>
      )}
      {showMaxLimitOptions && (
        <Col
          xs={4}
          sm={{ span: 2, offset: 0 }}
          lg={{ span: 2, offset: 0 }}
          className="px-1"
        >
          <Dropdown className="mb-1 mb-md-0">
            <Dropdown.Toggle
              size="sm"
              disabled={disabled}
              className="w-100"
              variant="outline-dark"
              id="dropdown-basic"
            >
              {translate("showing")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {maxLimitOptions.map((option) => (
                <Dropdown.Item
                  active={option === maxLimit}
                  key={option}
                  onClick={() => onMaxLimitOptionChange(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      )}
      {showSortOptions && (
        <>
          <Col
            xs={showMaxLimitOptions ? 6 : 10}
            sm={showMaxLimitOptions ? 2 : 3}
            lg={{ span: 1, offset: 0 }}
            className={`px-1`}
          >
            <Dropdown className="mb-1 mb-md-0">
              <Dropdown.Toggle
                size="sm"
                disabled={disabled}
                className="w-100"
                variant="outline-dark"
                id="dropdown-basic"
              >
                {translate("sort_by")}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortByOptions.map((option) => (
                  <Dropdown.Item
                    active={option.key === sortBy}
                    key={option.key}
                    onClick={() => onSortByOptionChange(option.key)}
                  >
                    {option.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={2} sm={1} lg={{ span: 1, offset: 0 }} className="px-1">
            <Button
              variant="dark"
              className="w-100"
              size="sm"
              disabled={disabled}
              onClick={onDescSortButtonClick}
            >
              {descSort ? (
                <ArrowDown className="text-align-top" />
              ) : (
                <ArrowUp className="text-align-top" />
              )}
            </Button>
          </Col>
        </>
      )}
      {showFilterOptions && (
        <Col xs={2} sm={1} lg={1} className="px-1 d-none d-sm-block">
          <Button
            size="sm"
            variant="dark"
            className="w-100"
            onClick={onFilterButtonClick}
            disabled={disabled}
          >
            <Funnel className="align-text-top" />
          </Button>
        </Col>
      )}

      {(showSortOptions || showMaxLimitOptions) && (
        <Col xs={12} className="d-none d-sm-block">
          <p className="text-end my-1 smallFont">
            {showMaxLimitOptions && (
              <>
                <b>{translate("showing")}: </b>
                <span className="text-muted me-3">
                  {translate("showing_n_items", { n: maxLimit })}
                </span>
              </>
            )}

            {showSortOptions && (
              <>
                <b>{translate("sorting_by")}: </b>
                <span className="text-muted">
                  {sortByOptions.find((o) => o.key === sortBy)?.title || "N/A"}
                </span>
              </>
            )}
          </p>
        </Col>
      )}
    </Row>
  );
};

export default SearchArea;
