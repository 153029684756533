import { startCase } from "lodash";
import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { getVariantFromStatus } from "../../helpers/ui";
import useLocalization from "../../hooks/useLocalization";

function isDateString(inputStr) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  return dateRegex.test(inputStr);
}

const getChangeText = (value, change, status) => {
  return (
    <span>
      {change && (
        <span>
          {" "}
          {change}{" "}
          {status ? (
            <Badge
              style={{ fontSize: 10 }}
              variant={getVariantFromStatus(value)}
            >
              {value}
            </Badge>
          ) : (
            <b>
              {isDateString(value)
                ? moment(value).format("MMMM Do YYYY, h:mm a")
                : typeof value !== "object"
                ? value
                : value?.name || value?.title}
            </b>
          )}
        </span>
      )}
    </span>
  );
};

const getBadgeFromAction = (action, className) => {
  let variant = "info";
  let text = "";
  switch (action) {
    case "create":
      text = `Create`;
      variant = "success";
      break;
    case "update":
      text = `Update`;
      variant = "dark";
      break;
    case "status":
      text = `Status Change`;
      variant = "danger";
      break;
    default:
      text = "";
      break;
  }

  return (
    <Badge style={{ fontSize: 14 }} variant={variant} className={className}>
      {text}
    </Badge>
  );
};

const LogItem = ({ log }) => {
  const { translate } = useLocalization();
  return (
    <div className="w-100 border p-1 my-1">
      <div style={{ fontSize: 12 }} className="mb-1">
        <b>{log?.["name"]}</b> {translate("made_the_following_change_on")}{" "}
        <span className="text-muted font-weight-bold">
          {moment(log?.["timestamp"]).format("MMMM Do YYYY, h:mm a")}
        </span>
        {log &&
          log.changes.map((l) => (
            <div className="mt-1">
              <b>{startCase(l.fieldName)}</b>
              {l.arrayActions ? (
                <div className="mx-3 my-1 rounded bg-light p-1">
                  {l.arrayActions.added?.length > 0 && (
                    <>
                      <h6 className="mb-1 smallFont">
                        <span className="fw-bold">{translate("added")}:</span>{" "}
                        {l.arrayActions.added.join(", ")}
                      </h6>
                    </>
                  )}
                  {l.arrayActions.updated?.length > 0 && (
                    <>
                      <h6 className="mb-1 smallFont">
                        <span className="fw-bold">{translate("updated")}:</span>{" "}
                        <div className="ms-2 mt-1 border rounded p-1">
                          {l.arrayActions.updated.map((o) => (
                            <h6 className="smallFont mb-0">
                              <span className="fw-bold">
                                {translate("from")}:
                              </span>{" "}
                              {o.oldValue}{" "}
                              <span className="fw-bold">
                                {translate("to")}:
                              </span>{" "}
                              {o.newValue}
                            </h6>
                          ))}
                        </div>
                      </h6>
                    </>
                  )}
                  {l.arrayActions.removed?.length > 0 && (
                    <>
                      <h6 className="mb-1 smallFont">
                        <span className="fw-bold">{translate("removed")}:</span>{" "}
                        {l.arrayActions.removed.join(", ")}
                      </h6>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {l?.fromValue &&
                    getChangeText(
                      l?.fromValue,
                      translate("from"),
                      l.fieldName === "status"
                    )}
                  {l?.toValue &&
                    getChangeText(
                      l?.toValue,
                      translate("to"),
                      l.fieldName === "status"
                    )}
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LogItem;
