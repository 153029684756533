import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Button, FormSelect } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import HorizontalProgress from "../../common/HorizontalProgress";

const DeleteTagModal = ({
  tagDeleteModalMeta,
  tags,
  setDeleteTagMeta,
  onHide,
  showProgress,
  onContinueClick,
}) => {
  const { translate } = useLocalization();

  const tagsToAssignOptions = useMemo(() => {
    if (tags.length > 0 && tagDeleteModalMeta?.tagToDelete?.name) {
      return (
        tags?.filter(
          (tag) => tag.name !== tagDeleteModalMeta?.tagToDelete?.name
        ) ?? []
      );
    }
  }, [tagDeleteModalMeta, tags]);

  const handleContinue = () => {
    onContinueClick(tagDeleteModalMeta);
  };

  return (
    <AppModal
      size={"md"}
      show={Boolean(tagDeleteModalMeta)}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      <div>
        {" "}
        <div className="text-center">
          <ExclamationTriangle className="text-danger" size={30} />
          <h6 className="mt-3">
            {translate("are_you_sure_you_want_to_delete_tag")}
          </h6>
        </div>
        <div className="d-flex flex-column justify-content-center flex-wrap align-items-center mt-2">
          <h6 className="mt-2 smallFont">Assign another tag</h6>
          <FormSelect
            size="sm"
            className="w-50"
            value={tagDeleteModalMeta?.alternativeTag}
            onChange={(e) => {
              let val = e.target.value;
              setDeleteTagMeta({
                ...tagDeleteModalMeta,
                alternativeTag:  val,
              });
            }}
          >
            <option value={"unassign"}>Unassign</option>
            {tagsToAssignOptions?.map(({ name }) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </FormSelect>{" "}
        </div>
        {showProgress && (
          <HorizontalProgress
            text={translate(snakeCase("Please wait")) || "Please wait"}
          />
        )}
        <div className="text-end mt-4">
          <Button
            size="sm"
            className="ms-2 px-2"
            variant={"dark"}
            onClick={onHide}
            disabled={showProgress}
          >
            {translate("cancel")}
          </Button>
          <Button
            size="sm"
            className="ms-2 px-2 text-white"
            variant={"success"}
            onClick={handleContinue}
            disabled={showProgress}
          >
            {translate("continue")}
          </Button>
        </div>
      </div>
    </AppModal>
  );
};

export default DeleteTagModal;
