import React from "react";
import AppModal from "../common/AppModal";
import SendWhatsappCampaignForm from "../forms/SendWhatsappCampaignForm";

const UpdateLogModal = ({
  show,
  onHide,
  log,
  showProgress,
  onUpdateCampaign,
  whatsappTemplates,
}) => {
  return (
    <AppModal
      title={"Update whatsapp campaign"}
      show={show}
      onHide={onHide}
      size={"md"}
      modalBodyClassName="p-2"
    >
      <SendWhatsappCampaignForm
        initialValues={log?.schedules[0]}
        onSubmit={onUpdateCampaign}
        showProgress={showProgress}
        whatsappTemplates={whatsappTemplates}
        submitButtonText={"Update"}
      />
    </AppModal>
  );
};

export default UpdateLogModal;
