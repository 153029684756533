import { MessageOutlined } from "@ant-design/icons";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { components } from "react-select";
import { templateUrl } from "../../helpers/global";

const WhatsappTemplateDropdownOption = ({
  isOption = false,
  whatsappTemplates,
  data,
  options,
  ...props
}) => {
  const template = whatsappTemplates.find((t) => t._id === data?.value);

  return (
    <components.SingleValue {...props}>
      {data ? (
        <div
          className={`d-flex hover border-bottom align-items-center justify-content-between ${
            isOption ? "hover-light" : ""
          } px-2 py-1`}
        >
          <a
            className="mx-1 truncate"
            onClick={(e) => {
              e.stopPropagation();
            }}
            target="_blank"
            href={templateUrl(template)}
          >
            {template?.templateName}
          </a>

          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip" style={{ zIndex: 9999999999 }}>
                {template?.messageBody}
              </Tooltip>
            }
          >
            <Button className="p-0" variant="transparent" size="sm">
              <MessageOutlined className="text-dark" />
            </Button>
          </OverlayTrigger>
        </div>
      ) : (
        ""
      )}
    </components.SingleValue>
  );
};
export default WhatsappTemplateDropdownOption;
