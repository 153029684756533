import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide the user name"),
  email: Yup.string().email("Email is not valid"),
  phone: Yup.string(),
  notes: Yup.string(),
});

const AddEditReferrerForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  const { user } = useAuth();

  return (
    <Formik
      initialValues={{
        name: initialValues?.name || "",
        email: initialValues?.email || "",
        phone: initialValues?.phone || "",
        notes: initialValues?.notes || "",
      }} // setting default role to Admin
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({ ...values });
      }}
    >
      {({ submitForm }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("email")}
                  </FormLabel>
                  <Field
                    name="email"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("phone_number")}
                  </FormLabel>
                  <Field
                    name="phone"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />

                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("notes")}
                  </FormLabel>
                  <Field
                    name="notes"
                    type="text"
                    as="textarea"
                    className="form-control form-control-sm"
                    size="sm"
                  />

                  <ErrorMessage
                    name="notes"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>
            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "save" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditReferrerForm;
