import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BrowserChrome,
  Facebook,
  PencilSquare,
  Reception2,
} from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import ContentLoader from "react-content-loader";
import MarketingTable from "../MarketingTable";

const StatCard = ({ name, value, variant, icon: Icon }) => {
  const { translate } = useLocalization();

  return (
    <div className={`custom-card h-100 d-flex  align-items-center`}>
      <div className="d-flex w-75 flex-column justify-content-between">
        <h6 className="xlarge mb-0">{translate(snakeCase(name)) ?? ""}</h6>
        <h6 className="xxxlarge fw-bold">{value}</h6>
      </div>
      <div className="d-flex w-25 justify-content-end">
        <Icon size={30} className={`text-${variant}`} />
      </div>
    </div>
  );
};

const MarketingStatBoard = ({ fetching }) => {
  const { translate } = useLocalization();
  const generalStats = useMemo(() => {
    return [
      {
        name: "total number of leads by channel",
        variant: "info",
        values: [
          {
            name: "facebook campaign",
            icon: Facebook,
            value: 67,
            variant: "info",
          },
          {
            name: "website",
            icon: BrowserChrome,
            value: 27,
            variant: "primary",
          },
          {
            name: "manual",
            icon: PencilSquare,
            value: 42,
            variant: "secondary",
          },
        ],
      },
      {
        name: "total number of sales volume by channel",
        variant: "success",
        values: [
          {
            name: "facebook campaign",
            icon: Facebook,
            value: 54,
            variant: "info",
          },
          {
            name: "website",
            icon: BrowserChrome,
            value: 87,
            variant: "primary",
          },
          {
            name: "manual",
            icon: PencilSquare,
            value: 21,
            variant: "secondary",
          },
        ],
      },
    ];
  }, []);

  return (
    <div>
      <Row className="my-2 mx-1">
        {generalStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={6} md={12} sm={12} xs={12}>
            {fetching ? (
              <ContentLoader viewBox="0 0 280 100">
                <rect x="0" y="0" rx="4" ry="4" width="250" height="15" />
                <rect x="0" y="20" rx="5" ry="3" width="200" height="10" />
                <rect x="0" y="35" rx="5" ry="5" width="50" height="50" />{" "}
                <rect x="80" y="35" rx="5" ry="5" width="50" height="50" />{" "}
                <rect x="160" y="35" rx="5" ry="5" width="50" height="50" />
              </ContentLoader>
            ) : (
              <div className={`custom-card h-100 `}>
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <h6 className="xxxlarge text-muted mb-0">
                      {translate(snakeCase(stat?.name)) ?? ""}
                    </h6>
                  </div>
                  <div
                    style={{ borderRadius: 50 }}
                    className={`d-flex justify-content-center align-items-center p-2 bg-${stat?.variant}`}
                  >
                    <Reception2 size={30} className="text-white" />
                  </div>
                </div>

                <Row className="mt-2 pt-2">
                  {" "}
                  {stat?.values?.map((v, index) => {
                    return (
                      <Col
                        className="p-2"
                        key={index}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <StatCard {...v} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </Col>
        ))}
      </Row>
      <MarketingTable />
    </div>
  );
};

export default MarketingStatBoard;
