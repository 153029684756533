import React, { useMemo } from "react";
import useContactStatus from "../../hooks/useContactStatus";
import useLocalization from "../../hooks/useLocalization";

const ContactOverviewCard = ({
  className = "p-1  border hover my-1",
  contact,
  selectedContact,
  onContactSelect,
  openContact,
  hideStatus = false,
}) => {
  const { translate } = useLocalization();
  const { getContactStatus } = useContactStatus();

  const contactStatus = useMemo(
    () => getContactStatus(contact.status),
    [contact, getContactStatus]
  );
  return (
    <div
      style={{ minWidth: "48%" }}
      className={` ${
        selectedContact?._id === contact?._id && "bg-primary text-white"
      } ${className}`}
      onClick={() => {
        if (onContactSelect) {
          onContactSelect(contact);
        }
        if (openContact) {
          const contactUrl = `/contacts?contact=${contact?._id}&view=contact`;
          window.open(contactUrl, "_blank");
        }
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <h6 className="smallFont mb-0 fw-bold">
          {contact?.firstName + " " + contact?.lastName}{" "}
        </h6>{" "}
        {!hideStatus && (
          <div
            style={{ fontSize: 10 }}
            className={`bg-${contactStatus.color} px-2 py-0 text-white rounded`}
          >
            {contactStatus.label}
          </div>
        )}
      </div>
      <div className="d-flex">
        <div className="d-flex">
          <h6 className="tiny mb-0">
            {contact?.emails?.length > 0
              ? contact?.emails?.join(", ")
              : "No emails"}{" "}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ContactOverviewCard;
