import React from "react";
import GoogleLogo from "../../assets/GoogleLogo.png";
const GoogleEventBadge = ({}) => (
  <div
    className="d-flex align-items-center mx-2 hover rounded"
    style={{
      background: "#3383fc",
      border: "2px solid #3383fc",
    }}
  >
    <div
      className="d-flex align-items-center justify-content-center bg-white rounded"
      style={{ padding: 2 }}
    >
      <img src={GoogleLogo} height={18} width={18} alt="Google" />
    </div>
    <h6 className="mb-0 smallFont text-white mx-1">
      This event is from Google
    </h6>
  </div>
);
export default GoogleEventBadge;
