import React, { createContext, useContext, useState } from "react";

export const SearchAndFilterContext = createContext();

export const SearchAndFilterProvider = ({ children }) => {
  const [searchBoxOptions, setSearchBoxOptions] = useState(null);
  const [isSearchBoxDisabled, setIsSearchBoxDisabled] = useState(null);
  const [isClearingFilter, setIsClearingFilter] = useState(false);

  const contextObj = {
    searchBoxOptions: searchBoxOptions || {},
    setSearchBoxOptions,
    isSearchBoxDisabled,
    setIsSearchBoxDisabled,
    setIsClearingFilter,
    isClearingFilter,
  };

  return (
    <SearchAndFilterContext.Provider value={contextObj}>
      {children}
    </SearchAndFilterContext.Provider>
  );
};

export const useSearchAndFilterBox = () => {
  const {
    searchBoxOptions,
    setSearchBoxOptions,
    isSearchBoxDisabled,
    setIsSearchBoxDisabled,
    setIsClearingFilter,
    isClearingFilter,
  } = useContext(SearchAndFilterContext);
  return {
    searchBoxOptions,
    setSearchBoxOptions,
    isSearchBoxDisabled,
    setIsSearchBoxDisabled,
    setIsClearingFilter,
    isClearingFilter,
  };
};
