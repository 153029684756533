import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import useAppChoices from "../../../hooks/useAppChoices";
import { SALES_ROLE } from "../../../helpers/constants";
import { GROUPPED_USER_BASED_ON_ROLE } from "../../../helpers/global";

const SalespersonDropdown = ({ value, onChange }) => {
  let users = useAppChoices("users");
  return (
    <CustomMultiSelect
      items={GROUPPED_USER_BASED_ON_ROLE({ users, priotize: SALES_ROLE })}
      selectedItems={value}
      isMulti
      isGroupped
      height="30px"
      onChange={onChange}
      placeholder="Select"
      maxToShow={100}
    />
  );
};

export default SalespersonDropdown;
