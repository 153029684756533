import { isNumber } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { ABORT_ERROR } from "../../helpers/api";
import { ADMIN_ROLE, DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import { getCommissionsTableColumns } from "../../helpers/dataSheetConstants";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { contractService } from "../../services/contractService";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";
import { findIfObjectIsInArrayOrNot } from "../contacts/BrowseContacts";
import EditCommissionsForm from "./EditCommissionsForm";
import { referralService } from "../../services/referralServices";
import { updateItemsInArray } from "../../helpers/global";
import { isAdminOrManager } from "../../helpers/session";
import useSelection from "../../hooks/useSelection";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};
const checkIfDealHasPayableCommissions = (deal, referredBy) => {
  const { payments = [], commissions = [] } = deal;

  if (!commissions.length && payments.length) return true;

  const maxCommissionDate = Math.max(
    ...commissions
      .filter((cm) => !Boolean(referredBy) || cm.type === "Referrer")
      .map((c) => new Date(c.commissionPaidDate || 0).getTime())
  );

  return payments.some((p) => new Date(p.date).getTime() > maxCommissionDate);
};

const BrowseCommissions = ({
  referredBy,
  allowFilter = true,
  allowSort = true,
  editable = true,
  columnToHide = [],
  specificClientMode = false,
  maxTableHeight = `calc(100vh - 135px)`,
}) => {
  const abortControllerRef = useRef(null);
  const { message } = useWebSocket();
  const users = useAppChoices("users");
  const products = useAppChoices("products");
  const units = useAppChoices("units");

  const { translate } = useLocalization();
  const { user } = useAuth();
  const { searchBoxOptions } = useSearchAndFilterBox();

  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState({
    key: "createdAt",
    order: "desc",
  });
  const [fetchingFirstPageDeals, setFetchingFirstPageDeals] = useState(false);
  const [fetchingMoreDeals, setFetchingMoreDeals] = useState(false);

  //original products array
  const [deals, setDeals] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [updatingContract, setUpdatingContract] = useState(false);
  const [deleteContractModalMeta, setDeleteContractModalMeta] = useState(null);

  const [showDealsWithPayableCommissions, setShowDealsWithPayableCommissions] =
    useState(false);
  // referrals
  const [referrals, setReferrals] = useState([]);
  // expanded row
  const [activeDeal, setActiveDeal] = useState(null);

  const handleDealChange = (values) => {
    setActiveDeal({ ...activeDeal, ...values });
  };

  const onRowClick = useCallback(
    (contract) => {
      setActiveDeal(activeDeal?._id === contract._id ? null : contract);
    },
    [editable, activeDeal]
  );

  const scrollToContactRow = (scrollHeight) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(deals);

  const tableColumns = useMemo(
    () =>
      getCommissionsTableColumns({
        columnToHide,
        users,
        products,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        selectAllMode,
        translate,
        referredBy,
      }),
    [
      users,
      products,
      onToggleSelectAll,
      isItemSelected,
      onToggleSelectItem,
      selectAllMode,
      columnToHide,
      referredBy,
    ]
  );

  const fetchDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }
    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;
    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreDeals(true);
    } else {
      if (!isNotInitialFetch) {
        setFetchingFirstPageDeals(true);
      }
    }

    const { query } = searchBoxOptions;
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: [
        ...tableFilterValues,
        // ...(referredBy ? [{ key: "referredBy", value: referredBy }] : []),
      ],
      referredOnly: true,
    };

    const { response, error } = await contractService.getContracts(
      requestBody,
      signal
    );
    if (error === ABORT_ERROR) return;

    setFetchingMoreDeals(false);
    setFetchingFirstPageDeals(false);

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollHeight)) {
      scrollToContactRow(toLoadPageInfo?.scrollHeight);
    }

    let { pageNumber, totalPages, results = [], resultCount } = response;

    if (!isNotInitialFetch) {
      clearSelection();
    }

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    results = showDealsWithPayableCommissions
      ? results.filter((deal) =>
          checkIfDealHasPayableCommissions(deal, referredBy)
        )
      : results;

    setDeals((prevProducts) =>
      loadingMoreProducts ? [...prevProducts, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (
      !loadedPageInfo ||
      fetchingFirstPageDeals ||
      fetchingMoreDeals ||
      referredBy
    )
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      isNotInitialFetch: true,
    });
  };

  const onDeleteSelectedContractClick = () => {
    setDeleteContractModalMeta({
      contractToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const deleteSelectedDeals = async () => {
    const { contractToBeDeleted } = deleteContractModalMeta;

    setDeleteContractModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contractService.deleteSelectedContract({
        ids: contractToBeDeleted?.map((deal) => deal._id),
      });
      if (response) {
        let updatedDeals = [...deals].filter(
          (deal) => !contractToBeDeleted.find((c) => c._id === deal?._id)
        );
        setDeals(updatedDeals);
        if (onDealChange) {
          onDealChange(updatedDeals);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteContractModalMeta(null);
    }
  };

  const handleSave = async () => {
    try {
      setUpdatingContract(true);
      let { _id, payments = [], commissions = [] } = activeDeal;
      const { response, error } = await contractService.updateContract({
        _id,
        payments,
        commissions,
      });
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success("Deal updated successfully");
        let updatedDeals = updateItemsInArray(deals, response);
        setDeals(updatedDeals);
        setActiveDeal(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingContract(false);
    }
  };

  const fetchReferrals = async () => {
    try {
      let reqBody = {
        pageNumber: 1,
        pageSize: 10,
        sort: { createdAt: -1 },
      };
      let { response, error } = await referralService.getReferrals(reqBody);
      let { results } = response;

      setReferrals(results);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleRemainingCommissionFilter = (checked) => {
    setShowDealsWithPayableCommissions(checked);
    clearSelection();
    setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
  };

  const fetchDealsAfterUpdate = (scrollTop) => {
    // fetches contacts and scrolls back to previous position
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      scrollTop,
      isNotInitialFetch: true,
    });
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  useEffect(() => {
    setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
  }, [tableFilterValues, tableSortOption]);

  useEffect(() => {
    if (referredBy) {
      setTableFilterValues(
        updateItemsInArray(
          tableFilterValues,
          {
            key: "referredBy",
            value: referredBy?.name,
          },
          "key"
        )
      );
    }
  }, [referredBy]);

  useEffect(() => {
    if (searchBoxOptions) {
      setToLoadPageInfo({ ...initialPageInfo });
    }
  }, [searchBoxOptions]);

  useDebouncedEffect(
    () => {
      fetchDeals();
    },
    [toLoadPageInfo],
    200
  );

  useEffect(() => {
    if (message) {
      let { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTACT_UPDATE:
          setDeals(
            deals.map((d) =>
              d.contact._id === payload._id ? { ...d, contact: payload } : d
            )
          );
          break;

        case BROADCAST_TYPES.CONTACT_DELETE:
          setDeals(deals.filter((d) => d.contact._id !== payload._id));
          break;

        case BROADCAST_TYPES.CONTRACT_DELETE:
          const filterDeals = (dealsList) =>
            dealsList.filter((deal) => deal._id !== payload._id);
          setDeals(filterDeals(deals));
          break;
      }
    }
  }, [message]);

  useDebouncedEffect(
    () => {
      if (message) {
        let { type, payload } = message;

        switch (type) {
          case BROADCAST_TYPES.CONTRACT_UPDATE:
            fetchDealsAfterUpdate();
            break;

          case BROADCAST_TYPES.CONTRACT_CREATE:
            fetchDealsAfterUpdate();
            break;
        }
      }
    },
    [message],
    500
  );

  const totalPageDate = useMemo(
    () => loadedPageInfo?.resultCount,
    [loadedPageInfo]
  );

  return (
    <>
      <Container
        fluid
        className={`position-relative d-flex flex-column ${
          specificClientMode ? "p-0 m-0 h-100" : "px-2 pb-2"
        }`}
      >
        {" "}
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <h6 className="mb-0 ">
            {translate("commissions")} ({loadedPageInfo?.resultCount || 0})
          </h6>
          <Form.Check
            id="show_deals_with_payable_commissions"
            checked={showDealsWithPayableCommissions}
            onChange={(e) => toggleRemainingCommissionFilter(e.target.checked)}
            label={
              <h6
                id="show_deals_with_payable_commissions"
                className="smallFont mb-0"
              >
                {translate("show_deals_with_payable_commissions")}
              </h6>
            }
          />
        </div>
        <div className={`flex-grow-1 pt-2`}>
          <DataTable
            maxTableHeight={maxTableHeight}
            rowKey={"_id"}
            columns={tableColumns}
            data={deals}
            sortOptions={tableSortOption}
            onSortChange={setTableSortOption}
            filterValues={tableFilterValues}
            onFilterValuesChange={setTableFilterValues}
            onRowClick={onRowClick}
            expandedRowKeys={activeDeal ? [activeDeal?._id] : []}
            renderExpandedRow={(row) => (
              <EditCommissionsForm
                updatingContract={updatingContract}
                contract={activeDeal}
                referrals={referrals}
                onDealChange={handleDealChange}
                onSaveClick={handleSave}
                onClose={() => setActiveDeal(null)}
                referredBy={referredBy}
              />
            )}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreDeals}
            bottomOffset={300}
            loadingFirstPageData={fetchingFirstPageDeals}
            allowFilter={allowFilter}
            allowSort={allowSort}
          />
        </div>
        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <div className="flex-grow-1 d-flex align-items-center">
            {(selectedItems.length > 0 || selectAllMode) && (
              <>
                <Button
                  className="smallFont py-0 mx-1"
                  variant="dark"
                  size="sm"
                >
                  <span className="text-white smallFont mx-1">
                    {selectAllMode
                      ? totalPageDate - unselectedItems?.length
                      : selectedItems?.length}{" "}
                    / {totalPageDate ?? 0} Selected
                  </span>
                </Button>

                {isAdminOrManager(user?.role) && !selectAllMode && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-1 py-0 mx-1"
                    onClick={onDeleteSelectedContractClick}
                  >
                    <Trash className="mb-1" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteContractModalMeta)}
          onHide={() => setDeleteContractModalMeta(false)}
          onDismissClick={() => setDeleteContractModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contracts")}
          showProgress={deleteContractModalMeta?.showProgress}
          onContinueClick={deleteSelectedDeals}
        />
      )}
    </>
  );
};

export default BrowseCommissions;
