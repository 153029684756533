import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowLeftShort,
  ArrowRightShort,
  CheckCircleFill,
  ExclamationCircle,
  SlashCircle,
} from "react-bootstrap-icons";

import { convertToScrollToTime } from "../../../../helpers/global";
import useAuth from "../../../../hooks/useAuth";
import useLocalization from "../../../../hooks/useLocalization";
import CircularProgressBar from "../../../common/circular-progress";
import { isAdminOrManager } from "../../../../helpers/session";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  TIME_LOG_TYPE_SICK_LEAVE,
  TIME_LOG_TYPE_VACATION,
  TIME_LOG_TYPE_WORK_LOG,
  TIME_LOGS_TYPES_OPTIONS,
} from "../../../../helpers/constants";

const CustomEvent = ({ event, onApproveClick }) => {
  const { user } = useAuth();
  let { name, type, start, end, approved, autoClockedOut } = event;
  const { translate, isRTL } = useLocalization();

  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-column px-1 mt-1">
        {autoClockedOut && !approved && (
          <Alert
            className="d-flex align-items-center justify-content-between mb-2 py-1 px-1"
            role="alert"
            variant="danger"
          >
            <div className="d-flex align-items-center">
              <ExclamationCircle size={10} />
              <span className="smallest mx-1">
                {translate("auto_clocked_out")}
              </span>
            </div>
          </Alert>
        )}

        <div className="d-flex gap-2 align-items-center">
          <div style={{ width: 15 }}>
            {isAdminOrManager(user?.role) ? (
              <CheckCircleFill
                size={16}
                className={`text-${
                  approved ? "success" : "secondary"
                } hover-light`}
                onClick={(e) => {
                  e.stopPropagation();
                  onApproveClick({ ...event, approved: !Boolean(approved) });
                }}
              />
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-not-authorized">
                    {!approved
                      ? "This record needs to be approved by Admin."
                      : "This record is approved"}
                  </Tooltip>
                }
              >
                <span>
                  <CheckCircleFill
                    size={15}
                    className={`text-${approved ? "success" : "secondary"}`}
                    onClick={(e) => e.stopPropagation()}
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
          <div
            className={isRTL ? "text-end" : "text-start"}
            style={{ width: "auto", flexGrow: 1 }}
          >
            {name && (
              <h6 className="tiny fw-bold mb-0 text-dark truncate">{name}</h6>
            )}
            {type === TIME_LOG_TYPE_WORK_LOG ? (
              <h6 className="mb-0 tiny truncate text-dark">
                {moment(start).format(DEFAULT_TIME_FORMAT) +
                  " - " +
                  moment(end).format(DEFAULT_TIME_FORMAT)}
              </h6>
            ) : (
              <h6 className="mb-0 tiny truncate text-dark">
                {TIME_LOGS_TYPES_OPTIONS?.find((o) => o?.value === type)?.label}
              </h6>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const smallScreenWidth = 576;
const mediumScreenWidth = 860;

function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

const getMessages = ({ activeView, screenWidth, locale, translate }) => ({
  next:
    activeView !== "day"
      ? activeView === "week"
        ? `Next ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `Next ${capitalizeFirstLetter(activeView)}`
      : "Next",
  previous:
    activeView !== "day"
      ? activeView === "week"
        ? `Previous ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `Previous ${capitalizeFirstLetter(activeView)}`
      : "Previous",
  today:
    activeView !== "day"
      ? activeView === "week"
        ? `This ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `This ${capitalizeFirstLetter(activeView)}`
      : "Today",
  week: screenWidth <= mediumScreenWidth ? "3 days" : "Week",
  showMore: (total, events) => {
    return (
      <Button
        variant="info"
        size="sm"
        className="mx-1 px-1 py-0 d-flex align-items-center"
      >
        <span className="tiny">
          +{total} {translate("more")}{" "}
        </span>
      </Button>
    );
  },
});

const TimingWeekCalendar = ({
  onSlotSelect,
  events = [],
  mode = "picker",
  allowEditing,
  onPlanDateTimeChange,
  customActiveView,
  customStep,
  onSessionDragAndDrop,
  onCurrentlyActiveViewOnCalender,
  onAppointmentClick,
  timeAndDateToScroll,
  initialSmallScreenView,
  initialLargeScreenView,
  calendarStyles,
  locale = "en",
  activeEvent,
  onShowMoreClick,
  renderMoreInToolbar,
  onDateChange,
  fetchingEvents = false,
  calendarDate,
  setCalendarDate,
  onApproveClick,
  onCalendarViewChange,
}) => {
  const dayRefs = useRef({});
  const { translate, isRTL } = useLocalization();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectable, setSelectable] = useState(false);
  const [resizable, setResizable] = useState(false);

  const [activeView, setActiveView] = useState(
    screenWidth >= smallScreenWidth
      ? initialLargeScreenView || "month"
      : initialSmallScreenView || "day"
  );

  const [scrollToTime, setScrollToTime] = useState(
    new Date().setHours(9, 0, 0, 0)
  );

  const handleEventClick = (event) => {
    onAppointmentClick && onAppointmentClick(event);
  };

  const handleShowMoreClick = (events) => {
    onShowMoreClick && onShowMoreClick(events);
  };

  const onSlotSelectInternal = (slot) => {
    onSlotSelect && onSlotSelect({ slot });
  };

  const handleNavigate = (newDate) => {
    setCalendarDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const slotPropGetter = (date) => ({
    style: {
      fontSize: 11,
      height: 80,
      zIndex: mode === "picker" ? 1 : undefined,
      display: "flex",
    },
    className: `${selectable ? "hover hover-light" : ""} ${
      slotHasMultipleAppointments(date, events) ? "red-border-right" : ""
    }`,
  });
  const eventPropGetter = useCallback(
    (event) => {
      const eventTypeColors = {
        [TIME_LOG_TYPE_SICK_LEAVE]: {
          borderTop: ({ approved }) => (approved ? "#D8AE47" : "#757575"),
          background: ({ approved }) => (approved ? "#F1F8E9" : "#FAFAFA"),
        },
        [TIME_LOG_TYPE_VACATION]: {
          borderTop: ({ approved }) => (approved ? "#D8AE47" : "#757575"),
          background: ({ approved }) => (approved ? "#F1F8E9" : "#FAFAFA"),
        },
        [TIME_LOG_TYPE_WORK_LOG]: {
          borderTop: ({ approved, autoClockedOut }) =>
            autoClockedOut && !approved
              ? "#DC3545"
              : approved
              ? "#D8AE47"
              : "#757575",
          background: ({ approved, autoClockedOut }) =>
            approved ? "#F1F8E9" : "#FAFAFA",
        },
      };

      return {
        style: {
          fontSize: 11,
          zIndex: 10,
          cursor: "pointer",
          margin: "0px",
          background: eventTypeColors?.[event?.type]?.background(event),
          padding: 2,
          borderTop: `5px solid ${eventTypeColors?.[event?.type]?.borderTop(
            event
          )}`,
          borderLeft: "1px solid #dee2e6",
          borderRight: "1px solid #dee2e6",
          borderBottom: "1px solid #dee2e6",
        },
      };
    },
    [activeEvent]
  );

  const dayPropGetter = useCallback(
    (day) => {
      return {
        style: {
          margin: 0,
          zIndex: 2,
          fontSize: 11,
        },
      };
    },
    [activeEvent, calendarDate, activeView]
  );

  const onEventDropInternal = (dropEvent) => {
    dropEvent.end.setTime(dropEvent.end.getTime() + 1);

    if (
      slotHasOverlappingAppointments(
        dropEvent.start,
        dropEvent.end,
        events,
        dropEvent.event
      )
    )
      return;
    onSessionDragAndDrop && onSessionDragAndDrop(dropEvent);
    onPlanDateTimeChange && onPlanDateTimeChange(dropEvent);
  };

  const onEventResizeInternal = (resizeEvent) => {
    if (resizeEvent.end.getTime() - resizeEvent.start.getTime() < 15 * 60000) {
      return;
    }
    onSessionDragAndDrop && onSessionDragAndDrop(resizeEvent);
    if (
      slotHasOverlappingAppointments(
        resizeEvent.start,
        resizeEvent.end,
        events,
        resizeEvent.event
      )
    )
      return;
    onPlanDateTimeChange && onPlanDateTimeChange(resizeEvent);
  };

  const onViewInternal = (view) => {
    setActiveView(view);
    customActiveView && onCurrentlyActiveViewOnCalender(view);
  };

  const messages = useMemo(
    () => getMessages({ activeView, screenWidth, locale, translate }),
    [activeView, screenWidth, locale, translate]
  );

  useEffect(() => {
    if (timeAndDateToScroll) {
      setActiveView("week");
      setCalendarDate(timeAndDateToScroll);
      setScrollToTime(convertToScrollToTime(timeAndDateToScroll));
    }
  }, [timeAndDateToScroll]);

  useEffect(() => {
    setSelectable(allowEditing);
    setResizable(allowEditing);
  }, [mode, allowEditing, activeView]);

  useEffect(() => {
    onCalendarViewChange && onCalendarViewChange(activeView);
  }, [activeView]);

  const ToolBar = ({ date, label, onNavigate, onView, view, views }) => {
    const getLabel = () => {
      if (view === "day") {
        // day month date
        const formattedDate = moment(date).format("ddd MMMM DD");
        const terms = formattedDate.split(" ");
        const day = translate(terms[0].toLowerCase()) || terms[0];
        const month = translate(terms[1].toLowerCase()) || terms[1];
        return `${day} ${month} ${terms[2]}`;
      }

      const [month, rest] = label.split(" ");
      return `${translate(month.toLowerCase()) || month} ${rest}`;
    };
    return (
      <Row
        className={`border-start border-end d-flex  flex-wrap  align-items-center m-0`}
      >
        <Col
          xs={5}
          md={2}
          className="d-flex justify-content-between align-items-center gap-1 p-2"
        >
          {renderMoreInToolbar && renderMoreInToolbar()}
          <Button
            size="sm"
            variant={"dark text-white"}
            className="px-1 py-0"
            style={{ width: "150px" }}
            onClick={(e) => onNavigate("TODAY")}
          >
            <span className="smallFont">{translate("today")}</span>
          </Button>
          <div />
        </Col>

        <Col
          xs={7}
          md={6}
          className="d-flex justify-content-center align-items-center p-2"
        >
          <Button
            size="sm"
            variant={"outline-dark"}
            className="px-1 py-0 d-flex justify-content-center align-items-center"
            style={{ width: "50px" }}
            onClick={(e) => onNavigate("PREV")}
          >
            {isRTL ? (
              <ArrowRightShort size={20} />
            ) : (
              <ArrowLeftShort size={20} />
            )}
          </Button>
          <div
            className="d-flex align-items-center justify-content-center smallFont"
            style={{ width: 120 }}
          >
            {getLabel(label, date, view)}
          </div>
          <Button
            size="sm"
            variant={"outline-dark"}
            className="px-1 py-0 d-flex justify-content-center align-items-center"
            style={{ width: "50px" }}
            onClick={(e) => onNavigate("NEXT")}
          >
            {isRTL ? (
              <ArrowLeftShort size={20} />
            ) : (
              <ArrowRightShort size={20} />
            )}
          </Button>
        </Col>

        <Col md={4} className="p-2">
          <ButtonGroup className={`d-flex  ${isRTL && "flex-row-reverse"}`}>
            {views.map((v) => (
              <Button
                size="sm"
                variant={v === view ? "dark" : "outline-dark"}
                className="px-1 py-0"
                style={{ width: "100px" }}
                onClick={(e) => onView(v)}
                key={v}
              >
                <span className="smallFont">{translate(v)}</span>
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  return (
    <div className="position-relative">
      {fetchingEvents && (
        <div
          className="position-absolute  start-0 w-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50"
          style={{ top: 50, zIndex: 10, height: "calc(100% - 50px" }}
        >
          <CircularProgressBar size={20} />
        </div>
      )}
      {resizable ? (
        <DragAndDropCalendar
          views={["week", "month"]}
          startAccessor="start"
          endAccessor="end"
          drilldownView="agenda"
          localizer={localizer}
          style={calendarStyles}
          messages={messages}
          events={events}
          step={customStep ?? 15}
          timeslots={1}
          scrollToTime={scrollToTime}
          view={activeView}
          selectable={selectable}
          resizable={resizable}
          date={calendarDate}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          eventPropGetter={eventPropGetter}
          formats={formats}
          elementProps={{
            style: {
              fontSize: 10,
            },
          }}
          onSelectEvent={handleEventClick}
          onView={onViewInternal}
          onSelectSlot={onSlotSelectInternal}
          onShowMore={handleShowMoreClick}
          onEventDrop={onEventDropInternal}
          onNavigate={handleNavigate}
          components={{
            event: (props) => {
              return (
                <CustomEvent
                  {...props}
                  activeEvent={activeEvent}
                  onApproveClick={onApproveClick}
                />
              );
            },
            toolbar: ToolBar,
            header: ({ date, label }) => {
              return <h5 className="smallFont fw-bold mb-0">{label}</h5>;
            },
            month: {
              header: ({ date, label }) => {
                return (
                  <h5 className="smallFont fw-bold mb-0">
                    {translate(label.toLowerCase()) || label}
                  </h5>
                );
              },
            },
            week: {
              header: ({ label }) => {
                const [date, day] = label.split(" ");
                return (
                  <h5 className="smallFont fw-bold mb-0">
                    {date} {translate(day.toLowerCase()) || day}
                  </h5>
                );
              },
            },
          }}
          popup={false}
          doShowMoreDrillDown={false}
          onEventResize={onEventResizeInternal}
          culture={locale}
          rtl={isRTL}
        />
      ) : (
        <Calendar
          style={calendarStyles}
          defaultView={activeView}
          views={["day", "week", "month"]}
          step={customStep || 15}
          events={events}
          defaultDate={calendarDate}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                timeAndDateToScroll={timeAndDateToScroll}
                view={activeView}
              />
            ),
            toolbar: ToolBar,
          }}
          messages={messages}
          localizer={localizer}
          selectable={selectable}
          scrollToTime={scrollToTime}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          slotPropGetter={slotPropGetter}
          onSelectSlot={onSlotSelectInternal}
          onNavigate={handleNavigate}
          onView={onViewInternal}
        />
      )}
    </div>
  );
};

const slotFallsOutsideAllowedHours = (start, end, dayStartHour, dayEndHour) => {
  return (
    start.getHours() * 60 + start.getMinutes() < dayStartHour * 60 ||
    end.getHours() * 60 + end.getMinutes() < dayStartHour * 60 ||
    start.getHours() * 60 + start.getMinutes() > dayStartHour * 60 ||
    end.getHours() * 60 + end.getMinutes() > dayEndHour * 60
  );
};

const slotHasOverlappingAppointments = (
  start,
  end,
  appointments,
  currentPlan
) => {
  let comparingappointments = currentPlan
    ? appointments.filter((p) => p !== currentPlan)
    : appointments;
  return comparingappointments.some(
    (p) =>
      end.getTime() > p.start.getTime() && p.end.getTime() > start.getTime()
  );
};

const slotHasMultipleAppointments = (slotDate, appointments) => {
  const slotStart = slotDate;
  const slotEnd = new Date(slotDate);
  slotEnd.setMinutes(slotEnd.getMinutes() + 14, 59, 999);

  return (
    appointments.filter(
      (p) =>
        p.start.getTime() >= slotStart.getTime() &&
        p.start.getTime() <= slotEnd.getTime()
    ).length > 1
  );
};

export default TimingWeekCalendar;
