import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";

import useLocalization from "../../../hooks/useLocalization";
import useContactStatus from "../../../hooks/useContactStatus";

const StatusDropdown = ({ value, onChange }) => {
  let { translate } = useLocalization();
  const { GROUPPED_STATUS } = useContactStatus();
  return (
    <CustomMultiSelect
      items={GROUPPED_STATUS}
      onChange={onChange}
      selectedItems={value}
      isMulti={false}
      isGroupped
      placeholder="Select"
      fieldColors={GROUPPED_STATUS.flatMap((o) => o?.options)?.find(
        (option) => option?.value === value
      )}
      closeMenuOnSelect
      height="30px"
      isClearable
    />
  );
};

export default StatusDropdown;
