import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const userRecordServices = {
  getUserTimeLogs: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_USER_TIME_LOG,
      requestBody,
      signal,
      method: "POST",
    });

    return { response, error };
  },
  createUserTimeLog: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_USER_TIME_LOG,
      requestBody,
      method: "POST",
    });

    return { response, error };
  },
  deleteUserTimeLog: async (data) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.USER_TIME_LOG_WITH_ID(data?._id),
      method: "DELETE",
    });

    return { response, error };
  },
  updateUserTimeLog: async (data) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.USER_TIME_LOG_WITH_ID(data?.timeLogData?._id),
      requestBody: data,
      method: "POST",
    });

    return { response, error };
  },
  getUserRecords: async (userId) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_USER_RECORDS(userId),
      method: "GET",
    });

    return { response, error };
  },
  updateUserRecordComments: async (userId, requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_USER_RECORDS_COMMENTS(userId),
      requestBody,
      method: "POST",
    });

    return { response, error };
  },
  downloadUserLogCSV: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DOWNLOAD_USER_LOG_CSV,
      method: "POST",
      requestBody: reqBody,
    });

    return { response, error };
  },
};
