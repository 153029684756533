import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const logsService = {
  getLogs: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_LOGS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  action: async (action, requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.LOG_ACTION(action),
      requestBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
};
