import React, { useMemo } from "react";
import { Person } from "react-bootstrap-icons";
import { components } from "react-select";

const PersonSelectOption = ({ data, options, ...props }) => {
  const user = useMemo(() => {
    return data;
  }, [data]);

  return (
    <components.SingleValue {...props}>
      {user ? (
        <div className="d-flex align-items-center hover-light">
          <Person size={14} />
          <div className="flex-grow-1 mx-2 py-1">
            {!user.__isNew__ ? (
              <>
                <h6 className="fw-bold mid mb-0">{user.name}</h6>
                <h6 className="tiny mb-0">
                  {user.email ||
                    user.emails?.join(", ") ||
                    user.phone ||
                    user.phoneNumbers?.join(", ")}
                </h6>
              </>
            ) : (
              <h6 className="fw-bold mid mb-0">{user.label}</h6>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </components.SingleValue>
  );
};

export default PersonSelectOption;
