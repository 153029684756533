import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useQueryParams() {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contactId =
      searchParams.get("contact") || searchParams.get("contactId");
    const dealId = searchParams.get("deal");
    const view = searchParams.get("view");
    const apointment = searchParams.get("apointment");
    const templateId = searchParams.get("templateId");
    setQueryParams({ contactId, dealId, view, apointment, templateId });
  }, [location.search]);
  return queryParams;
}
