import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const referralService = {
  getReferrals: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_REFERRALS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  createReferral: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_REFERRALS,
      requestBody,
    });

    return { response, error };
  },
  updateReferral: async (referrerId, newReferrer, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.REFARRAL_WITH_ID(referrerId),
      requestBody: newReferrer,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteReferral: async (referrer, requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.REFARRAL_WITH_ID(referrer._id),
      method: "DELETE",
      requestBody,
      signal,
    });

    return { response, error };
  },
};
