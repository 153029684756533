import { snakeCase } from "lodash";
import React from "react";
import { Button, FormControl } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import HorizontalProgress from "../common/HorizontalProgress";

const DeleteAppointmentModal = ({
  onHide,
  showProgress,
  appointmentToDelete,
  customMessage,
  onCustomMessageChange,
  onDeleteClick,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={"md"}
      show={Boolean(appointmentToDelete)}
      onHide={onHide}
      title={translate("delete_event")}
      showCloseButton={!showProgress}
    >
      <div>
        <div className="px-3">
          <div className="text-center">
            <h6 className="mt-3">
              {translate("send_delete_appointment_notification")}
            </h6>
          </div>
          <div className="d-flex flex-column justify-content-center flex-wrap align-items-center mt-2">
            <h6 className="mt-2 smallFont">
              {translate("optional_custom_message")}
            </h6>
            <FormControl
              size="sm"
              as={"textarea"}
              rows={3}
              value={customMessage}
              onChange={(e) => {
                let val = e.target.value;
                onCustomMessageChange(val);
              }}
            />
          </div>
          {showProgress && (
            <HorizontalProgress
              text={translate(snakeCase("Please wait")) || "Please wait"}
            />
          )}
        </div>
        <div className="d-flex mt-4">
          <div className="flex-grow-1">
            {" "}
            <Button
              size="sm"
              className="ms-2 px-2"
              variant={"dark"}
              onClick={onHide}
              disabled={showProgress}
            >
              {translate("cancel")}
            </Button>
          </div>
          <div className="">
            <Button
              size="sm"
              className="ms-2 px-2 text-white"
              variant={"primary"}
              onClick={() => onDeleteClick(false)}
              disabled={showProgress}
            >
              {translate("dont_send")}
            </Button>
            <Button
              size="sm"
              className="ms-2 px-2 text-white"
              variant={"danger"}
              onClick={() => onDeleteClick(true)}
              disabled={showProgress}
            >
              {translate("send")}
            </Button>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default DeleteAppointmentModal;
