import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import BootstrapForm from "react-bootstrap/Form";
// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Please provide title"),
  description: Yup.string().required("Please provide description"),
  duration: Yup.string().required("Please provide duration"),
});

const defaultValues = {
  title: "",
  description: "",
  duration: 30,
};

const EditAppointmentTypeForm = ({ initialValues, showProgress, onSubmit }) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("title")}
                  </FormLabel>
                  <Field
                    name="title"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("duration")}
                  </FormLabel>
                  <Field
                    name="duration"
                    type="number"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="duration"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("description")}
                  </FormLabel>
                  <BootstrapForm.Control
                    value={values?.description}
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                    as="textarea"
                    rows={3}
                  />{" "}
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate("update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditAppointmentTypeForm;
