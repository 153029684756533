import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import LanguagePicker from "../components/common/LanguagePicker";
import Logo from "../components/common/Logo";

const PublicRouteLayout = ({ logoInHeader }) => {
  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark h-100"></Col>
        <Col
          xs={11}
          className="pt-3 pb-2 px-0 px-md-5 bg-light h-100 d-flex flex-column"
        >
          <div className="d-flex">
            {logoInHeader && (
              <div className="flex-grow-1">
                <div className="">
                  <Logo dark height={30} />
                </div>
              </div>
            )}
            <div className="text-end">
              <LanguagePicker />
            </div>
          </div>
          <div className="py-1 flex-grow-1 overflow-auto">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicRouteLayout;
