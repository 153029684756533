import moment from "moment";
import React, { useMemo, useRef } from "react";
import { Button, FormControl } from "react-bootstrap";
import { Check, Pencil, Trash, X } from "react-bootstrap-icons";
import { DEFAULT_DATE_TIME_FORMAT } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "./circular-progress";

const Comments = ({
  comments = [],
  commentsUpdating,
  onCommentSubmit,
  text = "",
  onTextChange,
  onCommentEditClick,
  onCommentEditCancelClick,
  onCommentEditSaveClick,
  onCommentDeleteClick,
  editingComment,
  onEditingCommentUpdate,
  editable = true,
  fullHeight = true,
}) => {
  const { translate } = useLocalization();

  const textareaRef = useRef(null);

  const onNewCommentChange = (value) => {
    onTextChange(value);
    adjustTextareaHeight();
  };
  const onEditCommentCommentChange = (value) => {
    onEditingCommentUpdate(value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (!textareaRef.current) return;
    textareaRef.current.style.height = "inherit"; // Reset the height to recalculate
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };
  const sortedComments = useMemo(
    () => comments.sort((a, b) => new Date(b["date"]) - new Date(a["date"])),
    [comments]
  );

  return (
    <div className={`d-flex flex-column ${fullHeight ? "h-100" : ""}`}>
      {editable && (
        <div className="flex-grow-1 bg-light-gray rounded p-1 mb-1">
          <FormControl
            as="textarea"
            ref={textareaRef}
            className="bg-white"
            size="sm"
            rows={3}
            value={text}
            disabled={commentsUpdating}
            onChange={(e) => onNewCommentChange(e.target.value)}
            placeholder={translate("type_your_comment_here")}
            style={{ overflowY: "hidden" }}
          />

          <div className="d-flex justify-content-end">
            <Button
              disabled={commentsUpdating || text.trim().length === 0}
              className="mt-2 px-1 py-0"
              size={"sm"}
              variant="success"
              style={{ fontSize: 11 }}
              onClick={() => onCommentSubmit(text.trim())}
            >
              {commentsUpdating ? (
                <span className="mx-1 px-1 text-white">
                  <CircularProgressBar size={10} />
                  <span className="mx-1 smallFont">
                    {translate("please_wait")}
                  </span>
                </span>
              ) : (
                <span className="text-white smallFont">{translate("add")}</span>
              )}
            </Button>
          </div>
        </div>
      )}
      <div className="overflow-auto">
        {sortedComments && sortedComments.length > 0 ? (
          sortedComments.map((comment) => {
            return (
              <div key={comment["_id"]} className="mb-2">
                <div className="border rounded bg-primary-extra-light mt-1 large d-flex">
                  <div className="flex-grow-1  p-1 rounded">
                    {" "}
                    <h6 className="mb-0 text-info">{comment["name"]}</h6>
                    {editingComment?._id === comment._id ? (
                      <FormControl
                        as="textarea"
                        ref={textareaRef}
                        size="sm"
                        className="mb-2"
                        rows={2}
                        value={editingComment?.text}
                        onChange={(e) =>
                          onEditCommentCommentChange(e.target.value)
                        }
                        placeholder={translate("type_your_comment_here")}
                        disabled={commentsUpdating}
                      ></FormControl>
                    ) : (
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {comment["text"]}
                      </p>
                    )}
                  </div>
                  <div className="border-start" />
                  {editable && (
                    <div className="p-1 bg-white rounded">
                      {editingComment?._id !== comment._id ? (
                        <Button
                          className="d-flex align-items-center px-1 py-0"
                          style={{ height: 15 }}
                          size={"sm"}
                          variant="success"
                          onClick={
                            !commentsUpdating &&
                            ((e) => onCommentEditClick(comment))
                          }
                        >
                          <span className="text-white tiny">
                            {translate("edit")}
                          </span>
                        </Button>
                      ) : (
                        <>
                          <X
                            onClick={
                              !commentsUpdating &&
                              ((e) => onCommentEditCancelClick(comment))
                            }
                            className={`${
                              !commentsUpdating ? "hover" : ""
                            } mx-1 text-danger`}
                            size={18}
                          />
                          <Check
                            onClick={
                              !commentsUpdating &&
                              ((e) => onCommentEditSaveClick(comment))
                            }
                            className={`${
                              !commentsUpdating ? "hover" : ""
                            } mx-1 text-success`}
                            size={18}
                          />
                        </>
                      )}{" "}
                      <Button
                        className="d-flex align-items-center px-1 mt-1 py-0"
                        style={{ height: 15 }}
                        size={"sm"}
                        variant="danger"
                        onClick={
                          !commentsUpdating &&
                          ((e) => onCommentDeleteClick(comment))
                        }
                      >
                        <span className="text-white tiny">
                          {translate("delete")}
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <p className="text-end mb-1">
                    <i className="text-muted d-block smallFont">
                      {moment(comment["date"]).format(DEFAULT_DATE_TIME_FORMAT)}
                    </i>
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="border bg-light rounded px-3 py-2 text-center fw-bold text-secondary mid m-0">
            {translate("no_comments_to_show")}
          </p>
        )}
      </div>
    </div>
  );
};

export default Comments;
