import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ title, data, height, width }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 8,
          boxHeight: 8,
        },
      },
    },
  };

  return (
    <div className="custom-card">
      <h6 className="xxlarge text-muted mb-0">{title}</h6>
      <div className="d-flex py-2 justify-content-center align-items-center">
        <Pie options={options} data={data} height={height} width={width} />
      </div>
    </div>
  );
};

export default PieChart;
