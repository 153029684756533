import { useContext } from "react";
import { ApointmentCalendarContext } from "../context/ApointmentCalendarContext";

const useApointmetnCalendar = () => {
  const { openApointmentCalendar, closeApointmentCalendar } = useContext(
    ApointmentCalendarContext
  );

  return {
    openApointmentCalendar,
    closeApointmentCalendar,
  };
};

export default useApointmetnCalendar;
