import { useCallback, useEffect, useState } from "react";

const findIfObjectIsInArrayOrNot = (array, object, key = "_id") => {
  return array?.find((c) => c?.[key] === object?.[key]) || false;
};

const useSelection = (items = []) => {
  const [selectAllMode, setSelectAllMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unselectedItems, setUnselectedItems] = useState([]);

  const isInSelectedList = useCallback(
    (item) => findIfObjectIsInArrayOrNot(selectedItems, item),
    [selectedItems]
  );

  const isInUnselectedList = useCallback(
    (item) => findIfObjectIsInArrayOrNot(unselectedItems, item),
    [unselectedItems]
  );

  const onToggleSelectAll = (checked) => {
    if (checked && selectedItems.length > 0) {
      setSelectedItems([]);
    } else if (!checked && unselectedItems.length > 0) {
      setUnselectedItems([]);
    } else {
      setSelectAllMode(checked);
    }
  };

  const onToggleSelectItem = (item) => {
    if (selectAllMode) {
      setUnselectedItems(
        isInUnselectedList(item)
          ? unselectedItems.filter((c) => c._id !== item._id)
          : [...unselectedItems, item]
      );
    } else {
      setSelectedItems(
        isInSelectedList(item)
          ? selectedItems.filter((c) => c._id !== item._id)
          : [...selectedItems, item]
      );
    }
  };

  const isItemSelected = (item) => {
    if (selectAllMode) {
      return !isInUnselectedList(item);
    } else {
      return isInSelectedList(item);
    }
  };

  const clearSelection = () => {
    setSelectedItems([]);
    setUnselectedItems([]);
    setSelectAllMode(false);
  };

  useEffect(() => {
    const allIds = items.map((c) => c._id);
    setSelectedItems((prev) => prev.filter((s) => allIds.includes(s._id)));
    setUnselectedItems((prev) => prev.filter((s) => allIds.includes(s._id)));
  }, [items]);

  return {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  };
};

export default useSelection;
