import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AlertModal from "../components/common/AlertModal";
import { isAdminOrManager } from "../helpers/session";
import useAuth from "../hooks/useAuth";
import { miscService } from "../services/miscService";
import useLocalization from "../hooks/useLocalization";

export const ActiveTrailAndFacebookCampaignContext = createContext();

export const ActiveTrailAndFacebookCampaignProvider = ({ children }) => {
  const { user } = useAuth();
  const { translate } = useLocalization();
  const [facebookCampaigns, setFacebookCampaigns] = useState([]);
  const [activeTrailLists, setActiveTrailLists] = useState([]);
  const [fetchingActiveTrailAndCampaigns, setFetchingActiveTrailAndCampaigns] =
    useState(false);
  const [newlyCreatedActiveTrail, setNewlyCreatedActiveTrail] = useState(null);
  const [newTrailGroupMeta, setNewTrailGroupMeta] = useState(null);

  const handleCreateNewTrailGroup = ({ name = "", fieldInfo }) => {
    setNewTrailGroupMeta({ name, fieldInfo });
  };

  const createNewTrailGroup = async () => {
    setNewTrailGroupMeta({ ...newTrailGroupMeta, showProgress: true });
    const requestBody = { name: newTrailGroupMeta?.name };
    const { response, error } = await miscService.createActiveTrailLists(
      requestBody
    );
    // TODO
    // const error = null;
    // const response = {
    //   id: 243680,
    //   name: "crm-to-delete",
    //   active_counter: 0,
    //   counter: 0,
    //   created: "0001-01-01T00:00:00",
    //   last_generated: "0001-01-01T00:00:00",
    // };
    if (error) {
      return toast.error(error);
    }
    setNewlyCreatedActiveTrail({
      activeTrail: response,
      fieldInfo: newTrailGroupMeta?.fieldInfo,
    });
    setActiveTrailLists([response, ...activeTrailLists]);
    setNewTrailGroupMeta(null);
  };

  const getActiveTrailsAndCampaigns = async () => {
    setFetchingActiveTrailAndCampaigns(true);

    const { response: activeTrailLists, error: activeTrailError } =
      await miscService.getActiveTrailLists();

    const { response: campaignsLists, error: campaignError } =
      await miscService.getFacebookCampaigns();

    setFetchingActiveTrailAndCampaigns(false);

    if (activeTrailError || campaignError) {
      return toast.error(activeTrailError || campaignError);
    }

    setFacebookCampaigns(campaignsLists);
    setActiveTrailLists(activeTrailLists);
  };

  useEffect(() => {
    if (isAdminOrManager(user.role)) {
      getActiveTrailsAndCampaigns();
    }
  }, [user]);

  const contextObj = {
    facebookCampaigns,
    activeTrailLists,
    fetchingActiveTrailAndCampaigns,
    newlyCreatedActiveTrail,
    setNewlyCreatedActiveTrail,
    handleCreateNewTrailGroup,
  };

  return (
    <ActiveTrailAndFacebookCampaignContext.Provider value={contextObj}>
      {children}{" "}
      <AlertModal
        show={newTrailGroupMeta}
        onHide={() => setNewTrailGroupMeta(null)}
        onDismissClick={() => setNewTrailGroupMeta(null)}
        onContinueClick={createNewTrailGroup}
        showProgress={newTrailGroupMeta?.showProgress}
        alertText={translate('new_activetrail_message')}
      />
    </ActiveTrailAndFacebookCampaignContext.Provider>
  );
};
