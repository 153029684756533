import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Pencil, SendArrowDownFill, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import useLocalization from "../../../hooks/useLocalization";
import { whatsappTemplateService } from "../../../services/whatsappTemplateService";
import AlertModal from "../../common/AlertModal";
import UnderlineButton from "../../common/UnderlineButton";
import AddTemplateModal from "./AddTemplateModal";
import AssignPlaceholders from "./AssignPlaceholders";
import WhatsappChatScreen from "./whatsapp-chat-screen/WhatsappChatScreen";

const defaultPlaceholderToKey = [
  {
    value: "event.name",
    placeholder: "event_name",
  },
  {
    value: "event.date",
    placeholder: "event_date",
  },
  {
    value: "event.time",
    placeholder: "event_time",
  },
  {
    value: "event.webinarLink",
    placeholder: "webinar_link",
  },
];

function extractPlaceholders(
  text,
  editingPlaceholders = [],
  originalPlaceholders = []
) {
  // Regular expression to match placeholders in the format {{placeholderKey}}
  const regex = /\{\{(.+?)\}\}/g;
  let match;
  const newPlaceholders = [];

  // Loop through all matches of the placeholders
  while ((match = regex.exec(text)) !== null) {
    const placeholderKey = match[1]; // Extract the key inside {{ }}

    if (newPlaceholders.find((p) => p.key === placeholderKey)) continue;

    // Check if the placeholder matches any in the actions array
    const actionMatch = defaultPlaceholderToKey.find(
      (action) => action.placeholder === placeholderKey
    );

    // If a match is found in actions, set value to the action's key
    const valueFromAction = actionMatch ? actionMatch.value : "";

    const keyInOriginalPlaceholders = originalPlaceholders.find(
      (p) => p.key === placeholderKey
    );

    const keyInEditingPlaceholders = editingPlaceholders.find(
      (p) => p.key === placeholderKey
    );

    const value =
      keyInEditingPlaceholders?.value ||
      keyInOriginalPlaceholders?.value ||
      valueFromAction; // Use value from actions if no existing value is found

    const defaultValue =
      keyInEditingPlaceholders?.defaultValue ||
      keyInOriginalPlaceholders?.defaultValue ||
      ""; // Take value from existing or leave it blank

    // Add the placeholder to the result array
    newPlaceholders.push({
      key: placeholderKey,
      value,
      defaultValue,
    });
  }

  return newPlaceholders;
}
const WhatsappTemplate = ({
  template = {},
  onTemplateUpdate,
  onTemplateDelete,
}) => {
  const { translate, isRTL } = useLocalization();
  const [editingTemplate, setEditingTemplate] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [updateTemplateModalMeta, setUpdateTemplateModalMeta] = useState(null);
  const [deleteTemplateMeta, setDeleteTemplateMeta] = useState(null);
  const [sendForApprovalMeta, setSendForApprovalMeta] = useState(null);
  const [placeHolders, setPlaceHolders] = useState([]);
  const [savingMessageBody, setSavingMessageBody] = useState(false);
  const [savingPlaceholders, setSavingPlaceholders] = useState(false);

  useEffect(() => {
    setEditingTemplate(cloneDeep(template));
  }, [template]);

  useEffect(() => {
    setPlaceHolders(
      extractPlaceholders(
        editingTemplate.messageBody,
        placeHolders,
        template?.placeholders
      )
    );
  }, [editingTemplate]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onDeleteTemplateClick = () => {
    setDeleteTemplateMeta({
      show: true,
      alertText: translate("are_you_sure_delete_template"),
    });
  };

  const onEditTemplateClick = () => {
    setUpdateTemplateModalMeta({
      template: editingTemplate,
    });
  };

  const handleAddTemplate = async (values) => {
    setUpdateTemplateModalMeta({
      ...updateTemplateModalMeta,
      showProgress: true,
    });

    const requestBody = values;
    let { response, error } =
      await whatsappTemplateService.updateWhatsappTemplate(
        editingTemplate._id,
        requestBody
      );

    if (error) {
      toast.error(error);
      setUpdateTemplateModalMeta({
        ...updateTemplateModalMeta,
        showProgress: false,
      });
      return;
    }
    onTemplateUpdate(response);
    setUpdateTemplateModalMeta(null);
  };

  const deleteTemplate = async () => {
    setDeleteTemplateMeta({ ...deleteTemplateMeta, showProgress: true });

    const { response, error } =
      await whatsappTemplateService.deleteWhatsappTemplates(template._id);

    if (error) {
      setDeleteTemplateMeta({ ...deleteTemplateMeta, showProgress: false });
      toast.error(error);
      return;
    }

    toast.success("Template deleted succesfully");
    onTemplateDelete(response);
    setDeleteTemplateMeta(null);
  };

  const handleSendForApprovalClick = () => {
    setSendForApprovalMeta({
      show: true,
      alertText: translate("are_you_sure_send_for_approval"),
    });
  };

  const handleApproveTemplate = async (type) => {
    setSendForApprovalMeta({
      ...sendForApprovalMeta,
      showProgress: true,
    });

    const requestBody = {
      status: "Pending Approval",
    };
    let { response, error } =
      await whatsappTemplateService.updateWhatsappTemplate(
        editingTemplate._id,
        requestBody
      );

    if (error) {
      toast.error(error);
      setSendForApprovalMeta({
        ...sendForApprovalMeta,
        showProgress: false,
      });
      return;
    }
    onTemplateUpdate(response);
    setSendForApprovalMeta(null);
  };

  const handleSaveClick = async (type) => {
    setIsEditing(false);
    if (type === "message") {
      setSavingMessageBody(true);
    } else {
      setSavingPlaceholders(true);
    }

    const requestBody = {
      messageBody: editingTemplate.messageBody,
      placeholders: placeHolders,
    };
    let { response, error } =
      await whatsappTemplateService.updateWhatsappTemplate(
        editingTemplate._id,
        requestBody
      );

    if (error) {
      toast.error(error);
      return;
    }
    onTemplateUpdate(response);
    setSavingMessageBody(false);
    setSavingPlaceholders(false);
  };

  const handleCloseClick = () => {
    setEditingTemplate(cloneDeep(template));
    setIsEditing(false);
  };

  const onMessageChange = (value) => {
    setEditingTemplate({ ...editingTemplate, messageBody: value });
  };

  const isNotInApprovePipeline = useMemo(
    () => !["Approved", "Pending Approval"].includes(editingTemplate.status),
    [editingTemplate]
  );

  return (
    <>
      <Row className={`mx-0 ${isRTL ? "flex-row-reverse" : ""}`}>
        <Col
          xs={12}
          className={`d-flex ${
            !isRTL ? "justify-content-end" : ""
          } gap-1 border p-1 mb-1 rounded`}
        >
          <UnderlineButton
            Icon={Pencil}
            fontSize="mid"
            variant="success"
            text={translate("edit")}
            onClick={onEditTemplateClick}
          />

          <UnderlineButton
            Icon={Trash}
            fontSize="mid"
            variant="danger"
            text={translate("delete")}
            onClick={onDeleteTemplateClick}
          />

          {isNotInApprovePipeline && (
            <UnderlineButton
              variant="info"
              fontSize="mid"
              Icon={SendArrowDownFill}
              text={translate("send_for_approve")}
              onClick={handleSendForApprovalClick}
            />
          )}
        </Col>
        <Col md={7} className="border rounded">
          <h6 className="my-1 mb-0 mid">Place holders used in the text:</h6>
          <AssignPlaceholders
            placeholders={placeHolders}
            onPlaceholdersChanges={setPlaceHolders}
            onPlaceholdersSave={() => handleSaveClick("placeholder")}
            savingPlaceholders={savingPlaceholders}
          />
        </Col>
        <Col md={5} className="pe-0 ps-1">
          {" "}
          <WhatsappChatScreen
            isEditing={isEditing}
            savingMessageBody={savingMessageBody}
            editable={isNotInApprovePipeline}
            handleEditClick={handleEditClick}
            handleSaveClick={() => handleSaveClick("message")}
            handleCloseClick={handleCloseClick}
            message={editingTemplate?.messageBody || ""}
            onMessageChange={onMessageChange}
          />
        </Col>
      </Row>
      <AlertModal
        show={Boolean(deleteTemplateMeta)}
        onHide={() => setDeleteTemplateMeta(null)}
        onDismissClick={() => setDeleteTemplateMeta(null)}
        onContinueClick={deleteTemplate}
        showProgress={deleteTemplateMeta?.showProgress}
        alertText={deleteTemplateMeta?.alertText}
      />
      <AlertModal
        show={Boolean(sendForApprovalMeta)}
        onHide={() => setSendForApprovalMeta(null)}
        onDismissClick={() => setSendForApprovalMeta(null)}
        onContinueClick={handleApproveTemplate}
        showProgress={sendForApprovalMeta?.showProgress}
        alertText={sendForApprovalMeta?.alertText}
      />{" "}
      <AddTemplateModal
        show={Boolean(updateTemplateModalMeta)}
        onHide={() => setUpdateTemplateModalMeta(null)}
        showProgress={updateTemplateModalMeta?.showProgress}
        onSubmit={handleAddTemplate}
        editMode
        initialValue={updateTemplateModalMeta?.template}
      />
    </>
  );
};

export default WhatsappTemplate;
