import React, { useMemo } from "react";
import { FormCheck, FormGroup } from "react-bootstrap";
import { SALES_ROLE } from "../../helpers/constants";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import CustomMultiSelect from "../common/CustomMultiSelect";
import { GROUPPED_USER_BASED_ON_ROLE } from "../../helpers/global";

const ParticipantsOption = ({
  data,
  selected = false,
  disabled,
  onParticipantsChange,
}) => {
  return (
    <div
      className="hover rounded-md px-1 w-auto d-flex gap-1 align-items-center"
      onClick={(e) => {
        if (!disabled) {
          if (e.target.tagName !== "INPUT") {
            onParticipantsChange(!selected);
          }
        }
      }}
    >
      <FormCheck
        checked={selected}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            onParticipantsChange(e.target.checked);
          }
        }}
        style={{
          fontSize: 12,
        }}
        type={"checkbox"}
        id={`salesperson-${data?.name}`}
      />
      <h6 id={`salesperson-${data?.name}`} className="smallFont mb-0">
        {data?.name}
      </h6>
    </div>
  );
};

const SalesPersonFilter = ({
  value,
  onChange,
  showSelectAll = true,
  disabled,
  className,
}) => {
  const { translate } = useLocalization();
  const users = useAppChoices("users");

  const onAllSelect = (checked) => {
    onChange(
      checked ? { others: [] } : { salesperson: "unassign", others: [] }
    );
  };

  const isUnassignChecked = useMemo(
    () =>
      value.salesperson === "unassign" &&
      !Array.isArray(value.salesperson) &&
      !value.others.length,
    [value]
  );

  const isAllChecked = useMemo(
    () => value.salesperson === undefined && !value.others.length,
    [value]
  );

  return (
    <div className={className} style={{ borderRadius: 4 }}>
      {" "}
      <FormGroup className="">
        <div className="flex-wrap d-flex gap-1 align-items-center ">
          {showSelectAll && (
            <div
              style={{ height: "fit-content" }}
              className="hover rounded-md px-1 w-auto gap-1 d-flex align-items-center"
              onClick={(e) => {
                if (!disabled) {
                  if (e.target.tagName !== "INPUT") {
                    onAllSelect(!isAllChecked);
                  }
                }
              }}
            >
              <FormCheck
                checked={isAllChecked}
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onAllSelect(e.target.checked);
                }}
                style={{ fontSize: 12 }}
                type={"checkbox"}
              />
              <h6 className="smallFont mb-0">All</h6>
            </div>
          )}{" "}
          <div
            style={{ height: 18 }}
            className="hover rounded-md px-1 w-auto d-flex gap-1 align-items-center"
            onClick={(e) => {
              if (!disabled) {
                if (e.target.tagName !== "INPUT") {
                  onChange(
                    !isUnassignChecked
                      ? { salesperson: "unassign", others: [] }
                      : { salesperson: undefined, others: [] }
                  );
                }
              }
            }}
          >
            <FormCheck
              checked={isUnassignChecked}
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabled) {
                  onChange(
                    e.target.checked
                      ? { salesperson: "unassign", others: [] }
                      : { salesperson: undefined, others: [] }
                  );
                }
              }}
              style={{ fontSize: 12 }}
              type={"checkbox"}
            />
            <h6 className="mb-0 smallFont">Unassigned</h6>
          </div>
          {users
            .filter((u) => u.role === SALES_ROLE)
            ?.map((u) => (
              <ParticipantsOption
                key={u?._id}
                data={u}
                disabled={disabled}
                selected={
                  value.salesperson && value.salesperson !== "unassign"
                    ? value.salesperson.includes(u._id)
                    : false
                }
                onParticipantsChange={(checked) => {
                  const salesperson = value.salesperson || [];
                  const updatedParticipants = checked
                    ? salesperson !== "unassign"
                      ? [...salesperson, u._id]
                      : [u._id]
                    : salesperson.filter((p) => p !== u._id);

                  onChange({ ...value, salesperson: updatedParticipants });
                }}
              />
            ))}
        </div>
      </FormGroup>
      <div style={{ width: 130 }}>
        {" "}
        <CustomMultiSelect
          items={GROUPPED_USER_BASED_ON_ROLE({
            users: users.filter((u) => u.role !== SALES_ROLE),
          })}
          onChange={(otherUsers) => {
            onChange({
              salesperson:
                otherUsers.length === 0
                  ? undefined
                  : value.salesperson === "unassign"
                  ? []
                  : value.salesperson,
              others: otherUsers,
            });
          }}
          selectedItems={value.others || []}
          isGroupped
          placeholder="More"
          height="18px"
          isMulti
          isClearable
          maxToShow={0}
          maxItemCustomMessage={(length) => `+${length} more`}
          showMessageOnlyOnOverflow
        />
      </div>
    </div>
  );
};

export default SalesPersonFilter;
