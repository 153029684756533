import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { ArrowLeftShort, ArrowRightShort } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "./circular-progress";

const DashboardDataNavigator = ({
  dateRangeLabel,
  rangeType,
  handleNavigateDate,
  loading,
}) => {
  const { isRTL } = useLocalization();
  const labelWidth = useMemo(() => {
    let width = {
      Day: 100,
      Week: 200,
      Month: 120,
      Quarter: 150,
      Year: 80,
    };
    return width[rangeType];
  }, [rangeType]);
  if (rangeType === "All Time") return null;
  return (
    <div className="d-flex justify-content-center align-items-center">
      {loading && (
        <CircularProgressBar size={20} className="mx-2" variant="dark" />
      )}

      <Button
        size="sm"
        variant={"outline-dark"}
        className="px-1 py-0 m-1 d-flex justify-content-center align-items-center"
        style={{ width: "50px" }}
        onClick={() =>
          handleNavigateDate(rangeType, isRTL ? "Next" : "Previous")
        }
      >
        {isRTL ? <ArrowRightShort size={20} /> : <ArrowLeftShort size={20} />}
      </Button>
      <div
        className="d-flex justify-content-center align-items-center smallFont"
        style={{
          width: labelWidth ? labelWidth : 200,
        }}
      >
        {dateRangeLabel}
      </div>

      <Button
        size="sm"
        variant={"outline-dark"}
        className="px-1 py-0 m-1 d-flex justify-content-center align-items-center"
        style={{ width: "50px" }}
        onClick={() =>
          handleNavigateDate(rangeType, isRTL ? "Previous" : "Next")
        }
      >
        {isRTL ? <ArrowLeftShort size={20} /> : <ArrowRightShort size={20} />}
      </Button>
    </div>
  );
};

export default DashboardDataNavigator;
