import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import useActiveTrailAndFacebookCampaign from "../../../hooks/useActiveTrailAndFacebookCampaign";

const FacebookCampaignDropdown = ({ value, onChange, isMulti = true }) => {
  const { facebookCampaigns } = useActiveTrailAndFacebookCampaign();
  return (
    <CustomMultiSelect
      items={facebookCampaigns.map((option) => ({
        value: option.id,
        label: option.name,
      }))}
      selectedItems={value}
      isMulti={isMulti}
      height="30px"
      onChange={onChange}
      placeholder="Select"
    />
  );
};

export default FacebookCampaignDropdown;
