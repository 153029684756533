import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const timeLogService = {
  clockIn: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CLOCK_IN,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  clockOut: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CLOCK_OUT,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
};
