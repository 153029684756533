import { ADMIN_ROLE, MANAGER_ROLE } from "./constants";

export const getIsUserLoggedInFromLocal = () => {
  return Boolean(localStorage.getItem("user-is-loggedin"));
};

export const setIsUserLoggedInToLocal = (isLoggedIn) => {
  if (isLoggedIn) {
    localStorage.setItem("user-is-loggedin", isLoggedIn);
  } else {
    localStorage.clear();
  }
};

export const isAdminOrManager = (role) =>
  [ADMIN_ROLE, MANAGER_ROLE].includes(role);
export const isAdmin = (role) => [ADMIN_ROLE].includes(role);
