import { snakeCase } from "lodash";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "./AppModal";
import HorizontalProgress from "./HorizontalProgress";

const AlertModal = ({
  show,
  onHide,
  onContinueClick,
  onDismissClick,
  alertText,
  dismissButtonText = "No",
  dismissButtonVariant = "dark",
  continueButtonText = "Yes",
  continueButtonVariant = "danger",
  progressText = "Please wait",
  showProgress,
  size,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={size}
      show={show}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      <div className="text-center">
        <ExclamationTriangle className="text-danger" size={30} />
        <h6 className="mt-3"> {alertText} </h6>
      </div>
      {showProgress && (
        <HorizontalProgress
          text={translate(snakeCase(progressText)) || progressText}
        />
      )}

      <div className="text-end mt-4">
        <Button
          size="sm"
          className="ms-2 px-2"
          variant={dismissButtonVariant}
          onClick={onDismissClick}
          disabled={showProgress}
        >
          {translate(snakeCase(dismissButtonText)) || dismissButtonText}
        </Button>
        <Button
          size="sm"
          className="ms-2 px-2"
          variant={continueButtonVariant}
          onClick={() => onContinueClick()}
          disabled={showProgress}
        >
          {translate(snakeCase(continueButtonText)) || continueButtonText}
        </Button>
      </div>
    </AppModal>
  );
};

export default AlertModal;
