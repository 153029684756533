import { useCallback, useMemo } from "react";
import useAppChoices from "./useAppChoices";
import useLocalization from "./useLocalization";

function getStatusColors(colorName) {
  let backgroundColor, textColor, hoverColor;

  switch (colorName) {
    case "primary":
      backgroundColor = "#d89e31";
      textColor = "#ffffff";
      hoverColor = "#c4882d";
      break;
    case "info":
      backgroundColor = "#3260a8";
      textColor = "#ffffff";
      hoverColor = "#2b5699";
      break;
    case "success":
      backgroundColor = "#28a745";
      textColor = "#ffffff";
      hoverColor = "#218838";
      break;
    case "danger":
      backgroundColor = "#e60707";
      textColor = "#ffffff";
      hoverColor = "#c10505";
      break;
    default:
      backgroundColor = "#ffffff";
      textColor = "#000000";
      hoverColor = "#cccccc";
  }

  return { backgroundColor, textColor, hoverColor };
}

const STATUS_CATEGORIES = [
  { category: "Webinar", color: "primary" },
  { category: "Sales", color: "info" },
  { category: "Irrelevant", color: "danger" },
  { category: "Clients", color: "success" },
];

const useContactStatus = () => {
  const statuses = useAppChoices("statuses");

  const { langCode } = useLocalization();

  const STATUS = useMemo(() => {
    const groups = STATUS_CATEGORIES.map((c) => ({
      label: c.category,
      key: c.category,
      color: c.color,
      options: statuses.filter((s) => s.category === c.category),
    }));
    return groups;
  }, [statuses]);

  const GROUPPED_STATUS = useMemo(
    () =>
      STATUS.map((group) => {
        let colors = getStatusColors(group?.color);

        return {
          ...group,
          options: group.options.map((option) => ({
            label: langCode === "Hebrew" ? option?.hebrew : option?.english,
            value: option?.value,
            ...colors,
          })),
        };
      }),
    [STATUS, langCode]
  );

  const ALL_STATUSES = useMemo(
    () =>
      STATUS_CATEGORIES.map((c) => ({
        label: c.category,
        key: c.category,
        color: c.color,
        options: statuses
          .filter((s) => s.category === c.category)
          .map((s) => (langCode === "Hebrew" ? s?.hebrew : s?.english)),
      })).flatMap((s) => s.options),
    [STATUS, langCode]
  );

  const getContactStatus = useCallback(
    (status) => {
      const category = STATUS.find((s) =>
        s?.options?.find((o) => o.value === status)
      );

      const s = category?.options?.find((o) => o.value === status);

      return {
        color: category?.color,
        label: langCode === "Hebrew" ? s?.hebrew : s?.english,
        value: s?.value,
      };
    },
    [STATUS, langCode]
  );

  return { STATUS, GROUPPED_STATUS, ALL_STATUSES, getContactStatus };
};

export default useContactStatus;
