import React from "react";
import { LEAD_SOURCE_OPTIONS } from "../../../helpers/constants";
import useLocalization from "../../../hooks/useLocalization";
import CustomMultiSelect from "../../common/CustomMultiSelect";

const LeadSourceDropdown = ({ value, onChange }) => {
  let { translate } = useLocalization();
  return (
    <CustomMultiSelect
      items={LEAD_SOURCE_OPTIONS.map((l) => ({
        label: translate(l),
        value: l,
      }))}
      selectedItems={value}
      isMulti={false}
      height="30px"
      onChange={onChange}
      placeholder="Select"
      closeMenuOnSelect
    />
  );
};

export default LeadSourceDropdown;
