import React from "react";
import { formatElapsedtTime } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import RippleEffect from "../RippleEffect";
import { Button } from "react-bootstrap";

export default function Clock({ elapsedTime = 0, isPadded = false, onClick }) {
  const { isRTL } = useLocalization();

  return (
    <div
      size="sm"
      variant="dark"
      className={`bg-dark text-white rounded justify-content-center d-flex align-items-center hover p-1 ${
        isPadded ? "ps-2" : ""
      }`}
      onClick={onClick}
    >
      <RippleEffect size={15} />
      <h6 className={`clock-text large mb-0 ${isPadded ? "px-2" : "px-3"}`}>
        {formatElapsedtTime(elapsedTime, isRTL)}
      </h6>
    </div>
  );
}
