import React, { useEffect, useState } from "react";
import { Button, Alert, Col, FormControl, Row } from "react-bootstrap";
import {
  Check2,
  GripVertical,
  Pencil,
  Plus,
  Trash,
  XLg,
} from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "../common/circular-progress";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { snakeCase } from "lodash";

const StepCard = ({
  step,
  index,
  editingProductionStage,
  setEditingProductionStage,
  handleReset,
  productStageModalMeta,
  handleEditStep,
  onDeleteProductionStepClick,
  onEditClick,
  disabled,
}) => {
  const { translate } = useLocalization();

  return (
    <Draggable isDragDisabled={disabled} draggableId={step?._id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`border row m-0  rounded py-1 hover mb-2 ${
            editingProductionStage?._id === step?._id
              ? "bg-primary-light"
              : "bg-white"
          } `}
          title={step?.step}
        >
          <div className="col-10 align-items-center p-0 pt-1 m-0">
            {" "}
            {editingProductionStage?._id === step?._id ? (
              <FormControl
                type={"text"}
                value={editingProductionStage?.step}
                onChange={(e) =>
                  setEditingProductionStage({
                    ...editingProductionStage,
                    step: e.target.value,
                  })
                }
                className="mx-1 smallFont py-0 px-1"
                size="sm"
              />
            ) : (
              <div className="row align-items-center m-0 p-0">
                {!disabled && <GripVertical className="m-0 p-0 col-1" />}
                <h6 className="px-1 m-0 smallFont col-11">
                  {translate(snakeCase(step?.step)) || step?.step || ""}
                </h6>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center col-2 p-2">
            {productStageModalMeta?.updatingFieldId === step?._id ? (
              <CircularProgressBar className="mx-2" size={15} />
            ) : (
              <>
                {editingProductionStage?._id === step?._id ? (
                  <>
                    <XLg
                      className="hover text-danger "
                      size={13}
                      onClick={() => handleReset()}
                      title="cancel"
                    />
                    <Check2
                      className="hover text-success "
                      size={16}
                      onClick={handleEditStep}
                      title="save"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <Pencil
                      className="hover text-success "
                      size={12}
                      onClick={() => onEditClick(step)}
                      title="edit"
                    />{" "}
                    <Trash
                      className="hover text-danger "
                      size={12}
                      onClick={() => onDeleteProductionStepClick(step)}
                      title="delete"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const ProductStageForm = ({
  productionStages,
  productStageModalMeta,
  onEditProductionStageSubmit,
  onNewProductionStageSubmit,
  onDeleteProductionStepClick,
  onProductStepDragAndDrop,
  newProductionStage,
  setNewProductionStage,
  setEditingProductionStage,
  editingProductionStage,
}) => {
  const { translate } = useLocalization();

  const [errorMessage, setErrorMessage] = useState("");
  const handleReset = () => {
    setEditingProductionStage(null);
  };

  const onEditClick = (step) => {
    setEditingProductionStage(step);
  };

  const validateValues = (field) => {
    let { _id, step } = field;

    if (_id && productionStages?.find((pS) => pS?._id === _id)?.step === step) {
      return { isValid: true };
    }
    let stepWithSameNameExists = productionStages?.some(
      (pS) => pS?.step.toLowerCase().trim() === step.toLowerCase().trim()
    );
    if (step === "" || !step) {
      return { isValid: false, message: "Step name should not be empty" };
    }
    if (stepWithSameNameExists) {
      return {
        isValid: false,
        message: "There exists a step with the same name",
      };
    }
    return { isValid: true };
  };

  const handleEditStep = () => {
    let { isValid, message } = validateValues(editingProductionStage);
    if (!isValid) {
      return setErrorMessage(message);
    }
    setErrorMessage("");
    onEditProductionStageSubmit(editingProductionStage);
  };

  const handleAddNewStep = () => {
    let { isValid, message } = validateValues(newProductionStage);
    if (!isValid) {
      return setErrorMessage(message);
    }
    onNewProductionStageSubmit(newProductionStage?.step);
    setErrorMessage("");
  };

  return (
    <Row className="m-0">
      <Col xs={12} sm={12} md={12} lg={12} className="px-0">
        <>
          <DragDropContext onDragEnd={onProductStepDragAndDrop}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {productionStages?.length > 0 ? (
                    productionStages?.map((s, index) => {
                      return (
                        <StepCard
                          key={s?._id}
                          step={s}
                          index={index}
                          disabled={Boolean(editingProductionStage)}
                          editingProductionStage={editingProductionStage}
                          setEditingProductionStage={setEditingProductionStage}
                          handleReset={handleReset}
                          productStageModalMeta={productStageModalMeta}
                          handleEditStep={handleEditStep}
                          onDeleteProductionStepClick={
                            onDeleteProductionStepClick
                          }
                          onEditClick={onEditClick}
                        />
                      );
                    })
                  ) : (
                    <div className="py-4 d-flex justify-content-center align-items-center">
                      <span className="smallFont">No any steps to show!</span>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className="px-0">
        {!newProductionStage ? (
          <div className="d-flex justify-content-end">
            <Button
              variant="success text-white"
              size="sm"
              className="px-1 py-0"
              onClick={() => setNewProductionStage({ step: "" })}
            >
              <Plus /> <span className="smallFont">{translate("add_new")}</span>
            </Button>
          </div>
        ) : (
          <div className="mt-1 border-top">
            <h6 className="my-2 fw-bold smallFont">Adding new step</h6>
            <div className="">
              <FormControl
                className=""
                value={newProductionStage?.step}
                onChange={(e) =>
                  setNewProductionStage({ step: e.target.value })
                }
                placeholder="Enter new stage"
                size="sm"
              />
            </div>

            <div className="d-flex justify-content-end mt-2">
              <Button
                variant="danger text-white"
                size="sm"
                className="px-1 py-0 mx-1"
                onClick={() => {
                  setNewProductionStage(null);
                  setErrorMessage("");
                }}
              >
                <span className="smallFont">Cancel </span>
              </Button>
              <Button
                variant="primary text-white"
                size="sm"
                className="px-1 py-0 mx-1"
                onClick={handleAddNewStep}
              >
                <span className="smallFont">
                  {productStageModalMeta?.addingNewStep ? (
                    <>
                      <CircularProgressBar size={12} />{" "}
                      {translate("please_wait")}
                    </>
                  ) : (
                    translate("save")
                  )}{" "}
                </span>
              </Button>
            </div>
          </div>
        )}
        {errorMessage && (
          <Alert variant="danger" className="px-2 py-1 tiny mt-2 rounded-0">
            {errorMessage}
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default ProductStageForm;
