import React, { useEffect, useState } from "react";
import AppModal from "../../common/AppModal";
import WhatsappTemplateTable from "./WhatsappTemplateTable";
import { Button } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";

const AddEditWhatsappTemplateModal = ({
  show,
  onHide,
  initialValue = [],
  onSubmit,
  whatsappTemplates,
}) => {
  const { translate } = useLocalization();
  const [value, setValue] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSubmit = () => {
    if (value.find((v) => !v.template)) {
      setErrors("Template cannot be empty");
    } else {
      setErrors(null);
      onSubmit(value);
    }
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={"Update whatsapp campaign"}
      modalBodyClassName="p-1"
    >
      <div className="p-1">
        <hb className="smallFont">Whatsapp campaigns</hb>
        <WhatsappTemplateTable
          values={value}
          whatsappTemplates={whatsappTemplates}
          onChange={setValue}
          errors={[]}
        />
      </div>

      <div className="d-flex gap-1 justify-content-end align-items-center border-top p-1">
        {" "}
        <Button variant="dark" onClick={onHide} size="sm">
          {translate("close")}
        </Button>
        <Button variant="success text-white" onClick={handleSubmit} size="sm">
          {translate("save")}
        </Button>
      </div>
    </AppModal>
  );
};

export default AddEditWhatsappTemplateModal;
