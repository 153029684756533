import { useContext } from "react";
import { TimeTrackerContext } from "../context/TimeTrackerContext";

const useTimeTracker = () => {
  const {
    activeTimeEntry,
    elapsedTime,
    clockIn,
    clockOut,
    loading,
    openModal,
    closeModal,
    activeJob,
    setActiveJobDetails,
  } = useContext(TimeTrackerContext);

  return {
    activeTimeEntry,
    elapsedTime,
    clockIn,
    clockOut,
    loading,
    openModal,
    closeModal,
    activeJob,
    setActiveJobDetails,
  };
};

export default useTimeTracker;
