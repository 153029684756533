import React, { useEffect, useState } from "react";
import AppModal from "./AppModal";
import AppointmentDetails from "./AppointmentDetails";
import Loader from "./Loader";
import useContacts from "../../hooks/useContacts";
import ContactOverviewCard from "./ContactOverviewCard";
import { Button } from "react-bootstrap";
import SelectContactModal from "./SelectContactModal";
import useLocalization from "../../hooks/useLocalization";

const AppointmentDetailModal = ({
  show,
  onHide,
  fetchingAppointment,
  appointment,
  isFromAssignContact = false,
  onAppointmentChange,
  footerContent,
}) => {
  const { translate } = useLocalization();
  const { contacts, loadingContacts, setContactQuery } = useContacts(
    appointment?.invitees?.[0] || ""
  );
  const [selectContactForAppointmentMeta, setSelectContactForAppoinementMeta] =
    useState(null);

  useEffect(() => {
    setContactQuery(appointment?.invitees?.[0] || "");
  }, [appointment]);

  const assignNewContactToAppointemnt = () => {
    if (onAppointmentChange) {
      const { contact } = selectContactForAppointmentMeta;
      onAppointmentChange({ ...appointment, contact });
      setSelectContactForAppoinementMeta(null);
    }
  };
  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={appointment?.title}
      modalBodyClassName="p-2"
    >
      {fetchingAppointment ? (
        <Loader />
      ) : (
        <>
          {appointment ? (
            <>
              {isFromAssignContact && (
                <h6 className="mid text-muted mb-1 mt-2">
                  This zoom recording is related to the following appointment:
                </h6>
              )}
              <div className={isFromAssignContact ? "border rounded mb-2" : ""}>
                <AppointmentDetails
                  appointment={appointment}
                  isFromAssignContact={isFromAssignContact}
                />
              </div>
            </>
          ) : (
            <h6 className="smallFont text-center my-2">
              {translate("nothing_to_show")}
            </h6>
          )}
          {isFromAssignContact && appointment && (
            <div className="border rounded p-2 mb-2">
              <h6 className="mid mb-1">
                Please select a Contact to assign to the Appointment above:
              </h6>
              {appointment?.contact && (
                <ContactOverviewCard
                  contact={appointment?.contact}
                  openContact
                />
              )}
              <div>
                <Button
                  className="text-white"
                  variant="dark"
                  size="sm"
                  onClick={() =>
                    setSelectContactForAppoinementMeta({ showProgress: false })
                  }
                >
                  <h6 className="mb-0 smallFont">
                    {translate("select_contact")}
                  </h6>
                </Button>
                {appointment?.contact && (
                  <Button
                    className="mx-1 text-white"
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      if (onAppointmentChange) {
                        let { contact, ...remainingAppointmentData } =
                          appointment;
                        onAppointmentChange(remainingAppointmentData);
                      }
                    }}
                  >
                    <h6 className="mb-0 smallFont">
                      {" "}
                      {translate("remove_contact")}
                    </h6>
                  </Button>
                )}
              </div>{" "}
              {Boolean(selectContactForAppointmentMeta) && (
                <SelectContactModal
                  show={selectContactForAppointmentMeta}
                  onHide={() => setSelectContactForAppoinementMeta(null)}
                  contacts={contacts}
                  showProgress={
                    loadingContacts ||
                    selectContactForAppointmentMeta?.showProgress
                  }
                  defaultQuery={appointment?.invitees?.[0] || ""}
                  setContactQuery={setContactQuery}
                  onContactSelect={(contact) =>
                    setSelectContactForAppoinementMeta({
                      ...selectContactForAppointmentMeta,
                      contact,
                    })
                  }
                  selectedContact={selectContactForAppointmentMeta?.contact}
                  showFooter
                  onContactSelectClick={assignNewContactToAppointemnt}
                />
              )}
            </div>
          )}
          {footerContent && footerContent()}
        </>
      )}
    </AppModal>
  );
};

export default AppointmentDetailModal;
