import React, { useMemo } from "react";
import { Person } from "react-bootstrap-icons";
import { components } from "react-select";

const PersonSelectValue = ({ data, options, ...props }) => {
  const selectedUser = useMemo(() => {
    return data;
  }, [data]);

  return (
    <components.SingleValue {...props}>
      {selectedUser ? (
        <div className="d-flex align-items-center ">
          <Person size={14} />
          <div className="flex-grow-1 mx-2 py-1">
            <h6 className="fw-bold mid mb-0">{selectedUser.name}</h6>
            <h6 className="tiny mb-0">
              {selectedUser.email ||
                selectedUser.emails?.join(", ") ||
                selectedUser.phone ||
                selectedUser.phoneNumbers?.join(", ")}
            </h6>
          </div>
        </div>
      ) : (
        ""
      )}
    </components.SingleValue>
  );
};

export default PersonSelectValue;
