import React from "react";
import AppTable from "../../common/app-table/AppTable";
import { PLACEHOLDER_VALUES_OPTIONS } from "../../../helpers/constants";
import useLocalization from "../../../hooks/useLocalization";
import { snakeCase } from "lodash";
import { Button, FormControl, FormSelect } from "react-bootstrap";
import CircularProgressBar from "../../common/circular-progress";

const AssignPlaceholders = ({
  placeholders = [],
  onPlaceholdersChanges,
  onPlaceholdersSave,
  savingPlaceholders,
}) => {
  const { translate } = useLocalization();
  return (
    <>
      <AppTable>
        <thead>
          <tr>
            <th className="bg-dark text-white">Placeholder</th>
            <th className="bg-dark text-white">Take its value from?</th>
            <th className="bg-dark text-white">Default Value</th>
          </tr>
        </thead>
        <tbody>
          {placeholders.length > 0 ? (
            <>
              {placeholders.map((p) => (
                <tr key={p.key}>
                  <td className="p-0 px-2" style={{ verticalAlign: "middle" }}>
                    <b>{p.key}</b>
                  </td>
                  <td className="p-0">
                    <FormSelect
                      size="sm"
                      value={p.value}
                      onChange={(e) => {
                        let val = e.target.value;
                        onPlaceholdersChanges(
                          placeholders.map((p1) =>
                            p.key === p1.key ? { ...p1, value: val } : p1
                          )
                        );
                      }}
                    >
                      <option value={""}>Select</option>
                      {PLACEHOLDER_VALUES_OPTIONS?.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {translate(snakeCase(label)) || label}
                        </option>
                      ))}
                    </FormSelect>{" "}
                  </td>
                  <td className="p-0">
                    <FormControl
                      size="sm"
                      value={p.defaultValue}
                      onChange={(e) => {
                        let val = e.target.value;
                        onPlaceholdersChanges(
                          placeholders.map((p1) =>
                            p.key === p1.key ? { ...p1, defaultValue: val } : p1
                          )
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td
                className="text-center px-2"
                colSpan={3}
                style={{ verticalAlign: "middle" }}
              >
                <b>{translate("nothing_to_show")}</b>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        <Button
          size="sm"
          className="px-1 py-0 text-white"
          variant="success"
          disabled={savingPlaceholders}
          onClick={onPlaceholdersSave}
        >
          {savingPlaceholders ? (
            <span className="mx-1 px-1 text-white">
              <CircularProgressBar size={10} />
              <span className="mx-1 smallFont">{translate("please_wait")}</span>
            </span>
          ) : (
            <span className="text-white smallFont">{translate("save")}</span>
          )}
        </Button>
      </div>
    </>
  );
};

export default AssignPlaceholders;
