import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const statusServices = {
  getStatuses: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STATUS_BASE,
      method: "GET",
    });

    return { response, error };
  },
  createStatus: async (status, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STATUS_BASE,
      requestBody: status,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateStatus: async (status, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STATUS_WITH_ID(status?._id),
      requestBody: status,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteStatus: async (status, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STATUS_WITH_ID(status?._id),
      requestBody: status,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
  updateMultiple: async (statuses, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.STATUS_BASE,
      requestBody: statuses,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
};
