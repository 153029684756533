import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormCheck } from "react-bootstrap";
import { getTextColor } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import useUsers from "../../../hooks/useUsers";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

const ParticipantsOption = ({
  user,
  selected = false,
  disabled,
  onParticipantsChange,
  index,
  disableDnD,
}) => {
  let backgroundColor = user?.color || "#007f7f";
  let textColor = getTextColor(backgroundColor);

  return (
    <Draggable
      isDragDisabled={disableDnD}
      draggableId={user?._id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            background: backgroundColor,
            margin: 2,
            ...provided.draggableProps.style,
            width: snapshot.isDragging ? "240px" : "auto",
          }}
          className="hover p-1 d-flex align-items-center"
          onClick={() => onParticipantsChange(!selected)}
        >
          <FormCheck
            checked={selected}
            disabled={disabled}
            style={{
              height: "10px",
              marginBottom: 2,
            }}
            onChange={(e) => onParticipantsChange(e.target.checked)}
            className="hover d-flex align-items-center py-0"
            type="checkbox"
            id={`participant-${user?._id}`}
          />
          <h6 className="my-0 mx-2 mid truncate" style={{ color: textColor }}>
            {user?.name}
          </h6>
        </div>
      )}
    </Draggable>
  );
};

function UserFilter({
  disabled = false,
  selectedUsers = [],
  onUserChange,
  disableDnD,
  openedFilters,
  setOpenedFilters,
}) {
  const { users, mutipalUserUpdate } = useUsers();
  const { translate, isRTL } = useLocalization();
  const [userList, setUserList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [selectAll, setSelectAll] = useState({ user: true, others: true });

  const handleSelectAll = (checked, type) => {
    const list = type === "users" ? userList : otherList;
    const updatedSelectAll = { ...selectAll, [type]: checked };

    setSelectAll(updatedSelectAll);

    if (checked) {
      const newSelections = list
        ?.map((u) => u._id)
        .filter((id) => !selectedUsers.includes(id));
      onUserChange([...selectedUsers, ...newSelections]);
    } else {
      const remainingSelections = selectedUsers.filter(
        (id) => !list.some((u) => u._id === id)
      );
      onUserChange(remainingSelections);
    }
  };

  useEffect(() => {
    setSelectAll({
      others: otherList?.every((u) => selectedUsers.includes(u._id)),
      user: userList?.every((u) => selectedUsers.includes(u._id)),
    });
  }, [selectedUsers, userList, otherList]);

  useEffect(() => {
    let arrangedUsers = users.sort((a, b) => a.calendarIndex - b.calendarIndex);
    const initialUsers = arrangedUsers.filter(
      (user) => !user.showInOthersInCalendar
    );
    const initialOthers = arrangedUsers.filter(
      (user) => user.showInOthersInCalendar
    );
    setUserList(initialUsers);
    setOtherList(initialOthers);
  }, [users]);

  const moveItem = (arr, sourceIndex, destinationIndex) => {
    const newArr = [...arr]; // Create a shallow copy of the array
    const [removedItem] = newArr.splice(sourceIndex, 1); // Remove the item from sourceIndex
    newArr.splice(destinationIndex, 0, removedItem); // Insert the removed item at destinationIndex
    return newArr;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Exit if no destination

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceIsOthers = result.source.droppableId === "others";
    const isSameContainer =
      result.source.droppableId === result.destination.droppableId;

    let updatinguser = users?.find((u) => u?._id === result?.draggableId);

    let updatedUsersList = [];
    let updatedOthersList = [];

    if (isSameContainer) {
      if (sourceIsOthers) {
        updatedUsersList = [
          ...userList?.map((u, i) => ({
            ...u,
            showInOthersInCalendar: false,
          })),
        ];

        updatedOthersList = [
          ...moveItem(otherList, sourceIndex, destinationIndex)?.map(
            (u, i) => ({
              ...u,
              showInOthersInCalendar: true,
            })
          ),
        ];
      } else {
        updatedUsersList = [
          ...moveItem(userList, sourceIndex, destinationIndex)?.map((u, i) => ({
            ...u,
            showInOthersInCalendar: false,
          })),
        ];
        updatedOthersList = [
          ...otherList?.map((u, i) => ({
            ...u,
            showInOthersInCalendar: true,
          })),
        ];
      }
    } else {
      if (sourceIsOthers) {
        userList.splice(destinationIndex, 0, {
          ...updatinguser,
          showInOthersInCalendar: false,
        });

        updatedUsersList = [...userList];
        updatedOthersList = [
          ...otherList?.filter((u) => u?._id !== updatinguser?._id),
        ];
      } else {
        otherList.splice(destinationIndex, 0, {
          ...updatinguser,
          showInOthersInCalendar: true,
        });

        updatedUsersList = [
          ...userList?.filter((u) => u?._id !== updatinguser?._id),
        ];
        updatedOthersList = [...otherList];
      }
    }
    setUserList([...updatedUsersList]);
    setOtherList([...updatedOthersList]);

    // API call
    mutipalUserUpdate(
      [...updatedUsersList, ...updatedOthersList]?.map((u, i) => ({
        _id: u?._id,
        userUpdates: {
          name: u?.name,
          calendarIndex: i,
          showInOthersInCalendar: u?.showInOthersInCalendar,
        },
      }))
    );
  };

  const isFilterOpen = useCallback(
    (filter) => openedFilters.includes(filter),
    [openedFilters]
  );
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="">
        {/* Users List */}

        <Droppable isDropDisabled={disableDnD} droppableId="users">
          {(provided, snapshot) => (
            <div
              className="mt-1"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="d-flex justify-content-between align-items-center mx-1 mb-1">
                <h6 className="large fw-bold mb-0">{translate("users")}</h6>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="d-flex align-items-center hover"
                    onClick={() => handleSelectAll(!selectAll)}
                  >
                    <FormCheck
                      checked={selectAll?.user}
                      onChange={(e) =>
                        handleSelectAll(e.target.checked, "users")
                      }
                      className=""
                      style={{
                        fontSize: 16,
                      }}
                      id={"selct-all"}
                    />
                    <h6 className={`mb-0 mx-1 mid truncate`}>
                      {translate("select_all")}
                    </h6>
                  </div>
                  {isFilterOpen("users") ? (
                    <ChevronUp
                      className="hover-light"
                      onClick={() =>
                        setOpenedFilters(
                          openedFilters.filter((f) => f !== "users")
                        )
                      }
                    />
                  ) : (
                    <ChevronDown
                      className="hover-light"
                      onClick={() =>
                        setOpenedFilters([...openedFilters, "users"])
                      }
                    />
                  )}
                </div>
              </div>
              {isFilterOpen("users") && (
                <>
                  {" "}
                  {userList?.length > 0 ? (
                    userList?.map((user, index) => (
                      <ParticipantsOption
                        key={user?._id}
                        disableDnD={disableDnD}
                        disabled={disabled}
                        user={user}
                        selected={selectedUsers?.includes(user?._id)}
                        onParticipantsChange={(checked) =>
                          onUserChange(
                            checked
                              ? [...selectedUsers, user._id]
                              : selectedUsers?.filter((u) => u !== user._id)
                          )
                        }
                        index={index}
                      />
                    ))
                  ) : snapshot.isDraggingOver ? null : (
                    <h6 className="smallFont text-center mb-0 mt-1">
                      No users
                    </h6>
                  )}
                </>
              )}
            </div>
          )}
        </Droppable>

        {/* Other Users List */}
        <Droppable isDropDisabled={disableDnD} droppableId="others">
          {(provided, snapshot) => (
            <div
              className="mt-2"
              style={{ minHeight: isFilterOpen("others") && 80 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {" "}
              <div className="d-flex justify-content-between align-items-center mx-1 mb-1">
                <h6 className="large fw-bold mb-0">{translate("others")}</h6>
                <div className="d-flex align-items-center gap-2 ">
                  <div
                    className="d-flex align-items-center hover"
                    onClick={() => handleSelectAll(!selectAll)}
                  >
                    <FormCheck
                      checked={selectAll?.others}
                      onChange={(e) =>
                        handleSelectAll(e.target.checked, "others")
                      }
                      className=""
                      style={{
                        fontSize: 16,
                      }}
                      id={"selct-all"}
                    />
                    <h6 className={`mb-0 mx-1 mid truncate`}>
                      {translate("select_all")}
                    </h6>
                  </div>
                  {isFilterOpen("others") ? (
                    <ChevronUp
                      className=" hover-light"
                      onClick={() =>
                        setOpenedFilters(
                          openedFilters.filter((f) => f !== "others")
                        )
                      }
                    />
                  ) : (
                    <ChevronDown
                      className=" hover-light"
                      onClick={() =>
                        setOpenedFilters([...openedFilters, "others"])
                      }
                    />
                  )}
                </div>
              </div>
              {isFilterOpen("others") && (
                <>
                  {" "}
                  {otherList?.length > 0 ? (
                    otherList?.map((user, index) => (
                      <ParticipantsOption
                        key={user?._id}
                        disableDnD={disableDnD}
                        disabled={disabled}
                        user={user}
                        selected={selectedUsers?.includes(user?._id)}
                        onParticipantsChange={(checked) =>
                          onUserChange(
                            checked
                              ? [...selectedUsers, user._id]
                              : selectedUsers?.filter((u) => u !== user._id)
                          )
                        }
                        index={index}
                      />
                    ))
                  ) : snapshot.isDraggingOver ? null : (
                    <h6 className="border rounded py-3 smallFont text-center mb-0 mt-1">
                      No users to show
                    </h6>
                  )}
                </>
              )}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default UserFilter;
