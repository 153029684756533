import { CopyOutlined } from "@ant-design/icons";
import React from "react";
import { toast } from "react-toastify";

const MeetLink = ({ appointment }) => {
  return (
    <div
      className="p-2 w-100 border d-flex align-items-center justify-content-between"
      style={{ borderRadius: 5 }}
    >
      <a
        href={appointment?.zoomMeetLink}
        target="_blank"
        className="mb-0 large"
      >
        {appointment?.zoomMeetLink}
      </a>{" "}
      <CopyOutlined
        size={15}
        className="hover"
        title="Copy link"
        onClick={() => {
          navigator.clipboard.writeText(appointment?.zoomMeetLink);
          toast.success("Link copied to clipboard");
        }}
      />
    </div>
  );
};

export default MeetLink;
