import React from "react";
import LogItem from "./LogItem";
import useLocalization from "../../hooks/useLocalization";

const Logs = ({ logs = [] }) => {
  const {translate} =useLocalization()
  return (
    <div className={`d-flex flex-column align-items-start`}>
      {logs.length !== 0 ? (
        logs
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((l, i) => <LogItem log={l} key={`l-${i}`} />)
      ) : (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <h6>
            {translate('no_any_logs')}
            </h6>
        </div>
      )}
    </div>
  );
};

export default Logs;
