import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, FormControl, FormGroup, Table } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../helpers/constants";
import { WEBSITE } from "../../helpers/regex";
import useLocalization from "../../hooks/useLocalization";
import CurrencyInput from "../common/CurrencyInput";
// Yup validation schema
const validationSchema = Yup.object().shape({
  payments: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required("Please provide amount"),
      date: Yup.string().required("Please provide date"),
      invoiceLink: Yup.string().matches(WEBSITE, "Invalid website"),
    })
  ),
});

const DealPaymentForm = ({ contract, onChange, disabled, onAddNewPayment }) => {
  const { translate, isRTL } = useLocalization();

  return (
    <Formik
      initialValues={{
        payments: contract?.payments,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, values, setFieldValue }) => {
        useEffect(() => {
          onChange(values);
        }, [values]);
        return (
          <Form noValidate className="">
            <FormGroup className="">
              <Table bordered striped className="mb-0 w-100">
                <thead>
                  <tr className="mid">
                    <th className="" style={{ width: 100 }}>
                      Amount
                    </th>
                    <th className="">Date</th>
                    <th className="">Invoice Link</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="payments"
                    render={(arrayHelpers) => (
                      <>
                        {values.payments &&
                          values.payments.length > 0 &&
                          values.payments.map((e, index) => (
                            <tr key={`${index}`}>
                              <td>
                                <CurrencyInput
                                  value={values?.payments?.[index]?.amount}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `payments.${index}.amount`,
                                      value
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name={"amount"}
                                  component="div"
                                  className="text-danger mid"
                                />
                              </td>

                              <td>
                                <DatePicker
                                  disabled={disabled}
                                  className={`${
                                    isRTL ? "px-4" : ""
                                  }   mb-0 text-dark px-2 py-1 form-control form-control-sm`}
                                  selected={
                                    values?.payments?.[index]?.date
                                      ? new Date(
                                          values?.payments?.[index]?.date
                                        )
                                      : null
                                  }
                                  dateFormat={
                                    DEFAULT_DATE_TIME_FORMAT_NON_MOMENT
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      `payments.${index}.date`,
                                      e?.toISOString() || null
                                    );
                                  }}
                                  showMonthDropdown
                                  showYearDropdown
                                  showTimeInput
                                  timeFormat="HH:mm" // 24-hour format
                                  dropdownMode="scroll"
                                  clearButtonClassName="text-dark"
                                  portalId="root"
                                  popperClassName="datepickerPopperClassName"
                                />
                                <ErrorMessage
                                  name={`payments.${index}.date`}
                                  component="div"
                                  className="text-danger smallFont"
                                />
                              </td>
                              <td>
                                <Field
                                  name={`payments.${index}.invoiceLink`}
                                  type={"text"}
                                  as={FormControl}
                                  size="sm"
                                />
                                <ErrorMessage
                                  name={"invoiceLink"}
                                  component="div"
                                  className="text-danger mid"
                                />
                              </td>
                              <td align="center">
                                <Trash
                                  className="hover"
                                  size={12}
                                  onClick={() =>
                                    !disabled && arrayHelpers.remove(index)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        <tr className="">
                          <td
                            className="bg-white p-1"
                            colSpan={4}
                            align="right"
                          >
                            <Button
                              className="px-1 py-0 text-white"
                              variant="success"
                              disabled={disabled}
                              onClick={() => {
                                onAddNewPayment();
                                arrayHelpers.push({
                                  date: new Date(),
                                  amount: 0,
                                });
                              }}
                              size="sm"
                            >
                              <Plus />{" "}
                              <span className="smallFont">
                                {translate("add")}
                              </span>
                            </Button>
                          </td>
                        </tr>
                      </>
                    )}
                  />
                </tbody>
              </Table>
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DealPaymentForm;
