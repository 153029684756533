import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import Logo from "../common/Logo";
import UnderlineButton from "../common/UnderlineButton";
import LoginForm from "../forms/LoginForm";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { userService } from "../../services/userService";
import { toast } from "react-toastify";

const Login = () => {
  const { translate } = useLocalization();
  const { login, isLoggingIn, loginError } = useAuth();

  const [showForgotPassModal, setShowForgotPassModal] = useState(null);

  const onSubmit = async ({ email, password }) => {
    login(email, password);
  };

  const onForgotPasswordSubmit = async (data) => {
    try {
      setShowForgotPassModal({ showProgress: true });
      const { response, error } = await userService.forgotPassword(data);
      if (response) {
        toast.success("Password reset link has been sent");
        setShowForgotPassModal(null);
      }
      if(error){
        setShowForgotPassModal({showProgress: false,errorMessage: error});
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="justify-content-center h-100">
      <Col xs={10} md={6} className="align-self-center">
        <Card className="">
          <Card.Header className="p-3 bg-dark">
            <div className="">
              <Logo />
            </div>
          </Card.Header>
          <Card.Body>
            <h6 className="text-muted mx-2 my-1">
              {translate("welcome_to_ETICA_crm")}
            </h6>
            <h6 className="text-muted mx-2 my-1">
              <b className="text-dark">{translate("sign_in_to_continue")}</b>
            </h6>
            <hr />
            <LoginForm
              isLoggingIn={isLoggingIn}
              loginError={loginError}
              onSubmit={onSubmit}
            />{" "}
            <UnderlineButton
              onClick={() => setShowForgotPassModal({})}
              text={translate("forgot_password")}
              fontSize=""
            />
          </Card.Body>
        </Card>
      </Col>
      <ForgotPasswordModal
        show={showForgotPassModal}
        onHide={() => setShowForgotPassModal(null)}
        showProgress={showForgotPassModal?.showProgress}
        onSubmit={onForgotPasswordSubmit}
        forgotPasswordError={showForgotPassModal?.errorMessage}
      />
    </Row>
  );
};

export default Login;
