import useActiveTrailAndFacebookCampaign from "../../../hooks/useActiveTrailAndFacebookCampaign";
import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import AddEditEventForm from "../../forms/AddEditEventForm";

const AddEditEventModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  whatsappTemplates,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  const { facebookCampaigns } = useActiveTrailAndFacebookCampaign();
  const onFormSubmit = (event) => {
    let urls = (event.urls || []).map((u) => u.toLowerCase());
    urls = [...new Set(urls)];

    const eventToSubmit = {
      ...event,
      urls,
      facebookCampaigns: event.facebookCampaignIds.map((id) =>
        facebookCampaigns.find((l) => l.id == id)
      ),
    };

    onSubmit && onSubmit(eventToSubmit);
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={editMode ? translate(`update_event`) : translate(`add_new_event`)}
      showCloseButton={!showProgress}
    >
      <AddEditEventForm
        initialValues={initialValues}
        whatsappTemplates={whatsappTemplates}
        showProgress={showProgress}
        editMode={editMode}
        onSubmit={onFormSubmit}
      />
    </AppModal>
  );
};

export default AddEditEventModal;
