import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import ReferContactForm from "../forms/ReferContactForm";
import { contactService } from "../../services/contactService";
import { toast } from "react-toastify";
import { CheckCircle, InfoCircle } from "react-bootstrap-icons";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../helpers/constants";

const ReferForm = ({ isSubmitting, onSubmit }) => {
  const { translate } = useLocalization();

  return (
    <>
      <h6 className="text-dark my-1 fw-bold">
        {translate("welcome_to_ETICA_financials")}
      </h6>
      <h6 className="text-muted my-1 large">
        <b className="">{translate("referral_title_text")}</b>
      </h6>
      <hr className="mt-2 mb-0" />
      <ReferContactForm showProgress={isSubmitting} onSubmit={onSubmit} />
    </>
  );
};

const ContactAlreadyExists = ({ contact }) => {
  const { translate } = useLocalization();

  const { lastInboundDate } = contact;

  return (
    <div className="text-center p-2">
      <InfoCircle className="text-info" size={60} />
      <h4 className="my-4">
        <b>{translate("contact_already_known")}</b>
      </h4>

      <div className="px-3">
        <h5>
          {translate("contact_already_known_desc", {
            date: moment(lastInboundDate).format(DEFAULT_DATE_FORMAT),
          })}
        </h5>
      </div>

      <a href="/refer-contact">
        <Button className="text-white mt-4" variant="primary">
          {translate("refer_another_contact")}
        </Button>
      </a>
    </div>
  );
};

const ContactReferred = () => {
  const { translate } = useLocalization();

  return (
    <div className="text-center p-2">
      <CheckCircle className="text-success" size={60} />
      <h4 className="my-4">
        <b>{translate("thank_you")}</b>
      </h4>

      <div className="px-3">
        <h5>{translate("referal_success")}</h5>
      </div>

      <a href="/refer-contact">
        <Button className="text-white mt-4" variant="primary">
          {translate("refer_another_contact")}
        </Button>
      </a>
    </div>
  );
};

const ReferContact = () => {
  const { translate } = useLocalization();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactReferred, setContactReferred] = useState(false);
  const [contactAlreadyExists, setContactAlreadyExists] = useState(false);

  const onSubmit = async (form) => {
    setIsSubmitting(true);

    let reqBody = {
      contactInfo: {
        name: form.contactName,
        phone: form.contactPhone,
        email: form.contactEmail,
        comment: form.contactComment,
      },
      referrerInfo: {
        name: form.referrerName,
        phone: form.referrerPhone,
        email: form.referrerEmail,
      },
    };

    const { response, error } = await contactService.referContact(reqBody);
    setIsSubmitting(false);

    if (error) {
      return toast.error(error);
    }

    //handle response
    //if sucess show a message UI, else show we the latest inbound date
    if (response.success) {
      setContactReferred(true);
    } else if (response.alreadyExists) {
      setContactAlreadyExists(response);
    } else {
      return toast.error(response.message);
    }
  };

  return (
    <Row className="justify-content-center h-100 mt-3">
      <Col xs={10} md={10} className="align-self-center">
        <Card className="">
          <Card.Body className="py-2">
            {contactAlreadyExists ? (
              <ContactAlreadyExists contact={contactAlreadyExists} />
            ) : contactReferred ? (
              <ContactReferred />
            ) : (
              <ReferForm isSubmitting={isSubmitting} onSubmit={onSubmit} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReferContact;
