import React from "react";
import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import AddTemplateForm from "../../forms/AddTemplateForm";

export default function AddTemplateModal({
  show,
  onHide,
  onSubmit,
  showProgress,
  editMode,
  initialValue,
}) {
  const { translate } = useLocalization();
  return (
    <AppModal
      size="md"
      show={show}
      onHide={onHide}
      title={translate(editMode ? "update_template" : "add_template")}
      modalBodyClassName="p-1"
    >
      <AddTemplateForm
        editMode={editMode}
        showProgress={showProgress}
        onSubmit={onSubmit}
        initialValue={initialValue}
      />
    </AppModal>
  );
}
