import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import InputCell from "./InputCell";
import Title from "./Title";

const OverViewColumns = ({
  data = {},
  onDataChange,
  fields,
  parentContainerClassName = "",
  cellContainerClassName = "",
  titleClassName = "large",
  inputCellClassName = "",
  parentContainerStyle,
  cellContainerStyle,
  titleStyle = { fontSize: "14px" },
  inputCellStyle,
  cellSizeXS,
  cellSizeSM,
  cellSizeMD,
  cellSizeLG,
  isUpdating = false,
  editable: editModeFromParent,
  alwaysInEditMode = false,
}) => {
  const editMode = useMemo(
    () => alwaysInEditMode || editModeFromParent,
    [editModeFromParent, alwaysInEditMode]
  );

  const onEditingObjectChange = (field, newValue) => {
    onDataChange({ ...data, [field.key]: newValue });
  };

  return (
    <>
      <Row className={parentContainerClassName} style={parentContainerStyle}>
        {fields.map((field) => {
          return (
            <Col
              key={field.key}
              xs={field.cellSizeXS || cellSizeXS}
              sm={field.cellSizeSM || cellSizeSM}
              md={field.cellSizeMD || cellSizeMD}
              lg={field.cellSizeLG || cellSizeLG}
              className={`px-1 d-flex flex-column align-self-end ${cellContainerClassName}`}
              style={{ marginBottom: 2, ...cellContainerStyle }}
            >
              {!field?.hideTitle && (
                <Title
                  field={field}
                  editMode={editMode}
                  className={titleClassName}
                  style={titleStyle}
                />
              )}
              <InputCell
                data={data}
                onChange={onEditingObjectChange}
                disabled={field.isDisabled || isUpdating || !editMode}
                field={field}
                className={`flex-grow-1 ${field.className}`}
                style={inputCellStyle}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default OverViewColumns;
``;
