export const languageContent = {
  English: {
    payments: "Payments",
    down_payment: "Down Payment",
    paying_tax_investing_somewhere:
      "Paying the tax and investing somewhere else",
    paying_tax_at_period_end: "Paying tax at period end",
    tax: "Tax",
    pay_installment_cost: "Pay installment cost",
    pay_indexing_cost: "Pay indexing cost",
    gradual_payment: "Gradual Payment",
    pay: "Pay",
    target_irr: "Target IRR",
    real_estate_irr: "Real Estate IRR",
    irr: "IRR",
    irr_no_reconstruction: "IRR in case there is no reconstruction",
    net_yearly_return_on_rent: "Net yearly return on rent",
    yearly_return_from_rent: "Yearly return from rent",
    return_cashflow: "Expected Return (Cash Flow)",
    total_roi: "Total roi",
    return_appreciation: "Expected Return (Appreciation)",
    bad: "Bad",
    current_scenario: "Current Scenario",
    good: "Good",
    original: "Original",
    armageddon: "Armageddon",
    armageddon_scenario: "Armageddon Scenario",
    margin_ceiling: "Margin Ceiling",
    real_estate_holding_period: "Real estate holding period (years)",
    grace_period: "Grace Period",
    configure: "Configure",
    role: "Role",
    description: "Description",
    title_in_prospero: "Title In Prospero",
    expected_cashflow: "Expected Cashflow",
    cashflow: "Cashflow",
    multi_generational: "Multi-generational",
    strategic_planning: "Strategic planning",
    frequency: "Frequency",
    end_date_calculated_automatically: "End date calculated automatically?",
    end_date: "End Date",
    added_on: "Added On",
    start_date: "Start Date",
    share_with_new_user: "Share with new user",
    show_general_info_to_client: "Show General Info To Client?",
    share_strategies: "Share Strategies",
    share: "Share",
    annuity: "Annuity",
    yearly: "Yearly",
    buying_year: "Buying Year",
    end_year: "End Year",
    selling_year: "Selling Year",
    start_year: "Start Year",
    configure_armageddon: "Configure Armageddon",
    change: "Change",
    year: "Year",
    kept_remaining_cash: "Kept remaining cash <b>{amount}</b>",
    saving_cashflow_changes: "Saving cashflow changes",
    maintain_margin: "Maintain margin",
    save: "Save",
    secondary_name: "Secondary Name",
    realtor_seller_name: "Realtor/ seller name + phone number",
    name: "Name",
    sent_remaining_cash:
      "Sent remaining cash <b>{amount}</b> to linked bank account <b>{accountName}</b>",
    welcome_to_ETICA_financials: "Hello! Welcome to Ethica Finance",
    rent: "Rent",
    clone_strategy: "Clone Strategy {strategy}",
    clone: "Clone",
    duplicate: "Duplicate",
    rent_pre_contruction: "Rent Preconstruction",
    rent_post_contruction: "Rent Post Construction",
    total_income_from_rent_minus_monthly_payment:
      "Total income from rent minus total monthly payments",
    total_rent: "Total Rent",
    return_cashflow_monthly: "Expected Monthly Cashflow",
    monthly_rent: "Monthly rent",
    rent_during_contruction:
      "Rent During Construction (default is as pre- demolition)",
    forgot_password: "Forgot password?",
    marginal_rate_of_income: "Marginal rate of income",
    capital_appreciation_tax: "Capital appreciation tax",
    one_off_change: "One Off Change",
    interest_rate_changes: "Interest Rate Changes",
    one_off_change_by: "One off change by",
    heads_up: "Heads Up!",
    update_start_date_note_for_strategies:
      "Please note, This will update the start month and year of each strategy in the customer.",
    area_in_square_meters: "Area In Square Meters",
    unarchive: "Unarchive",
    saving_variable: "Saving Variable",
    saving_admin: "Saving Admin",
    saving_taxbrackets: "Saving Tax Bracket",
    saving_customer: "Saving Customer",
    saving_preset: "Saving Preset",
    archiving_strategy: "Archiving strategy",
    future_value_pre_tax: "Future value pre-tax",
    net_value: "Net value",
    total_value: "Total value",
    value_of_stocks_with_marginal_tax_rate:
      "Value of stocks with marginal tax rate",
    value_of_stocks_with_capital_aprreciation_tax_rate:
      "Value of stocks with capital aprreciation tax rate",
    value_of_apartment_today: "Value of received apartment today",
    value_after_renovation: "Value after renovation",
    appreciation: "Appreciation",
    margin_floor: "Margin Floor",
    level: "Level",
    buying: "Buying",
    mortgage_interest: "Mortgage interest",
    interest: "Interest",
    loan_interest: "Loan Interest",
    profit_from_selling_brfore_tax: "Profit from selling (before tax)",
    profit_from_selling_after_tax: "Profit from selling (after tax)",
    total_net_profit: "Total net profit",
    profit_at_excersising_pre_tax: "Profit at excersising pre tax",
    profit: "Profit",
    pensioni: "Pensioni",
    quaterly: "Quaterly",
    asset_name: "Link Asset",
    link_a_new_liability: "Link a new liability",
    stock_KSH: "stock KSH",
    video_link: "Video Link",
    total_loan_decrease: "Total loan decrease",
    asset_category: "Asset Category",
    category: "Category",
    apartment_floor_number: "Apartment's floor number",
    files_from_drive: "Files From Drive",
    other_files: "Other Files",
    set_a_ceiling_value: "Set a ceiling value",
    set_a_floor_value: "Set a floor value",
    set: "Set",
    get_reset_email: "Get Reset Email",
    fixed: "Fixed",
    view_files: "View Files",
    view_profile: "View Profile",
    yearly_growth_rate: "Yearly Growth Rate (%)",
    balance_sheet_growth_with_time: "Balance Sheet Growth With Time",
    add_new_strategy: "Add New Strategy",
    viewing_user: "Viewing User",
    open_drive_folder: "Open Drive Folder",
    profile_details: "Profile Details",
    difference: "Difference",
    active: "Active",
    actions: "Actions",
    edit_mode: "Edit Mode",
    action: "Action",
    filters: "Filters",
    stock_portfolio: "stock portfolio",
    end_value: "End value",
    monthly_value: "Monthly Value",
    initial_value: "Initial Value",
    value: "Value",
    edit: "Edit",
    yearly_appreciation: "Yearly appreciation",
    annual_appreciation: "Annual Appreciation",
    expected_value_appreciation_from_change:
      "Expected Value Appreciation From Additional Costs",
    expected_appreciation: "Expected Appreciation",
    value_added_by_renovation: "Value Added by Renovation",
    do_we_need_to_pay_material_cost_index:
      "Do we need to pay material cost index?",
    renovation_cost: "Renovation cost",
    total_deal_cost: "Total deal cost",
    cost_of_retailer_lawyer:
      "Cost of realtor, lawyer, buying tax and all related costs",
    realtor_cost_in_percentage_terms_pre_vat:
      "Realtor cost (in percentage terms pre V.A.T)",
    cost_of_time_to_delivery_after_buying:
      "Cost of time to delivery after buying",
    cost_of_vacancy_and_annual_repairs: "Cost of vacancy and annual repairs",
    realtor_cost: "Realtor cost (including VAT)",
    purchase_cost: "Purchase Cost",
    additional_buy_cost: "Additional Buying Cost",
    sales_cost: "Sales Cost",
    additional_sell_cost: "Additional Selling Cost",
    related_costs: "Related costs (lawyer, apraiser etc.)",
    additional_cost: "Additional Cost",
    additional_purchase_cost_for: "Additional Purchase Cost for",
    additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment:
      "Additional costs for making the apartment ready for rent",
    city: "City",
    drive_folder_not_created:
      "A drive folder for this client has not yet been created. Tap here to create one",
    initial_account_balance: "Initial Account Balance",
    update_reinvestment: "Update Reinvestment",
    update_strategy_name: "Update Strategy Name",
    update_cashflow_change: "Update Cashflow Change",
    group_update: "Group Update",
    update_variable: "Update Variable",
    update_admin: "Update Admin",
    update_taxbrackets: "Update Tax Bracket",
    update_customer: "Update Customer",
    resort_to_default: "Resort to default",
    update_notes: "Update Notes",
    reapply_comp: "Reapply Comp",
    update_preset: "Update Preset",
    updating_preset: "Updating Preset",
    update: "Update",
    updating_armageddon_scenario: "Updating Armageddon Scenario",
    update_strategy_start_time: "Update Strategy Start Time",
    updating_cashflow_changes: "Updating cashflow changes",
    to: "To",
    past: "Past",
    symbol: "Symbol",
    old_password: "Old Password",
    new_password: "New Password",
    password: "Password",
    action_summary: "Action Summary",
    loan_summary: "Loan Summary",
    one_off_event_summary: "One Off Event Summary",
    end_with_asset: "End with asset",
    end_with_maturity: "End with maturity",
    end: "End",
    stock_type: "Stock Type",
    program_type: "Program Type",
    type_of_interest: "Interest Type",
    interest_type: "Interest Type",
    transfer_type: "Transfer Type",
    loan_type: "Loan Type",
    apartment_type: "Apartment Type",
    type: "Type",
    total_assets: "Total Assets",
    total_liabilities: "Total Liabilities",
    total_income: "Total Income",
    total_expenditure: "Total Expenditure",
    total: "Total",
    rearrange_strategies: "Rearrange Strategies",
    close: "Close",
    shown_to_customer: "Shown To Customer",
    ignored_assets: "Ignored Assets",
    related_assets: "Related Assets",
    linked_asset: "Linked Asset ",
    assets: "Assets",
    asset_liquidated_on: "Asset liquidated on <b>{date}</b>",
    asset_is_linked_ib_stock:
      "This asset is the linked IB stock of the liability. So trying partial selling",
    asset: "Asset",
    can_sell_partially: "Can Sell Partially?",
    percentage_of_payment_delayed_to_delivery:
      "Percentage of payment delayed to delivery",
    risk_management: "Risk Management",
    manage_users: "Manage Users",
    stocks: "Stocks",
    net_after_excersising_and_selling_stocks:
      "Net after excersising and selling stocks",
    net: "Net",
    real_estate: "Real Estate",
    selected: "Selected",
    advanced: "Advanced",
    new_variables: "New Variables",
    variables: "Variables",
    user_already_invited:
      "This user has already been invited once. Proceeding will update the current user id and password of the user.",
    mortgage_to_be_taken:
      "Mortgage to be taken (this will depend on several options)",
    maximal_mortgage_available: "Maximal Mortgage Available",
    mortgage: "Mortgage",
    is_salary: "Is Salary",
    unarchiving_customer: "Unarchiving customer",
    something_went_wrong_try_again:
      "Something went wrong! Click Here to try again",
    margin_call: "Margin Call",
    showing: "Showing",
    normal_state: "Normal State",
    detailed_mode: "Detailed Mode",
    extreme_case: "Extreme Case",
    depressed_state: "Depressed State",
    mode: "Mode",
    status: "Status",
    updating_strategy_name: "Updating strategy name",
    updating_video_link: "Updating Video Link...",
    updating_variable: "Updating Variable",
    updating_admin: "Updating Admin",
    updating_taxbrackets: "Updating Tax Bracket",
    updating_customer: "Updating Customer",
    updating_notes: "Updating Notes",
    archiving_customer: "Archiving customer",
    vat: "V.A.T",
    total_years: "Total Years",
    number_floor_in_building: "Number of floors in the building",
    user_id: "User Id",
    secondary_phone_number: "Secondary Phone Number",
    phone_number: "Phone Number",
    months_to_sustain_negative:
      "Months to Sustain Negative Cashflow (After Crash)",
    number_of_rooms: "number of rooms",
    buying_tax: "Buying Tax",
    buying_tax_for: "Buying Tax for",
    tax_on_excersising: "Tax on excersising (selling stocks)",
    tax_on_rent: "Tax on rent",
    tax_calculated_automatically: "Tax calculated automatically?",
    buying_tax_calculated_automatically: "Buying Tax calculated automatically?",
    selling_tax_calculated_automatically:
      "Selling Tax calculated automatically?",
    total_tax: "Total Tax",
    tax_amount: "Tax Amount",
    tax_and_additional_costs: "Tax and additional costs",
    selling_tax: "Selling Tax",
    stock: "STOCK",
    admins: "Admins",
    new_admin: "New Admin",
    sorting_by: "Sorting By",
    partially_sold_from_asset:
      "Partially sold <b>{amount}</b> from asset <b>{assetName}</b> to meet the margin requirements.",
    partial_selling_not_possible:
      "Partial Selling is not possible for this stock. Trying complete liquidation",
    selling: "Selling",
    sell: "Sell",
    sort_by: "Sort By",
    fetching_admins: "Fetching admins",
    apartment_info: "Apartment Info",
    additional_information: "Additional Information",
    general_information: "General Info",
    who_is_typical_renter_for_apartment:
      "Who is the typical renter for the apartment?",
    allowed_calculators: "Allowed Calculators",
    calculators: "Calculators",
    pinui_binui: "Pinui Binui",
    irr_calculator: "IRR Calculator",
    target_price_calculator: "Target Price Calculator",
    paper_apartment: "Paper apartment",
    rsu_options: "RSU and Options",
    options_calculator: "Options calculator",
    rsu_calculator: "RSU calculator",
    group_delete_cashflow: "Group delete cashflow",
    group_delete_only_asset: "Group delete only Asset",
    group_delete_liability: "Group delete liability",
    group_delete_reinvestment: "Group delete reinvestment",
    group_delete_linked_liabilities_also:
      "Group delete linked liabilities also",
    delete_only_asset: "Delete only Asset",
    delete_linked_liabilities_also: "Delete linked liabilities also",
    delete: "Delete",
    wanted_price_per_sqm: "Wanted price per SQM",
    buying_price: "Buying Price",
    options_strike_price: "Options strike price",
    current_stock_price: "Current stock price (Publicly traded companies)",
    selling_price: "Selling price",
    price_per_square_meter: "Price per square meter",
    target_price: "Target Price",
    stock_value_at_options_receival_date:
      "Stock value at options receival date (If publicly traded company then 0)",
    do_we_need_to_pay_material_cost_index_paper_calculator:
      "Do we need to pay material cost index?",
    asked_price: "Asked Price",
    inviting_user: "Inviting user",
    cash: "Cash",
    adding_strategy: "Adding strategy",
    deleting_file: "Deleting file",
    deleting_variable: "Deleting Variable",
    deleting_admin: "Deleting admin",
    deleting_taxbrackets: "Deleting Bracket",
    deleting_customer: "Deleting customer",
    deleting_preset: "Deleting Preset",
    deleting_strategy: "Deleting strategy",
    new_tax_bracket: "New Bracket",
    selling_tax_brackets_only_apartment:
      "Selling Tax Brackets [Only Apartment]",
    selling_tax_brackets: "Selling Tax Brackets",
    buying_tax_brackets_only_apartment: "Buying Tax Brackets [Only Apartment]",
    buying_tax_brackets: "Buying Tax Brackets",
    taxbrackets: "Tax Brackets",
    yearly_materials_cost_index_rise_paper_calculator:
      "Yearly materials cost index rise",
    yearly_materials_cost_index_rise_comp: "Yearly materials cost index rise",
    additional_costs_for_making_the_apartment_ready_for_rent:
      "Additional costs for making the apartment ready for rent",
    stress_test: "Stress Test",
    resetting_comp: "Resetting comp",
    balance_sheet: "Balance Sheet",
    from: "From",
    took_from_asset: "Took <b>{amount}</b> from asset <b>{assetName}</b>",
    took_from_linked_bank_account:
      "Took <b>{amount}</b> from linked bank account <b>{accountName}</b>",
    customers: "Customers",
    clone_customer: "Clone Customer",
    new_customer: "New Customer",
    asset_has_linked_loans:
      "The asset you are trying to delete has {n} loans linked to it. Do you also want to delete the loans?",
    asset_has_negative_value:
      "Asset <b>{assetName}</b> has negative value on <b>{date}</b>",
    asset_has_no_money: "Asset <b>{assetName}</b> has no value",
    meeting_video_link: "Meeting Video Link",
    real_estate_guidance: "Real estate guidance",
    press_alt_to_move:
      "Press alt on Windows or option on Mac to move the chart around",
    logs: "Logs",
    no_stocks_with_linked_liabilities:
      "No stocks with linked liabilities found!",
    could_not_use_asset_loan_is_greater:
      "Could not use this asset, Loan is greater than liquidated value of this asset. Liquidated Value: <b>{liquidationAmount}</b>, LOAN Value: <b>{loanAmount}</b>",
    no_strategies_created: "No strategies created",
    no_notes_added: "No notes added",
    no: "No",
    address: "Address",
    cushions: "Cushions",
    available_level1_funds_before_crash:
      "Available Level1 Funds (Before Crash)",
    available_level1_funds_after_crash: "Available Level1 Funds (After Crash)",
    yes: "Yes",
    stock_amount: "Stock amount",
    options_to_cash_after_two_years:
      "Options to cash (after two years of holding)",
    options_to_cash_pre_two_years: "Options to cash (Pre two years of holding)",
    setup_mode: "Setup Mode",
    already_have_an_account: "Already have an account? Click here to login",
    remaining_payment: "Remaining Payment",
    mortgage_left_at_the_end: "Mortgage left at the end of period of holding",
    one_final_payment: "One Final Payment",
    not_enough_assets:
      "Remaining amount is <b>{amount}</b>. Not enough assets to liquidate",
    some_investment_are_not_applied:
      "Some of the investments might not have been applied because this strategy has cyclic investments. Please correct them below",
    assets_decrease: "Assets Decrease",
    value_lost_in_months: "Value lost in 6 months",
    depreciations: "Depreciations",
    maximal_drawdown: "Maximal Drawdown",
    target: "Target",
    creating_an_account: "Creating an account for {email}",
    upload_files_to_this_apartment: "Upload Files To This Apartment",
    loading_file: "Loading file",
    fetching_customers: "Fetching Customers",
    liquidation_table: "Liquidation Table",
    opening_balance: "Opening Balance",
    linked_bank_account: "Linked Bank Account",
    primary_bank_account: "Primary Bank Account",
    semi_yearly: "Semi Yearly",
    asset_class_distribution: "Asset Class Distribution",
    quick_search: "Quick Search...",
    search: "Search",
    back_to_site: "Back to site",
    monthly: "Monthly",
    buying_month: "Buying Month",
    end_month: "End Month",
    selling_month: "Selling Month",
    start_month: "Start Month",
    starting_month: "Starting Month",
    month: "Month",
    new: "New",
    estimated_time_for_finishing_contruction:
      "Estimated time for finishing construction from demolition in years",
    estimated_time_for_contruction:
      "Estimated time for finishing construction in years",
    estimated_time_for_contruction_months:
      "Estimated time for finishing construction in months",
    estimated_time_for_demolition: "Estimated time for demolition in years",
    mortgage_time_to_maturity: "Mortgage time to maturity",
    time_to_maturity: "Time To Maturity (Months)",
    time_to_delivery_after_buying: "Time to delivery after buying (months)",
    holding_period_in_years: "Holding period in years",
    this_will_also_delete_related_investments:
      "This will also delete their related investments (if any).",
    app_videos: "App Videos",
    video_not_found_please_contact_admin:
      "Video not found, Please contact the admin of the site",
    logout: "Logout",
    start: "Start",
    liability_ends_with: "Liability Ends With",
    duplicate_linked_liabilities:
      "This asset also has liabilities linked to it. Do you also want to duplicate them?",
    liabilities: "Liabilities",
    sign_in_to_continue: "Sign in to continue",
    login: "Login",
    reinvestments: "Reinvestments",
    cashflow_impacts: "Cashflow Impacts",
    compare_strategies: "Compare Strategies",
    strategies_comparison: "Strategies Comparison",
    leave_empty_to_view_results: "Enter 2 characters to start searching...",
    show_stress_test_to_client: "Show Stress Test to Client?",
    show_all_strategies_from_customer: "Show all strategies to customer",
    show_archived_to_client: "Show Archived to Client?",
    show: "Show",
    meeting_video: "Meeting Video",
    still_insufficient_amount_for_buying:
      "Materials cost indexing to be paid at construction end",
    show_explainatory_video: "Show Explainatory Video",
    show_archived: "Show Archived",
    difference_between_value_and_price:
      "Difference between appraised value and asked price",
    apply_stress_test: "Apply Stress Test",
    additional_comments: "Additional comments",
    notes: "Notes",
    upload: "Upload",
    upload_files: "Upload Files",
    reinvestment_and_gradual_selling: "Reinvestment and Gradual Selling ",
    transactions: "Transactions",
    archive: "Archive",
    hide_zero_rows: "Hide rows with 0 cashflow",
    hide_explainatory_video: "Hide Explainatory Video",
    hide_all_strategies_from_customer: "Hide all strategies from customer",
    hide: "Hide",
    negative_amount_is: "Negative amount is <b>{amount}</b>",
    remaining_amount_trying_asset:
      "Remaining amount is <b>{amount}</b>. Trying asset <b>{assetName}</b>",
    required_amount_for_margin_is:
      "Required amount for margin requirements is <b>{amount}</b>",
    asset_liquidated_from_to:
      "Asset liquidated from <b>{date1}</b> to <b>{date2}</b>",
    asset_linked_asset_of_loan:
      "Current asset is linked asset of the loan. Needs to be liquidated. Liquidated Value: <b>{liquidatedValue}</b>. Remaining Loan Amount: <b>{loanValue}</b>",
    comp_table: "Comp Table",
    user_has_not_signed_up_yet: "User has not signed up yet",
    continue: "Continue",
    convert_from_another_currency: "Convert from another currency",
    convert: "Convert",
    customer_shared: "This customer has been shared with the following users.",
    customer_not_shared:
      "This customer has not been shared with other users yet!",
    margin_loan_against: "Margin Loan Against",
    fixed_income: "Fixed Income",
    loan_to_asset: "Loan To Asset",
    loan_against: "Loan Against",
    loan: "LOAN",
    total_income_from_rent_after_vacancy_and_tax:
      "Total income from rent (after vacancy and tax)",
    total_income_from_rent: "Total income from rent",
    income: "Income",
    enter_otp_here: "Enter OTP Here",
    all: "All",
    holding_postponing_tax: "Holding and postponing the tax",
    register: "Register",
    subtract: "Subtract",
    transfer_applied_to: "Transfer Applied To",
    apply: "Apply",
    reapply: "Reapply",
    total_monthly_payments: "Total monthly mortgage payments",
    monthly_mortgage_payment: "Maximal Monthly mortgage payment",
    invite_user: "Invite User",
    the_weakest_month_and_year:
      "The weakest month and year of this strategy is",
    invite: "Invite",
    cleared_linked_loan: "Cleared linked loan of value: <b>{clearedLoan}</b>.",
    cleared_linked_loan_remaining_cash:
      "Cleared linked loan of value: <b>{clearedLoan}</b>. Remaining cash: <b>{remainingCash}</b>",
    download: "Download",
    expenditure: "Expenditure",
    add_cashflow: "Add Cashflow",
    update_cashflow: "Update Cashflow",
    add_new_change: "Add new change",
    add_new_cashflow_change: "Add Cashflow Change",
    group_add: "Group Add",
    add_value: "Add value",
    add_new_asset: "Add New Asset",
    add_asset: "Add Asset",
    add_apartment: "Add Appartment",
    add_new_variable: "Add New Variable",
    add_new_admin: "Add New Admin",
    add_new_taxbrackets: "Add New Tax Bracket",
    add_new_customer: "Add New Customer",
    add_new: "Add New",
    add_liability: "Add Liability",
    add_reinvestment: "Add Reinvestment",
    add_new_preset: "Add New Preset",
    add: "Add",
    equity_received: "Equity Received",
    total_equity_for_buying: "Total equity for buying",
    equity_for_taking_mortgage: "Equity for taking mortgage",
    equity_to_assets: "Equity To Assets",
    total_equity_to_put_in_the_deal: "Total equity to put in the deal",
    equity: "Equity",
    equity_for: "Equity for",
    required_equity_for_excercising: "Required equity for excercising (strike)",
    app_configuration: "App Configuration",
    preset: "Preset",
    strategies_archived: "The following strategies were archived!",
    is_rent: "Is Rent?",
    is_there_an_balcony: "Is there a balcony?",
    is_there_an_elevator: "Is there an elevator?",
    is_there_a_safe_room: "Is there a safe room?",
    is_there_parking: "Is there parking? Is it legaly registered?",
    is_there_garden: "Is there a garden?",
    is_only_apartment: "Is Only apartment",
    is_it_the_only_apartment_buying: "Is it the only apartment when buying",
    is_it_the_only_apartment_selling: "Is it the only apartment when selling",
    is_apartment_properly_registered: "Is the apartment properly registered?",
    is_there_construction_without_permit:
      "is there any construction without permit?",
    is_apartment_registered_on_seller_name:
      "Is the apartment registered on the seller's name?",
    is_apartment_abroad: "Is Apartment Abroad?",
    are_you_sure_you_want_to_archive_strategy:
      "Are you sure you want to archive this strategy ({strategy})?",
    are_you_sure_you_want_to_unarchive_strategy:
      "Are you sure you want to unarchive this strategy ({strategy})?",
    are_you_sure_you_want_to_unselect_strategy:
      "Are you sure you want to unselect this strategy ({strategy})?",
    are_you_sure_you_want_to_select_strategy:
      "Are you sure you want to select this strategy ({strategy})?",
    liquidation_score: "Liquidation Score",
    rate_from_1_to_10: "Rate from 1 to 10 (1- Terrible 10- Excellent)",
    how_easy_to_find_renters:
      "How easy is it to find renters for the apartment?",
    how_well_maintained: "How well maintained is the apartment?",
    how_good_is_location: "How good is the location?",
    how_good_are_current_renters: "How good are the current renters?",
    linked_apartment: "Linked Apartment",
    apartment: "Apartment",
    tenant_stops_paying: "Tenant stops paying",
    charts: "Charts",
    interest_only: "Interest only",
    cashflow_appreciation: "Cashflow Appreciation",
    loan_provider: "Loan Provider",
    apartment_size: "apartment's size",
    apartment_size_after_reconstruction: "Apartment size after reconstruction",
    loan_value: "Loan Value",
    new_presets: "New Presets",
    presets: "Presets",
    highest_earner: "Highest Earner",
    balance_with: "Balance With",
    weakest: "Weakest",
    yearly_materials_cost_index_rise: "Yearly materials cost index rise",
    balloon: "Balloon",
    home: "Home",
    unarchiving_strategy: "Unarchiving strategy",
    unselecting_strategy: "Unselecting strategy",
    disable_reinvestment: "Disable all reinvestments",
    unselect: "Unselect",
    are_you_sure_you_want_to_delete_taxbrackets:
      "Are you sure you want to delete this Bracket? This action can not be undonenot be undone",
    linked_bank_account_has_no_money:
      "Linked bank account <b>{accountName}</b> has no money",
    select_unselect_all: "Select/Unselect All",
    select: "Select",
    selecting_strategy: "Selecting strategy",
    please_provide_a_primary_bank_account:
      "Please provide a primary bank account for this strategy",
    are_you_sure_you_want_to_unarchive_customer:
      "Are you sure you want to unarchive the customer ({userName}) ? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_cashflow:
      "Are you sure you want to delete this Cashflow? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_asset:
      "Are you sure you want to delete this Asset? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_liability:
      "Are you sure you want to delete this Liability? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_investment:
      "Are you sure you want to delete this investment option? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_one_off_event:
      "Are you sure you want to delete this One Off Event? This action can not be undonenot be undone",
    are_you_sure_you_want_to_archive_customer:
      "Are you sure you want to archive the customer ({userName}) ? This action can not be undonenot be undone",
    are_you_sure_you_want_to_reapply:
      "Are you sure to reapply the comp table to this strategy? We recommend you make a clone of the original strategy first",
    are_you_sure_you_want_to_delete_admin:
      "Are you sure you want to delete this Admin ({admin})? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_customer:
      "Are you sure you want to delete the customer ({userName}) ? This action can not be undonenot be undone",
    are_you_sure_delete_file:
      "Are you sure to delete this file ({fileName}). You will lose access to this file permanently?",
    are_you_sure_you_want_to_delete_variable:
      "Are you sure you want to delete this Variable? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_preset:
      "Are you sure you want to delete this Preset? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_strategy:
      "Are you sure you want to delete this Strategy ({strategy})? This action can not be undonenot be undone",
    are_you_sure_reset_comp_table:
      "Are you sure you want to reset the Comp Table to default? This action can not be undonenot be undone!",
    confirm_password: "Confirm Password",
    strategy_archive: "Strategy ARCHIVE",
    archived: "Archived",
    reset_to_default: "Reset To Default",
    strategies: "Strategies",
    new_strategy: "New Strategy",
    strategy: "Strategy",
    anona: "Anona",
    please_provide_a_primary_bank_account_for_this_strategy:
      "Please provide a primary bank account for this strategy",
    please_provide_your_email_address:
      "Please provide your email address to continue",
    alphabetically: "Alphabetically",
    real_estate_full_service: "Real estate full service",
    reset_zoom: "Reset Zoom",
    no_name: "No name",
    no_files_to_show: "No files to show!",
    no_asset: "No Asset",
    margin_not_met:
      "Margin for {stockType} loan <b>{loanName}</b> has not been met on <b>{date}</b>",
    nothing_to_show: "Nothing to show!",
    dont_have_an_account: "Don't have an account? Click here to register",
    no_incomes: "No Incomes",
    no_expenditure: "No Expenditures",
    no_email: "No email",
    recipient_emails: "Recipient Emails",
    secondary_email: "Secondary Email",
    email: "Email",
    mortgage_percentage: "Mortgage percentage",
    percentage: "Percentage",
    problems_detected: "Problems Detected",
    appraised_value: "Appraised Value",
    crash_percentage: "Crash Percentage",
    loses_place_of_work: "Loses a place of work",
    showing_n_items: "{n} items",
    please_wait_n_secs:
      "Please wait {timer} seconds before requesting a new OTP...",
    did_not_receive_send_otp_again:
      "Didn't receive OTP? Click here to send again...",
    logging_in: "Logging In",
    checking_email: "Checking email",
    creating_account: "Creating account",
    please_wait: "Please wait...",
    value_in_currency: "Value in {currency}",
    hello: "Hello!",
    welcome_to_ETICA_crm: "Welcome to Ethica CRM",
    please_reset_your_password: "Please create a password",
    contacts: "Contacts",
    how_to_use_app: "How to use App?",
    comments: "Comments ({count})",
    comments_no_count: "Comments",
    contracts: "Contracts",
    inbounds: "Inbounds ({count})",
    inbounds_no_count: "Inbounds",
    emails: "Emails",
    phone_calls: "Phone Calls",
    phone_calls_count: "Phone Calls ({count})",
    no_comments_to_show: "No comments to show!",
    no_contracts_to_show: "No contracts to show!",
    type_your_comment_here: "Type your comment here...",
    reset: "Reset",
    are_you_sure_delete_comment:
      "Are you sure you want to delete this comment? This action can not be undonenot be undone",
    admin: "Admin",
    products: "Products",
    add_new_product: "Add New Product",
    update_product: "Update Product",
    price: "Price",
    template_link: "Template Link on Prospero",
    are_you_sure_you_want_to_delete_products:
      "Are you sure you want to delete the product ({productName}) ? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_contract:
      "Are you sure you want to delete the contract ({contractName}) ? This action can not be undonenot be undone",
    create_new_deal: "Create new Deal",
    edit_contract: "Edit Contract",
    send: "Send",
    dont_send: `Don't Send`,
    quote: "Quote",
    anona_monthly_payment: "Anona Monthly Payment",
    source: "Source",
    quote: "Quote",
    followup_date: "Follow Up Date",
    details: "Details",
    inbound_source_details: "Inbound Source Details",
    events: "Events",
    users: "Users",
    cancel: "Cancel",
    add_new_event: "Add Event",
    update_event: "Update Event",
    event_date: "Event Date",
    event_time: "Event Time",
    webinar_link: "Webinar Link",
    event_name: "Event Name",
    facebook_campaign: "Facebook Campaign",
    select_campaign: "Select Campaign",
    active_trail_list: "Active Trail Groups",
    select_trail_list: "Select Trail List",
    add_new_user: "Add User",
    update_user: "Update User",
    are_you_sure_you_want_to_delete_events:
      "Are you want to delete this event? This action can not be undonenot be undone",
    are_you_sure_you_want_to_delete_user:
      "Are you certain you wish to delete this user ({name})? Deleting this user may impact contacts, so kindly assign another user in replace or remove them from all contacts.",
    are_you_sure_you_want_to_delete_tag:
      "Are you certain you wish to delete this tag? Deleting this tag may impact contacts, so kindly assign another tag in replace or remove them from all contacts.",
    websites: "Websites",
    previous_unsaved_contact:
      "The contact {name} has unsaved changes. Do you want to save the changes before proceeding?",
    call: "Call",
    whatsapp: "Whatsapp",
    set_appointment: "Appointment",
    add_new_contact: "Add New Contact",
    first_name: "First Name",
    last_name: "Last Name",
    spouse_first_name: "Spouse First Name",
    spouse_last_name: "Spouse Last Name",
    referrer: "Referrer",
    adding_new_row: "Adding New Row",
    change_logs: "Logs",
    made_the_following_change_on: "made the following changes on",
    added: "Added",
    updated: "Updated",
    removed: "Removed",
    salesperson: "Salesperson",
    add_appointment: "Add Appointment",
    update_appointment: "Update Appointment",
    participants: "Participants",
    automationAndevents: "Automation & Events",
    appConfig: "App Config",
    appointment_defaults: "Appointment Defaults",
    update_appointment_default_type: "Update Appointment Default Type",
    title: "Title",
    automations: "Automation",
    are_you_sure_you_want_to_delete_automation:
      "Are you want to delete this automation? This action can not be undonenot be undone",
    add_new_automation: "Add new automation",
    update_automation: "Update automation",
    duration: "Duration",
    new: "New",
    phone_call_unanswered: "Phone Call Unanswered",
    sales: "Sales",
    expressed_interest: "Expressed Interest",
    finished_first_phone_call: "Finished First Phone Call",
    was_in_meeting: "Was in Meeting",
    contract_sent: "Contract Sent",
    sent: "Sent",
    contract_signed: "Contract Signed",
    doesnt_meet_our_requirements: `Doesn’t Meet Our Requirements`,
    meeting_scheduled: "Meeting Scheduled",
    didnt_answer_5_times: `Didn’t Answer 5 Times`,
    asked_to_not_be_contacted: "Asked To Not be Contacted",
    not_interested_now: "Not Interested Now",
    client_paid: "Client Paid",
    clients: "Clients",
    client_in_process: "Client in Process",
    client_finished: "Client Finished",
    awaiting_upsale: "Awaiting Upsale",
    on_hold: "On Hold",
    please_select_one: "Please Select One",
    inbound_source_default_status: "Inbound Source Defaults",
    update_default_status: "Update Default Status",
    inboundSource: "Inbound Source",
    exceptions: "Exceptions",
    add_exceptions: "Add Exceptions",
    irrelevant: "Irrelevant",
    confirm_password: "Confirm Password",
    submit: "Submit",
    already_have_an_account: "Already have an account? Click here to login",
    ethica_finance: "Ethica Finance",
    no_any_logs: "No any logs available",
    send_to_rujum: "Rujum",
    get_reset_email: "Get Reset Email",
    update_user_permissions: "Update User Permissions",
    rule: "Rule",
    go_to_rujum: "Go to Rujum",
    tags: "Tags",
    update_tag: "Update Tag",
    add_tag: "Add Tag",
    are_you_sure_you_want_to_delete_contacts:
      "Are you sure you want to permanently delete selected contacts?",
    are_you_sure_you_want_to_delete_contracts:
      "Are you sure you want to permanently delete selected deals?",
    filter: "Filter",
    edit_multiple_row: "Edit multiple Row",
    edit_multiple_contacts_field: "Edit multiple contacts",
    any: "Any",
    are_you_sure_you_want_to_delete_filter:
      "Are you sure you want to delete this filter",
    filters: "Filters",
    saved_filters: "Saved Filters",
    custom_filters: "Custom Filters",
    enter_name: "Enter Name",
    save_and_apply: "Save & Apply",
    manually_added: "Manually Added",
    follow_up_date: "Follow Up Date",
    sales_person: "Sales Person",
    financial_strategist: "Financial Strategist",
    real_estate_analyst: "Real Estate Analysist",
    phone_numbers: "Phone Numbers",
    emails: "Emails",
    last_inbound_date: "Last Inbound Date",
    last_inbound_source_campaign_url: "Last Inbound Campaign/Url",
    last_inbound_source_type: "Last Inbound Channel",
    inbound_date: "Inbound Date",
    inbound_source_campaign_url: "Inbound Campaign/Url",
    inbound_source_type: "Inbound Channel",
    contains: "Contains",
    begins_with: "Begins With",
    ends_with: "Ends With",
    does_not_contain: "Does not contain",
    does_not_begin_with: "Does not begin with",
    does_not_end_with: "Does not end with",
    is_null: "Has a value",
    is_not_null: "Does not have a value",
    in: "In",
    not_in: "Not In",
    between: "Between",
    not_between: "Not Between",
    deals_count: "Deals ({count})",
    contract_sent_on: "Deal created on",
    deal_value: "Deal Value",
    sent_by: "Sent By",
    // client: "Client",
    published: "Published",
    signed: "Deal won",
    opened: "Opened",
    declined: "Declined",
    no_payments_to_show: "No payments to show",
    add_payment: "Add Payment",
    update_payment: "Update Payment",
    amount: "Amount",
    date: "Date",
    invoice_link: "Invoice Link",
    payment_details: "Payment Details",
    payment_received: "Payment Received",
    are_you_sure_you_want_to_delete_payment:
      "Are you sure you want to delete this payment?",
    editing_filter: "Editing ({filterName})",
    show_events_of: "Show Events Of:",
    custom_filter: "Custom filter",
    updating_filter: "Updating filter...",
    dashboard: "Dashboard",
    general_stat_board: "General statistics board",
    total_new_leads: "Total new Leads",
    total_new_meetings: "Total new meetings (zoom or office)",
    total_new_phone_calls: "Total new phone calls",
    monthly_sales: `Sales (won deals)`,
    monthly_income: "Income (payments/ receipts)",
    number_of_new_clients: "Number of new clients",
    total_expenses_and_marketing_expenses:
      "Total expenses and marketing expenses",
    marketing_stat_board: "Marketing statistics board",
    number_of_leads_by_channel: "Number of leads by channel",
    number_of_sales_volume_by_channel: "Number of sales volume by channel",
    today: "Today",
    day: "Day",
    month: "Month",
    week: "Week",
    export_as_csv: "Export as CSV",
    nothing_added: "Nothing Added!",
    commented_by: "Commented by",
    cancel: "Cancel",
    clear_filters: "Clear",
    close_filters: "Close {mode} Filters",
    nothing_added: "Nothing Added!",
    color: "Color",
    no_contact_found: "No contact found!",
    contract_details: "Contract Details",
    assigned_to: "Assigned to",
    deal_value: "Deal Value",
    follow_up_due: "Follow up due",
    clear_followup_filter: "Clear Followup Filter",
    production_stage: "Production Stage",
    commissions: "Commissions",
    referrers: "Referrers",
    add_new_referrer: "Add New Referrer",
    update_referrer: "Update Referrer",
    are_you_sure_you_want_to_delete_referrer:
      "Are you sure you want to delete this referrer? This action can not be undone",
    are_you_sure_you_want_to_delete_product_stage:
      "Are you sure you want to delete the product stage ({stageName}) ? This action can not be undone",
    more: "more",
    day: "Day",
    month: "Month",
    week: "Week",
    assign_another_step: "Assign a new stage",
    assign_another_product: "Assign a new product",
    assign_another_contract: "Assign a new contract",
    appointment_type: "Appointment Type",
    call_recording: "Call Recording",
    office_meeting: "Office Meeting",
    google_meet_meeting: "Google Meet Meeting",
    phone_call: "Phone Call",
    zoom_meeting: "Zoom Meeting",
    would_you_like_to_append_this_comment_to_an_existing_contact:
      "This contact already exists. Would you like to append this comment to an existing contact?",
    contract_link: "Contract Link",
    contact: "Contact",
    contract_not_needed: "Contract Not Needed",
    general_stat_board: "General",
    marketing_stat_board: "Marketing",
    sales_stat_board: "Sales",
    operation_stat_board: "Operation",
    finance_stat_board: "Financial",
    conversion_rate_from_lead_to_phone_call:
      "Conversion rate from lead to phone call",
    conversion_rate_from_phone_call_to_meeting:
      "Conversion rate from phone call to meeting",
    conversion_rate_from_meeting_to_signed_deal:
      "Conversion rate from meeting to signed deal",
    total_sales_and_total_commissions_paid_to_be_paid:
      "Total sales and total commissions paid/ to be paid",
    average_time_from_first_phone_call_to_deal_closed:
      "Average time from first phone call to deal closed",
    same_per_sales_person: "Same per sales person",
    number_of_new_clients_per_product: "Number of new clients per product",
    average_time_to_finish_client_per_product:
      "Average time to finish client per product",
    average_client_satisfaction_per_user:
      "Average client satisfaction per user (company employee)",
    finish_rate_and_cancellations_rate:
      "Finish rate (how many clients get lost) and cancellations rate",
    product: "Product",
    total_number_of_leads_by_channel: "Total number of leads by channel",
    total_number_of_sales_volume_by_channel:
      "Total number of sales volume by channel",
    website: "Website",
    manual: "Manual",
    all_day: "All Day",
    this_product_doesnot_have_link:
      "This contract does not have an attached proposal link",
    send_contract: "Send Contract",
    change_logs: "Logs",
    no_of_client_in_each_step: "Number of client in each step",
    contract_link: "Contract Link",
    contact: "Contact",
    select_contact: "Select contact to assign",
    contract_not_needed: "Contract Not Needed",
    product: "Product",
    all_day: "All Day",

    send_contract: "Send Contract",
    comment: "Comment",
    one_week: "1 week",
    three_week: "3 weeks",
    three_month: "3 months",
    close_archive: "Close Trash",
    secondary_emails: "Secondary Emails",
    are_you_sure_you_want_to_trash_contacts:
      "Are you sure you want to trash selected contacts?",
    are_you_sure_you_want_to_restore_contacts:
      "Are you sure you want to restore selected contacts?",
    trash: "Trash",
    merge_contact: "Merge contact",
    referral: "Referral",
    merge: "Merge contact",
    merge_message:
      "There already exists another contact with similar details. Would you like to merge new one with the existing one? you can compare the changes below",
    deals: "Deals",
    is_current_deal: "Is current deal?",
    activity: "Activity",
    time_of_the_call: "Time of the call",
    call_type: "Call Type",
    receiver: "Receiver",
    caller: "Caller",
    call_length: "Call Length",
    fields_with_multiple_values: "Fields with multiple values",
    you_can_choose_to_keep_both_the_values_for_these_fields:
      "You can choose to keep both the values for these fields",
    merge_contact: "Merge contact",
    referral: "Referral",
    merge: "Merge",
    merge_message:
      "There already exists another contact with similar details. Would you like to merge new one with the existing one? you can compare the changes below",
    deals: "Deals",
    is_current_deal: "Is current deal?",
    activity: "Activity",
    time_of_the_call: "Time of the call",
    call_type: "Call Type",
    receiver: "Receiver",
    caller: "Caller",
    call_length: "Call Length",
    payment_due: "Payment Due",
    contract_signed_on: "Deal won date",
    multi_value: "Multi Value",
    are_you_sure_to_change_production_stage:
      "Are you sure you want to change the production stage?",
    this_product_has_no_producation_stage:
      "This product has no production stages",

    client: "Client",
    deal_date: "Deal Date",
    meeting_type: "Meeting Type",
    meeting_title: "Meeting Title",
    meeting_setting_date: "Meeting Setting Date",
    meeting_schedule_date: "Meeting Schedule Date",
    meeting_participants_users: "Meeting participants users",
    meeting_participants_clients: "Meeting participants clients",
    meeting_description: "Meeting description",
    total_commissions: "Total Commissions",
    total_sales: "Total Sales",
    commission_value: "Commission value",
    commission_payment_data: "Commission payment data",
    are_you_sure_you_want_to_delete_this_deal:
      "Are you sure you want to delete this deal?",
    select_user: "Select User",
    select_product: "Select Product",
    select_status: "Select Status",
    deal_won: "Deal won",
    deal_finished: "Deal finished",
    new_appointment: "New Appointment",
    remove_host: "Remove Host",
    make_host: "Make Host",
    user_dont_have_zoomId: "No zoom Id",
    no_host_has_been_selected:
      "No host has been selected for this zoom meeting. The office account will be the host of the meeting by default",
    connected: "Connected",
    connect: "Connect",
    send_invition_link: "Send Invitation Link",
    show_holidays: "Show Holidays",
    click_to_join_zoom_meet: "Click to join the meeting",
    meeting_link: "Meeting Link",
    appointment: "Appointment",
    edit_appointment: "Edit Appointment",
    select_all: "Select All",
    floating: "Floating",
    is_floating: "Is Floating?",
    viewing_appointment_of: "Appointments of {name}",
    total_inbounds: "Total Inbounds",
    update_contract: "Update contract",
    add_new_contract: "Add new contract",
    contract: "Contract",
    remove_contact: "Remove contact",
    show_floating: "Floating",
    Please_add_an_email_to_this_contact:
      "Please add an email to this contact to create a new deal",
    create_zoom_meeting: "Create zoom meeting",
    meetings: "Meetings",
    import: "Import",
    import_untracked_meets: "Import untracked apppointments",
    close_untracked_meets: "Close untracked apppointments",
    are_you_sure_delete_untracked_zoom_meet: `Are you sure to continue? This will delete the meeting on ZOOM. Any recordings associated with the meetings will be migrated to the folder "Untracked Recordings" on google drive.`,
    add_record: "Add record",
    update_record: "Update record",
    are_you_sure_to_delete_this_record:
      "Are you sure you want to delete this record?",
    total_hours_worked_this_month: "Total hours worked this month",
    referral_title_text:
      "Please provide the contact details of the person you are referring and your own information. Thank you for your referral!",
    contact_details: "Contact Info",
    referrer_details: "Your Info",
    contact_already_known: "Contact Already Known",
    contact_already_known_desc: `Thank you for submitting a referral to Ethica Finance. We appreciate your support. We already know this contact and have been in touch since {date}. Thank you for helping us strengthen our connections!`,
    refer_another_contact: "Refer Another Contact",
    referal_success: `Thank you for submitting a referral to Ethica Finance. We appreciate your trust and support. Our team will review the information and follow up accordingly. Your referral means a lot to us!`,
    thank_you: "Thank you!",
    add_user_file: "Add user file",
    update_user_file: "Update user file",
    file_type: "File type",
    are_you_sure_to_delete_user_file:
      "Are you sure you want to delete this file?",
    no_any_file_to_show: "No any files to show",
    optional: "Optional",
    saving: "Saving",
    contract_title: "Contract title",
    are_you_sure_to_delete_this_file:
      "Are you sure you want to delete this file? This action can not be undone",
    drive_folder: "Google Drive Folder",
    send_delete_appointment_notification:
      "Would you also like to send cancellation notifications to the guests?",
    optional_custom_message: "Optional Custom Message",
    delete_event: "Delete Event",
    send_update_emails_to_guests:
      "Would you like to send update emails to guests?",
    auto_start_recording_meeting: "Auto start recording meeting",
    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    survey_sent: "Survey sent",
    survey_completed: "Survey completed",
    others: "Others",
    new_log: "New log",
    log_type: "Log type",
    show_all_fields: "Show all fields",
    show_deals_with_payable_commissions: "Show deals with payable commissions",
    merge_contacts: "Merge contacts",
    new_unattended: "New - Unattended",
    new_didnt_answer: `New - Didn't Answer`,
    phone_call_was_set: "Phone call was set",
    zoom_meeting_was_set: "Zoom meeting was set",
    office_meeting_was_set: "Office meeting was set",
    lead_source: "Lead source",
    webinar: "Webinar",
    website: "Website",
    facebook: "Facebook",
    direct: "Direct",
    other: "Other",
    show_duplicates: "Show duplicates",
    close_duplicates: "Close duplicates",
    are_you_sure_to_auto_merge:
      "Are you sure you want to auto merge the selected contacts? The merge prioritizes Hebrew names, the most recent status and lead source in hierarchy, and the earliest follow-up date (future or closest past). Address and referral will come from the oldest contact if available. Survey fields will be marked 'Yes' if any contact has it as 'Yes'. Multiple fields will be combined, while single fields are taken from the oldest contact.",
    spouse_phone_numbers: "Spouse phone numbers",
    affected_documents: "Affected documents",
    before: "Before",
    after: "After",
    changed: "Changed",
    spouse_emails: "Spouse Emails",
    status_count: "Status count",
    show_deals_with_remaining_payment: "Show deals with remaining payment",
    date_of_birth: "Date of Birth",
    auto_clocked_out: "Auto Clocked-Out",
    whats_app_templates: "WhatsApp Templates",
    send_for_approve: "Send For Approval",
    are_you_sure_delete_template:
      "Are you sure you want to delete the template",
    add_template: "Add New Template",
    template_name: "Template Name",
    enter_template_name: "Enter Template Name",
    are_you_sure_send_for_approval:
      "Are you sure you want to send for approval?",
    delete: "Delete",
    insert_placeholder: "Insert Placeholder (ctrl + I)",
    place_holder: "Placeholder",
    equals_desc: "Checks if the field value is equal to a specified value.",
    not_equals_desc:
      "Checks if the field value is not equal to a specified value.",
    less_than_desc: "Checks if the field value is less than a specified value.",
    less_than_or_equal_desc:
      "Checks if the field value is less than or equal to a specified value.",
    greater_than_desc:
      "Checks if the field value is greater than a specified value.",
    greater_than_or_equal_desc:
      "Checks if the field value is greater than or equal to a specified value.",
    contains_desc: "Checks if the field value contains a specified substring.",
    does_not_contain_desc:
      "Checks if the field value does not contain a specified substring.",
    begins_with_desc:
      "Checks if the field value begins with a specified substring.",
    does_not_begin_with_desc:
      "Checks if the field value does not begin with a specified substring.",
    ends_with_desc:
      "Checks if the field value ends with a specified substring.",
    does_not_end_with_desc:
      "Checks if the field value does not end with a specified substring.",
    is_null_desc: "Checks if the field value is null.",
    is_not_null_desc: "Checks if the field value is not null.",
    is_empty_desc:
      "Checks if the field value is empty (e.g., an empty string).",
    is_not_empty_desc: "Checks if the field value is not empty.",
    between_desc: "Checks if the field value is between two specified values.",
    not_between_desc:
      "Checks if the field value is not between two specified values.",
    in_desc: "Checks if the field value is within a specified list of values.",
    not_in_desc:
      "Checks if the field value is not within a specified list of values.",
    matches_regex_desc:
      "Checks if the field value matches a specified regular expression.",
    does_not_match_regex_desc:
      "Checks if the field value does not match a specified regular expression.",
    past_event_creation_warning:
      "You are trying to create an appointment in the past, Are you sure to continue?",
    replace: "Replace",
    send_now: "Send now",
    on_signup: "On signup",
    date_time: "Scheduled",
    edit_message: "Edit message",
    update_template: "Update template",
    language: "Language",
    groups: "Groups",
    are_you_sure_delete_zoom_record:
      "Are you sure you want to delete this zoom recording? This action cannot be undone",
    assign: "Assign",
    whatsapp_logs: "WA logs",
    sent_at: "Sent at",
    new_activetrail_message:
      "Are you sure you want to add new activetrail group?",
    pending: "Pending",
    failed: "Failed",
    update_status: "Update Status",
    add_status: "Add Status",
    are_you_sure_you_want_to_delete_status:
      "Are you sure you want to delete this status ({status})?",
    auto_merge: "Auto merge",
    auto_merge_info:
      "Auto-merging automatically combines duplicate contacts that share the same email or phone number.",
    confirm_app_config_auto_merge:
      "Are you sure to change the auto merge setting?",
    whatsapp_campaigns: "Whatsapp campaigns",
    whatsapp_messages: "Whatsapp messages",
  },
  Hebrew: {
    facebook: "פייסבוק",
    direct: "פנייה ישירה",
    website: "אתר",
    other: "אחר",
    webinar: "וובינר",
    new_unattended: "חדש- לא טופל",
    new_didnt_answer: `חדש- לא ענה`,
    phone_call_was_set: "נקבעה שיחה",
    zoom_meeting_was_set: "נקבעה פגישת זום",
    office_meeting_was_set: "נקבעה פגישה במשרד",
    survey_sent: "נשלח שאלון",
    survey_completed: "ענו על השאלון",
    sun: "ראשון",
    mon: "שני",
    tue: "שלישי",
    wed: "רביעי",
    thu: "חמישי",
    fri: "שישי",
    sat: "שבת",
    january: "ינואר",
    february: "פברואר",
    march: "מרץ",
    april: "אפריל",
    may: "מאי",
    june: "יוני",
    july: "יולי",
    august: "אוגוסט",
    september: "ספטמבר",
    october: "אוקטובר",
    november: "נובמבר",
    december: "דצמבר",
    today: "היום",
    month: "חוֹדֶשׁ",
    day: "יְוֹם",
    week: "שָׁבוּעַ",
    more: "יותר",
    new: "ליד חדש קר",
    phone_call_unanswered: "לא ענה",
    expressed_interest: "ליד חדש חם",
    finished_first_phone_call: "סיים טלפון היכרות",
    was_in_meeting: "סיים פגישת היכרות",
    contract_signed: "נחתם הסכם",
    contract_sent: "החוזה נשלח",
    doesnt_meet_our_requirements: `לא עונה על הדרישות שלנו`,
    meeting_scheduled: "נקבעה פגישת היכרות",
    didnt_answer_5_times: `לא ענה 5 פעמים`,
    asked_to_not_be_contacted: "ביקש לא ליצור קשר",
    not_interested_now: "לא מעוניין כרגע",
    client_paid: "לקוח שילם",
    clients: "Clients",
    client_in_process: "לקוח התחיל תהליך",
    client_finished: "לקוח סיים תהליך",
    awaiting_upsale: "מחכה לאפסייל",
    on_hold: "בהקפאה",
    please_select_one: "נא לבחור",
    default_status: "Default Status",
    payments: "תשלומים",
    down_payment: "תשלום ראשון (Downpayment)",
    paying_tax_investing_somewhere: "תשלום מס והשקעה חלופית",
    paying_tax_at_period_end: "תשלום מס בתום התקופה",
    tax: "תשלום מס",
    pay_installment_cost: "תשלום לקבלן",
    pay_indexing_cost: "תשלום הצמדה למדד",
    gradual_payment: "תשלום הדרגתי",
    pay: "תשלום",
    target_irr: "תשואת יעד",
    real_estate_irr: "תשואה שנתית על הון עצמי",
    irr: "תשואה שנתית על הון עצמי",
    irr_no_reconstruction: "תשואה שנתית במקרה בו לא תהיה התחדשות",
    net_yearly_return_on_rent: "תשואה פירותית נטו",
    yearly_return_from_rent: "תשואה פירותית ברוטו",
    return_cashflow: "תשואה פירותית",
    total_roi: "תשואה כוללת על הון עצמי",
    return_appreciation: "תשואה הונית",
    bad: "תרחיש שלילי",
    current_scenario: "תרחיש נוכחי",
    good: "תרחיש חיובי",
    original: "תרחיש בסיס",
    armageddon: "תרחיש ארמגדון",
    armageddon_scenario: "תרחיש ארמגדון",
    margin_ceiling: "תקרת מרג’ין",
    real_estate_holding_period: "תקופת החזקה צפוייה (שנים)",
    grace_period: "תקופת גרייס (בחודשים)",
    configure: "תצוגה",
    role: "תפקיד",
    description: "תיאור",
    title_in_prospero: "כותרת בפרוספרו",
    expected_cashflow: "תזרים צפוי",
    cashflow: "תזרים מזומנים ",
    multi_generational: "תוכנית בין דורית",
    strategic_planning: "תוכנית אסטרטגית",
    frequency: "תדירות",
    end_date_calculated_automatically: "תאריך סיום מחושב אוטומטית?",
    end_date: "תאריך סיום",
    added_on: "תאריך יצירה",
    start_date: "תאריך התחלה",
    share_with_new_user: "שתף עם משתמש חדש",
    show_general_info_to_client: "שתף מידע כללי עם הלקוח?",
    share_strategies: "שתף אסטרטגיות",
    share: "שתף",
    annuity: "שפיצר",
    yearly: "שנתי",
    buying_year: "שנת קנייה",
    end_year: "שנת סיום",
    selling_year: "שנת מכירה",
    start_year: "שנת התחלה",
    configure_armageddon: "שנה הנחות יסוד ארמגדון",
    change: "שנה",
    year: "שנה",
    kept_remaining_cash: "שמרנו יתרת מזומנים <b>{amount}</b>",
    saving_cashflow_changes: "שמירת שינויים בתזרים",
    maintain_margin: "שמירת מרג'ין",
    save: "שמור",
    secondary_name: "שם נוסף",
    realtor_seller_name: "שם המתוווך\\ בעל הדירה",
    name: "שם",
    sent_remaining_cash:
      "שלחנו יתרת מזומנים <b>{amount}</b> לחשבון בנק <b>{accountName}</b>",
    welcome_to_ETICA_financials: "שלום וברוכים הבאים לאתיקה פיננסים!",
    rent: "שכר דירה",
    clone_strategy: "שכפל אסטרטגיה {strategy}",
    clone: "שכפל",
    duplicate: "שכפל",
    rent_pre_contruction: "שכירות לפני פינוי",
    rent_post_contruction: "שכירות לאחר טופס 4",
    total_income_from_rent_minus_monthly_payment:
      "שכירות כוללת בקיזוז החזרי משכנתה כוללים",
    total_rent: "שכירות כוללת",
    return_cashflow_monthly: "שכירות חודשית צפוייה",
    monthly_rent: "שכירות חודשית",
    rent_during_contruction: "שכירות בזמן פינוי (ברירת מחדל: כמו בשגרה)",
    forgot_password: "שכחת סיסמא?",
    marginal_rate_of_income: "שיעור מס שולי",
    capital_appreciation_tax: "שיעור מס רווחי הון",
    one_off_change: "שינויים חד פעמיים",
    interest_rate_changes: "שינויים בריבית",
    one_off_change_by: "שינוי",
    heads_up: "שים לב!",
    update_start_date_note_for_strategies:
      "שים לב, החודש והשנה של כל אסטרטגיה אצל הלקוח יעודכנו",
    area_in_square_meters: 'שטח במ"ר',
    unarchive: "שחזר",
    saving_variable: "שומר משתנים",
    saving_admin: "שומר מנהל",
    saving_taxbrackets: "שומר מדרגת מס",
    saving_customer: "שומר לקוח",
    saving_preset: "שומר הגדות יסוד",
    archiving_strategy: "שומר אסטרטגיה בארכיון",
    future_value_pre_tax: "שווי עתידי לפני מס",
    net_value: "שווי נטו",
    total_value: "שווי כולל",
    value_of_stocks_with_marginal_tax_rate: "שווי המניות החייבות במס שולי",
    value_of_stocks_with_capital_aprreciation_tax_rate:
      "שווי המניות החייבות במס רווחי הון",
    value_of_apartment_today: "שווי דירת תמורה כיום",
    value_after_renovation: "שווי אחרי שיפוץ",
    appreciation: "שבח",
    margin_floor: "רצפת מרג’ין",
    level: "רמת הרשאה",
    buying: "רכישה",
    mortgage_interest: "ריבית משכנתה",
    interest: "ריבית ",
    loan_interest: "ריבית ",
    profit_from_selling_brfore_tax: "רווח ממכירה (לפני מס)",
    profit_from_selling_after_tax: "רווח ממכירה (אחרי מס)",
    total_net_profit: "רווח כולל נטו",
    profit_at_excersising_pre_tax: "רווח במימוש לפני מס",
    profit: "רווח",
    pensioni: "רגיל",
    quaterly: "רבעוני",
    asset_name: "קשר נכס",
    link_a_new_liability: "קשר התחייבות חדשה",
    stock_KSH: "קרן השתלמות",
    video_link: "קישורי וידאו",
    total_loan_decrease: "קיטון בחוב לאורך התקופה",
    asset_category: "קטגוריה",
    apartment_floor_number: "קומת הדירה",
    files_from_drive: "קבצים בתיקייה",
    other_files: "קבצים אחרים",
    set_a_ceiling_value: "קבע תקרה",
    set_a_floor_value: "קבע רצפה",
    set: "קבע",
    get_reset_email: "קבל מייל לשחזור סיסמא",
    fixed: "קבועה",
    view_files: "צפה בקבצים",
    view_profile: "צפה בפרופיל",
    yearly_growth_rate: "צמיחה שנתית (%)",
    balance_sheet_growth_with_time: "צמיחה במאזן לאורך זמן",
    add_new_strategy: "צור אסטרטגיה חדשה",
    viewing_user: "צופה במשתמש",
    open_drive_folder: "פתח תיקיה בדרייב",
    profile_details: "פרטי משתמש",
    difference: "פער",
    active: "פעיל",
    actions: "פעולות",
    edit_mode: "פעולה חדשה",
    action: "פעולה",
    filters: "פילטרים",
    stock_portfolio: "פורטפוליו מניות",
    end_value: "ערך סיום",
    monthly_value: "ערך חודשי",
    initial_value: "ערך התחלתי",
    value: "ערך",
    edit: "ערוך",
    yearly_appreciation: "עליית ערך שנתית צפוייה",
    annual_appreciation: "עליית ערך שנתית",
    expected_value_appreciation_from_change:
      "עליית ערך צפויה בעקבות תוספות ושדרוגים (לא כולל עלות)",
    expected_appreciation: "עליית ערך צפויה",
    value_added_by_renovation: "עליית ערך בעקבות שיפוץ",
    do_we_need_to_pay_material_cost_index:
      "עליית ערך אזורית לא רלוונטית בצורה שונה מהותית מעליית הערך של הדירה הספציפית.",
    renovation_cost: "עלות שיפוץ",
    total_deal_cost: "עלות עסקה כוללת",
    cost_of_retailer_lawyer: 'עלות מתווך, עו"ד, מס רכישה וכל העלויות הנלוות',
    realtor_cost_in_percentage_terms_pre_vat: 'עלות מתווך (באחוזים לפי מע"מ)',
    cost_of_time_to_delivery_after_buying: "עלות זמן המכירה לאחר רכישה",
    cost_of_vacancy_and_annual_repairs: "עלות vacancy ותיקונים שנתיים",
    realtor_cost: "עלויות תיווך",
    purchase_cost: "עלויות נלוות לרכישה",
    additional_buy_cost: "עלויות נלוות לרכישה",
    sales_cost: "עלויות נלוות למכירה",
    additional_sell_cost: "עלויות נלוות למכירה",
    related_costs: "עלויות נלוות (עו\"ד, שמאי וכו')",
    additional_cost: "עלויות נלוות",
    additional_purchase_cost_for: "עלויות נלוות",
    additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment:
      "עלויות להכנת הדירה להשכרה",
    city: "עיר",
    drive_folder_not_created: "עוד לא נוצרה תיקייה בדרייב. לחץ כדי ליצור",
    initial_account_balance: 'עו"ש התחלתי',
    update_reinvestment: "עדכן תקרות ורצפות",
    update_strategy_name: "עדכן שם אסטרטרגיה",
    update_cashflow_change: "עדכן שינוי בתזרים",
    group_update: "עדכן קבוצתית",
    update_variable: "עדכן משתנה",
    update_admin: "עדכן מנהל",
    update_taxbrackets: "עדכן מדרגת מס",
    update_customer: "עדכן לקוח",
    resort_to_default: "עדכן לברירת מחדל",
    update_notes: "עדכן הערות",
    reapply_comp: "עדכן הנחות יסוד",
    update_preset: "עדכן הגדרות יסוד",
    updating_preset: "עדכן ברירות מחדל",
    update: "עדכן",
    updating_armageddon_scenario: "עדכון תרחיש ארמגדון",
    update_strategy_start_time: "עדכון תאריך התחלת אסטרטגיה",
    updating_cashflow_changes: "עדכון שינויים בתזרים ",
    to: "עד",
    past: "עבר",
    symbol: "סמל",
    old_password: "סיסמא ישנה",
    new_password: "סיסמא חדשה",
    password: "סיסמא",
    action_summary: "סיכום פעולות",
    loan_summary: "סיכום הלוואות",
    one_off_event_summary: "סיכום אירועים חד פעמיים",
    end_with_asset: "סיים עם נכס",
    end_with_maturity: "סיים לפי מועד פירעון",
    end: "סיום",
    stock_type: 'סוג תיק ני"ע',
    program_type: "סוג תוכנית",
    type_of_interest: "סוג ריבית",
    interest_type: "סוג ריבית",
    transfer_type: "סוג העברה",
    loan_type: "סוג הלוואה",
    apartment_type: "סוג דירה",
    type: "סוג",
    total_assets: "סה”כ נכסים",
    total_liabilities: "סה”כ התחייבויות",
    total_income: "סה”כ הכנסה",
    total_expenditure: "סה”כ הוצאה",
    total: 'סה"כ',
    rearrange_strategies: "סדר אסטרטגיות מחדש ",
    close: "סגור",
    shown_to_customer: "נראה ללקוח",
    ignored_assets: "נכסים שלא נקנו",
    related_assets: "נכסים קשורים",
    linked_asset: "נכסים קשורים",
    assets: "נכסים",
    asset_liquidated_on: "נכס מונזל בתאריך <b>{date}</b>",
    asset_is_linked_ib_stock: "נכס זה קשור לחשבון מרג'ין. מנסה הנזלה חלקית",
    asset: "נכס",
    can_sell_partially: "ניתן למכור חלקית?",
    percentage_of_payment_delayed_to_delivery:
      "ניתן לחשוב על זה כעל 20% כברירת מחדל בתחילה והיתרה בקבלת טופס 4. ניתן להגדיר לשנה מסויימת לאורך הדרך תשלום מסויים וזה ירד מיתרת התשלום בטופס 4.",
    risk_management: "ניהול סיכונים",
    manage_users: "ניהול משתמשים",
    stocks: 'ני"ע',
    net_after_excersising_and_selling_stocks: "נטו לאחר מימוש ומכירת מניות",
    net: "נטו",
    real_estate: "נדל”ן",
    selected: "נבחר",
    advanced: "מתקדם",
    new_variables: "משתנים חדשים",
    variables: "משתנים",
    user_already_invited:
      "משתמש זה כבר הוזמן פעם אחת. המשך יאפס את פרטי המשתמש ויזמין אותו מחדש",
    mortgage_to_be_taken: "משכנתה שתילקח",
    maximal_mortgage_available: "משכנתה מקסימלית",
    mortgage: "משכנתה",
    is_salary: "משכורת",
    unarchiving_customer: "משחזר לקוח",
    something_went_wrong_try_again: "משהו השתבש, לחץ כאן כדי לנסות שוב",
    margin_call: "מרג'ין קול",
    showing: "מציג",
    normal_state: "מצב רגיל",
    detailed_mode: "מצב פיתוח",
    extreme_case: "מצב משבר פיננסי",
    depressed_state: "מצב משבר פיננסי",
    mode: "מצב",
    updating_strategy_name: "מעדכן שם אסטרטגיה",
    updating_video_link: "מעדכן קישורי וידאו",
    updating_variable: "מעדכן משתנים",
    updating_admin: "מעדכן מנהל",
    updating_taxbrackets: "מעדכן מדרגת מס",
    updating_customer: "מעדכן לקוח",
    updating_notes: "מעדכן הערות",
    archiving_customer: "מעביר לארכיון",
    vat: 'מע"מ',
    total_years: "מספר שנים",
    number_floor_in_building: "מספר קומות בבניין",
    user_id: "מספר משתמש",
    secondary_phone_number: "מספר טלפון נוסף",
    phone_number: "מספר טלפון",
    months_to_sustain_negative:
      "מספר חודשים בהם ניתן לשאת בתזרים שלילי מההון (אחרי משבר)",
    number_of_rooms: "מספר חדרים",
    buying_tax: "מס רכישה",
    buying_tax_for: "מס רכישה",
    tax_on_excersising: "מס על מימוש (מכירת מניות)",
    tax_on_rent: "מס על השכירות",
    tax_calculated_automatically: "מס מחושב אוטומטית?",
    total_tax: "מס כולל",
    tax_amount: "מס כולל",
    tax_and_additional_costs: "מס ועלויות נוספות",
    selling_tax: "מס במכירה",
    stock: "מניה",
    admins: "מנהלים",
    new_admin: "מנהל חדש",
    sorting_by: "ממיין לפי",
    partially_sold_from_asset:
      "מכרנו חלקית <b>{amount}</b> מתוך נכס <b>{assetName}</b> כדי לעמוד בדרישות המרג'ין",
    partial_selling_not_possible:
      "מכירה חלקית לא אפשרית לנכס זה. מנסה מכירה מלאה",
    selling: "מכירה",
    sell: "מכירה",
    sort_by: "מיין לפי",
    fetching_admins: "מייבא מנהלים",
    apartment_info: "מידע על הדירה",
    additional_information: "מידע נוסף",
    general_information: "מידע כללי",
    who_is_typical_renter_for_apartment:
      "מי השוכר הקלאסי לנכס? סטודנטים וזוגות צעירים\\ משפחות ומבוגרים\\ תיירים",
    allowed_calculators: "מחשבונים זמינים",
    calculators: "מחשבונים",
    pinui_binui: "מחשבון פינוי בינוי",
    irr_calculator: "מחשבון מחיר יעד",
    target_price_calculator: "מחשבון מחיר יעד",
    paper_apartment: "מחשבון דירה על הנייר",
    rsu_options: "מחשבון אופציות ו- RSU",
    options_calculator: "מחשבון אופציות",
    rsu_calculator: "מחשבון RSU",
    group_delete_cashflow: "מחק קבוצתית תזרים מזומנים",
    group_delete_only_asset: "מחק קבוצתית נכס בלבד",
    group_delete_liability: "מחק קבוצתית התחייבות",
    group_delete_reinvestment: "מחק קבוצתית השקעה מחדש, רצפות ותקרות",
    group_delete_linked_liabilities_also: "מחק קבוצתית גם התחייבויות",
    delete_only_asset: "מחק נכס בלבד",
    delete_linked_liabilities_also: "מחק גם התחייבויות",
    delete: "מחק",
    wanted_price_per_sqm: "מחיר רצוי למטר רבוע",
    buying_price: "מחיר רכישה",
    options_strike_price: "מחיר סטרייק של האופציות",
    current_stock_price: "מחיר מנייה נוכחי (חברות ציבוריות)",
    selling_price: "מחיר מכירה",
    price_per_square_meter: 'מחיר למ"ר',
    target_price: "מחיר יעד",
    stock_value_at_options_receival_date:
      "מחיר המנייה במועד מתן האופציות (חברות פרטיות- 0)",
    do_we_need_to_pay_material_cost_index_paper_calculator:
      "מחיר הדירה צמוד למדד?",
    asked_price: "מחיר ביקוש",
    inviting_user: "מזמין משתמש",
    cash: "מזומן",
    adding_strategy: "מוסיף אסטרטגיה",
    deleting_file: "מוחק קובץ",
    deleting_variable: "מוחק משתנה",
    deleting_admin: "מוחק מנהל",
    deleting_taxbrackets: "מוחק מדרגת מס",
    deleting_customer: "מוחק לקוח",
    deleting_preset: "מוחק ברירת מחדל",
    deleting_strategy: "מוחק אסטרטגיה",
    new_tax_bracket: "מדרגת מס חדשה",
    selling_tax_brackets_only_apartment: "מדרגות מס שבח [דירה יחידה]",
    selling_tax_brackets: "מדרגות מס שבח",
    buying_tax_brackets_only_apartment: "מדרגות מס רכישה [דירה יחידה]",
    buying_tax_brackets: "מדרגות מס רכישה",
    taxbrackets: "מדרגות מס",
    yearly_materials_cost_index_rise_paper_calculator: "מדד תשומות הבנייה שנתי",
    yearly_materials_cost_index_rise_comp: "מדד תשומות הבנייה",
    additional_costs_for_making_the_apartment_ready_for_rent:
      "מדד התשומות יחושב רק על החלק שלא שולם עדיין ובאחוזים משווי הרכישה כלומר 40% מתוך החוב יוצמד והיתרה לא. כאשר חלק משולם הוא משולם בצורה שווה משני הרכיבים. אפשר להזין פשוט מדד נמוך יותר.",
    stress_test: "מבחן לחץ",
    resetting_comp: "מאפס טבלת הגדרות יסוד",
    balance_sheet: "מאזן",
    from: "מ",
    took_from_asset: "לקחנו <b>{amount}</b> מחשבון בנק <b>{assetName}</b>",
    took_from_linked_bank_account:
      "לקחנו <b>{amount}</b> מחשבון בנק <b>{accountName}</b>",
    customers: "לקוחות",
    clone_customer: "לקוח משוכפל",
    new_customer: "לקוח חדש",
    asset_has_linked_loans:
      "לנכס שאתה מנסה למחוק יש {n} התחייבויות קשורות. אתה רוצה למחוק גם את ההתחייבויות?",
    asset_has_negative_value:
      "לנכס <b>{assetName}</b> יתרה שלילית בתאריך <b>{date}</b>",
    asset_has_no_money: "לנכס <b>{assetName}</b> אין שווי",
    meeting_video_link: "לינק הקלטת פגישה",
    real_estate_guidance: "ליווי נדל”ן",
    press_alt_to_move:
      "לחץ על ALT בווינדוס או על Option בMac כדי להזיז את התרשים",
    logs: "לוגים",
    no_stocks_with_linked_liabilities: 'לא נמצאו ני"ע עם הלוואות כנגדם',
    could_not_use_asset_loan_is_greater:
      "לא ניתן להשתמש בנכס זה. יתרת הלוואה גדולה משווי נכס <b>{liquidationAmount}</b>, LOAN Value: <b>{loanAmount}</b>",
    no_strategies_created: "לא נוצרו אסטרטגיות",
    no_notes_added: "לא נוספו הערות",
    no: "לא ",
    address: "כתובת",
    cushions: "כריות",
    available_level1_funds_before_crash: "כספים נזילים מדרגה 1 (לפני משבר)",
    available_level1_funds_after_crash: "כספים נזילים מדרגה 1 (אחרי משבר)",
    yes: "כן",
    stock_amount: "כמות מניות",
    options_to_cash_after_two_years:
      "כדאיות מימוש אופציות למזומן (כאשר עברו שנתיים מקבלת האופציות)",
    options_to_cash_pre_two_years:
      "כדאיות מימוש אופציות למזומן (כאשר *לא* עברו שנתיים מקבלת האופציות)",
    setup_mode: "כבר קיים",
    already_have_an_account: "כבר יש לך חשבון? לחץ כאן להתחבר",
    remaining_payment: "יתרת תשלום",
    mortgage_left_at_the_end: "יתרת משכנתה בתום התקופה",
    one_final_payment: "יתרה בטופס 4",
    not_enough_assets: "יתרה <b>{amount}</b> אין מספיק נכסים להנזיל",
    some_investment_are_not_applied:
      "יתכן שחלק מההשקעות לא יושמו בגלל היגיון מעגלי. אנא בדוק סגירות",
    assets_decrease: "ירידת ערך נכסים",
    value_lost_in_months: "ירידת ערך ב6 חודשים",
    depreciations: "ירידות ערך",
    maximal_drawdown: "ירידה מקסימלית",
    target: "יעד",
    creating_an_account: "יוצר חשבון עבור {email}",
    upload_files_to_this_apartment: "טען קבצים לדירה זו",
    loading_file: "טוען קבצים",
    fetching_customers: "טוען לקוחות",
    liquidation_table: "טבלת הנזלה",
    opening_balance: "חשבון פתיחה",
    linked_bank_account: "חשבון משויך",
    primary_bank_account: "חשבון בנק ראשי",
    semi_yearly: "חצי- שנתי",
    asset_class_distribution: "חלוקה בסוגי נכסים",
    quick_search: "חיפוש מהיר",
    search: "חיפוש",
    back_to_site: "חזרה לאתר",
    monthly: "חודשי",
    buying_month: "חודש קנייה",
    end_month: "חודש סיום",
    selling_month: "חודש מכירה",
    start_month: "חודש התחלה",
    starting_month: "חודש התחלה",
    month: "חודש",
    new: "חדש",
    estimated_time_for_finishing_contruction:
      "זמן משוער לסיום הבנייה ממועד ההריסה (בשנים)",
    estimated_time_for_contruction: "זמן משוער לסיום בנייה (בשנים)",
    estimated_time_for_contruction_months: "זמן משוער לסיום בנייה (בחודשים)",
    estimated_time_for_demolition: "זמן משוער להריסת מבנה קיים (בשנים)",
    mortgage_time_to_maturity: "זמן לפרעון",
    time_to_maturity: "זמן לפרעון",
    time_to_delivery_after_buying: "זמן למסירה לאחר חתימה (חודשים)",
    holding_period_in_years: "זמן החזקה בשנים",
    this_will_also_delete_related_investments:
      "זה ימחק גם את ההשקעות מחדש\\ רצפות\\ תקרות המקושרות",
    app_videos: "וידאויים",
    video_not_found_please_contact_admin: "וידאו לא נמצא",
    logout: "התנתק",
    start: "התחלה",
    liability_ends_with: "התחייבות מסתיימת עם",
    duplicate_linked_liabilities:
      "התחייבויות מקושרות לנכס הזה, האם תרצה לשכפל אותן?",
    liabilities: "התחייבויות",
    sign_in_to_continue: "התחבר כדי להמשיך",
    login: "התחבר",
    reinvestments: "השקעה מחדש",
    cashflow_impacts: "השפעה על תזרים",
    compare_strategies: "השוואת אסטרטגיות",
    strategies_comparison: "השוואת אסטרטגיות",
    leave_empty_to_view_results: "הזן 2 תווים כדי להתחיל בחיפוש...",
    show_stress_test_to_client: "הראה מבחן לחץ ללקוח",
    show_all_strategies_from_customer: "הראה את כל האסטרטגיות ללקוח",
    show_archived_to_client: "הראה ארכיון ללקוח",
    show: "הראה",
    meeting_video: "הקלטת פגישה",
    still_insufficient_amount_for_buying: "הצמדת מדד לתשלום בסוף תקופת הבנייה",
    show_explainatory_video: "הצג סרטון הסבר",
    show_archived: "הצג ארכיון",
    difference_between_value_and_price: "הפרש בין שווי שמאי ומחיר יעד",
    apply_stress_test: "הפעל מבחן לחץ",
    additional_comments:
      "הערות (דיבור על התחדשות עירונית, בעיות שכנים, רעשי סביבה, זכויות בנייה לא ממומשות וכו')",
    notes: "הערות",
    upload: "העלה",
    upload_files: "העלאת קבצים",
    reinvestment_and_gradual_selling: "העברות, תקרות ורצפות",
    transactions: "העברות",
    archive: "העבר לארכיון",
    hide_zero_rows: "הסתר שורות ללא תזרים מזומנים",
    hide_explainatory_video: "הסתר סרטון הסבר",
    hide_all_strategies_from_customer: "הסתר את כל האסטרטגיות מהלקוח",
    hide: "הסתר",
    negative_amount_is: "הסכום השלילי הוא <b>{amount}</b>",
    remaining_amount_trying_asset:
      "הסכום הנותר הוא <b>{amount}</b> מנסה נכס <b>{assetName}</b>",
    required_amount_for_margin_is:
      "הסכום הנדרש לשמירה על דרישות מרג'ין הוא <b>{amount}</b>",
    asset_liquidated_from_to:
      "הנכס מונזל מתאריך <b>{date1}</b> עד תאריך <b>{date2}</b>",
    asset_linked_asset_of_loan:
      "הנכס הנוכחי שקשור להלוואה צריך להיות מונזל. שווי מונזל <b>{liquidatedValue}</b> יתרת הלוואה <b>{loanValue}</b>",
    comp_table: "הנחות יסוד",
    user_has_not_signed_up_yet: "המשתמש עוד לא נרשם למערכת",
    continue: "המשך",
    convert_from_another_currency: "המר ממטבע אחר",
    convert: "המר",
    customer_shared: "הלקוח הזה שותף עם המשתמשים",
    customer_not_shared: "הלקוח הזה לא שותף עם משתמשים נוספים",
    margin_loan_against: "הלוואת מרג’ין כנגד",
    fixed_income: "הלוואות חברתיות",
    loan_to_asset: "הלוואה לנכס",
    loan_against: "הלוואה כנגד",
    loan: "הלוואה",
    total_income_from_rent_after_vacancy_and_tax:
      "הכנסה כוללת משכירות (לאחר מיסים וvacancy)",
    total_income_from_rent: "הכנסה כוללת משכירות",
    income: "הכנסה",
    enter_otp_here: "הכנס את הסיסמא החד פעמית שנשלחה לך למייל כאן",
    all: "הכל",
    holding_postponing_tax: "הישארות בחברה ודחיית מס",
    register: "הירשם",
    subtract: "החסר",
    transfer_applied_to: "החל על\\ מקור העברה",
    apply: "החל",
    reapply: "החל",
    total_monthly_payments: "החזר משכנתה כולל",
    monthly_mortgage_payment: "החזר משכנתה חודשי",
    invite_user: "הזמן משתמש",
    the_weakest_month_and_year: "הזמן החלש ביותר הוא",
    invite: "הזמן",
    cleared_linked_loan: "הורדנו שווי <b>{clearedLoan}</b> מההלוואה הקשורה",
    cleared_linked_loan_remaining_cash:
      "הורדנו שווי  <b>{clearedLoan}</b> מההלוואה הקשורה. יתרת מזומנים <b>{remainingCash}</b>",
    download: "הורד",
    expenditure: "הוצאה",
    add_cashflow: "הוסף תזרים מזומנים",
    update_cashflow: "הוסף תזרים מזומנים",
    add_new_change: "הוסף שינוי חדש",
    add_new_cashflow_change: "הוסף שינוי בתזרים",
    group_add: "הוסף קבוצתית",
    add_value: "הוסף ערך",
    add_new_asset: "הוסף נכס חדש",
    add_asset: "הוסף נכס",
    add_apartment: "הוסף נכס",
    add_new_variable: "הוסף משתנה חדש",
    add_new_admin: "הוסף מנהל חדש",
    add_new_taxbrackets: "הוסף מדרגת מס חדשה",
    add_new_customer: "הוסף לקוח חדש",
    add_new: "הוסף חדש",
    add_liability: "הוסף התחייבות",
    add_reinvestment: "הוסף השקעה תקרות ורצפות",
    add_new_preset: "הוסף ברירת מחדל חדשה",
    add: "הוסף",
    equity_received: "הון שהתקבל",
    total_equity_for_buying: "הון עצמי לרכישה",
    equity_for_taking_mortgage: "הון עצמי לעיניין לקיחת משכנתה",
    equity_to_assets: "הון עצמי לנכסים",
    total_equity_to_put_in_the_deal: "הון עצמי כולל לעסקה",
    equity: "הון עצמי",
    equity_for: "הון עצמי",
    required_equity_for_excercising: "הון נדרש למימוש (סטרייק)",
    app_configuration: "הגדרות",
    preset: "הגדרה",
    strategies_archived: "האסטרטגיות הבאות נשמרו בארכיון!",
    is_rent: "האם מדובר בשכירות?",
    is_there_an_balcony: "האם יש מרפסת? פרט",
    is_there_an_elevator: "האם יש מעלית? פרט",
    is_there_a_safe_room: 'האם יש ממ"ד? פרט',
    is_there_parking: "האם יש חניה? פרט (בטאבו\\ בשימוש)",
    is_there_garden: "האם יש גינה? פרט",
    is_only_apartment: "האם זו דירה יחידה?",
    is_it_the_only_apartment_buying: "האם זו דירה יחידה במעמד הרכישה?",
    is_it_the_only_apartment_selling: "האם זו דירה יחידה במעמד המכירה?",
    is_apartment_properly_registered: "האם הנכס רשום בטאבו? (אם לא פרט)",
    is_there_construction_without_permit:
      "האם הכל נבנה בהיתר? (אם לא, פרט לגבי חריגות הבנייה)",
    is_apartment_registered_on_seller_name:
      "האם הדירה רשומה על שם המוכר? אם לא אז על שם מי?",
    is_apartment_abroad: 'האם הדירה בחו"ל?',
    are_you_sure_you_want_to_archive_strategy:
      "האם אתה בטוח שברצונך לשמור את האסטרטגיה בארכיון?",
    are_you_sure_you_want_to_unarchive_strategy:
      "האם אתה בטוח שברצונך להוציא את האסטרטגיה מהארכיון?",
    are_you_sure_you_want_to_unselect_strategy:
      "האם אתה בטוח שברצונך לבטל את בחירת האסטרטגיה?",
    are_you_sure_you_want_to_select_strategy:
      "האם אתה בטוח שברצונך לבחור באסטרטגיה?",
    liquidation_score: "דרגת הנזלה",
    rate_from_1_to_10: "דרג מ-1 עד 10 (1- גרוע 10- מצויין)",
    how_easy_to_find_renters: "דירוג עד כמה קל למצוא שוכרים לנכס?",
    how_well_maintained: "דירוג מצב תחזוקת הנכס",
    how_good_is_location: "דירוג מיקום בעיר",
    how_good_are_current_renters: "דירוג השוכרים הקיימים (אם יש)",
    linked_apartment: "דירה מקושרת",
    apartment: "דירה",
    tenant_stops_paying: "דייר מפסיק לשלם",
    charts: "גרפים",
    interest_only: "גרייס",
    cashflow_appreciation: "גידול שנתי",
    loan_provider: "גוף מלווה",
    apartment_size: "גודל הנכס",
    apartment_size_after_reconstruction: "גודל אחרי התחדשות",
    loan_value: "גובה הלוואה",
    new_presets: "ברירות מחדל חדשות",
    presets: "ברירות מחדל",
    highest_earner: "בעל השכר הגבוה יותר",
    balance_with: "בעזרת העבר ל",
    weakest: "בנקודה החלשה ביותר",
    yearly_materials_cost_index_rise:
      "בנוסף ייתכן שתהיה או שלא תהיה הצמדה למדד תשומות הבנייה. צריכים מקום לשנות את ערך המדד.",
    balloon: "בלון",
    home: "בית",
    unarchiving_strategy: "ביטול שמירת האסטרטגיה בארכיון",
    unselecting_strategy: "ביטול בחירה באסטרטגיה",
    disable_reinvestment: "בטל השקעות מחדש",
    unselect: "בטל בחירה",
    are_you_sure_you_want_to_delete_taxbrackets:
      "בטוח שברצונך למחוק מדרגת מס זו? פעולה זו בלתי הפיכה",
    linked_bank_account_has_no_money:
      "בחשבון בנק קשור <b>{accountName}</b> אין כסף",
    select_unselect_all: "בחר הכל/בטל בחירה בהכל ",
    select: "בחר",
    selecting_strategy: "בחירת אסטרטגיה",
    please_provide_a_primary_bank_account: "בבקשה בחר חשבון ראשי לאסטרטגיה",
    are_you_sure_you_want_to_unarchive_customer:
      "אתה בטוח שברצונך לשחזר את {{שם לקוח}}",
    are_you_sure_you_want_to_delete_cashflow:
      "אתה בטוח שברצונך למחוק תזרים מזומנים זה? פעולה זו בלתי הפיכה",
    are_you_sure_you_want_to_delete_asset:
      "אתה בטוח שברצונך למחוק נכס זה? פעולה זו בלתי הפיכה",
    are_you_sure_you_want_to_delete_liability:
      "אתה בטוח שברצונך למחוק התחייבות זו ? פעולה זו בלתי הפיכה",
    are_you_sure_you_want_to_delete_investment:
      "אתה בטוח שברצונך למחוק אפשרות השקעה זו? פעולה זו בלתי הפיכה",
    are_you_sure_you_want_to_delete_one_off_event:
      "אתה בטוח שברצונך למחוק אירוע חד פעמי זה? פעולה זו בלתי הפיכה",
    are_you_sure_you_want_to_archive_customer:
      "אתה בטוח שברצונך להעביר לארכיון את {{שם לקוח}}?",
    are_you_sure_you_want_to_reapply:
      "אתה בטוח שאתה רוצה לעדכן את הנחות היסוד של האסטרטגיה הזו? אנחנו ממליצים ליצור עותק שלה קודם",
    are_you_sure_you_want_to_delete_admin:
      "אתה בטוח שאתה רוצה למחוק את מנהל {admin} אי אפשר לבטל פעולה זו",
    are_you_sure_you_want_to_delete_customer:
      "אתה בטוח שאתה רוצה למחוק את לקוח {userName} אי אפשר לבטל פעולה זו",
    are_you_sure_delete_file:
      "אתה בטוח שאתה רוצה למחוק את הקובץ ({fileName})? אין דרך חזרה",
    are_you_sure_you_want_to_delete_variable:
      "אתה בטוח שאתה רוצה למחוק את המשתנה הזה? אין דרך חזרה",
    are_you_sure_you_want_to_delete_preset:
      "אתה בטוח שאתה רוצה למחוק את ברירת המחדל הזו? אין דרך חזרה",
    are_you_sure_you_want_to_delete_strategy:
      "אתה בטוח שאתה רוצה למחוק את אסטרטגיה {strategy} אי אפשר לבטל פעולה זו",
    are_you_sure_reset_comp_table:
      "אתה בטוח שאתה רוצה לאפס את הטבלה הזו? אין דרך חזרה",
    confirm_password: "אשר סיסמא",
    strategy_archive: "ארכיון אסטרטגיות",
    archived: "ארכיון",
    reset_to_default: "אפס לברירת מחדל",
    strategies: "אסטרטגיות",
    new_strategy: "אסטרטגיה חדשה",
    strategy: "אסטרטגיה",
    anona: "אנונה",
    please_provide_a_primary_bank_account_for_this_strategy:
      "אנא ספק חשבון בנק ראשי לאסטרטגיה זו",
    please_provide_your_email_address: "אנא כתוב את האימייל שלך כדי להמשיך",
    alphabetically: "אלפבתי",
    real_estate_full_service: "איתור נדל”ן",
    reset_zoom: "איפוס גודל תצוגה",
    no_name: "אין שם",
    no_files_to_show: "אין קבצים עדיין!",
    no_asset: "אין נכס",
    margin_not_met:
      "אין מספיק יתרה לעמוד במרג'ין עבור נכס  {stockType} והלוואה <b>{loanName}</b> בתאריך <b>{date}</b>",
    nothing_to_show: "אין מה להראות!",
    dont_have_an_account: "אין לך חשבון? לחץ כאן להירשם",
    no_incomes: "אין הכנסות",
    no_expenditure: "אין הוצאות",
    no_email: "אין אימייל",
    recipient_emails: "אימיילים נמענים",
    secondary_email: "אימייל נוסף",
    email: "Email",
    mortgage_percentage: "אחוזי מימון על הנכס הנרכש",
    percentage: "אחוז",
    problems_detected: "אותרו בעיות",
    appraised_value: "אומדן שווי שמאי",
    crash_percentage: "אובדן שווי במשבר",
    loses_place_of_work: "אובדן מקום עבודה",
    showing_n_items: "{n} פריטים",
    welcome_to_ETICA_crm: "Welcome to Ethica CRM",
    hello: "!Hello",
    show_events_of: ":הצג אירועים של",
    office_meeting: "פגישה במשרד",
    google_meet_meeting: "פגישה בגוגל מיט",
    phone_call: "שיחת טלפון",
    zoom_meeting: "פגישת זום",
    edit_multiple_contacts_field: "עדכון אנשי קשר",
    select_user: "בחר משתמש",
    select_product: "בחר מוצר",
    select_status: "בחר סטטוס",
    deal_won: "עסקה התקבלה",
    deal_finished: "עסקה הסתיימה",
  },
};

export const languageOptions = Object.keys(languageContent);

export const getActiveLanguage = () => {
  return localStorage.getItem("language") || "Hebrew";
};

export const setActiveLanguage = (lang = "English") => {
  return localStorage.setItem("language", lang);
};

export const isRtl = (language) => {
  return language === "Hebrew";
};
