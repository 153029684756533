import React, { useEffect, useState } from "react";
import AppModal from "../common/AppModal";
import { Button, FormControl } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

const AddEditReferrerCodeModal = ({
  show,
  onHide,
  showProgress,
  initialValue = "",
  onSubmit,
}) => {
  const { translate } = useLocalization();
  const [referralCode, setReferralCode] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const code = referralCode?.trim() ? referralCode.trim() : null;
    onSubmit(code);
  };

  useEffect(() => {
    setReferralCode(initialValue);
  }, [show, initialValue]);
  return (
    <AppModal show={show} onHide={onHide} title={"Update referrer code"}>
      <div>
        <h6 className="smallFont">Referrer code</h6>
        <FormControl
          size="sm"
          value={referralCode}
          placeholder="Enter referrer code here"
          onChange={(e) => setReferralCode(e.target.value)}
        />
        <h6 className="tiny text-muted mt-1">
          Leave the field empty if you want to remove the referral link.
        </h6>
      </div>
      {showProgress && <HorizontalProgress text={translate("please_wait")} />}
      <div className="d-flex justify-content-end align-items-center mt-2">
        <Button
          size="sm"
          variant="success text-white"
          onClick={handleFormSubmit}
        >
          {translate("save")}
        </Button>
      </div>
    </AppModal>
  );
};

export default AddEditReferrerCodeModal;
