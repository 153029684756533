import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Button, FormSelect } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import HorizontalProgress from "../../common/HorizontalProgress";

const DeleteStatusModal = ({
  statusDeleteModalMeta,
  statuses,
  setDeleteStatusMeta,
  onHide,
  showProgress,
  onContinueClick,
}) => {
  const { translate, isRTL } = useLocalization();

  const statusesToAssignOptions = useMemo(() => {
    if (statuses.length > 0 && statusDeleteModalMeta?.statusToDelete?._id) {
      return (
        statuses?.filter(
          (status) => status._id !== statusDeleteModalMeta?.statusToDelete?._id
        ) ?? []
      );
    }
    return [];
  }, [statusDeleteModalMeta, statuses]);

  const handleContinue = () => {
    onContinueClick(statusDeleteModalMeta);
  };

  return (
    <AppModal
      size={"md"}
      show={Boolean(statusDeleteModalMeta)}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      <div>
        {" "}
        <div className="text-center">
          <ExclamationTriangle className="text-danger" size={30} />
          <h6 className="mt-3">
            {translate("are_you_sure_you_want_to_delete_status", {
              status: isRTL
                ? statusDeleteModalMeta?.statusToDelete?.hebrew
                : statusDeleteModalMeta?.statusToDelete?.english,
            })}
          </h6>
        </div>
        <div className="d-flex flex-column justify-content-center flex-wrap align-items-center mt-2">
          <h6 className="mt-2 smallFont">Assign another status</h6>
          <FormSelect
            size="sm"
            className="w-50"
            value={statusDeleteModalMeta?.alternativeStatus}
            onChange={(e) => {
              let val = e.target.value;
              setDeleteStatusMeta({
                ...statusDeleteModalMeta,
                alternativeStatus: val,
              });
            }}
          >
            {statusesToAssignOptions?.map(({ value, english, hebrew }) => (
              <option key={value} value={value}>
                {isRTL ? hebrew : english}
              </option>
            ))}
          </FormSelect>{" "}
        </div>
        {showProgress && (
          <HorizontalProgress
            text={translate(snakeCase("Please wait")) || "Please wait"}
          />
        )}
        <div className="text-end mt-4">
          <Button
            size="sm"
            className="ms-2 px-2"
            variant={"dark"}
            onClick={onHide}
            disabled={showProgress}
          >
            {translate("cancel")}
          </Button>
          <Button
            size="sm"
            className="ms-2 px-2 text-white"
            variant={"success"}
            onClick={handleContinue}
            disabled={showProgress}
          >
            {translate("continue")}
          </Button>
        </div>
      </div>
    </AppModal>
  );
};

export default DeleteStatusModal;
