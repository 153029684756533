import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useLocalization from "../../hooks/useLocalization";
import { contactService } from "../../services/contactService";
import AppModal from "../common/AppModal";
import CircularProgressBar from "../common/circular-progress";
import Logs from "./Logs";

const LogModal = ({ show, onHide, contactId }) => {
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [logs, setLogs] = useState([]);
  const { translate } = useLocalization();

  const fetchLogs = async () => {
    setLoadingLogs(true);

    const { response, error } = await contactService.getContactChangeLogs(
      contactId
    );

    setLoadingLogs(false);

    if (error) {
      return toast.error(error);
    }

    setLogs(response);
  };

  useEffect(() => {
    if (show && contactId) {
      fetchLogs();
    }
  }, [show]);

  return (
    <AppModal show={show} onHide={onHide} title={translate("change_logs")}>
     <div className="p-2">
     {loadingLogs ? (
        <div className="text-center">
          <CircularProgressBar size={50} />
        </div>
      ) : (
        <Logs logs={logs} />
      )}
     </div>
      
    </AppModal>
  );
};

export default LogModal;
