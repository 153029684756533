import { useCallback } from "react";

const useNotification = () => {
  // Function to show notification
  const showNotification = useCallback((title, options) => {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const notification = new Notification(title, options);

      // Handle the onClick event if provided
      if (options.onClick) {
        notification.onclick = options.onClick;
      }
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification(title, options);

          // Handle the onClick event if provided
          if (options.onClick) {
            notification.onclick = options.onClick;
          }
        }
      });
    }
  }, []);

  return showNotification;
};

export default useNotification;
