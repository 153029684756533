import React, { useEffect, useState } from "react";
import useLocalization from "../../hooks/useLocalization";
import CustomMultiSelect from "./CustomMultiSelect";

function generateMinuteOptions(interval = 1) {
  return Array.from({ length: Math.ceil(60 / interval) }, (_, index) => {
    let value = (index * interval).toString().padStart(2, "0");
    return { label: value, value };
  });
}

function generateHourOptions(interval = 1) {
  return Array.from({ length: Math.ceil(24 / interval) }, (_, index) => {
    let hourValue = (index * interval + 8) % 24; // Adjust starting hour and wrap back to 00:00 after 24:00
    if (hourValue === 0) hourValue = "00"; // Convert 0 to 00 for midnight
    else hourValue = hourValue.toString().padStart(2, "0");
    return { label: hourValue, value: hourValue };
  });
}

const TimePicker = ({
  value,
  disabled,
  className,
  onChange,
  minuteInterval = 5,
  hourInterval = 1,
}) => {
  const [time, setTime] = useState();
  const { isRTL } = useLocalization();

  const HH_TIME_FORMAT = /^([01]?[0-9]|2[0-3]):[0-5]?[0-9]$/;

  const onTimeChange = (value, type) => {
    let newTime = { ...time, [type]: value };
    setTime(newTime);
    onChange(Object.values(newTime)?.join(":"));
  };

  useEffect(() => {
    if (HH_TIME_FORMAT.test(value)) {
      let [hour, minute] = value.split(":");
      setTime({
        hour,
        minute,
      });
    } else {
      setTime({
        hour: "08", // Set initial hour to 08
        minute: "00", // Set initial minute to 00
      });
    }
  }, [value]);

  return (
    <div
      className={`d-flex align-items-center ${
        isRTL ? "flex-row-reverse" : ""
      } ${className}`}
      style={{ width: "fit-content" }}
    >
      <CustomMultiSelect
        disabled={disabled}
        isMulti={false}
        items={generateHourOptions(hourInterval)}
        selectedItems={time?.hour}
        onChange={(hr) => onTimeChange(hr, "hour")}
      />{" "}
      <div className="mx-1">
        <CustomMultiSelect
          disabled={disabled}
          isMulti={false}
          items={generateMinuteOptions(minuteInterval)}
          selectedItems={time?.minute}
          onChange={(min) => onTimeChange(min, "minute")}
        />
      </div>
    </div>
  );
};

export default TimePicker;
