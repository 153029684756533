import React, { useMemo, useState } from "react";
import { DEFAULT_DATE_TIME_FORMAT } from "../../helpers/constants";
import { toSentenceCase } from "../../helpers/global";
import AppModal from "../common/AppModal";
import DataTable from "../common/data-table/DataTable";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { Badge } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

const logColumns = [
  {
    label: "Phone Number",
    key: "phoneNumber",
  },
  {
    label: "Send Method",
    key: "sendMethod",
    valueSelector: (l) => toSentenceCase(l.sendMethod),
  },
  {
    label: "status",
    key: "Status",
    valueSelector: (l) => toSentenceCase(l.status),
  },
  {
    label: "Sent at",
    key: "sentAt",
    type: "date",
    dateFormat: DEFAULT_DATE_TIME_FORMAT,
  },
];

const LogDetailModal = ({ show, onHide, log }) => {
  const [activeStatus, setActiveStatus] = useState(null);
  const { isRTL } = useLocalization();

  const statusBreakdown = useMemo(() => {
    if (!log?.logs?.length) {
      return [];
    }

    // Count logs by statuses using `reduce`
    const statusCount = log.logs.reduce((acc, item) => {
      const status = item.status;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    // Convert the count object to an array of objects
    return Object.entries(statusCount).map(([status, count]) => ({
      status,
      count,
    }));
  }, [log]);

  return (
    <AppModal
      title={"Whatsapp campaign details"}
      show={show}
      onHide={onHide}
      size={"md"}
      modalBodyClassName="p-2"
    >
      {statusBreakdown.length > 0 && (
        <div className="mb-2">
          {statusBreakdown.map((s) => (
            <Badge
              onClick={(e) =>
                activeStatus === s.status
                  ? setActiveStatus(null)
                  : setActiveStatus(s.status)
              }
              className={`bg-${
                activeStatus === s.status
                  ? "dark text-white"
                  : "white text-dark"
              } hover-dark mx-1 border border-dark`}
              bg="dark"
            >
              {toSentenceCase(s.status)}: {s.count}{" "}
              {activeStatus === s.status && (
                <span className={isRTL ? "me-2" : "ms-2"}>
                  <X />
                </span>
              )}
            </Badge>
          ))}
        </div>
      )}
      <DataTable
        rowKey={"_id"}
        data={
          activeStatus
            ? log?.logs?.filter((l) => l.status === activeStatus)
            : log?.logs
        }
        columns={logColumns}
        allowFilter={false}
        allowSort={false}
      />
    </AppModal>
  );
};

export default LogDetailModal;
