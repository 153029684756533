import React from "react";
import { Dropdown } from "react-bootstrap";
import { languageOptions, setActiveLanguage } from "../../helpers/localization";
import useLocalization from "../../hooks/useLocalization";

const LanguagePicker = () => {
  const { changeLanguage, langCode } = useLocalization();

  const onLanguageChange = (lang) => {
    changeLanguage(lang);
    setActiveLanguage(lang);
  };

  return (
    <Dropdown className="mx-2">
      <Dropdown.Toggle
        size="sm"
        className="py-1 px-2 border border-dark"
        variant="outline-dark"
        style={{ fontSize: 12, border: 0 }}
      >
        {langCode === "Hebrew" ? "עִברִית" : langCode}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {languageOptions.map((l) => (
          <Dropdown.Item
            onClick={() => onLanguageChange(l)}
            key={l}
            className="smallFont"
            active={l === langCode}
          >
            {l === "Hebrew" ? "עִברִית" : l}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguagePicker;
