import React, { useEffect, useMemo, useRef, useState } from "react";
import { getInboundTableColumns } from "../../../helpers/dataSheetConstants";
import DataTable from "../../common/data-table/DataTable";
import InboundDetailsModal from "../../contacts/inbounds/InboundDetailsModal";
import { miscService } from "../../../services/miscService";
import { toast } from "react-toastify";
import useLocalization from "../../../hooks/useLocalization";
import { DEFAULT_PAGE_SIZE } from "../../../helpers/constants";
import { ABORT_ERROR } from "../../../helpers/api";
import { contactService } from "../../../services/contactService";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const InboundTable = ({ dateRange, users = [] }) => {
  const { translate } = useLocalization();
  const abortControllerRef = useRef(null);

  const [inboundSourceDetailsToShow, setInboundSourceDetailsToShow] =
    useState(null);
  const [contacts, setContacts] = useState([]);
  const onDetailsClick = (inbound) => {
    setInboundSourceDetailsToShow(inbound?.sourceDetails);
  };
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);
  const [fetchingFirstPageContacts, setFetchingFirstPageContacts] =
    useState(false);
  const [fetchingMoreContacts, setFetchingMoreContacts] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState();
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const onCampaignClick = async (inbound) => {
    const campaignId = inbound?.sourceDetails?.campaignId;
    const adSetId = inbound?.sourceDetails?.adSetId;
    const adId = inbound?.sourceDetails?.adId;

    if (!campaignId || !adSetId || !adId) {
      return toast.error("No Ad Id found in the source details");
    }

    const { response: ads = [], error } = await miscService.getFacebookAds(
      null,
      {
        campaignId,
        adSetId,
      }
    );

    if (error) {
      return toast.error(error);
    }

    const adPreviewLink = ads.find(
      (a) => a.id === adId
    )?.preview_shareable_link;

    if (!adPreviewLink) {
      return toast.error("Preview link not found");
    }

    window.open(adPreviewLink, "_blank");
  };

  const tableColumns = useMemo(
    () => getInboundTableColumns({ onDetailsClick, onCampaignClick }),
    [onDetailsClick]
  );

  const fetchContacts = async () => {
    setIsFetchingContacts(true);
    if (!toLoadPageInfo) {
      setIsFetchingContacts(false);
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const pageToFetch = toLoadPageInfo?.pageNumber;

    const loadingMoreContacts = pageToFetch > 1;

    if (loadingMoreContacts) {
      setFetchingMoreContacts(true);
    } else {
      setFetchingFirstPageContacts(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;
    let { startDate, endDate } = dateRange;
    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      filter: [
        { key: "lastInboundDate", value: { start: startDate, end: endDate } },
      ],
      salesPersonFilter: users,
    };

    const { response, error } = await contactService.getContacts(
      requestBody,
      signal
    );
    if (error === ABORT_ERROR) return;

    setFetchingMoreContacts(false);
    setFetchingFirstPageContacts(false);

    if (error) {
      setIsFetchingContacts(false);
      toast.error(error);
      return;
    }

    const { pageNumber: pgNumber, totalPages, resultCount, results } = response;

    setLoadedPageInfo({
      totalPages,
      pageNumber: pgNumber,
      resultCount,
    });

    setContacts((prevContacts) => {
      const newArray = loadingMoreContacts
        ? [...prevContacts, ...results]
        : results;

      return newArray;
    });
    setIsFetchingContacts(false);
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageContacts || fetchingMoreContacts)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    setToLoadPageInfo({ ...initialPageInfo });
  }, [dateRange]);

  useEffect(() => {
    fetchContacts({});
  }, [toLoadPageInfo]);

  const inboundsTableData = useMemo(() => {
    let { startDate, endDate } = dateRange;

    let allInbounds = contacts.flatMap((contact) =>
      contact.inbounds
        .filter((inbound) => {
          const inboundDate = new Date(inbound.date);
          return (
            inboundDate >= new Date(startDate) &&
            inboundDate <= new Date(endDate)
          );
        })
        .map((inbound) => ({
          ...inbound,
          contact,
        }))
    );

    // Sort inbounds in descending order by date
    allInbounds.sort((a, b) => new Date(b.date) - new Date(a.date));

    return allInbounds;
  }, [contacts, dateRange]);

  return (
    <div className="custom-card h-100 p-2 mx-1 mt-2">
      {" "}
      <h6 className="xxlarge fw-bold mb-3">{translate("inbounds_no_count")}</h6>
      <DataTable
        maxTableHeight={`400px`}
        rowKey={"_id"}
        columns={tableColumns}
        data={inboundsTableData}
        bottomOffset={300}
        onBottomReached={loadMoreData}
        loadingMoreData={fetchingMoreContacts}
        loadingFirstPageData={fetchingFirstPageContacts}
        allowSort={false}
        allowFilter={false}
      />
      <InboundDetailsModal
        show={Boolean(inboundSourceDetailsToShow)}
        onHide={() => setInboundSourceDetailsToShow(null)}
        inbound={inboundSourceDetailsToShow}
      />
    </div>
  );
};

export default InboundTable;
