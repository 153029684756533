import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import { STATUS_CATEGORIES } from "../../helpers/constants";
// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  english: Yup.string().required("Please provide status in english"),
  hebrew: Yup.string().required("Please provide status in hebrew"),
  category: Yup.string()
    .oneOf(STATUS_CATEGORIES.map((s) => s.category))
    .required("Please select a category"),
});

const defaultValues = {
  english: "",
  hebrew: "",
  category: STATUS_CATEGORIES[0].category,
};

const AddEditStatusForm = ({ initialValues, showProgress, onSubmit }) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Category</FormLabel>
                  <Field
                    name="category"
                    as="select"
                    className="form-control form-control-sm"
                    size="sm"
                  >
                    {STATUS_CATEGORIES.map(({ category }) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">English</FormLabel>
                  <Field
                    name="english"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="english"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Hebrew</FormLabel>
                  <Field
                    name="hebrew"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="hebrew"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(initialValues?._id ? "update" : "add")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditStatusForm;
