import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import { ChatText, Clock, PlayFill, StopFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { makeRESTApiRequests } from "../../helpers/api";
import { DEFAULT_TIME_FORMAT, ENDPOINTS } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import useTimeTracker from "../../hooks/useTimeTracker";
import HorizontalProgress from "../common/HorizontalProgress";
import ClockButton from "../common/Clock";

const ClockInForm = ({ onSubmit, showProgress }) => {
  const { activeTimeEntry, elapsedTime } = useTimeTracker();
  const [note, setNote] = useState("");
  const [extendingTimer, setExtendingTimer] = useState(false); 

  const handleFormSubmit = () => {
    if (!activeTimeEntry) {
      const clockInInfo = {
        name: note,
      };

      onSubmit(clockInInfo);
    } else {
      onSubmit();
    }
  };

  const handleExtendRunningTime = async () => {
    setExtendingTimer(true);
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.RESET_TIME,
      requestBody: {},
    });
    setExtendingTimer(false);

    if (error) {
      return toast.error(error);
    }
    if (response) {
      activeTimeEntry.clockOutExpiry = response?.clockOutExpiry;
      toast.success("Clock-out timer extended successfully.");
    }
  };

  return (
    <Form>
      <Row className="m-0">
        {activeTimeEntry && (
          <Col xs={12} className="mb-2">
            {" "}
            <ClockButton elapsedTime={elapsedTime} />
            <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
              <span className="smallFont line">
                You will be automatically clocked out at{" "}
                <strong>
                  {moment(activeTimeEntry?.clockOutExpiry).format(
                    DEFAULT_TIME_FORMAT
                  )}
                </strong>
                .{" "}
                <span
                  className="text-primary hover-light text-underline"
                  onClick={handleExtendRunningTime}
                >
                  Click Here to Extend.
                </span>
                {extendingTimer && <Spinner animation="border" size="sm" />}
              </span>
            </div>
          </Col>
        )}

        <Col xs={12}>
          {activeTimeEntry ? (
            <FormGroup className="mb-2">
              <Form.Label className="mid mb-1 d-flex align-items-center gap-1">
                <ChatText />
                Notes
              </Form.Label>
              <div
                className="form-control form-control-sm mb-2"
                readOnly
                style={{ whiteSpace: "pre-wrap" }}
              >
                {activeTimeEntry?.name || "----"}
              </div>
            </FormGroup>
          ) : (
            <FormGroup className="mb-2">
              <Form.Label className="mid mb-1 d-flex align-items-center gap-1">
                <ChatText />
                Notes
              </Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Optional notes..."
                value={note}
                className="form-control form-control-sm"
                size="sm"
                onChange={(e) => setNote(e.target.value)}
                disabled={!!activeTimeEntry}
              />
            </FormGroup>
          )}
        </Col>

        <Col xs={12}>
          <FormGroup className="mb-2">
            <FormLabel className="mid mb-1 d-flex align-items-center gap-1">
              <Clock />
              {activeTimeEntry ? "Started On" : "Current Time"}
            </FormLabel>
            <div className="form-control form-control-sm" readOnly>
              {moment(activeTimeEntry?.from || new Date()).format(
                DEFAULT_TIME_FORMAT
              )}
            </div>
          </FormGroup>
        </Col>

        <Col xs={12} className="mt-3">
          <Button
            className="w-100 text-white d-flex justify-content-center"
            variant={activeTimeEntry ? "danger" : "success"}
            onClick={handleFormSubmit}
            size="sm"
            disabled={showProgress}
          >
            {activeTimeEntry ? <StopFill size={22} /> : <PlayFill size={22} />}
            <span className="mx-1">
              {activeTimeEntry ? "Clock Out" : " Clock In"}
            </span>
          </Button>
        </Col>
      </Row>

      {showProgress && (
        <HorizontalProgress className="my-0 mt-3" text="Please wait..." />
      )}
    </Form>
  );
};

export default ClockInForm;
