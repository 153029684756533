import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const automationService = {
  getAutomations: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_AUTOMATIONS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  updateAutomation: async (automation, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.AUTOMATION_WITH_ID(automation._id),
      requestBody: automation,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  createAutomation: async (automation, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_AUTOMATION,
      requestBody: automation,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateAutomation: async (automationID, newAutomation, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.AUTOMATION_WITH_ID(automationID),
      requestBody: newAutomation,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteAutomation: async (automation, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.AUTOMATION_WITH_ID(automation._id),
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
};
