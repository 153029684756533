import React from "react";
import { Image } from "react-bootstrap";

const Logo = ({ small = false, dark, height }) => {
  return (
    <Image
      src={`${process.env.PUBLIC_URL}/logo-new${dark ? "-dark" : ""}.png`}
      height={height ? height : small ? 22 : 60}
    />
  );
};

export default Logo;
