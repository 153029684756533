import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { ABORT_ERROR } from "../../helpers/api";
import {
  ALL_ROLES,
  DEFAULT_PAGE_SIZE,
  role_to_color_map,
} from "../../helpers/constants";
import { getUserTableColumns } from "../../helpers/dataSheetConstants";
import {
  getDateObjectFromInputString,
  getFormattedDate,
  updateItemsInArray,
} from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { userService } from "../../services/userService";
import FloatingButton from "../common/FloatingButton";
import DataTable from "../common/data-table/DataTable";
import SearchBox from "../common/searchbox";
import AddEditUserModal from "./AddEditUserModal";
import DeleteUserModal from "./DeleteUserModal";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const Users = () => {
  const abortControllerRef = useRef(null);

  const { translate } = useLocalization();
  const { searchBoxOptions, setIsSearchBoxDisabled, isSearchBoxDisabled } =
    useSearchAndFilterBox();

  const [fetchingFirstPageUsers, setFetchingFirstPageUsers] = useState(false);
  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);

  //original users array

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [userAddEditModalMeta, setUserAddEditModalMeta] = useState(null);
  const [userDeleteModalMeta, setUserDeleteModalMeta] = useState(null);
  const [activeRoleFilter, setActiveRoleFilter] = useState(null);

  useEffect(() => {
    const resultUsers = users.filter((u) =>
      activeRoleFilter ? u.role === activeRoleFilter : true
    );
    setFilteredUsers(resultUsers);
  }, [users, activeRoleFilter]);

  const onAddNewUserClick = () => {
    setUserAddEditModalMeta({});
  };

  const refreshUser = async (user) => {
    try {
      let updatedUser = {
        _id: user?._id,
      };
      toast.info("Fetching zoom details of this user...");
      const { response, error } = await userService.updateUser(updatedUser);
      if (error) {
        toast.error(error);
      }
      if (response) {
        if (response?.zoomUserId) {
          toast.success("User connected successfully");
        } else {
          toast.info("User not found on Zoom");
        }
        onUserChange(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEditUserClick = (user) => {
    setUserAddEditModalMeta({
      initialValues: {
        ...user,
        birthDate: user.birthDate
          ? getFormattedDate(new Date(user.birthDate))
          : "",
      },
      editMode: true,
      editingUser: user,
    });
  };

  const onUserDeleteClick = async (user) => {
    setUserDeleteModalMeta({ user, anotherUserToAssign: null });
  };

  const sendPasswordResetLink = async (user) => {
    if (!user?.email) return toast.error("This user donot have an email");
    let reqBody = { email: user?.email };
    toast.info("Sending password reset link...");
    try {
      const { response, error } = await userService.forgotPassword(reqBody);
      if (response) {
        toast.success("Password reset link has been sent");
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableColumns = useMemo(
    () =>
      getUserTableColumns(
        translate,
        onUserDeleteClick,
        sendPasswordResetLink,
        refreshUser
      ),
    [onUserDeleteClick, sendPasswordResetLink]
  );

  const fetchUsers = async () => {
    if (!searchBoxOptions || !toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const { query } = searchBoxOptions;

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreEvents = pageToFetch > 1;

    if (loadingMoreEvents) {
      setFetchingMoreUsers(true);
    } else {
      setFetchingFirstPageUsers(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: 100,
      pageNumber: pageToFetch,
      sort: { name: 1 },
    };

    const { response, error } = await userService.getUsers(requestBody, signal);

    if (error === ABORT_ERROR) return;

    setFetchingMoreUsers(false);
    setFetchingFirstPageUsers(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [] } = response;
    setLoadedPageInfo({ totalPages, pageNumber });
    setUsers((prevEvents) =>
      loadingMoreEvents ? [...prevEvents, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageUsers || fetchingMoreUsers) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    if (searchBoxOptions) setToLoadPageInfo({ ...initialPageInfo });
  }, [searchBoxOptions]);

  useEffect(() => {
    fetchUsers();
  }, [toLoadPageInfo]);

  const onUserChange = (user) => {
    setUsers((prevUsers) => {
      const updatedUsers = updateItemsInArray(prevUsers, [user]);
      return updatedUsers;
    });
  };

  const onUserSubmit = async (user) => {
    const { editMode } = userAddEditModalMeta;
    setUserAddEditModalMeta((meta) => ({ ...meta, showProgress: true }));

    let updatedUser = {
      _id: user._id,
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      role: user?.role,
      isEnabled: user?.isEnabled,
      specialRoles: user?.specialRoles,
      color: user?.color,
      secondaryEmails: user?.secondaryEmails,
      driveFolder: user?.driveFolder,
      sip: user?.sip,
      birthDate: user.birthDate
        ? getDateObjectFromInputString(user.birthDate).toISOString()
        : null,
    };
    const { response, error } = editMode
      ? await userService.updateUser(updatedUser)
      : await userService.createUser(user);
    setUserAddEditModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setUserAddEditModalMeta(null);
    onUserChange(response);
    toast.success(
      editMode ? "Successfully updated user" : "Successfully added user"
    );
  };

  const deleteUser = async (deleteInfo) => {
    const { user, anotherUserToAssign } = deleteInfo;
    try {
      setUserDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));
      const { error } = await userService.deleteUser({
        email: user?.email,
        anotherUserToAssign,
      });
      setUserDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));
      if (error) {
        return toast.error(error);
      }

      setUsers((users) => users.filter((p) => p._id !== user._id));
      toast.success("Successfully deleted user");
    } catch (error) {
      console.log(error);
    } finally {
      setUserDeleteModalMeta(null);
    }
  };

  return (
    <>
      <Container fluid className={"px-2 py-3"}>
        <div className="border rounded p-2 bg-white">
          <h6 className="mb-3 xlarge fw-bold">
            {translate("users")} ({users.length}):
          </h6>
          <Row className="">
            <Col md={4} className="mb-2">
              {" "}
              <SearchBox
                containerClass="bg-white rounded"
                disabled={isSearchBoxDisabled}
              />
            </Col>
            <Col
              md={8}
              className="d-flex align-items-center flex-wrap gap-1 mb-2"
            >
              {" "}
              <Button
                variant={activeRoleFilter === null ? "dark" : "outline-dark"}
                size="sm"
                onClick={() => setActiveRoleFilter(null)}
              >
                <h6 className="mb-0 smallFont">All Roles</h6>
              </Button>
              {ALL_ROLES.map((r) => (
                <Button
                  variant={activeRoleFilter === r ? "dark" : `outline-dark`}
                  size="sm"
                  key={r}
                  onClick={() => setActiveRoleFilter(r)}
                >
                  <h6 className="mb-0 smallFont"> {r}</h6>
                </Button>
              ))}
            </Col>
          </Row>

          <DataTable
            maxTableHeight={`calc(100vh - 195px)`}
            rowKey={"_id"}
            columns={tableColumns}
            data={filteredUsers}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreUsers}
            onRowClick={onEditUserClick}
            loadingFirstPageData={fetchingFirstPageUsers}
            allowFilter={false}
            allowSort={false}
            striped
          />
        </div>
      </Container>
      <FloatingButton
        className="text-white"
        Icon={PlusCircleFill}
        variant="success"
        text={translate("add_new_user")}
        onClick={onAddNewUserClick}
      />
      <AddEditUserModal
        show={Boolean(userAddEditModalMeta)}
        initialValues={userAddEditModalMeta?.initialValues}
        editMode={userAddEditModalMeta?.editMode}
        onHide={() => setUserAddEditModalMeta(null)}
        showProgress={userAddEditModalMeta?.showProgress}
        onSubmit={onUserSubmit}
      />
      <DeleteUserModal
        users={users}
        userDeleteModalMeta={userDeleteModalMeta}
        setUserDeleteModalMeta={setUserDeleteModalMeta}
        onHide={() => setUserDeleteModalMeta(false)}
        onContinueClick={deleteUser}
        showProgress={userDeleteModalMeta?.showProgress}
      />
    </>
  );
};

export default Users;
