import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const filterServices = {
  getFilters: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_FILTER,
      requestBody: {},
      method: "POST",
    });

    return { response, error };
  },
  createFilter: async (filter, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_FILTER,
      requestBody: filter,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateFilter: async (filter, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_FILTER(filter?._id),
      requestBody: filter,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteFilter: async (filter, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_FILTER(filter?._id),
      requestBody: filter,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
  updateMultiple: async (filters, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_MANY,
      requestBody: filters,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
};
