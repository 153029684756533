import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import EditAppointmentTypeForm from "../../forms/EditAppointmentTypeForm";

const EditAppointmentModal = ({
  show,
  initialValues,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={translate(`update_appointment_default_type`)}
      showCloseButton={!showProgress}
    >
      <EditAppointmentTypeForm
        initialValues={initialValues}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default EditAppointmentModal;
