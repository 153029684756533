import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const contactService = {
  getDuplicateContacts: async (requestBody = {}, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTACT_FIND_DUPLICATE,
      requestBody,
      signal,
    });

    return { response, error };
  },
  getContacts: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_CONTACTS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  autoMergeContacts: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTACTS_AUTO_MERGE,
      method: "POST",
      requestBody,
    });

    return { response, error };
  },
  createContact: async (contact, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_CONTACTS,
      requestBody: contact,
      signal,
    });

    return { response, error };
  },
  updateContact: async (contact, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTACT_WITH_ID(contact._id),
      requestBody: contact,
      method: "PUT",
      signal,
    });

    return { response, error };
  },

  deleteSelectedContact: async (selectedContacts, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_SELECTED_CONTACTS,
      requestBody: selectedContacts,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
  updateContactComment: async (contact, commentRequestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTACT_COMMENT_WITH_ID(contact._id),
      requestBody: commentRequestBody,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  getContactChangeLogs: async (contactId, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTACT_LOGS(contactId),
      method: "GET",
      signal,
    });

    return { response, error };
  },
  updateManyContacts: async (contacts, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_MANY_CONTACTS,
      requestBody: contacts,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  mergeContacts: async (mergeInfo, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.MERGE_CONTACT,
      requestBody: mergeInfo,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  referContact: async (referInfo, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.REFER_CONTACT,
      requestBody: referInfo,
      method: "POST",
      signal,
    });

    return { response, error };
  },
};
