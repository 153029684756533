import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide name"),
});

const defaultValues = {
  name: "",
};

const AddEditTagForm = ({ initialValues, showProgress, onSubmit }) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate("update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditTagForm;
