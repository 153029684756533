import { snakeCase } from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import HorizontalProgress from "../common/HorizontalProgress";

const DeleteReferrerModal = ({
  referrerDeleteModalMeta,
  onHide,
  showProgress,
  onContinueClick,
}) => {
  const { translate } = useLocalization();

  const handleContinue = () => {
    onContinueClick(referrerDeleteModalMeta);
  };

  return (
    <AppModal
      size={"md"}
      show={Boolean(referrerDeleteModalMeta)}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      <div>
        {" "}
        <div className="text-center">
          <ExclamationTriangle className="text-danger" size={30} />
          <h6 className="mt-3">
            {translate("are_you_sure_you_want_to_delete_referrer")}
          </h6>
        </div>
        {showProgress && (
          <HorizontalProgress
            text={translate(snakeCase("Please wait")) || "Please wait"}
          />
        )}
        <div className="text-end mt-4">
          <Button
            size="sm"
            className="ms-2 px-2"
            variant={"dark"}
            onClick={onHide}
            disabled={showProgress}
          >
            {translate("cancel")}
          </Button>
          <Button
            size="sm"
            className="ms-2 px-2 text-white"
            variant={"danger"}
            onClick={handleContinue}
            disabled={showProgress}
          >
            {translate("continue")}
          </Button>
        </div>
      </div>
    </AppModal>
  );
};

export default DeleteReferrerModal;
