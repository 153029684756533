import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userService } from "../services/userService";

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const requestBody = {};

    const { response, error } = await userService.getUsers(requestBody);

    if (error) {
      return toast.error(error);
    }
    const { results = [] } = response;

    setUsers(results);
  };

  const mutipalUserUpdate = async (requestBody) => {
    const { response, error } = await userService.updateMultipleUser(
      requestBody
    );
    setUsers(response);
    if (error) {
      return toast.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const contextObj = {
    users,
    mutipalUserUpdate,
  };

  return (
    <UsersContext.Provider value={contextObj}>{children}</UsersContext.Provider>
  );
};
