import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useMemo } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { isAdminOrManager } from "../../helpers/session";
import CustomMultiSelect from "../common/CustomMultiSelect";
import {
  TIME_LOG_TYPE_WORK_LOG,
  TIME_LOGS_TYPES_OPTIONS,
} from "../../helpers/constants";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string(),
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
  type: Yup.string().required("Type is required"),
  date: Yup.string().when("type", {
    is: (value) => value === TIME_LOG_TYPE_WORK_LOG,
    then: () => Yup.string().required("Date is required"),
    otherwise: () => Yup.string().nullable(),
  }),
});

const defaultValues = {
  name: "",
  date: "",
  startTime: "",
  endTime: "",
  type: "",
};

const getInitialTimes = (initialValues, type) => {
  const initialDate = initialValues?.from
    ? moment(initialValues?.from).format("YYYY-MM-DD")
    : "";
  const initialStartTime = initialValues?.from
    ? moment(initialValues?.from).format(
        type === TIME_LOG_TYPE_WORK_LOG ? "HH:mm" : "YYYY-MM-DD"
      )
    : "";
  const initialEndTime = initialValues?.to
    ? moment(initialValues?.to).format(
        type === TIME_LOG_TYPE_WORK_LOG ? "HH:mm" : "YYYY-MM-DD"
      )
    : "";
  return { initialDate, initialStartTime, initialEndTime };
};

const AddEditUserRecordForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
  onDeleteClick,
}) => {
  const { translate } = useLocalization();
  const { user } = useAuth();
  const combinedInitialValues = useMemo(() => {
    let { type } = initialValues;
    let { initialDate, initialStartTime, initialEndTime } = getInitialTimes(
      initialValues,
      type
    );
    return {
      ...initialValues,
      date: initialDate,
      startTime: initialStartTime,
      endTime: initialEndTime,
    };
  }, [initialValues]);

  return (
    <Formik
      initialValues={combinedInitialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const from =
          values?.type === TIME_LOG_TYPE_WORK_LOG
            ? moment(`${values.date}T${values.startTime}`).toISOString()
            : moment(values.startTime).startOf("day").toISOString();

        const to =
          values?.type === TIME_LOG_TYPE_WORK_LOG
            ? moment(`${values.date}T${values.endTime}`).toISOString()
            : moment(values.endTime).endOf("day").toISOString();

        const {
          startTime,
          start,
          end,
          endTime,
          date,
          updatedAt,
          ...remaining
        } = values;

        onSubmit({ ...remaining, from, to });
      }}
    >
      {({ setFieldValue, values, errors, submitForm }) => {
        return (
          <Form className="p-2">
            <Row className="m-0">
              <Col className="px-1 py-0" xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("notes")} ({translate("optional")})
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col className="px-1 py-0" xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("log_type")}
                  </FormLabel>
                  <CustomMultiSelect
                    selectedItems={values?.type || ""}
                    items={TIME_LOGS_TYPES_OPTIONS}
                    isMulti={false}
                    closeMenuOnSelect
                    onChange={(type) => {
                      let { initialDate, initialStartTime, initialEndTime } =
                        getInitialTimes(initialValues, type);

                      setFieldValue("date", initialDate);
                      setFieldValue("startTime", initialStartTime);
                      setFieldValue("endTime", initialEndTime);

                      setFieldValue("type", type);
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              {values?.type === TIME_LOG_TYPE_WORK_LOG && (
                <Col className="px-1 py-0" xs={12} md={6}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate("date")}
                    </FormLabel>
                    <FormControl
                      size="sm"
                      type="date"
                      name="date"
                      value={values.date}
                      onChange={(e) => {
                        setFieldValue("date", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              )}
              <Col
                className="px-1 py-0"
                xs={12}
                md={values?.type === TIME_LOG_TYPE_WORK_LOG ? 3 : 6}
              >
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("start")}
                  </FormLabel>
                  <FormControl
                    size="sm"
                    type={
                      values?.type !== TIME_LOG_TYPE_WORK_LOG ? "date" : "time"
                    }
                    name="startTime"
                    value={values.startTime}
                    onChange={(e) => {
                      setFieldValue("startTime", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="startTime"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col
                className="px-1 py-0"
                xs={12}
                md={values?.type === TIME_LOG_TYPE_WORK_LOG ? 3 : 6}
              >
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">{translate("end")}</FormLabel>
                  <FormControl
                    size="sm"
                    type={
                      values?.type !== TIME_LOG_TYPE_WORK_LOG ? "date" : "time"
                    }
                    name="endTime"
                    value={values.endTime}
                    onChange={(e) => {
                      setFieldValue("endTime", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="endTime"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex flex flex-column" style={{ height: 45 }}>
              <div className="flex-grow-1">
                {" "}
                {showProgress && (
                  <HorizontalProgress
                    className="my-0"
                    text={`${translate("please_wait")}`}
                  />
                )}
              </div>{" "}
              <div className="d-flex justify-content-end gap-2">
                {editMode && isAdminOrManager(user?.role) && (
                  <Button
                    className=""
                    variant="danger text-white"
                    onClick={() => onDeleteClick(values)}
                    size="sm"
                    disabled={showProgress}
                  >
                    <h6 className="mb-0 smallFont"> {translate("delete")}</h6>
                  </Button>
                )}

                <Button
                  className=""
                  variant="success text-white"
                  onClick={submitForm}
                  size="sm"
                  disabled={showProgress}
                >
                  <h6 className="mb-0 smallFont">
                    {" "}
                    {translate(!editMode ? "save" : "update")}
                  </h6>
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditUserRecordForm;
