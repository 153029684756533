import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const appChoiceService = {
  getAppchoices: async (requestBody) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_APP_CHOICES,
      requestBody,
      method: "POST",
    });

    return { response, error };
  },
  createAppchoices: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_APP_CHOICE,
      requestBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateAppchoice: async (appChoice, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.APP_CHOICE_WITH_ID(appChoice?._id),
      requestBody: appChoice,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteAppchoice: async (appChoice, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.APP_CHOICE_WITH_ID(appChoice?._id),
      requestBody: appChoice,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
};
