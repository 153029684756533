import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import AddEditStatusForm from "../../forms/AddEditStatusForm";

const AddEditStatusModal = ({
  show,
  initialValues,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={
        initialValues?._id
          ? translate(`update_status`)
          : translate(`add_status`)
      }
      showCloseButton={!showProgress}
    >
      <AddEditStatusForm
        initialValues={initialValues}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default AddEditStatusModal;
