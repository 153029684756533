import React, { createContext, useContext, useState } from "react";

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [addContactMeta, setAddContactMeta] = useState(null);

  const contextObj = { addContactMeta, setAddContactMeta };

  return (
    <ContactContext.Provider value={contextObj}>
      {children}
    </ContactContext.Provider>
  );
};

export const useContact = () => {
  const { addContactMeta, setAddContactMeta } = useContext(ContactContext);
  return {
    addContactMeta,
    setAddContactMeta,
  };
};
