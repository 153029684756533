import { useMemo } from "react";
import useLocalization from "../../hooks/useLocalization";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../helpers/constants";
import { Clock, PlayCircle, PlayCircleFill } from "react-bootstrap-icons";
import { getAppointmentVideo, isToday } from "../../helpers/global";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const CustomEvent = ({
  event,
  activeEvent,
  showFullDate = false,
  showLinkToVideo,
  markPastEvents = false,
  pastEventOpacity = "opacity-25",
  compactMode = false,
}) => {
  const { title, participants = [], appointmentType } = event;
  const { isRTL } = useLocalization();

  const isActiveEvent = useMemo(
    () => event?._id === activeEvent?._id,
    [activeEvent, event]
  );

  const videoUrl = useMemo(() => getAppointmentVideo(event), [event]);

  const isPastEvent = useMemo(() => {
    if (!markPastEvents) return false;

    const isTodayAllDayEvent =
      event?.allDay && isToday(new Date(event?.endDateTime));

    if (isTodayAllDayEvent) return false;

    return new Date().getTime() >= new Date(event?.endDateTime);
  }, [event, markPastEvents]);

  const time = useMemo(
    () =>
      moment(event.start || event?.startDateTime).format(
        showFullDate ? DEFAULT_DATE_TIME_FORMAT : "HH:mm"
      ),
    [event, showFullDate]
  );

  return (
    <div className="w-100">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip style={{ zIndex: 900000 }} id="tooltip">
            <h6 className="text-start fw-bold smallFont">{title}</h6>{" "}
            <h6 className="d-flex gap-2 align-items-center smallFont">
              <Clock />
              {time}
            </h6>
          </Tooltip>
        }
      >
        <div
          className={`${isPastEvent ? pastEventOpacity : ""} ${
            isActiveEvent
              ? "bg-dark-green  border-success"
              : "bg-dark  border-dark"
          } w-100 text-white d-flex ${
            isRTL && "flex-row-reverse"
          }  border justify-content-between align-items-center`}
        >
          <h6
            className={`truncate mb-0 ${
              compactMode ? "tiny" : "smallFont px-1"
            } `}
          >
            {title}{" "}
          </h6>
          <div className="d-flex align-items-center gap-2">
            {videoUrl && showLinkToVideo && (
              <PlayCircleFill
                size={12}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(videoUrl, "_blank");
                }}
              />
            )}
            <h6
              className={`mb-0 d-flex align-items-center tiny border-dark bg-light text-dark fw-bold px-1 ${
                compactMode ? "tiny" : "smallFont"
              } `}
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              {time}
            </h6>
          </div>
        </div>
      </OverlayTrigger>

      <div className="d-flex w-100" style={{ height: "5px" }}>
        {appointmentType === "holiday" ? (
          <div
            className="flex-grow-1 text-dark h-100"
            style={{ background: "gray" }}
          />
        ) : (
          participants?.map((p, index) => {
            return (
              <div
                key={index}
                className="flex-grow-1 text-dark h-100"
                style={{ background: p?.color }}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
