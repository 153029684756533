import { snakeCase } from "lodash";
import React from "react";
import useLocalization from "../../hooks/useLocalization";

const TextDropdownToggle = ({
  variant,
  options = [],
  value = "",
  onOptionClick,
  disabled,
  renderExtraInfo,
  hint = "please_select_one",
  noHint = false,
  required = true,
  className = "",
}) => {
  const { translate } = useLocalization();

  return (
    <div className={className}>
      <select
        className={`form-control form-control-sm form-select truncate py-0`}
        disabled={disabled}
        style={{ fontSize: 12, borderRadius: 5 }}
        onChange={(e) => onOptionClick(e.target.value)}
        value={value}
      >
        {!noHint && (
          <option value="" disabled={required}>
            {translate(snakeCase(hint) ?? "") || hint}
          </option>
        )}

        {options.map((option) => {
          if (typeof option === "string") {
            return (
              <option value={option} key={option}>
                <span className="smallFont mb-0">
                  {" "}
                  {translate(snakeCase(option ?? "") ?? "") || option}
                </span>
                {renderExtraInfo && renderExtraInfo(option)}
              </option>
            );
          } else if (typeof option === "object" && option.options) {
            return (
              <optgroup
                label={
                  translate(snakeCase(option?.label ?? "") ?? "") ||
                  option?.label
                }
                key={option.label}
              >
                {!option.options?.length && (
                  <option disabled>Nothing to show</option>
                )}
                {option.options.map((o) => {
                  if (typeof o === "string") {
                    return (
                      <option value={o} key={o} className={option?.color ? `bg-${option?.color} text-white` : ''}>
                        <span className="smallFont mb-0">
                          {translate(snakeCase(o ?? "") ?? "") || o}{" "}
                        </span>
                        {renderExtraInfo && renderExtraInfo(o)}
                      </option>
                    );
                  } else {
                    return (
                      <option value={o?.value} key={o?.value}>
                        <span className="smallFont mb-0">
                          {translate(snakeCase(o?.label ?? "") ?? "") ||
                            o?.label}{" "}
                        </span>
                        {renderExtraInfo && renderExtraInfo(o)}
                      </option>
                    );
                  }
                })}
              </optgroup>
            );
          } else if (
            typeof option === "object" &&
            option.label &&
            option.value
          ) {
            return (
              <option value={option.value} key={option.value}>
                {/* {translate(snakeCase(option.label ?? "") ?? "")}{" "} */}
                <span className="smallFont mb-0">
                  {" "}
                  {translate(snakeCase(option.label ?? "") ?? "") ||
                    option.label}
                </span>

                {renderExtraInfo && renderExtraInfo(option)}
              </option>
            );
          }
          return null;
        })}
      </select>
    </div>
  );
};

export default TextDropdownToggle;
