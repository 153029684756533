import { useContext } from "react";
import { UsersContext } from "../context/UsersContext";

const useUsers = () => {
  const { users, mutipalUserUpdate } = useContext(UsersContext);

  return {
    users,
    mutipalUserUpdate,
  };
};

export default useUsers;
