import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import CurrencyInput from "../common/CurrencyInput";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide the product description"),
  price: Yup.number().required("Please provide the product price"),
});

const defaultValues = {
  description: "",
  price: 0,
};
const AddEditProductForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className="">
            <Row className="m-0">
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("price")}
                  </FormLabel>

                  <CurrencyInput
                    value={values.price}
                    onChange={(price) => setFieldValue("price", price)}
                  />
                  <ErrorMessage
                    name={"price"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={8}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("description")}
                  </FormLabel>

                  <Field
                    name={"description"}
                    className="form-control form-control-sm"
                    rows={2}
                  />
                  <ErrorMessage
                    name={"description"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end border-top">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditProductForm;
