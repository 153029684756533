import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { Copy } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import useLocalization from "../../hooks/useLocalization";

const CopyLinkField = ({ link, minWidth = 300 }) => {
  const { isRTL } = useLocalization();
  const copyToClipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(link);
    toast.success("Link copied succesfully");
  };

  return (
    <InputGroup
      className={`${isRTL ? "d-flex flex-row-reverse" : ""}`}
      style={{ minWidth }}
    >
      <FormControl
        className="border border-secondary"
        value={link}
        aria-label="Link"
        readOnly
        size="sm"
        style={{ fontSize: 12 }}
        onClick={(e) => e.stopPropagation()}
      />
      <Button
        size="sm"
        variant="outline-secondary"
        style={{ zIndex: 0 }}
        onClick={copyToClipboard}
      >
        <Copy />
      </Button>
    </InputGroup>
  );
};

export default CopyLinkField;
