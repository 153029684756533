import { ContactsOutlined } from "@ant-design/icons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { CardText, PersonCircle } from "react-bootstrap-icons";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Name is required"),
  contactPhone: Yup.string().required("Phone number is required"),
  contactEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  contactComment: Yup.string(),
  referrerName: Yup.string().required("Name is required"),
  referrerPhone: Yup.string().required("Phone number is required"),
  referrerEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
});

const ReferContactForm = ({
  initialValues = {
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    contactComment: "",
    referrerName: "",
    referrerPhone: "",
    referrerEmail: "",
  },
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form noValidate className="px-1 py-3">
          <h6 className="d-flex align-items-center mb-2 fw-bold large">
            <ContactsOutlined className="text-primary" />
            <span className="mx-2">{translate("contact_details")}:</span>
          </h6>
          {/* Contact Details Section */}
          <Row className="px-2">
            <Col xs={12} md={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("name")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="contactName"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="contactName"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("email")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="contactEmail"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="contactEmail"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("phone_number")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="contactPhone"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="contactPhone"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>
          </Row>

          {/* Referer's Details Section */}
          <h6 className="d-flex align-items-center mt-3 mb-2 fw-bold large">
            <PersonCircle className="text-primary" />
            <span className="mx-2">{translate("referrer_details")}:</span>
          </h6>
          <Row className="px-2">
            <Col xs={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("name")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="referrerName"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="referrerName"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("email")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="referrerEmail"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="referrerEmail"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup className="mb-1">
                <FormLabel className="mid mb-1">
                  {translate("phone_number")}
                  <span className="text-danger">* </span>
                </FormLabel>
                <Field
                  name="referrerPhone"
                  type="text"
                  className="form-control form-control-sm"
                />
                <ErrorMessage
                  name="referrerPhone"
                  component="div"
                  className="text-danger smallFont"
                />
              </FormGroup>
            </Col>
          </Row>
          {/* Referer's Details Section */}
          <h6 className="d-flex align-items-center mt-3 mb-2 fw-bold large">
            <CardText className="text-primary" />
            <span className="mx-2">{translate("comment")}:</span>
          </h6>
          <div className="mx-2">
            <FormControl
              as={"textarea"}
              value={values?.contactComment}
              onChange={(e) => setFieldValue("contactComment", e.target.value)}
              cols={4}
            />
          </div>
          {showProgress && (
            <HorizontalProgress text={`${translate("please_wait")}`} />
          )}
          <div className="text-end mt-2">
            <Button
              className="mt-2"
              variant="primary"
              onClick={submitForm}
              size="sm"
              disabled={showProgress}
            >
              {translate("submit")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReferContactForm;
