import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import {
  GraphUpArrow,
  PersonPlusFill,
  PersonVideo3,
  TelephoneFill,
} from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import PieChart from "../../common/graph/PieChart";
import StatCard from "../StatCard";
import useContactStatus from "../../../hooks/useContactStatus";

function generatePieChartData(statuses) {
  // Helper function to generate a random RGB color
  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.2)`; // Using 0.2 opacity for background
  }

  // Helper function to increase opacity for borders
  function convertToBorderColor(rgba) {
    return rgba.replace("0.2", "1");
  }

  // Generate random data and colors for each status
  const data = statuses.map(() => Math.floor(Math.random() * 100) + 1);
  const backgroundColors = statuses.map(getRandomColor);
  const borderColors = backgroundColors.map(convertToBorderColor);

  return {
    labels: statuses, // Use the statuses as labels
    datasets: [
      {
        label: "Total Clients by Status",
        data: data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
}

const OperationStatBoard = ({ fetching }) => {
  const { translate } = useLocalization();

  const { ALL_STATUSES } = useContactStatus();

  const generalStats = useMemo(() => {
    return [
      {
        name: "number of new clients per product",
        data: 642,
        variant: "primary",
        icon: PersonPlusFill,
      },
      {
        name: "average time to finish client per product",
        data: 732,
        variant: "secondary",
        icon: TelephoneFill,
      },
      {
        name: "average client satisfaction per user",
        data: 322,
        variant: "info",
        icon: PersonVideo3,
      },
      {
        name: "finish rate and cancellations rate",
        data: 342,
        variant: "dark",
        icon: GraphUpArrow,
      },
    ];
  }, []);

  const data = useMemo(
    () => generatePieChartData(ALL_STATUSES),
    [ALL_STATUSES]
  );
  console.log({ data });

  return (
    <Row className="m-0">
      {generalStats?.map((stat, index) => (
        <Col className="p-1" key={index} xs={6} md={3}>
          <StatCard stat={stat} fetching={fetching} />
        </Col>
      ))}

      <Col xs={12} className="h-100 mt-1">
        <PieChart
          title={translate("no_of_client_in_each_step")}
          data={data}
          height={"400px"}
          width={"100px"}
        />
      </Col>
    </Row>
  );
};

export default OperationStatBoard;
