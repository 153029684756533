import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../../helpers/api";
import {
  getCurrentWeekStartAndEnd,
  getMonthStartAndEnd,
  getWeekStartAndEnd,
  updateItemsInArray,
} from "../../../../helpers/global";
import { isAdminOrManager } from "../../../../helpers/session";
import useAuth from "../../../../hooks/useAuth";
import useDebouncedEffect from "../../../../hooks/useDebouncedEffect";
import useLocalization from "../../../../hooks/useLocalization";
import { userRecordServices } from "../../../../services/userRecordServices";
import AlertModal from "../../../common/AlertModal";
import AddEditRecordModal from "./AddEditRecordModal";
import TimingWeekCalendar from "./TimingWeekCalendar";
import {
  TIME_LOG_TYPE_VACATION,
  TIME_LOG_TYPE_WORK_LOG,
} from "../../../../helpers/constants";
import moment from "moment";

const EmployeeTiming = ({
  selectedUser,
  addEditRecordMeta,
  setAddEditRecordMeta,
  onUpdateTiming,
}) => {
  const abortControllerRef = useRef(null);
  let { translate } = useLocalization();
  let { user } = useAuth();
  // date for week calendar
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [activeWeekData, setActiveWeekData] = useState([]);
  const [activeCalendarView, setActiveCalendarView] = useState("week");
  const [toFetchUserRecordInfo, setToFetchUserRecordInfo] = useState(
    getCurrentWeekStartAndEnd()
  );
  const [deleteRecordMeta, setDeleteRecordMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSlotSelect = ({ slot }) => {
    let { start: from, end: to } = slot;

    if (activeCalendarView === "month") {
      to.setTime(to.getTime() - 1);
    }

    setAddEditRecordMeta({
      mode: "add",
      data: {
        from,
        to,
        type:
          activeCalendarView === "week"
            ? TIME_LOG_TYPE_WORK_LOG
            : TIME_LOG_TYPE_VACATION,
      },
    });
  };

  const handleRecordClick = (record) => {
    setAddEditRecordMeta({ mode: "update", data: record });
  };

  const onDeleteClick = (record) => {
    setDeleteRecordMeta({ recordToDelete: record });
  };

  const deleteRecord = async () => {
    try {
      setDeleteRecordMeta({ ...deleteRecordMeta, showProgress: true });
      let { recordToDelete } = deleteRecordMeta;

      const { response, error } = await userRecordServices.deleteUserTimeLog(
        recordToDelete
      );

      if (error) {
        toast.error(error);
        return;
      }
      if (response) {
        onUpdateTiming(selectedUser);
        toast.success("Record deleted succesfully");
        let remainingRecords = activeWeekData?.filter(
          (r) => r?._id !== response?.id
        );
        setActiveWeekData(remainingRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddEditRecordMeta(null);
      setDeleteRecordMeta(null);
    }
  };

  const fetchUserRecords = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    abortControllerRef.current = controller;

    setIsLoading(true);

    try {
      const reqBody = {
        filter: {
          users: toFetchUserRecordInfo?.users || [],
          from: toFetchUserRecordInfo?.startTime,
          to: toFetchUserRecordInfo?.endTime,
        },
      };

      const { response, error } = await userRecordServices.getUserTimeLogs(
        reqBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      if (error) {
        toast.error(error);
        return;
      }

      const { results, totalHoursThisMonth } = response;

      setActiveWeekData([...results]);
    } catch (e) {
      console.log(e);
    } finally {
      if (controller === abortControllerRef.current) {
        setIsLoading(false);
        abortControllerRef.current = null;
      }
    }
  };

  //for fetching the latest user records after the clock out
  useEffect(() => {
    if (!user?.activeTimeEntry) {
      fetchUserRecords();
    }
  }, [user?.activeTimeEntry]);

  const handleCalendarDateChange = (date) => {
    const { startTime, endTime } =
      activeCalendarView === "week"
        ? getWeekStartAndEnd(date)
        : getMonthStartAndEnd(date);

    setToFetchUserRecordInfo({
      startTime,
      endTime,
      users: [selectedUser],
    });
  };

  const handleAddUpdateUserRecord = async (data) => {
    try {
      let { mode } = addEditRecordMeta;
      setAddEditRecordMeta({ ...addEditRecordMeta, showProgress: true });
      let requestBody = {
        timeLogData: {
          ...data,
          ...(mode === "add" && isAdminOrManager(user?.role)
            ? { user: selectedUser }
            : {}),
        },
      };
      let { response, error } =
        mode === "add"
          ? await userRecordServices.createUserTimeLog(requestBody)
          : await userRecordServices.updateUserTimeLog(requestBody);
      if (error) {
        toast.error(error);
        setAddEditRecordMeta({ ...addEditRecordMeta, showProgress: false });
        return;
      }
      if (response) {
        onUpdateTiming(selectedUser);
        setActiveWeekData(
          mode === "add"
            ? [...activeWeekData, response]
            : updateItemsInArray(activeWeekData, response)
        );
        setAddEditRecordMeta(null);
        toast.success(
          mode === "add" ? "Log added succesfully" : "Log updated succesfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecordApprove = async (data) => {
    try {
      let requestBody = {
        timeLogData: data,
      };
      let { response, error } = await userRecordServices.updateUserTimeLog(
        requestBody
      );
      if (error) {
        toast.error(error);

        return;
      }
      if (response) {
        onUpdateTiming(selectedUser);
        setActiveWeekData(updateItemsInArray(activeWeekData, response));
        toast.success(
          response?.approved ? "Log approved" : "Log status changed to pending"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useDebouncedEffect(
    () => {
      if (toFetchUserRecordInfo) {
        fetchUserRecords();
      }
    },
    [toFetchUserRecordInfo],
    200
  );

  useEffect(() => {
    setToFetchUserRecordInfo({
      ...toFetchUserRecordInfo,
      users: [selectedUser],
    });
  }, [selectedUser]);

  useEffect(() => {
    const { startTime, endTime } =
      activeCalendarView === "week"
        ? getWeekStartAndEnd(calendarDate)
        : getMonthStartAndEnd(calendarDate);

    setToFetchUserRecordInfo({
      startTime,
      endTime,
      users: [selectedUser],
    });
  }, [activeCalendarView]);

  return (
    <div className="">
      <TimingWeekCalendar
        calendarDate={calendarDate}
        setCalendarDate={setCalendarDate}
        customStep={60}
        initialLargeScreenView="week"
        initialSmallScreenView="week"
        calendarStyles={{
          height: `calc(100vh - 130px)`,
        }}
        allowEditing={true}
        events={activeWeekData?.map((d) => ({
          ...d,
          start: new Date(d?.from),
          end: new Date(d?.to),
        }))}
        onSlotSelect={handleSlotSelect}
        onDateChange={handleCalendarDateChange}
        onAppointmentClick={handleRecordClick}
        fetchingEvents={isLoading}
        onApproveClick={handleRecordApprove}
        onCalendarViewChange={(view) => setActiveCalendarView(view)}
      />
      <AddEditRecordModal
        show={addEditRecordMeta}
        onHide={() => setAddEditRecordMeta(null)}
        data={addEditRecordMeta?.data}
        editMode={addEditRecordMeta?.mode === "update"}
        onSubmit={handleAddUpdateUserRecord}
        showProgress={addEditRecordMeta?.showProgress}
        onDeleteClick={onDeleteClick}
      />
      <AlertModal
        show={deleteRecordMeta}
        onHide={() => setDeleteRecordMeta(null)}
        onContinueClick={deleteRecord}
        alertText={translate("are_you_sure_to_delete_this_record")}
        showProgress={deleteRecordMeta?.showProgress}
      />
    </div>
  );
};

export default EmployeeTiming;
