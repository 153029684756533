import React, { useEffect, useState } from "react";
import AppModal from "../../common/AppModal";
import { availabilityService } from "../../../services/availabilityService";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { mergeAppAndGoogleAppointments } from "../../../helpers/global";
import { CustomEvent } from "../../common/CustomEvent";
import { Button } from "react-bootstrap";
import { Check2Circle, Eye, PciCardNetwork } from "react-bootstrap-icons";
import HorizontalProgress from "../../common/HorizontalProgress";
import useLocalization from "../../../hooks/useLocalization";
import { useScreenWidth } from "../../../hooks/useScreenWidth";

const ContactAppointmentModal = ({
  show,
  onHide,
  contact,
  showProgress,
  onCreateNewAppointmentClick,
  onAddRecordToAppointmentClick,
  onViewAppointmentClick,
}) => {
  const { screenHeight } = useScreenWidth();
  const { translate } = useLocalization();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [fetchingAppointments, setFetchingAppointments] = useState(false);

  const fetchContactAppointments = async () => {
    setFetchingAppointments(true);
    const requestBody = {
      filter: { contact: contact?._id },
      returnGoogleEvents: false,
    };
    const { response, error } = await availabilityService.search(requestBody);
    let { results } = response;
    if (error) {
      toast.error(error);
    }
    setAppointments(mergeAppAndGoogleAppointments(results));
    setFetchingAppointments(false);
  };

  useEffect(() => {
    if (show) {
      fetchContactAppointments();
      setSelectedAppointment(null);
    }
  }, [show]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={`${contact?.firstName + " " + contact?.lastName}'s appointments`}
      modalBodyClassName="p-1"
    >
      {fetchingAppointments ? (
        <Loader />
      ) : (
        <div
          style={{
            maxHeight: screenHeight - 200,
            overflowY: "scroll",
          }}
          className="d-flex flex-column gap-2 p-1"
        >
          <h6 className="mb-0 smallFont fw-bold">Created appointments</h6>
          {appointments.length === 0 ? (
            <h6 className="mb-0 smallFont">No appointments</h6>
          ) : (
            appointments.map((app) => (
              <div key={app._id} className="d-flex align-items-center gap-2">
                <div className="flex-grow-1">
                  <CustomEvent event={app} showFullDate showLinkToVideo />{" "}
                </div>
                <Button
                  size="sm"
                  className=" d-flex align-items-center"
                  variant={
                    selectedAppointment?._id === app._id
                      ? "dark"
                      : "outline-dark"
                  }
                  onClick={() => setSelectedAppointment(app)}
                >
                  <Check2Circle />
                </Button>
                <Button
                  size="sm"
                  className=" d-flex align-items-center"
                  variant="outline-info"
                  onClick={() => onViewAppointmentClick(app)}
                >
                  <Eye />
                </Button>
              </div>
            ))
          )}
        </div>
      )}
      {showProgress && <HorizontalProgress text={translate("please_wait")} />}
      <div className="d-flex align-items-center justify-content-end gap-2 border-top mt-2 pt-1">
        <Button
          size="sm"
          variant="success text-white"
          onClick={onCreateNewAppointmentClick}
        >
          <h6 className="mb-0 smallFont">Create new</h6>
        </Button>{" "}
        <Button
          disabled={!Boolean(selectedAppointment)}
          size="sm"
          variant="primary text-white"
          onClick={() =>
            onAddRecordToAppointmentClick({
              appointment: selectedAppointment,
            })
          }
        >
          <h6 className="mb-0 smallFont">{translate("assign")}</h6>
        </Button>
      </div>
    </AppModal>
  );
};

export default ContactAppointmentModal;
