import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useLocalization from "../../hooks/useLocalization";
import { userService } from "../../services/userService";
import Logo from "../common/Logo";
import ConfirmPasswordForm from "../forms/ConfirmPasswordForm";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { translate } = useLocalization();
  const navigate = useNavigate();
  const { key } = useParams();
  const [findingUser, setFindUser] = useState(true);
  const [resetingPassword, setResetingPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);

  const findUser = async () => {
    try {
      setFindUser(true);
      const { response, error } = await userService.getUserByID(key);
      if (response) {
        setUser(response);
      }
      if (error) {
        setErrorMessage({
          type: "user",
          message: error,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFindUser(false);
    }
  };

  useEffect(() => {
    if (key) {
      findUser();
    }
  }, [key]);

  const createPassword = async ({ password }) => {
    try {
      setResetingPassword(true);
      const { response, error } = await userService.resetPassword({
        email: user?.email,
        resetPasswordKey: key,
        password,
      });
      if (response) {
        toast.success(
          "Password updated successfully. You can login using your new password"
        );
        navigate("/auth/login");
      }
      if (error) {
        setErrorMessage({
          type: "createPassword",
          message: error,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResetingPassword(false);
    }
  };

  return findingUser || errorMessage?.type === "user" ? (
    <div className="h-100 d-flex justify-content-center align-items-center">
      {findingUser ? (
        <Spinner />
      ) : (
        <Alert className="p-2 mt-3 mid" variant="danger">
          {errorMessage?.message}
        </Alert>
      )}
    </div>
  ) : (
    <Row className="justify-content-center h-100">
      <Col xs={10} md={6} className="align-self-center">
        <Card className="">
          <Card.Header className="p-3 bg-dark">
            <div className="">
              <Logo />
            </div>
          </Card.Header>
          <Card.Body>
            <h6 className="text-muted mx-2 my-1">
              {translate("hello")} <strong>{user?.name}, </strong>
              {translate("welcome_to_ETICA_crm")}
            </h6>
            <h6 className="text-muted mx-2 my-1">
              <b className="text-dark">
                {translate("please_reset_your_password")}
              </b>
            </h6>
            <hr />
            <ConfirmPasswordForm
              loading={resetingPassword}
              errorMessage={
                errorMessage?.type === "createPassword"
                  ? errorMessage?.message
                  : null
              }
              onSubmit={createPassword}
            />
            <Link className="text-dark" to={"/auth/login"}>
              {translate("already_have_an_account")}
            </Link>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;
