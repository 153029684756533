import React from "react";
import useAppChoices from "../../../hooks/useAppChoices";
import UserFilter from "./UserFilter";
import { FormCheck, FormControl, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import { isAdminOrManager } from "../../../helpers/session";
import useAuth from "../../../hooks/useAuth";
import AppointmentTypeFilter from "./AppointmentTypeFilter";

const AppointmentSidebar = ({
  mobileViewCalendar,
  collapseSidebar,
  className,
  checkedParticipants,
  setCheckedParticipants,
  disabled = false,
  searchQuery,
  setSearchQuery,
  hideHolidayCheckbox,
  openedFilters,
  setOpenedFilters,
  appointmentTypeFilter,
  setAppointmentTypeFilter,
}) => {
  const users = useAppChoices("users");
  const { isRTL } = useLocalization();
  const { user } = useAuth();
  return (
    <div className={className}>
      <div className="d-flex gap-2 align-items-center p-1 border-bottom">
        <InputGroup size="sm" className="">
          {isRTL && (
            <InputGroup.Text>
              <Search size={12} />
            </InputGroup.Text>
          )}
          <FormControl
            size="sm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter event title or description.."
          />
          {!isRTL && (
            <InputGroup.Text>
              <Search size={12} />
            </InputGroup.Text>
          )}
        </InputGroup>
        {mobileViewCalendar && collapseSidebar()}
      </div>{" "}
      <AppointmentTypeFilter
        openedFilters={openedFilters}
        setOpenedFilters={setOpenedFilters}
        appointmentTypeFilter={appointmentTypeFilter}
        setAppointmentTypeFilter={setAppointmentTypeFilter}
      />
      <UserFilter
        users={users}
        onUserChange={setCheckedParticipants}
        selectedUsers={checkedParticipants}
        disabled={disabled}
        disableDnD={!isAdminOrManager(user?.role)}
        openedFilters={openedFilters}
        setOpenedFilters={setOpenedFilters}
      />
    </div>
  );
};

export default AppointmentSidebar;
