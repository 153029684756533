import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import {
  Button,
  Col,
  FormCheck,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import CustomMultiSelect from "../common/CustomMultiSelect";
import { CAMPAIGN_TRIGGER_OPTIONS } from "../../helpers/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../helpers/constants";
import WhatsappTemplateDropdownOption from "../common/WhatsappTemplateDropdownOption";

const validationSchema = (salespersonMode) =>
  Yup.object().shape({
    ...(!salespersonMode
      ? { template: Yup.string().required("Please provide template") }
      : {}),
    trigger: Yup.string().required("Trigger is required"),
    dateTime: Yup.date()
      .nullable()
      .test(
        "is-required-when-date_time",
        "DateTime is required for 'date_time' trigger",
        function (value) {
          const { trigger } = this.parent;
          if (trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME) {
            return Boolean(value); // Only valid if dateTime is provided when trigger is 'date_time'
          }
          return true; // If trigger is not 'date_time', no need to validate dateTime
        }
      )
      .test(
        "is-not-in-past",
        "DateTime cannot be in the past",
        function (value) {
          const { trigger } = this.parent;
          if (trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME && value) {
            return new Date(value) >= new Date(); // Ensure dateTime is not in the past
          }
          return true; // If trigger is not 'date_time', no need to validate for past dates
        }
      ),
  });

const defaultValues = {
  template: "",
  trigger: CAMPAIGN_TRIGGER_OPTIONS.SEND_NOW,
  dateTime: null,
};

const SendWhatsappCampaignForm = ({
  initialValues = defaultValues,
  showProgress,
  onSubmit,
  whatsappTemplates,
  submitButtonText,
  salespersonMode = false,
}) => {
  const { translate, isRTL } = useLocalization();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => validationSchema(salespersonMode)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form noValidate className="">
            <Row className="m-0">
              {!salespersonMode && (
                <Col xs={12} md={12}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      Whatsapp template:{" "}
                    </FormLabel>
                    <CustomMultiSelect
                      items={whatsappTemplates.map((t) => ({
                        label: t.templateName,
                        value: t._id,
                      }))}
                      closeMenuOnSelect
                      isMulti={false}
                      selectedItems={values?.template || ""}
                      onChange={(template) =>
                        setFieldValue("template", template)
                      }
                      CustomSingleValueComponent={(props) => (
                        <WhatsappTemplateDropdownOption
                          {...props}
                          whatsappTemplates={whatsappTemplates}
                        />
                      )}
                      CustomOption={(props) => (
                        <WhatsappTemplateDropdownOption
                          {...props}
                          isOption
                          whatsappTemplates={whatsappTemplates}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="template"
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-2">Trigger: </FormLabel>
                  <div className="d-flex align-items-center gap-4 form-control form-control-sm">
                    <FormCheck
                      type="radio"
                      className="large mb-0"
                      label={"Send Now"}
                      checked={values.trigger === "send_now"}
                      onChange={() => {
                        setFieldValue(
                          "trigger",
                          CAMPAIGN_TRIGGER_OPTIONS.SEND_NOW
                        );
                        setFieldValue("dateTime", null);
                      }}
                    />
                    <FormCheck
                      type="radio"
                      className="large mb-0"
                      label={"Schedule Campaign"}
                      checked={values.trigger === "date_time"}
                      onChange={() =>
                        setFieldValue(
                          "trigger",
                          CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME
                        )
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="trigger"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              {values.trigger === "date_time" && (
                <Col xs={12} md={12}>
                  <FormGroup className="mb-0">
                    <FormLabel className="mid mb-1">
                      Campaign Date time:{" "}
                    </FormLabel>
                    <DatePicker
                      wrapperClassName="w-100"
                      className={`${
                        isRTL ? "px-4" : ""
                      }   mb-0 text-dark px-2 py-1 form-control form-control-sm `}
                      selected={
                        values?.dateTime ? new Date(values?.dateTime) : null
                      }
                      dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                      onChange={(e) => {
                        setFieldValue("dateTime", e ? e.toISOString() : null);
                      }}
                      openToDate={new Date()} // Opens the datepicker to the current month and year
                      showYearDropdown
                      showMonthDropdown
                      showTimeInput
                      dropdownMode="scroll"
                      timeFormat="HH:mm" // 24-hour format
                      popperClassName="datepickerPopperClassName"
                      clearButtonClassName="text-dark"
                      portalId="root"
                    />
                    <ErrorMessage
                      name="dateTime"
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end border-top mt-2">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                <h6 className="smallFont mb-0">
                  {" "}
                  {submitButtonText || translate("send")}
                </h6>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SendWhatsappCampaignForm;
