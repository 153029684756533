import React, { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./bar.scss";
const SlidingSideBar = ({
  containerRef,
  children,
  visible = false,
  title = "",
  showCloseButton = true,
  onClose,
  fullScreen,
  style,
  paddingHorizontal,
  paddingVertical,
  className = "",
}) => {
  const Header = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <h5 className="flex-grow-1 large mb-0">
            <b>{title} </b>
          </h5>

          {showCloseButton && (
            <Button
              size="sm"
              className="py-0 px-1"
              onClick={onClose}
              variant="dark"
            >
              Close <X size={20} className="align-text-top" />
            </Button>
          )}
        </div>
        <hr className="my-2" />
      </>
    );
  };

  //to prevent scrolling of content underneath, when sidebar is visible
  useEffect(() => {
    if (visible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup to ensure we remove the class when component is unmounted or if the sidebar is destroyed/hidden for some other reason
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [visible]);

  const hasPadding = useMemo(
    () => paddingHorizontal || paddingVertical,
    [paddingHorizontal, paddingVertical]
  );

  return (
    <div
      style={{ ...style }}
      onClick={(e) => {
        onClose();
      }}
      className={`bar-container ${
        visible ? "bar-visible" : "bar-collapsed"
      }  ${className}`}
    >
      <div
        className={`bar-content${fullScreen ? "-fullscreen" : ""}`}
        style={{
          paddingLeft: paddingHorizontal,
          paddingRight: paddingHorizontal,
          paddingTop: paddingVertical,
          paddingBottom: paddingVertical,
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`bar-children d-flex flex-column ${
            hasPadding ? "rounded" : ""
          }`}
        >
          {(title || showCloseButton) && <Header />}
          <div id="bar-body" className="flex-grow-1" ref={containerRef}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlidingSideBar;
