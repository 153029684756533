import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const tagsServices = {
  createTag: async (tag, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_TAG,
      requestBody: tag,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  getTags: async () => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_TAGS,
      method: "GET",
    });

    return { response, error };
  },
  deleteTag: async (tag, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_TAG,
      requestBody: tag,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updateTag: async (tag, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.RENAME_TAG,
      requestBody: tag,
      method: "POST",
      signal,
    });

    return { response, error };
  },
};
