import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FormControl } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";

const AutoCompleteSearch = ({
  query,
  onQueryChange,
  onSearchClick,
  disabled,
  recommendations = [],
  setRecommendations,
  onRecommendationClick,
  showSearchButton,
}) => {
  const { translate } = useLocalization();

  const handleReturn = (e) => {
    if (
      !disabled &&
      !recommendations[selectedRecommendationIndex] &&
      e.key === "Enter"
    ) {
      onSearchClick && onSearchClick();
    }
  };

  const recommendationCardRef = useRef(null);
  const searchTextInputRef = useRef(null);

  const [selectedRecommendationIndex, setSelectedRecommendationIndex] =
    useState(-1);

  const handleRecommendationsNavigation = (e) => {
    if (recommendations.length === 0) return;

    if (e.key === "ArrowUp") {
      setSelectedRecommendationIndex((prevIndex) =>
        prevIndex <= 0 ? recommendations.length - 1 : prevIndex - 1
      );
    }

    if (e.key === "ArrowDown") {
      setSelectedRecommendationIndex(
        (prevIndex) => (prevIndex + 1) % recommendations.length
      );
    }

    if (e.key === "Enter" && recommendations[selectedRecommendationIndex]) {
      onRecommendationClick &&
        onRecommendationClick(recommendations[selectedRecommendationIndex]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        [recommendationCardRef, searchTextInputRef].some(
          (ref) => !ref.current || ref.current.contains(event.target)
        )
      ) {
        return;
      }
      setRecommendations && setRecommendations([]);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setRecommendations]);

  useEffect(() => {
    document.addEventListener("keyup", handleRecommendationsNavigation);

    return () => {
      document.removeEventListener("keyup", handleRecommendationsNavigation);
    };
  }, [recommendations, selectedRecommendationIndex]);

  return (
    <>
      <div className="input-group">
        <FormControl
          ref={searchTextInputRef}
          placeholder={translate("leave_empty_to_view_results")}
          style={{ minWidth: "220px", fontSize: 12 }}
          onKeyUp={handleReturn}
          value={query}
          onChange={(e) => onQueryChange(e.target.value)}
          size="sm"
          onClick={(e) => {
            onQueryChange(query);
          }}
        />
        {showSearchButton && (
          <div className="input-group-append">
            <Button
              size="sm"
              variant="dark"
              style={{ zIndex: 1 }}
              disabled={disabled}
              onClick={onSearchClick}
            >
              <Search className="align-text-middle mx-1" size={13} />
              {/* <span className="d-none d-md-inline">{translate("search")}</span> */}
            </Button>
          </div>
        )}
      </div>
      {recommendations?.length > 0 && (
        <Card
          ref={recommendationCardRef}
          style={{ zIndex: 9999 }}
          className="position-absolute border bg-light w-50"
        >
          {recommendations.map((rec, index) => (
            <div
              key={index}
              className={`d-flex align-items-center px-2 py-1 large mb-0 hover-light ${
                index === selectedRecommendationIndex ? "bg-primary-light " : ""
              }`}
              onClick={() =>
                onRecommendationClick && onRecommendationClick(rec)
              }
            >
              <Search size={11} />
              <b className="mx-2">{rec}</b>
            </div>
          ))}
        </Card>
      )}
    </>
  );
};

export default AutoCompleteSearch;
